import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLoggedInUserThunk,
  getCurrentLoggedInUserThunk,
} from "src/slices/shared/user/user-thunks";
import {
  UserResponse,
  UserStore,
  CurrentUserResponse,
} from "src/slices/shared/user/user-interface";
import { initialState } from "src/slices/shared/user";
import { storeValueInStorage } from "src/utils/storage";
import {
  LOCALSTORAGE_IDP_USER_FLAG,
  LOCALSTORAGE_USER_TOKEN_KEY,
} from "src/constants/auth-constants";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.jwt = payload;
    },
    clearToken: (state) => {
      state.jwt = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getLoggedInUserThunk.fulfilled,
      (state: UserStore, { payload }: PayloadAction<UserResponse>) => {
        state.isLoading = false;
        state.jwt = payload.data.token;
        storeValueInStorage(LOCALSTORAGE_IDP_USER_FLAG, `${payload.isIDPToken}`);
        storeValueInStorage(LOCALSTORAGE_USER_TOKEN_KEY, payload.data.token);
      }
    );
    builder.addCase(getLoggedInUserThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoggedInUserThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      getCurrentLoggedInUserThunk.fulfilled,
      (state: UserStore, { payload }: PayloadAction<CurrentUserResponse>) => {
        state.isLoading = false;
        const { first_name, last_name } = payload.data;
        payload.data.first_name = getConvertedUpperCaseString(first_name);
        payload.data.last_name = getConvertedUpperCaseString(last_name);
        state.loggedInUser = payload.data;
      }
    );
    builder.addCase(getCurrentLoggedInUserThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCurrentLoggedInUserThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
