import React from "react";
import { DataGridPro, GridSortItem, GridSortModel } from "@mui/x-data-grid-pro";
import { dataGridStyles } from "src/constants/datagrid-styles";
import SortingIcon from "src/shared/icons/sorting-icon";
import { GridColDef } from "@mui/x-data-grid-pro";
import Pagination from "src/shared/pagination";
import { Box } from "@mui/material";
import { Facility } from "src/interfaces/groups.interface";
import { FacilityUser } from "src/interfaces/user-impersonation.interface";

interface TableProps {
  disableColumnMenu?: boolean;
  disableVirtualization?: boolean;
  handleSortModelChange?: (incomingModel: GridSortModel) => void;
  sortModel?: GridSortItem[];
  //DIfferent types for table row types
  rows: Array<Facility | FacilityUser>;
  extraStyles?: { [key: string]: string | number };
  columns: GridColDef[];
  isDisableRowSelection?: boolean;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowId?: (row: any) => string | number;
  showPagination?: boolean;
  dataSetSize?: number;
  currentPage?: number;
  handlePagination?: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export default function DataGridProTable({
  disableColumnMenu,
  disableVirtualization,
  handleSortModelChange,
  sortModel,
  rows,
  columns,
  extraStyles,
  isLoading,
  rowId,
  showPagination,
  dataSetSize,
  currentPage,
  handlePagination,
}: TableProps) {
  return (
    <>
      <Box sx={{ backgroundColor: "white", width: "100%" }}>
        <DataGridPro
          disableColumnMenu={disableColumnMenu || true}
          disableVirtualization={disableVirtualization}
          autoHeight
          onSortModelChange={handleSortModelChange}
          sortModel={sortModel}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick={true}
          hideFooterRowCount
          sx={dataGridStyles}
          style={extraStyles}
          loading={isLoading}
          getRowId={rowId}
          slots={{
            columnSortedAscendingIcon: SortingIcon,
            columnSortedDescendingIcon: SortingIcon,
          }}
        />
      </Box>
      {showPagination ? null : (
        <Pagination
          handleChange={handlePagination}
          dataSize={dataSetSize}
          pageNumber={currentPage}
        />
      )}
    </>
  );
}
