import styled from "styled-components";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
export const GroupManagementDiv = styled.div``;
export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
`;

export const GroupButtonDiv = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 1rem;
`;

export const AddGroupDiv = styled(Button)`
  text-transform: none;
  background: #034a93;
  font-weight: bold;
  padding: 10px 22px;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

export const AddGroupIcon = styled(AddIcon)``;
export const SearchIconDiv = styled(Box)`
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  background: #034a93;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EditUserIconDiv = styled.div`
  margin-right: 1rem;
  cursor: pointer;
`;
export const DeleteUserIconDiv = styled.div`
  margin-right: 1rem;
  cursor: pointer;
`;
export const ActionDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 1rem;
`;
