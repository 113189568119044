import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SideBarDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  height: 100%;
  border: 1px solid #f6f6f6;
  box-shadow: 94px 100px 55px rgba(80, 78, 78, 0.01), 53px 57px 47px rgba(80, 78, 78, 0.02),
    24px 25px 34px rgba(80, 78, 78, 0.03), 6px 6px 19px rgba(80, 78, 78, 0.04),
    0px 0px 0px rgba(80, 78, 78, 0.04);
  border-radius: 0px 0px 12px 0px;
`;

export const NavLink = styled(Link)<{ color: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0.8rem;
  height: 3rem;
  background: ${(props) => props.color};
  border-radius: 12px;
  margin-bottom: 1rem;
  text-decoration: none;
`;

export const NavText = styled(Box)<{ color: string }>`
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: ${(props) => props.color};
  margin-left: 0.5rem;
`;

export const IconDiv = styled.div`
  margin-top: 0.4rem;
`;

export const LogoutDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 1rem;
  text-decoration: none;
  padding: 8px 16px;
  width: 200px;
  height: 40px;
  cursor: pointer;
`;
