import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledImpersonateUserContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: Column;
`;

export const StyledSelectUser = styled(Box)`
  flex: 1;
  position: relative;
  background-color: yellow;
  border: 1px solid #ccc;
  flex: 1;
  padding: 0.4rem 1rem;
`;
