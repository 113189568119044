import { TextField, InputAdornment } from "@mui/material";
import React from "react";
import SearchIcon from "src/shared/icons/search-icon";
import { SearchIconDiv } from "src/shared/ui/search-bar/styles";

interface Props {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleFilterList: () => void;
  placeholderText?: string;
  styles?: {};
  searchValue?: string;
}

export default function SearchBar({
  handleSearch,
  handleKeyPress,
  handleFilterList,
  placeholderText,
  styles,
  searchValue,
}: Props) {
  return (
    <TextField
      id="search"
      className="text"
      onChange={handleSearch}
      onKeyPress={handleKeyPress}
      variant="outlined"
      value={searchValue}
      placeholder={placeholderText ? placeholderText : "Search"}
      size="small"
      sx={{
        background: "white",
        width: "100%",
        borderRadius: "6px",
        ...styles,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIconDiv data-testid="searchIcon" onClick={handleFilterList}>
              <SearchIcon />
            </SearchIconDiv>
          </InputAdornment>
        ),
      }}
      inputProps={{ "data-testid": "content-input" }}
    />
  );
}
