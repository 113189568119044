import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRange } from "@mui/lab";
import { Box } from "@mui/material";
import moment from "moment";
import { StyledBox, StyledKeyboardArrowDownIcon } from "src/shared/calendar/styles";

interface Props {
  handleDate: (from: string, to: string) => void;
  selectedDate: string;
  isDisableFuture?: boolean;
  placeholderText: string;
  handleClose?: () => void;
}

export default function SingleInputDateRangePicker({
  handleDate,
  selectedDate,
  isDisableFuture,
  placeholderText,
  handleClose,
}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [value, setValue] = useState<DateRange<any>>();

  const [open, toggleOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRange = (value?: DateRange<any>) => {
    setValue(value);
    const start = value && value[0] && value[0].$d;
    const end = value && value[1] && value[1].$d;
    handleDate(
      start
        ? moment(start as Date)
            .startOf("day")
            .toISOString()
        : "",
      end
        ? moment(end as Date)
            .endOf("day")
            .toISOString()
        : ""
    );
  };
  useEffect(() => {
    if (selectedDate === "") {
      setValue([null, null]);
    }
  }, [selectedDate]);

  return (
    <Box sx={{ display: "flex", gap: "10px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          open={open}
          value={value}
          onClose={() => toggleOpen(false)}
          dayOfWeekFormatter={(day) => day.slice(0, 3)}
          disableFuture={isDisableFuture}
          sx={{
            ".MuiInputBase-input": {
              padding: "8px 14px !important",
              width: "12rem",
              border: "1px solid #034A93",
              borderRadius: "6px",
              textAlign: "center",
              opacity: 1,
            },
          }}
          data-testid="close-calendar"
          onChange={handleRange}
          slotProps={{
            textField: {
              InputProps: {
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                "data-testid": "select-date",
                placeholder: placeholderText ? placeholderText : "",
                sx: {
                  "input::placeholder": {
                    opacity: 1,
                    color: "#000A14 !important",
                    fontWeight: 400,
                    fontSize: "16px",
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000A14",
                    cursor: "pointer",
                  },
                },
                onClick: () => {
                  if (handleClose) handleClose();
                  toggleOpen(!open);
                },
              },
              value: value,
              disabled: true,
            },
          }}
          slots={{
            field: SingleInputDateRangeField,
          }}
        />
      </LocalizationProvider>
      <StyledBox
        onClick={() => {
          toggleOpen(!open);
        }}
        data-testid="open-calendar"
      >
        <StyledKeyboardArrowDownIcon />
      </StyledBox>
    </Box>
  );
}
