import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import {
  DialogHeader,
  DialogContent,
  DialogSearch,
  DialogRow,
  DialogActionDiv,
  AddDiv,
  CancelDiv,
} from "src/pages/opie-forms-management/groups/components/add-members/styles";

import Checkbox from "@mui/material/Checkbox";
import { Facility } from "src/interfaces/groups.interface";
import SearchBar from "src/shared/ui/search-bar";
import ChipContainer from "src/shared/ui/chip";
import { searchFacilityFromGroupThunk } from "src/slices/opie-forms-management/groups/groups-thunk";
import { AppDispatch } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { currentGroup, groupSelector } from "src/slices/opie-forms-management/groups";
import {
  mapFacilitiesWithCheckedKey,
  filterCheckedFacilitiesFromList,
} from "src/pages/opie-forms-management/groups/utils/methods";
import Loader from "src/shared/loader";
import CustomDialog from "src/shared/modal";

interface Props {
  open: boolean;
  handleOpen: (open: boolean) => void;
  filteredFacilities: Facility[];
  handleFacilityList: (facility: Facility[]) => Promise<boolean>;
  isLoading: boolean;
}

function AddMembersDialog({
  open,
  handleOpen,
  filteredFacilities,
  handleFacilityList,
  isLoading,
}: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const selectedGroup = useSelector(currentGroup);
  const groupStore = useSelector(groupSelector);
  const [searchValue, setSearchValue] = useState<string>("");
  const [newFacilities, setNewFacilities] = useState<Facility[]>([]);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [originalFilteredFacilities, setOriginalFilteredFacilities] = useState<Facility[]>([]);

  useEffect(() => {
    setFacilities(filteredFacilities);
    setOriginalFilteredFacilities(filteredFacilities);
    setNewFacilities([]);
  }, [filteredFacilities]);

  const handleAddMemberBox = (e: React.ChangeEvent<HTMLInputElement>, data: Facility) => {
    setFacilities([
      ...facilities.map((member) => {
        if (member.id == data.id) {
          member.isChecked = !member.isChecked;
        }
        return member;
      }),
    ]);
    if (e.target.checked) {
      setNewFacilities([...newFacilities, data]);
    } else {
      setNewFacilities(newFacilities.filter((member: Facility) => member.id != data.id));
    }
    return;
  };

  const handleRemoveMember = (facility: Facility) => {
    const { id } = facility;
    setFacilities([
      ...facilities.map((member) => {
        if (member.id == id) {
          member.isChecked = !member.isChecked;
        }
        return member;
      }),
    ]);
    setNewFacilities(newFacilities.filter((member: Facility) => member.id != id));
  };

  const searchFilteredFacility = (list: Facility[], selectedGroupFacilities: Facility[]) => {
    const data = selectedGroupFacilities || [];
    const filteredList = list.filter((facility) => {
      const matchingFacilities = data.some(
        (existingFacility: Facility) => existingFacility.id === facility.id
      );
      return !matchingFacilities;
    });
    return filteredList;
  };

  const handleFilterList = () => {
    if (searchValue.length > 0) {
      handleSearchFacilities();
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value === "") {
      resetFacilitiesList();
    }
  };

  useEffect(() => {
    if (searchValue) {
      if (groupStore.searchFacilities.length > 0) {
        const list = mapFacilitiesWithCheckedKey(groupStore.searchFacilities);
        const filteredList = searchFilteredFacility(list, selectedGroup.facilities);
        const mappedExistingFilters = filterCheckedFacilitiesFromList(filteredList, newFacilities);
        if (mappedExistingFilters.length > 0) {
          setFacilities(mappedExistingFilters);
        } else {
          setFacilities(filteredList);
        }
      } else {
        setFacilities([]);
      }
    }
    // eslint-disable-next-line
  }, [groupStore.searchFacilities]);

  const handleSearchFacilities = () => {
    dispatch(searchFacilityFromGroupThunk(searchValue));
  };

  const resetFacilitiesList = () => {
    //add isChecked to facilities
    const list = mapFacilitiesWithCheckedKey(originalFilteredFacilities);
    const filteredList = searchFilteredFacility(list, selectedGroup.facilities);
    const mappedExistingFilters = filterCheckedFacilitiesFromList(filteredList, newFacilities);
    if (mappedExistingFilters.length > 0) {
      setFacilities(mappedExistingFilters);
    } else {
      setFacilities(filteredList);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === "Enter" && searchValue) {
      handleSearchFacilities();
    }
  };

  const clearData = () => {
    setFacilities([]);
    setNewFacilities([]);
  };
  return (
    <CustomDialog open={open} handleOpen={handleOpen} maxWidth={"md"}>
      <Box sx={{ padding: { xs: "0 1rem", sm: "0 5rem" } }}>
        <DialogHeader>Add Facilities</DialogHeader>
        <DialogSearch>
          <SearchBar
            handleSearch={handleSearch}
            handleKeyPress={handleKeyPress}
            handleFilterList={handleFilterList}
            placeholderText="Search for Facility Name or ID"
          />
        </DialogSearch>
        <ChipContainer facilities={newFacilities} handleRemoveMember={handleRemoveMember} />
        <DialogContent>
          {facilities.length > 0 && !groupStore.isSearchLoading ? (
            facilities.map((member: Facility, index: number) => {
              return (
                <DialogRow
                  sx={{
                    gridTemplateColumns: { xs: "40% 60%", sm: "30% 70%" },
                    padding: "0.5rem",
                  }}
                  key={index}
                  style={
                    index == facilities.length - 1
                      ? { borderBottom: "none" }
                      : { borderBottom: "1px solid #d1d3d4" }
                  }
                >
                  <Box
                    sx={{
                      fontSize: { xs: "12px", sm: "20px" },
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                      checked={member.isChecked}
                      data-testid="checkbox"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleAddMemberBox(e, member)
                      }
                    />
                    <Typography
                      sx={{ fontSize: { xs: "12px", sm: "18px" } }}
                      variant="h6"
                      component="h6"
                    >
                      {member.name}
                    </Typography>
                  </Box>
                  <Box sx={{ fontSize: { xs: "12px", sm: "18px" } }}>ID : {member.id}</Box>
                </DialogRow>
              );
            })
          ) : groupStore.isSearchLoading ? (
            <Loader />
          ) : (
            <Typography
              sx={{ fontSize: { xs: "12px", sm: "18px" }, padding: "1rem", textAlign: "center" }}
              variant="h6"
              component="h6"
            >
              No Facilities Found!
            </Typography>
          )}
        </DialogContent>
        <DialogActionDiv>
          <AddDiv
            data-testid="add-facility"
            variant="contained"
            disabled={newFacilities.length > 0 ? false : true}
            loading={isLoading}
            onClick={async () => {
              const status = await handleFacilityList(newFacilities);
              if (!status) {
                handleOpen(false);
              }
            }}
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              height: "3rem",
            }}
          >
            ADD
          </AddDiv>
          <CancelDiv
            data-testid="cancel"
            variant="outlined"
            onClick={() => {
              clearData();
              handleOpen(false);
            }}
            sx={{
              fontSize: { sm: "14px", md: "16px" },
              height: "3rem",
            }}
          >
            CANCEL
          </CancelDiv>
        </DialogActionDiv>
      </Box>
    </CustomDialog>
  );
}

export default AddMembersDialog;
