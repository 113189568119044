import {
  checkDigit,
  checkLowerCase,
  checkMinimumLength,
  checkSpecialChar,
  checkUpperCase,
} from "src/utils/regex-functions";

export const errorConstants = [
  { id: 1, text: "one uppercase character", status: false, func: checkUpperCase },
  { id: 2, text: "1 special character", status: false, func: checkSpecialChar },
  { id: 3, text: "one lowercase character", status: false, func: checkLowerCase },
  {
    id: 4,
    text: "8 characters minimum",
    status: false,
    func: checkMinimumLength,
  },
  { id: 5, text: "one number", status: false, func: checkDigit },
];
