import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";

interface SwitchExtendedProps extends SwitchProps {
  switchcolor: string;
}

const SwitchIcon = styled((props: SwitchExtendedProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    checked={props.checked}
  />
))(({ theme, checked, switchcolor }) => ({
  width: 32,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" || !checked ? "#2ECA45" : switchcolor,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode !== "light" || checked
          ? theme.palette.grey[600]
          : theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode !== "light" || checked ? 0.3 : 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "dark" || checked ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface Props {
  checked: boolean;
  handleFunction: (id: string, status: boolean) => void;
  rowData?: { id: string; is_active: boolean };
  switchcolor?: string;
  disabled?: boolean;
}

export default function CustomizedSwitches({
  checked,
  handleFunction,
  rowData,
  switchcolor,
  disabled,
}: Props) {
  return (
    <FormControlLabel
      control={
        <SwitchIcon
          onClick={() => handleFunction(rowData?.id as string, rowData?.is_active as boolean)}
          sx={{ m: 1 }}
          checked={checked}
          type={checked ? "dark" : "light"}
          data-testid="update-status"
          switchcolor={switchcolor ? switchcolor : "#034A93"}
          disabled={disabled}
        />
      }
      label=""
    />
  );
}
