import styled from "styled-components";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border: 1px solid #034a93;
  border-radius: 6px;
  cursor: pointer;
`;

export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  color: #034a93;
`;
