import { Box } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LayoutFilters, ModalFilterType } from "src/interfaces/groups.interface";
import RangeCalendar from "src/shared/calendar";
import {
  AddDiv,
  CancelDiv,
  DialogActionDiv,
  DialogSearch,
  StyledBody,
  StyledButton,
  StyledClearButton,
  StyledDiv,
  StyledFilterForm,
  StyledFilterHeader,
  StyledFilterNameHeading,
  StyledFilterSidebar,
  StyledFormList,
} from "src/shared/layout-modal/styles";
import { getConvertedSentenceToUpperCase } from "src/utils/convert-strings-case";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { DialogHeader } from "src/pages/opie-forms-management/groups/components/add-members/styles";
import { AppDispatch } from "src/redux/store";
import FilterSidebar from "src/shared/layout-modal/components/sidebar";
import Loader from "src/shared/loader";
import CustomDialog from "src/shared/modal";
import MultiSelect from "src/shared/ui/multi-select";
import SearchBar from "src/shared/ui/search-bar";
import { groupSelector } from "src/slices/opie-forms-management/groups";
import {
  getAssignedLayoutsThunk,
  updateGroupLayoutThunk,
} from "src/slices/opie-forms-management/groups/groups-thunk";
import { currentSelectedUserSelector } from "src/slices/opie-forms-management/user-management/user-management-selector";
import {
  getUserLayoutThunk,
  updateUserLayoutThunk,
} from "src/slices/opie-forms-management/user-management/user-management-thunks";
import { getLabelValuePair, renderValues } from "src/utils/layout-method";
import LayoutTitle from "src/shared/layout-modal/layout-title";
import { layoutInitialFilterValues } from "src/constants/filter-constants";
import {
  opieLayoutsSelector,
  opieLayoutsLoaderSelector,
} from "src/slices/opie-forms-management/layouts";
import {
  associatedLayoutTypes,
  visitedLayoutTypes,
  sharedLayoutsStoreSelector,
} from "src/slices/shared/layouts";
import { getLayoutsThunk } from "src/slices/opie-forms-management/layouts/layouts-thunks";
import { Layout, OpieLayout } from "src/interfaces/layouts.interface";
import { LayoutSearchParams } from "src/interfaces/layouts.interface";
import { formCategoriesSelector } from "src/slices/shared/layouts/layouts-selector";

interface Props {
  open: boolean;
  handleOpen: (open: boolean) => void;
  modalName: string;
  type: "user" | "groups";
  filters: ModalFilterType;
  assignedLayouts: Layout[];
}

function LayoutModal({ open, handleOpen, modalName, type, filters, assignedLayouts }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(currentSelectedUserSelector);
  const groupData = useSelector(groupSelector);
  const allLayouts = useSelector(opieLayoutsSelector);
  const layoutsData = useSelector(sharedLayoutsStoreSelector);
  const layoutsLoader = useSelector(opieLayoutsLoaderSelector);
  const [layouts, setLayouts] = useState<Layout[]>(allLayouts);
  const [filterValues, setFilterValues] = useState<LayoutFilters>(layoutInitialFilterValues);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedLayouts, setSelectedLayouts] = useState(assignedLayouts);
  const associatedLayoutData = useSelector(associatedLayoutTypes);
  const visitedLayoutData = useSelector(visitedLayoutTypes);
  const formCategoriesData = useSelector(formCategoriesSelector);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isSideFilterApplied = React.useRef(false);
  const isTopFilterApplied = React.useRef(false);
  const isPublic = useMemo(() => {
    return type === "user" ? false : true;
  }, [type]);

  const computeLayouts = useCallback(() => {
    let layoutData = [...allLayouts] as OpieLayout[];
    for (let i = 0; i < assignedLayouts.length; i++) {
      const item = assignedLayouts[i];
      const index = layoutData.findIndex((l) => l.id === item.id);
      if (index > -1) {
        const assignedLayout = layoutData.splice(index, 1);
        layoutData = [...assignedLayout, ...layoutData];
      }
    }
    setLayouts(layoutData);
    setIsLoading(false);
  }, [allLayouts, assignedLayouts]);

  useEffect(() => {
    computeLayouts();
  }, [allLayouts, assignedLayouts, computeLayouts]);

  type HandlerFunction = () => void;

  const clearAllFiltersData = () => {
    setSearchValue("");
    setFilterValues(layoutInitialFilterValues);
  };

  const handleVisitAssociateClear = () => {
    setFilterValues({ ...filterValues, associatedType: [], visitType: [], formCategories: [] });
    if (!isSideFilterApplied.current) return;

    let params: LayoutSearchParams = {
      visitType: [],
      associatedType: [],
      deviceType: filterValues.deviceType,
      associatedTrack: filterValues.associatedTrack,
      formCategories: [],
    };
    if (filterValues.fromDate.length > 0 && filterValues.toDate.length > 0) {
      params = {
        ...params,
        fromDate: filterValues.fromDate,
        toDate: filterValues.toDate,
      };
    }
    if (type === "user") {
      params.public = isPublic;
    }
    dispatch(getLayoutsThunk(params));
    isSideFilterApplied.current = false;
  };

  const handleClear = (
    e: React.MouseEvent<HTMLButtonElement | SVGSVGElement | HTMLDivElement, MouseEvent>
  ) => {
    clearAllFiltersData();

    if (!isTopFilterApplied.current && !isSideFilterApplied.current) return;
    e.stopPropagation();

    const params: LayoutSearchParams = {};
    if (type === "user") {
      params.public = isPublic;
    }

    dispatch(getLayoutsThunk(params));
    isTopFilterApplied.current = false;
    isSideFilterApplied.current = false;
  };

  const handleFilterList = () => {
    const value = searchValue.toLowerCase();
    const filteredLayoutList = layouts.filter((form) =>
      form.defaultLayoutName.toLowerCase().includes(value)
    );

    setLayouts(filteredLayoutList);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length === 0) {
      computeLayouts();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === "Enter" && !searchValue) computeLayouts();
    if (event.nativeEvent.key === "Enter" && searchValue) {
      const filteredLayoutList = layouts.filter((form) =>
        form.defaultLayoutName.toLowerCase().includes(searchValue.toLowerCase())
      );
      setLayouts(filteredLayoutList);
      isTopFilterApplied.current = true;
    }
  };

  const handleApplyFilter = () => {
    if (filterValues.fromDate.length > 0 && filterValues.toDate.length === 0) {
      toast.error(getConvertedSentenceToUpperCase("Select To Date value!"));
      return;
    }
    if (filterValues.fromDate.length === 0 && filterValues.toDate.length > 0) {
      toast.error(getConvertedSentenceToUpperCase("Select From Date value!"));
      return;
    }

    let params: LayoutSearchParams = {
      visitType: filterValues.visitType,
      associatedType: filterValues.associatedType,
      deviceType: filterValues.deviceType,
      associatedTrack: filterValues.associatedTrack,
      formCategories: filterValues.formCategories,
    };
    if (filterValues.fromDate.length > 0 && filterValues.toDate.length > 0) {
      params = {
        ...params,
        fromDate: filterValues.fromDate,
        toDate: filterValues.toDate,
      };
    }
    if (type === "user") {
      params.public = isPublic;
    }
    dispatch(getLayoutsThunk(params));
    isTopFilterApplied.current = true;
    isSideFilterApplied.current = true;
  };

  const handleUpdateUserLayouts = () => {
    const id = currentUser.id as string;
    setIsLoading(true);
    dispatch(updateUserLayoutThunk({ id, layouts: selectedLayouts }))
      .unwrap()
      .then(() => {
        dispatch(getUserLayoutThunk(id));
        handleOpen(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateGroupAssignForm = () => {
    const id = groupData.currentGroup.id;
    setIsLoading(true);
    dispatch(updateGroupLayoutThunk({ id, layouts: selectedLayouts }))
      .unwrap()
      .then(() => {
        dispatch(getAssignedLayoutsThunk(id));
        handleOpen(false);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const assignHandlers: Record<string, HandlerFunction> = {
    user: handleUpdateUserLayouts,
    groups: handleUpdateGroupAssignForm,
    // Add more mappings as needed
  };

  const handleUpdate = () => {
    const handler = assignHandlers[type];
    if (handler) {
      handler();
      clearAllFiltersData();
    }
  };

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, item: Layout) => {
    let selected: Layout[];
    if (selectedLayouts.some((layout: Layout) => layout.id === item.id)) {
      selected = selectedLayouts.filter((v) => v.id !== item.id);
    } else {
      selected = [...selectedLayouts, item];
    }
    setSelectedLayouts(selected);
  };

  const handleDate = (from: string, to: string) => {
    setFilterValues({ ...filterValues, fromDate: from.split("T")[0], toDate: to.split("T")[0] });
  };

  //   memoized values
  const deviceTypeOptions = useMemo(() => {
    return getLabelValuePair(layoutsData.filters.associatedDeviceTypes);
  }, [layoutsData.filters.associatedDeviceTypes]);
  const trackTypeOptions = useMemo(() => {
    return getLabelValuePair(layoutsData.filters.associatedTracks);
  }, [layoutsData.filters.associatedTracks]);
  const isDisabled = useMemo(() => {
    return JSON.stringify(assignedLayouts) === JSON.stringify(selectedLayouts);
  }, [assignedLayouts, selectedLayouts]);

  return (
    <CustomDialog open={open} handleOpen={handleOpen} maxWidth={"md"} handleClear={handleClear}>
      <Box sx={{ padding: { xs: "0 1rem", sm: "0 2rem" } }}>
        <DialogHeader>{modalName}</DialogHeader>
        <DialogSearch>
          <SearchBar
            handleSearch={handleSearch}
            handleKeyPress={handleKeyPress}
            handleFilterList={handleFilterList}
            searchValue={searchValue}
          />
        </DialogSearch>
        <StyledFilterForm>
          <StyledDiv>
            <FilterListOutlinedIcon />
            {filters.deviceType && (
              <MultiSelect
                placeholder="Device Type"
                value={filterValues.deviceType}
                options={deviceTypeOptions}
                renderValue={renderValues}
                handleChange={(event) => {
                  event.preventDefault();
                  setFilterValues({ ...filterValues, deviceType: event.target.value as string[] });
                }}
                id="device"
                enableSearch={true}
              />
            )}
            {filters.trackType && (
              <MultiSelect
                placeholder="Track"
                value={filterValues.associatedTrack}
                options={trackTypeOptions}
                renderValue={renderValues}
                handleChange={(event) =>
                  setFilterValues({
                    ...filterValues,
                    associatedTrack: event.target.value as string[],
                  })
                }
                id="track"
              />
            )}

            {filters.date && (
              <Box>
                <RangeCalendar
                  handleDate={handleDate}
                  selectedDate={filterValues.fromDate}
                  isDisableFuture={true}
                  placeholderText="Date Published Range"
                  data-testid="calendar"
                />
              </Box>
            )}
          </StyledDiv>
          <StyledDiv>
            <StyledButton
              variant="outlined"
              data-testid="apply-filters"
              onClick={handleApplyFilter}
            >
              Apply
            </StyledButton>
            <StyledClearButton onClick={(e) => handleClear(e)} data-testid="clear-filter">
              Clear
            </StyledClearButton>
          </StyledDiv>
        </StyledFilterForm>
        <StyledBody>
          <StyledFormList>
            {!layoutsLoader ? (
              layouts.length > 0 ? (
                layouts.map((layout: Layout, index) => {
                  const isChecked = selectedLayouts.some((sl) => sl.id === layout.id);
                  return (
                    <LayoutTitle
                      layout={layout}
                      isChecked={isChecked}
                      key={index}
                      onChange={(e) => handleCheckBox(e, layout)}
                    />
                  );
                })
              ) : (
                <Box sx={{ textAlign: "center", fontSize: "18px", marginTop: "3rem" }}>
                  No Layouts Are Available...
                </Box>
              )
            ) : (
              <Loader />
            )}
          </StyledFormList>
          <StyledFilterSidebar>
            <StyledFilterHeader>
              <StyledFilterNameHeading>Filters</StyledFilterNameHeading>
              <StyledClearButton
                onClick={handleVisitAssociateClear}
                data-testid="clear-associate-visit-filter"
              >
                Clear
              </StyledClearButton>
            </StyledFilterHeader>
            {filters.associatedType && (
              <FilterSidebar
                name="Associated Type"
                handleChange={(values) =>
                  setFilterValues({ ...filterValues, associatedType: values })
                }
                selectedItems={filterValues.associatedType}
                sidebarFilterList={associatedLayoutData}
              />
            )}
            {filters.associatedType && (
              <FilterSidebar
                name="Form Category"
                handleChange={(values) =>
                  setFilterValues({ ...filterValues, formCategories: values })
                }
                selectedItems={filterValues.formCategories}
                sidebarFilterList={formCategoriesData}
              />
            )}
            {filters.visitType && (
              <FilterSidebar
                handleChange={(values) => setFilterValues({ ...filterValues, visitType: values })}
                selectedItems={filterValues.visitType}
                name="Visit Type"
                sidebarFilterList={visitedLayoutData}
              />
            )}
          </StyledFilterSidebar>
        </StyledBody>
        <DialogActionDiv>
          <AddDiv
            data-testid="add-layouts"
            variant="contained"
            loading={isLoading}
            onClick={() => {
              handleUpdate();
            }}
            disabled={isDisabled}
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              height: "3rem",
            }}
          >
            ASSIGN
          </AddDiv>
          <CancelDiv
            data-testid="cancel"
            variant="outlined"
            onClick={(e) => {
              clearAllFiltersData();
              handleClear(e);
              handleOpen(false);
            }}
            sx={{
              fontSize: { sm: "14px", md: "16px" },
              height: "3rem",
            }}
          >
            CANCEL
          </CancelDiv>
        </DialogActionDiv>
      </Box>
    </CustomDialog>
  );
}

export default LayoutModal;
