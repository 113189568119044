import React, { SVGProps } from "react";

interface ImpersonateUserProps extends SVGProps<SVGSVGElement> {
  backgroundColor?: string;
  iconColor?: string;
}

const ImpersonateUser: React.FC<ImpersonateUserProps> = ({
  backgroundColor = "#fff",
  iconColor = "#034A93",
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
    <rect width={31} height={31} x={0.5} y={0.5} fill={backgroundColor} rx={5.5} />
    <g clipPath="url(#a)">
      <path
        fill={iconColor}
        d="M17.665 17.877v1.742A5 5 0 0 0 11 24.334l-1.667-.001a6.666 6.666 0 0 1 8.333-6.457ZM16 16.834c-2.763 0-5-2.238-5-5 0-2.763 2.237-5 5-5 2.762 0 5 2.237 5 5 0 2.762-2.238 5-5 5Zm0-1.667a3.332 3.332 0 0 0 3.333-3.333 3.332 3.332 0 1 0-6.667 0A3.332 3.332 0 0 0 16 15.167Zm4.827 7.428 2.946-2.946 1.18 1.179-4.126 4.125-2.947-2.947 1.18-1.178 1.766 1.767h.001Z"
      />
    </g>
    <rect width={31} height={31} x={0.5} y={0.5} stroke={iconColor} rx={5.5} />
    <defs>
      <clipPath id="a">
        <path fill={backgroundColor} d="M6 6h20v20H6z" />
      </clipPath>
    </defs>
  </svg>
);

export default ImpersonateUser;
