import { Button } from "@mui/material";
import HistoryIcon from "src/shared/icons/history-icon";
import styled from "styled-components";
import { AddUserDiv } from "src/pages/opie-forms-management/user-management/styles";
import FormIcon from "src/shared/icons/form-icon";

export const StyledGroupDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 1rem;
`;
export const StyledHistoryDiv = styled(Button)`
  text-transform: none;
  background: white !important;
  font-weight: bold;
  color: #034a93 !important;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  height: 3rem;
  gap: 4px;
  white-space: nowrap;
`;
export const StyledHistoryIcon = styled(HistoryIcon)``;

export const AssignFormButton = styled(AddUserDiv)`
  background: white;
  color: #034a93;
  &:hover {
    opacity: 0.8;
    background: none !important;
  }
`;
export const StyledFormIconDiv = styled.div`
  margin-right: 0.5rem;
  margin-top: 0.5rem;
`;

export const StyledFormIcon = styled(FormIcon)``;
