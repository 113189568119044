import { CUSTOM_LAYOUTS, OPIE_LAYOUTS, PUBLISHED_LAYOUTS } from "src/constants/route-constants";
import { DashboardStore } from "src/slices/facility-forms-management/dashboard/dashboard-interface";

export const initialState: DashboardStore = {
  counts: {
    defaultLayoutCount: {
      label: "Available OPIE Layouts",
      count: 0,
      route: OPIE_LAYOUTS,
    },
    customLayoutCount: {
      label: "Custom Layouts",
      count: 0,
      route: CUSTOM_LAYOUTS,
    },
    publishedLayoutCount: {
      label: "Published Layouts",
      count: 0,
      route: PUBLISHED_LAYOUTS,
    },
    isLoading: false,
  },
};
