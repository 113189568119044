import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LOCALSTORAGE_USER_TOKEN_KEY } from "src/constants/auth-constants";
import AppLayout from "src/layouts/app-layout";
import Login from "src/pages/shared/auth/login";
import { AppDispatch } from "src/redux/store";
import { facilityPrivateRoutes, opiePrivateRoutes, publicRoutes } from "src/shared/side-bar/routes";
import { currentUserSelector, tokenSelector } from "src/slices/shared/user";
import { getCurrentLoggedInUserThunk } from "src/slices/shared/user/user-thunks";
import { useLocalStorage } from "src/utils/storage.hook";
import { UserRoles } from "src/interfaces/sidebarRoutes.interface";
import { appEnv } from "src/constants/get-env";
import { MANAGEMENT_KEY } from "src/constants/app-config-constants";
import { getLayoutsFilterThunk } from "src/slices/shared/layouts/layouts-thunks";

const Router = () => {
  const currentUser = useSelector(currentUserSelector);
  const [userType, setUserType] = useState<UserRoles | string>("");
  const dispatch = useDispatch<AppDispatch>();
  const [storedToken] = useLocalStorage(LOCALSTORAGE_USER_TOKEN_KEY);
  const token = useSelector(tokenSelector) || storedToken;
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser && currentUser?.role) {
      setUserType(currentUser.role.role_name);
    } else {
      setUserType("");
    }
    setLoading(true);
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setLoading(false);
      dispatch(getCurrentLoggedInUserThunk());
      dispatch(getLayoutsFilterThunk());
    }
  }, [dispatch, token]);

  return (
    <BrowserRouter>
      {isLoading && (
        <Routes>
          {publicRoutes.map((route) => (
            <Route path={route.path} element={route.element} key={route.path} />
          ))}
          <Route element={<AppLayout />}>
            {appEnv === MANAGEMENT_KEY.OPIE ? (
              <>
                {opiePrivateRoutes(userType).map((route) => (
                  <Route path={route.path} element={route.element} key={route.path} />
                ))}
              </>
            ) : (
              <>
                {facilityPrivateRoutes(userType).map((route) => (
                  <Route path={route.path} element={route.element} key={route.path} />
                ))}
              </>
            )}
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default Router;
