import { Grid } from "@mui/material";
import styled from "styled-components";

export const LoginContainer = styled(Grid)`
  height: 100vh;
  display: flex;
`;

export const LeftContainer = styled(Grid)`
  background: white;
`;

export const LogoDiv = styled(Grid)`
  height: 54px;
  width: 100%;
  background: #034a93;
`;

export const HeaderLogo = styled.img`
  width: 38px;
  height: 26px;
  padding: 0.6rem 0 0 1.2rem;
`;

export const RightContainer = styled(Grid)`
  background: #034a93;
  height: 100%;
`;
