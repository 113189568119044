import React from "react";
import { Box } from "@mui/material";
import Tooltip from "src/shared/ui/Tooltip";
import {
  StyledLeftColumn,
  StyledLayoutHeading,
  StyledInfoIcon,
  StyledNavigateNextIcon,
  StyledGridLeftHeaderChild,
  StyledGridLayoutRightHeader,
  StyledKeyboardBackspaceOutlinedIcon,
  StyledCheckbox,
  StyledTooltipChildContainer,
  StyledTooltipItemContainer,
} from "src/pages/facility-forms-management/layout-details/components/header/styles";
import { StyledSelectButton } from "src/pages/facility-forms-management/user-impersonation/components/table/styles";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  layoutName: string;
}

function Header({ layoutName }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const toolTipItems = ["Show on the form", "Make field Required"];

  const handleBack = () => {
    const pathname = location.pathname.split("/")[1];
    navigate(`/${pathname}`);
    return;
  };

  const tooltipContent = (
    <StyledTooltipChildContainer>
      {toolTipItems.map((item: string, index: number) => {
        return (
          <StyledTooltipItemContainer
            key={index}
            style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
          >
            <StyledCheckbox disabled data-testid={"tooltip-checkbox"} index={index} />
            {item}
          </StyledTooltipItemContainer>
        );
      })}
    </StyledTooltipChildContainer>
  );

  return (
    <StyledLeftColumn item xs={12}>
      <StyledSelectButton
        variant="outlined"
        data-testid="back-button"
        bgcolor={"white"}
        border={"#034A93"}
        onClick={handleBack}
      >
        <StyledKeyboardBackspaceOutlinedIcon />
        Back
      </StyledSelectButton>
      <StyledGridLeftHeaderChild item>
        <StyledLayoutHeading>{layoutName}</StyledLayoutHeading>{" "}
        <Tooltip title={tooltipContent}>
          <StyledInfoIcon />
        </Tooltip>
      </StyledGridLeftHeaderChild>
      <StyledGridLayoutRightHeader item onClick={handleBack}>
        <Box> Layouts</Box>
        <StyledNavigateNextIcon />
      </StyledGridLayoutRightHeader>
    </StyledLeftColumn>
  );
}

export default Header;
