import { Grid } from "@mui/material";
import styled from "styled-components";

export const StyledHeaderDiv = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14vh;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: start;
  }
`;
