import {
  CancelUserDiv,
  DeleteUserDiv,
  DialogActionDiv,
} from "src/shared/delete-confirmation-modal/styles";
import styled from "styled-components";

export const HideFormContainer = styled.div`
  padding: 1rem;
`;
export const HideFormText = styled.p`
  text-align: center;
  margin-top: 3rem;
`;
export const HideFormFooter = styled(DialogActionDiv)``;
export const HideFormCancelButton = styled(CancelUserDiv)``;
export const HideFormContinueButton = styled(DeleteUserDiv)``;
