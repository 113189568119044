import axios from "axios";
import {
  LOCALSTORAGE_USER_TOKEN_KEY,
  LOCALSTORAGE_IDP_USER_FLAG,
  LOCALSTORAGE_IMPERSONATION_TOKEN,
} from "src/constants/auth-constants";
import { getValueFromStorage } from "src/utils/storage"; // import { getUserImpersonationFromSession } from "src/utils/storage";
axios.interceptors.request.use((request) => {
  const userImpersonationToken = getValueFromStorage(LOCALSTORAGE_IMPERSONATION_TOKEN);
  const token = getValueFromStorage(LOCALSTORAGE_USER_TOKEN_KEY);
  const isIDPUser = getValueFromStorage(LOCALSTORAGE_IDP_USER_FLAG);
  // Regular expression pattern to match S3 bucket URLs
  const s3BucketPattern = /^https:\/\/[a-zA-Z0-9.-]+\.s3\.amazonaws\.com\//;

  // Check if the request URL contains '/api/eos/formsintegration'
  const isEosFormsIntegration = request?.url?.includes("/api/eos/formsintegration");

  // Check if the request URL is an S3 bucket URL
  const isS3Bucket = s3BucketPattern.test(request?.url ?? "");
  // Add authorization headers based on the type of request
  if (token) {
    if (isEosFormsIntegration) {
      request.headers["IDP-Access-Token"] = token;
      console.log(request.headers);
    } else if (!isS3Bucket && !isEosFormsIntegration) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    if (userImpersonationToken) {
      request.headers["IDP-Impersonation-Token"] = userImpersonationToken;
    }
    if (isIDPUser == "true") {
      request.headers["is_idp_user"] = true;
    }
  }

  return request;
});
