import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@mui/material";
import { GROUPS } from "src/constants/route-constants";
import BreadCrumb from "src/shared/ui/bread-crumb";
import { DataGridPro, GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import {
  getGroupByIdThunk,
  updateGroupThunk,
  getFacilitiesThunk,
  deleteFacilityFromGroupThunk,
  getAssignedLayoutsThunk,
} from "src/slices/opie-forms-management/groups/groups-thunk";
import SortingIcon from "src/shared/icons/sorting-icon";
import { dataGridStyles } from "src/constants/datagrid-styles";
import AddMembersDialog from "src/pages/opie-forms-management/groups/components/add-members";
import AccordionTemplate from "src/shared/ui/accordion";
import FormsAccordion from "src/pages/opie-forms-management/groups/components/forms-accordion";
import {
  UserButtonDiv,
  AddUserIcon,
  HeaderDiv,
  Heading,
  AddUserDiv,
  DeleteUserIconDiv,
} from "src/pages/opie-forms-management/user-management/styles";
import {
  StyledGroupDiv,
  StyledHistoryDiv,
  StyledHistoryIcon,
  AssignFormButton,
  StyledFormIcon,
  StyledFormIconDiv,
} from "src/pages/opie-forms-management/groups/components/edit-group/styles";

import {
  SaveUserDiv,
  SaveUserIcon,
} from "src/pages/opie-forms-management/user-management/components/create-user/styles";

import { UpdateGroup, Facility } from "src/interfaces/groups.interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/redux/store";
import {
  currentGroup,
  groupFacilities,
  groupSelector,
} from "src/slices/opie-forms-management/groups";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "src/shared/icons/delete-icon";
import DeleteGroup from "src/shared/delete-confirmation-modal";
import FormDataComponent from "src/pages/opie-forms-management/groups/components/form-data";
import { mapFacilitiesWithCheckedKey } from "src/pages/opie-forms-management/groups/utils/methods";
import HistoryModal from "src/pages/opie-forms-management/groups/components/history-modal";
import { getLayoutsThunk } from "src/slices/opie-forms-management/layouts/layouts-thunks";
import LayoutModal from "src/shared/layout-modal";

interface Loader {
  isUpdateLoading: boolean;
  isUpdatingFacilityLoading: boolean;
  isDeleteLoading: boolean;
}
const initialState: UpdateGroup = {
  id: "",
  name: "",
  description: "",
  facilities: [],
  is_default: false,
  is_system_generated: false,
};
interface Props {
  isDisableVirtualization?: boolean;
}

function EditGroup({ isDisableVirtualization }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const groupsList = useSelector(groupSelector);
  const facilities = useSelector(groupFacilities);
  const selectedGroup = useSelector(currentGroup);
  const navigate = useNavigate();
  const params = useParams();
  const [groupData, setGroupData] = useState<UpdateGroup>(initialState);
  const [isLoading, setIsLoading] = useState<Loader>({
    isUpdateLoading: false,
    isUpdatingFacilityLoading: false,
    isDeleteLoading: false,
  });
  const [openAddMembersModal, setOpenAddMembersModal] = useState<boolean>(false);
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<Facility>({ id: "", name: "", isChecked: false }); //selected row from dataGridPro
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]); //array won't include already selected facilities
  const [rows, setRows] = useState<Facility[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [openLayoutModal, setOpenLayoutModal] = useState<boolean>(false);
  // const allGroupLayouts = useSelector(combinedPublicLayouts);

  const isFormChanged = JSON.stringify(groupData) !== JSON.stringify(selectedGroup);
  useEffect(() => {
    dispatch(getLayoutsThunk({}));
    dispatch(getGroupByIdThunk(params.id as string));
    dispatch(getAssignedLayoutsThunk(params.id as string));
    // eslint-disable-next-line
  }, [dispatch]);

  const handleAddMembersDialog = (open: boolean) => {
    setOpenAddMembersModal(open);
  };

  const handleHistoryModal = (open: boolean) => {
    setOpenHistoryModal(open);
  };

  const handleAssignFormsDialog = (open: boolean) => {
    setOpenLayoutModal(open);
  };

  useEffect(() => {
    setIsDisabled(!isFormChanged);
  }, [isFormChanged]);

  useEffect(() => {
    dispatch(getFacilitiesThunk());
  }, [dispatch]);

  const facilityMappedList = useMemo(() => mapFacilitiesWithCheckedKey(facilities), [facilities]);

  const handleSelectedGroup = () => {
    if (Object.keys(selectedGroup).length) {
      setGroupData({
        ...selectedGroup,
      });
      setRows(selectedGroup.facilities as []);
    }
  };

  useEffect(() => {
    handleSelectedGroup();
    // eslint-disable-next-line
  }, [selectedGroup]);

  const handleFacilityData = () => {
    if (groupData.facilities) {
      const filteredList = facilityMappedList.filter((facility: Facility) => {
        const matchingFacilities = (groupData.facilities as []).some(
          (existingFacility: Facility) => existingFacility.id === facility.id
        );
        return !matchingFacilities;
      });
      const res = filteredList.map((item: Facility) => {
        item.isChecked = false;
        return item;
      });
      setFilteredFacilities(res);
    }
  };
  const handleDeleteModal = (open: boolean) => {
    setIsDeleteModal(open);
  };

  const handleModalEmpty = () => {
    handleSelectedGroup();
  };

  const handleDeleteFacility = () => {
    setIsLoading({ ...isLoading, isDeleteLoading: true });
    dispatch(
      deleteFacilityFromGroupThunk({ group_id: groupData.id, facility_id: rowData.id as string })
    )
      .unwrap()
      .then(() => {
        dispatch(getGroupByIdThunk(params.id as string));
      })
      .finally(() => {
        setIsLoading({ ...isLoading, isDeleteLoading: false });
        setIsDeleteModal(false);
      });
  };

  const handleFacilityList = async (list: Facility[]) => {
    const mappedData = {
      ...selectedGroup,
      facilities: list,
    };
    setIsLoading({ ...isLoading, isUpdatingFacilityLoading: true });
    await dispatch(updateGroupThunk(mappedData))
      .unwrap()
      .then(() => {
        dispatch(getGroupByIdThunk(params.id as string));
      })
      .finally(() => {
        setIsLoading({ ...isLoading, isUpdatingFacilityLoading: false });
      });
    return isLoading.isUpdatingFacilityLoading;
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id, checked } = e.target;
    if (id === "checkbox") {
      setGroupData({ ...groupData, is_default: checked });
    } else {
      setGroupData({ ...groupData, [id]: value });
    }
  };

  const handleGroup = () => {
    setIsLoading({ ...isLoading, isUpdateLoading: true });
    dispatch(updateGroupThunk(groupData))
      .unwrap()
      .then(() => {
        dispatch(getGroupByIdThunk(params.id as string));
      })
      .finally(() => {
        setIsLoading({ ...isLoading, isUpdateLoading: false });
      });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Facility Name",
      flex: 1,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "id",
      headerName: "Facility Id",
      flex: 1,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: (params: GridCellParams) => {
        const rowData: Facility = params.row;
        return (
          <DeleteUserIconDiv
            data-testid="delete-row"
            onClick={() => {
              handleDeleteModal(true);
              setRowData(rowData);
            }}
          >
            <DeleteIcon />
          </DeleteUserIconDiv>
        );
      },
    },
  ];

  useEffect(() => {
    if (Object.keys(selectedGroup).length > 0 && isFormChanged) {
      for (const [key, value] of Object.entries(groupData)) {
        if (key !== "facilities" && value === "") {
          setIsDisabled(true);
          return;
        }
        setIsDisabled(false);
      }
    }
  }, [groupData, isFormChanged, selectedGroup]);
  return (
    <>
      <HeaderDiv>
        <Heading>Facility Groups</Heading>
        <UserButtonDiv>
          <StyledHistoryDiv
            data-testid="open-history-modal"
            variant="contained"
            onClick={() => {
              handleHistoryModal(true);
            }}
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            <StyledHistoryIcon />
            History
          </StyledHistoryDiv>
          <AssignFormButton
            data-testid="layout-modal"
            variant="contained"
            onClick={() => {
              handleAssignFormsDialog(true);
            }}
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              height: "3rem",
            }}
          >
            <StyledFormIconDiv>
              <StyledFormIcon />
            </StyledFormIconDiv>
            Assign Layout
          </AssignFormButton>
          {!groupData.is_system_generated && Object.keys(selectedGroup).length ? (
            <AddUserDiv
              data-testid="open-facility-modal"
              variant="contained"
              onClick={() => {
                handleAddMembersDialog(true);
                handleFacilityData();
              }}
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                height: "3rem",
              }}
            >
              <AddUserIcon />
              Add Facilities
            </AddUserDiv>
          ) : null}
        </UserButtonDiv>
      </HeaderDiv>
      <BreadCrumb
        linkList={[
          {
            url: GROUPS,
            heading: "Facility Groups",
          },
        ]}
      />
      <StyledGroupDiv>
        <FormDataComponent
          handleInput={handleInput}
          groupData={{
            name: groupData.name,
            description: groupData.description,
            is_default: groupData.is_default,
            is_system_generated: groupData.is_system_generated as boolean,
            created_time: groupData.created_time as Date,
            updated_time: groupData.updated_time as Date,
          }}
        />
        <FormsAccordion accordionData={groupsList.assignedLayouts} groupId={selectedGroup.id} />
        <AccordionTemplate heading="Facilities">
          <DataGridPro
            disableColumnMenu
            autoHeight
            rows={rows}
            columns={columns}
            hideFooterRowCount
            filterMode="client"
            sx={{ ...dataGridStyles, borderRadius: "0px" }}
            sortingOrder={["asc", "desc"]}
            slots={{
              columnSortedAscendingIcon: SortingIcon,
              columnSortedDescendingIcon: SortingIcon,
            }}
            hideFooterSelectedRowCount
            disableVirtualization={isDisableVirtualization ? true : false}
            columnVisibilityModel={{
              actions: !groupData.is_system_generated,
            }}
          />
        </AccordionTemplate>
      </StyledGroupDiv>
      {openHistoryModal && (
        <HistoryModal
          open={openHistoryModal}
          handleOpen={handleHistoryModal}
          groupId={selectedGroup.id}
          groupName={selectedGroup.name}
        />
      )}
      {openLayoutModal && (
        <LayoutModal
          assignedLayouts={groupsList.assignedLayouts}
          open={openLayoutModal}
          handleOpen={handleAssignFormsDialog}
          modalName={"Assign Layouts"}
          type={"groups"}
          filters={{
            associatedType: true,
            visitType: true,
            deviceType: true,
            trackType: true,
            date: false,
            formCategory: true,
          }}
        />
      )}
      {openAddMembersModal && (
        <AddMembersDialog
          handleFacilityList={handleFacilityList}
          open={openAddMembersModal}
          handleOpen={handleAddMembersDialog}
          filteredFacilities={filteredFacilities}
          isLoading={isLoading.isUpdatingFacilityLoading}
        />
      )}
      <Box sx={{ display: "flex", gap: "15px", margin: "1rem 0" }}>
        <SaveUserDiv
          data-testid="save-data"
          loading={isLoading.isUpdateLoading}
          variant="contained"
          onClick={handleGroup}
          disabled={isDisabled}
        >
          <SaveUserIcon />
          Save Facility Group
        </SaveUserDiv>
        <SaveUserDiv
          data-testid="cancel"
          onClick={() => {
            handleModalEmpty();
            navigate(GROUPS);
          }}
          variant="outlined"
          sx={{ background: "white !important", color: "#034a93" }}
        >
          Cancel
        </SaveUserDiv>
      </Box>
      {isDeleteModal && (
        <DeleteGroup
          open={isDeleteModal}
          type=""
          handleOpen={handleDeleteModal}
          heading={"Delete Facility"}
          subHeading={"Are you sure you want to delete"}
          handleDeleteUser={handleDeleteFacility}
          isLoading={isLoading.isDeleteLoading}
          name={rowData.name}
        />
      )}
    </>
  );
}

export default EditGroup;
