import { Box, CircularProgress, Typography } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EMPTY_SEARCH_VALUE } from "src/constants/error-constants";
import { Facility } from "src/interfaces/groups.interface";
import { FacilityUser, UserImpersonation } from "src/interfaces/user-impersonation.interface";
import { PaginationFormat } from "src/interfaces/pagination.interface";
import Table from "src/pages/facility-forms-management/user-impersonation/components/table";
import { userColumns } from "src/pages/facility-forms-management/user-impersonation/components/table/columns";
import {
  getUserSearchData,
  sortArray,
} from "src/pages/facility-forms-management/user-impersonation/util";
import SearchBar from "src/shared/ui/search-bar";
import { impersonationSelector } from "src/slices/facility-forms-management/impersonation";
import { LOCALSTORAGE_USER_TOKEN_KEY } from "src/constants/auth-constants";
import { impersonateUserThunk } from "src/slices/facility-forms-management/impersonation/impersonation-thunks";
import { useAppDispatch } from "src/redux/hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { DASHBOARD } from "src/constants/route-constants";

interface props {
  isFacilityTabSelected: boolean;
  resetPagination: (dataLength: number) => void;
  selectedFacility?: Facility;
  pagination: PaginationFormat;
  isDisableVirtualization?: boolean;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  handlePagination: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export default function UserTab({
  isFacilityTabSelected,
  resetPagination,
  selectedFacility,
  pagination,
  isDisableVirtualization,
  handlePreviousPage,
  handleNextPage,
  handlePagination,
}: props) {
  const facilityUsers = useSelector(impersonationSelector);
  const [userList, setUserList] = useState<FacilityUser[]>([]);
  const [userSearchList, setUserSearchList] = useState<FacilityUser[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortModel, setSortModel] = useState<GridSortItem[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setSortModel([]);
    if (!isFacilityTabSelected && selectedFacility) {
      const users = facilityUsers.users.filter((item: FacilityUser) => item.isFormsLayoutAdmin);
      setUserList(users);
      resetPagination(users.length);
    }
    // eslint-disable-next-line
  }, [facilityUsers, isFacilityTabSelected]);

  const handleSortModelChange = (incomingModel: GridSortModel) => {
    const newSortModel = incomingModel[0] as GridSortItem;
    if (newSortModel) {
      const users = sortArray<FacilityUser>(
        userList,
        newSortModel.field as keyof FacilityUser,
        newSortModel.sort
      ) as FacilityUser[];
      setUserList(users);
      setSortModel(incomingModel);
    }
  };

  const filterSearchList = () => {
    const value = searchValue.toLowerCase();
    if (value) {
      setIsSearching(true);
      const filteredUserList = getUserSearchData(userList, value);
      setUserSearchList(filteredUserList);
      resetPagination(filteredUserList.length);
    } else {
      toast.error(EMPTY_SEARCH_VALUE);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value === "") {
      setIsSearching(false);
      setUserSearchList([]);
      resetPagination(userList.length);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === "Enter" && searchValue) {
      filterSearchList();
    }
  };

  const handleUserImpersonation = async (user: FacilityUser) => {
    const userData: UserImpersonation = {
      user: user,
      token: localStorage.getItem(LOCALSTORAGE_USER_TOKEN_KEY) || "",
    };
    try {
      await dispatch(impersonateUserThunk(userData)).unwrap();
      const { state } = location;
      state && state.redirectUrl ? navigate(state.redirectUrl) : navigate(DASHBOARD);
    } catch (err) {
      console.error(err);
    }
  };

  const getUserList = () => {
    return userSearchList || userList
      ? isSearching
        ? (userSearchList as []).slice(pagination.firstRecordIndex, pagination.lastRecordIndex)
        : (userList as []).slice(pagination.firstRecordIndex, pagination.lastRecordIndex)
      : [];
  };

  const showPagination = (facilityUsers && facilityUsers.isLoading) || pagination.dataCount === 0;

  return (
    <Box sx={{ width: "100%" }}>
      <SearchBar
        handleSearch={handleSearch}
        handleKeyPress={handleKeyPress}
        handleFilterList={filterSearchList}
        styles={{ margin: "1rem 0" }}
        searchValue={searchValue}
      />
      {facilityUsers.isImpersonationLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
          <Typography variant="body1" style={{ marginLeft: "10px" }}>
            Impersonating User
          </Typography>
        </div>
      )}
      {!facilityUsers.isImpersonationLoading && (
        <Table
          isDisableVirtualization={isDisableVirtualization}
          isFacilityTabSelected={isFacilityTabSelected}
          pagination={pagination}
          sortModel={sortModel}
          rows={facilityUsers && facilityUsers.isLoading ? [] : getUserList()}
          handleSortModelChange={handleSortModelChange}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handlePagination={handlePagination}
          facilityUserLoading={facilityUsers && facilityUsers.isLoading}
          dataSetSize={pagination.paginationSize}
          currentPage={pagination.currentPage}
          showPagination={showPagination}
          columns={userColumns(handleUserImpersonation)}
          rowId={(row: FacilityUser) => row && (row.userID as string)}
        />
      )}
    </Box>
  );
}
