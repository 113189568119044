import React, { useState, useEffect } from "react";
import { Checkbox, Grid } from "@mui/material";
import CustomDialog from "src/shared/modal";
import {
  StyledContainer,
  StyledFilterHeading,
  StyledBox,
  StyledGridItem,
  StyledDelegateHeading,
  StyledFilterFooter,
  StyledSelectApplyButton,
  StyledCheckBoxText,
} from "src/pages/facility-forms-management/layout-details/components/filter-layout-modal/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  layoutDetailsSelector,
  resetFilter,
  updateFilter,
} from "src/slices/facility-forms-management/layout-details";
import { DelegateInterface } from "src/interfaces/layout-details.interface";
import { delegatesList } from "src/constants/delegates";

interface Props {
  open: boolean;
  handleOpen: (value: boolean) => void;
}

const FilterLayoutModal: React.FC<Props> = ({ open, handleOpen }) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(layoutDetailsSelector);
  const [isRequired, setIsRequired] = useState<boolean>(filter.isRequired);
  const [isVisible, setIsVisible] = useState<boolean>(filter.isVisible);
  const [delegatesData, setDelegatesData] = useState<DelegateInterface[]>();

  useEffect(() => {
    setDelegatesData(filter.delegates);
  }, [filter.delegates]);

  const handleModal = () => handleOpen(false);

  const handleDelegates = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const updatedData =
      delegatesData &&
      delegatesData.map((item) =>
        item.name === name ? { ...item, isSelected: !item.isSelected } : item
      );
    setDelegatesData(updatedData);
  };

  const toggleRequiredData = () => setIsRequired(!isRequired);

  const toggleIsVisible = () => setIsVisible(!isVisible);

  const handleApplyFilterFields = () => {
    dispatch(
      updateFilter({
        delegates: delegatesData ?? [],
        isRequired: isRequired,
        isApplied: true,
        isVisible: isVisible,
      })
    );
    const isSelected: [] = delegatesData?.filter((item) => item.isSelected) as [];
    if (isSelected?.length <= 0 && !isRequired && !isVisible) {
      dispatch(resetFilter());
    }
    handleModal();
  };

  const isDisabled =
    JSON.stringify(delegatesData) !== JSON.stringify(filter.delegates) ||
    JSON.stringify(isVisible) !== JSON.stringify(filter.isVisible) ||
    JSON.stringify(isRequired) !== JSON.stringify(filter.isRequired);

  function renderElements(label: string, checked: boolean, onChange: () => void) {
    return (
      <Grid item md={4}>
        <StyledBox>
          <Checkbox
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
            checked={checked}
            data-testid="checkbox"
            onChange={onChange}
          />
          <StyledCheckBoxText>{label}</StyledCheckBoxText>
        </StyledBox>
      </Grid>
    );
  }

  function renderDelegate(item: DelegateInterface, index: number) {
    const name: string = delegatesList[item.name.toLowerCase() as string];
    return (
      <StyledGridItem item md={4} key={index}>
        <StyledBox>
          <Checkbox
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
            checked={item.isSelected}
            data-testid="delegate-checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDelegates(e, item.name)}
          />
          <StyledCheckBoxText>{name ? name : item.name}</StyledCheckBoxText>
        </StyledBox>
      </StyledGridItem>
    );
  }

  return (
    <CustomDialog open={open} handleOpen={handleModal} maxWidth={"md"}>
      <StyledContainer>
        <StyledFilterHeading>Filters</StyledFilterHeading>
        <Grid container spacing={2}>
          {renderElements("Included Fields", isVisible, toggleIsVisible)}
          {renderElements("Required Fields", isRequired, toggleRequiredData)}
        </Grid>
        <StyledDelegateHeading>Delegates</StyledDelegateHeading>
        <Grid container spacing={3}>
          {delegatesData && delegatesData.map((item, index) => renderDelegate(item, index))}
        </Grid>
        <StyledFilterFooter>
          <StyledSelectApplyButton
            variant="contained"
            data-testid="apply-filter"
            onClick={handleApplyFilterFields}
            disabled={!isDisabled}
          >
            APPLY
          </StyledSelectApplyButton>
        </StyledFilterFooter>
      </StyledContainer>
    </CustomDialog>
  );
};

export default FilterLayoutModal;
