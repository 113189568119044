import React from "react";
import { Drawer, Box } from "@mui/material";
import SideBarItems from "src/shared/side-bar/sidebar-items";

interface Props {
  isSideBarOpen: boolean;
  handleSidebarView: () => void;
}

export default function SideBar({ isSideBarOpen, handleSidebarView }: Props) {
  return (
    <Box sx={{ width: "inherit" }}>
      <Drawer
        variant="temporary"
        open={isSideBarOpen}
        data-testid="side-drawer"
        onClose={handleSidebarView}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "14rem",
            marginTop: "9vh",
            height: "91vh",
          },
        }}
      >
        <SideBarItems />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          width: { xs: 0, sm: 0, md: "inherit", lg: "inherit", xl: "inherit" },
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            display: { xs: "none", sm: "none", md: "block" },
            boxSizing: "border-box",
            marginTop: "9vh",
            width: { sm: 0, md: "inherit", xs: 0, lg: "inherit", xl: "inherit" },
            height: "91vh",
          },
        }}
        open
      >
        <SideBarItems />
      </Drawer>
    </Box>
  );
}
