import { LayoutDetailStore } from "src/slices/facility-forms-management/layout-details/layout-detail-interface";

export const initialStateData = {
  id: "",
  defaultLayoutGuid: "",
  defaultLayoutName: "",
  defaultLayoutFriendlyName: "",
  associatedType: [],
  createdDate: "",
  modifiedDate: "",
  version: "",
  associatedDeviceTypes: [],
  associatedVisitTypes: [],
  associatedTrack: [],
  layoutDescription: "",
  isPublic: false,
  isCustom: false,
  isHidden: false,
  isPublished: false,
  allowSharing: false,
};

export const initialFilterData = {
  delegates: [],
  isRequired: false,
  isVisible: false,
  isApplied: false,
};

export const initialState: LayoutDetailStore = {
  layoutDetail: {
    details: { ...initialStateData },
    fields: [],
  },
  filter: { ...initialFilterData },
  isLoading: false,
  isUpdateLoading: false,
  affectedNoOfFields: 0,
};
