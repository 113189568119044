import {
  DownloadResponse,
  S3DeleteRequest,
  UploadRequest,
  UploadResponse,
  downloadFile,
  s3DeleteFiles,
  uploadFiles,
} from "src/utils/s3-file-upload";

export const uploadHandlerFn = async (data: UploadRequest): Promise<UploadResponse[] | null> => {
  try {
    const uploadResponses = await uploadFiles(data);
    if (!uploadResponses) {
      console.error("Upload failed: No response received");
      throw new Error("Upload failed: No response received");
    }

    data.files.forEach((file) => {
      const matchingResponse = uploadResponses.find(
        (response) => response.fileName === file.fileName
      );

      if (matchingResponse) {
        file.fileUID = matchingResponse.fileUID;
      }
    });

    return uploadResponses;
  } catch (error) {
    console.error("Upload failed:", error);
    throw error;
  }
};

export const downloadHandlerFn = async (
  fileUID: string,
  fileName: string,
  mimeType: string
): Promise<DownloadResponse> => {
  try {
    return await downloadFile(fileName, fileUID, mimeType);
  } catch (error) {
    console.error("Download failed:", error);
    throw error;
  }
};

export const fileRemoveFn = async (data: S3DeleteRequest[]): Promise<boolean> => {
  try {
    await s3DeleteFiles(data);
    return true;
  } catch (error) {
    console.error("File deletion failed:", error);
    throw error;
  }
};
