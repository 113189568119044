import { LayoutDetails } from "src/interfaces/layout-details.interface";

export function uniquePreFilteredDelegates(layoutDetailData?: LayoutDetails): string[] {
  const uniqueDelegatesSet = new Set<string>();

  layoutDetailData &&
    layoutDetailData.fields.forEach((item) => {
      if (item.prefilteredDelegates) {
        item.prefilteredDelegates.forEach((delegate) => {
          uniqueDelegatesSet.add(delegate);
        });
      }
    });
  return Array.from(uniqueDelegatesSet).sort();
}

export function uniqueDelegatesType(layoutDetailData?: LayoutDetails): string[] {
  const uniqueDelegatesSet = new Set<string>();

  layoutDetailData &&
    layoutDetailData.fields.forEach((item) => {
      if (item.delegateTypes) {
        item.delegateTypes.forEach((delegate) => {
          uniqueDelegatesSet.add(delegate);
        });
      }
    });
  return Array.from(uniqueDelegatesSet).sort();
}
