import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { LayoutDetails } from "src/interfaces/layout-details.interface";
import {
  getLayoutById,
  updateLayoutById,
  updateHideFormById,
} from "src/services/layout-details.service";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";

export const getLayoutDetailThunk = createAsyncThunk(
  "getLayoutDetailThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getLayoutById(id);
      return {
        data: res.data.data,
        message: res.data.message,
        statusCode: res.data.statusCode,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateLayoutDetailThunk = createAsyncThunk(
  "updateLayoutDetailThunk",
  async (layoutData: LayoutDetails, { rejectWithValue }) => {
    try {
      const res = await updateLayoutById(layoutData);
      toast.success("Updated Layout Successfully!");
      return {
        data: res.data.data,
        message: res.data.message,
        statusCode: res.data.statusCode,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateHideFormLayout = createAsyncThunk(
  "updateHideFormLayout",
  async (layoutData: LayoutDetails, { rejectWithValue }) => {
    try {
      const res = await updateHideFormById(layoutData);
      toast.success("Layout Updated Successfully!");
      return {
        data: res.data.data,
        message: res.data.message,
        statusCode: res.data.statusCode,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedUpperCaseString(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
