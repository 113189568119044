import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "src/reportWebVitals";
import { Provider } from "react-redux";
import { setupStore } from "src/redux/store";
import App from "src/App";
import "react-toastify/dist/ReactToastify.css";
import "src/index.css";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "src/theme";
import { LicenseInfo } from "@mui/x-license-pro";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const store = setupStore();
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
      <ToastContainer
        position="top-center"
        autoClose={1600}
        bodyStyle={{ textTransform: "capitalize" }}
      />
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
