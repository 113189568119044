import styled from "styled-components";
import {
  StyledButton,
  StyledClearButton,
  StyledDiv,
  StyledFilterForm,
} from "src/shared/layout-modal/styles";

export const StyledFilter = styled(StyledFilterForm)`
  padding: 0.625rem 1rem;
`;

export const StyledFilterDiv = styled(StyledDiv)``;

export const StyledBtn = styled(StyledButton)``;

export const ClearButton = styled(StyledClearButton)``;
