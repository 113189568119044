import { initialState } from "src/slices/opie-forms-management/groups/groups-initial-state";
import { groupManagementSlice } from "src/slices/opie-forms-management/groups/groups-slices";
import {
  groupSelector,
  groupFacilities,
  currentGroup,
  mappedFacilities,
} from "src/slices/opie-forms-management/groups/groups-selector";

export { initialState };

export default groupManagementSlice.reducer;
export { groupSelector, groupFacilities, currentGroup, mappedFacilities };
