/* eslint-disable @typescript-eslint/no-empty-interface */
import styled from "styled-components";
import { DialogContent } from "src/pages/opie-forms-management/groups/components/add-members/styles";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled as muiStyled } from "@mui/system";

export const StyledDialogContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 10px;
  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;
export const DialogActionDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;
export const AddDiv = styled(LoadingButton)`
  text-transform: none;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  padding: 2% 14%;
  color: white;
`;
export const StyledInputDiv = styled.div`
  display: flex;
  gap: 4px;
  min-width: 14rem;
  height: 1.5rem;
  max-width: 15rem;
  margin-left: 0.5rem;
  overflow-x: scroll;
  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledOptionInputDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 9rem;
  text-overflow: ellipsis;
`;
export const CancelDiv = styled(AddDiv)`
  background: white;
  color: #034a93;
`;
export const DialogSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBody = styled(Box)`   
display: flex;
gap: 4px;
}`;
export const StyledDialogRow = styled(Box)`
  padding: 0;
  margin-left: 0.2rem;
  align-items: center;
  .custom-checkbox-root .MuiSvgIcon-root {
    width: 36px;
    height: 36px;
  }
`;
export const StyledFormList = styled(DialogContent)`
  flex: 3;
  max-height: 32rem;
`;
export const StyledFilterSidebar = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 32rem;
`;
export const StyledFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 7px;
  border-bottom: 1px solid #dededf;
`;

export const StyledFilterNameHeading = styled.div`
  color: #034a93;
  font-size: 20px;
  font-weight: bold;
`;

export const StyledHeading = styled.div`
  color: #000a14;
  font-weight: 550;
  font-size: 16px;
  padding: 2px 7px;
  margin-top: 0.5rem;
`;
export const StyledClearButton = styled.div`
  text-decoration: underline;
  color: #034a93;
  font-size: 16px;
  cursor: pointer;
`;
export const StyledFilterForm = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 8px 0px;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 0.5rem;
`;
export const StyledDiv = styled(Box)`
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
`;
export const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 16px;
  padding: 6px 24px;
  text-transform: none;
  border: 1px solid #034a93;
  border-radius: 6px;
`;
export const StyledDropDownBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;
  border: 1px solid #034a93;
  border-radius: 6px;
`;
export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  color: #034a93;
`;

export const StyledAssociateDiv = styled(Box)`
  min-height: 4.5rem;
  max-height: 18rem;
  margin-bottom: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1d3d4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

export const StyledAutocomplete = muiStyled(Autocomplete)`
width: 15rem;
border: 1px solid #034a93;
border-radius: 6px;
height: 42px;

& .MuiAutocomplete-inputRoot {
  flex-wrap: nowrap;
}

& .Mui-focused {
  border: none;
}

& .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 0;
  min-width: 0;
}
` as typeof Autocomplete;

export const StyledTextField = styled(TextField)`
  & input::placeholder {
    opacity: 1;
    color: #000a14 !important;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }

  & .Mui-focused {
    border: none;
  }

  & .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 0;
    min-width: 0;
  }
`;
