import { UpdateGroup } from "src/interfaces/groups.interface";
import { GroupManagementStore } from "src/slices/opie-forms-management/groups/groups-interface";
export const initialState: GroupManagementStore = {
  groups: [],
  facilities: [],
  isLoading: false,
  currentGroup: <UpdateGroup>{},
  assignedLayouts: [],
  groupSearchData: [],
  searchFacilities: [],
  isSearchLoading: false,
  auditHistory: [],
};
