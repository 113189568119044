import { Box, Checkbox, Chip } from "@mui/material";
import * as React from "react";
import { OpieLayout } from "src/interfaces/layouts.interface";
import { StyledDialogRow } from "src/shared/layout-modal/styles";
interface Props {
  layout: OpieLayout;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const LayoutTitle = (props: Props) => {
  const { layout, isChecked, onChange } = props;
  return (
    <StyledDialogRow
      sx={{
        gridTemplateColumns: { xs: "40% 60%", sm: "30% 70%" },
        padding: "0.5rem",
      }}
      style={{ borderBottom: "1px solid #d1d3d4" }}
    >
      <Box
        sx={{
          fontSize: { xs: "12px", sm: "20px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Checkbox
          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          checked={isChecked}
          data-testid="checkbox"
          onChange={onChange}
        />

        <Box
          sx={{
            textDecoration: "underline #034A93",
            fontSize: "18px",
            fontWeight: "500",
            color: "#034A93",
          }}
        >
          {layout.defaultLayoutName}
        </Box>
        {!layout.isPublic && (
          <Chip
            sx={{
              background: "#F10008",
              fontWeight: 600,
              fontSize: "8px",
              color: "white",
              marginLeft: "8px",
              borderRadius: "20px",
              padding: "2Px 4px",
            }}
            size="small"
            label="Private"
            variant="outlined"
          />
        )}
      </Box>
    </StyledDialogRow>
  );
};

export default LayoutTitle;
