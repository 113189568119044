import moment from "moment";

export function sortByNumber(v1: number, v2: number) {
  return v1 - v2;
}

export function sortByDate(v1: Date, v2: Date) {
  const dateIsAfter = moment(v1).isAfter(moment(v2));
  return dateIsAfter ? 1 : -1;
}

export function sortByName(v1: string, v2: string): number {
  const value = v1?.localeCompare(v2, "en", { numeric: true });
  if (value === undefined) {
    return -1;
  } else {
    return value;
  }
}
