import * as React from "react";
import { Box } from "@mui/material";
const DeleteIcon = () => (
  <Box sx={{ cursor: "pointer" }}>
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none">
      <path
        fill="#F10008"
        d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3Zm1 2H6v12h12V8Zm-9 3h2v6H9v-6Zm4 0h2v6h-2v-6ZM9 4v2h6V4H9Z"
      />
    </svg>
  </Box>
);
export default DeleteIcon;
