import React, { useEffect, useState, useMemo, memo } from "react";
import {
  filterLayoutData,
  generateNestedItems,
} from "src/pages/facility-forms-management/layout-details/utils/layout-details-methods";
import FieldsMetaDataList from "src/pages/facility-forms-management/layout-details/components/fields-metadata/components/fields-metadata-list";
import { DragAndDropItemPosition, Field } from "src/interfaces/layout-details.interface";
import {
  GridContainer,
  LeftGridItem,
  RightGridItem,
} from "src/pages/facility-forms-management/layout-details/components/fields-metadata/styles";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/redux/store";
import {
  filterSelector,
  reArrangeLayoutFields,
  layoutFieldDetailsByIdSelector,
  bulkUpdateFields,
} from "src/slices/facility-forms-management/layout-details";
import FormCom from "src/pages/facility-forms-management/layout-details/components/fields-metadata/components/form";
import { Box } from "@mui/material";
import BulkUpdateConfirmationModal from "src/pages/facility-forms-management/layout-details/components/bulk-update-modal";

const FieldsMetaData: React.FC = memo(() => {
  const dispatch = useDispatch<AppDispatch>();
  const filterData = useSelector(filterSelector);
  const layoutFieldDetailData = useSelector(layoutFieldDetailsByIdSelector);

  const [localLayoutDetailData, setLocalLayoutDetailData] = useState<Field[]>([]);
  const [confirmBulkUpdateModal, setConfirmBulkUpdateModal] = useState(false);
  const filter = useMemo(() => filterData, [filterData]);

  const [bulkUpdateOptions, setBulkUpdateOptions] = useState<{
    option: string;
    fieldGuid: string;
    actionType: "update" | "getCount";
  }>({ fieldGuid: "", option: "", actionType: "getCount" });

  const onDragEnd = (value: DropResult) => {
    if (value.destination && !value.destination.index) return;

    const payload = {
      destinationPosition: value.destination && (value.destination.index as number),
      sourcePosition: value.source.index,
      draggableId: value.draggableId,
    };
    dispatch(reArrangeLayoutFields(payload as DragAndDropItemPosition));
  };

  useEffect(() => {
    if (filter.isApplied) {
      const filteredFields: Field[] = filterLayoutData(layoutFieldDetailData, filter);
      setLocalLayoutDetailData(filteredFields);
      return;
    }
  }, [filter, layoutFieldDetailData]);

  const nestedFields = generateNestedItems(
    filter.isApplied && localLayoutDetailData !== undefined
      ? localLayoutDetailData
      : layoutFieldDetailData
  );

  const onActionMenuItemSelectHandler = (option: string, fieldGuid: string) => {
    setBulkUpdateOptions({ option, fieldGuid, actionType: "update" });
    setConfirmBulkUpdateModal(true);
    dispatch(bulkUpdateFields({ option, fieldGuid, actionType: "getCount" }));
  };

  return (
    <>
      <BulkUpdateConfirmationModal
        openModal={confirmBulkUpdateModal}
        handleClose={() => setConfirmBulkUpdateModal(!confirmBulkUpdateModal)}
        handleConfirm={() => dispatch(bulkUpdateFields(bulkUpdateOptions))}
      />

      {(filter.isApplied &&
        localLayoutDetailData !== undefined &&
        localLayoutDetailData.length > 0) ||
      (!filter.isApplied && layoutFieldDetailData.length > 0) ? (
        <GridContainer container sx={{ paddingBottom: "4rem" }}>
          <LeftGridItem
            item
            xl={6}
            lg={6}
            sm={12}
            sx={{ height: "59vh", background: "white", overflowY: "auto" }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="fields">
                {(droppableProvided) => (
                  <section
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    style={{ paddingTop: "0.8rem" }}
                  >
                    {nestedFields.map((field) => (
                      <FieldsMetaDataList
                        key={field.fieldGUID}
                        field={field}
                        onDragEnd={onDragEnd}
                        onActionMenuItemSelectHandler={onActionMenuItemSelectHandler}
                      />
                    ))}
                    {droppableProvided.placeholder}
                  </section>
                )}
              </Droppable>
            </DragDropContext>
          </LeftGridItem>
          <RightGridItem item xl={5.9} lg={5.9} sm={12} sx={{ overflowY: "auto", height: "59vh" }}>
            <FormCom localLayoutDetailData={localLayoutDetailData} />
          </RightGridItem>
        </GridContainer>
      ) : (
        <Box sx={{ textAlign: "center", fontSize: "2rem", fontWeight: "bold", marginTop: "3rem" }}>
          No Fields Are Available!
        </Box>
      )}
    </>
  );
});

FieldsMetaData.displayName = "FieldsMetaData";

export default FieldsMetaData;
