import React from "react";
import CustomDialog from "src/shared/modal";
import {
  HideFormContainer,
  HideFormText,
  HideFormCancelButton,
  HideFormContinueButton,
  HideFormFooter,
} from "src/pages/facility-forms-management/layout-details/components/hide-form-modal/styles";

interface Props {
  open: boolean;
  handleOpen: (open: boolean, isContinue?: boolean) => void;
}

function HideFormModal({ open, handleOpen }: Props) {
  const handleContinue = async () => {
    handleOpen(false, true);
  };

  return (
    <CustomDialog open={open} handleOpen={handleOpen} maxWidth={"sm"}>
      <HideFormContainer>
        <HideFormText>
          This layout is currently available in OPIE Anywhere. Hiding the layout from your list will
          remove access to adding the form to patient records.
        </HideFormText>
        <HideFormFooter>
          <HideFormContinueButton
            variant="contained"
            data-testid="continue-btn"
            onClick={handleContinue}
          >
            Continue
          </HideFormContinueButton>
          <HideFormCancelButton
            data-testid="cancel-hide-form-btn"
            onClick={() => handleOpen(false, false)}
          >
            Cancel
          </HideFormCancelButton>
        </HideFormFooter>
      </HideFormContainer>
    </CustomDialog>
  );
}

export default HideFormModal;
