import * as React from "react";
import { GridColDef, GridCellParams } from "@mui/x-data-grid-pro";
import { Layout } from "src/interfaces/layouts.interface";
import moment from "moment";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";
import { sortByDate, sortByName } from "src/utils/sorting-methods";
import { LayoutActionColumnsRenderer } from "src/pages/facility-forms-management/layouts/components/layout-action-menu";
import { PUBLISHED_LAYOUTS } from "src/constants/route-constants";
import { StyledBadge, StyledEllipse } from "src/pages/facility-forms-management/layouts/styles";
import LayoutBadge from "src/shared/ui/badge";
import { LayoutBadgeType } from "src/shared/ui/badge/styles";

interface Props {
  handleMenuItemClick: (type: string, rowData: Layout) => void;
  pathName: string;
}

export const getLayoutColumns = ({ handleMenuItemClick, pathName }: Props): GridColDef[] => [
  {
    field: "defaultLayoutName",
    headerName: "Name",
    flex: 1,
    renderCell: (params: GridCellParams) => {
      const rowData: Layout = params.row;
      return (
        <>
          {" "}
          <StyledEllipse data-testid="data-grid-pro">
            {getConvertedUpperCaseString(
              rowData.defaultLayoutFriendlyName || rowData.defaultLayoutName
            )}
          </StyledEllipse>
          <StyledBadge>
            {moment().diff(
              pathName === PUBLISHED_LAYOUTS ? rowData.publishedOn : rowData.createdDate,
              "days"
            ) < 30 ? (
              <LayoutBadge layoutBadgeType={LayoutBadgeType.NEW} />
            ) : moment().diff(rowData.modifiedDate, "days") < 30 ? (
              <LayoutBadge layoutBadgeType={LayoutBadgeType.UPDATED} />
            ) : (
              <></>
            )}
          </StyledBadge>
        </>
      );
    },
    sortingOrder: ["asc", "desc"],
    valueGetter: (params) => params.row.defaultLayoutName,
    sortComparator: sortByName,
  },
  {
    field: "associatedType",
    headerName: "Device",
    sortable: false,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "associatedAnatomy",
    headerName: "Associated Anatomy",
    flex: 1,
    sortable: false,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "associatedDeviceType",
    headerName: "Device Type",
    flex: 1,
    sortable: false,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "associatedVisitTypes",
    headerName: "Visit Type",
    flex: 1,
    sortable: false,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "createdDate",
    headerName: pathName === PUBLISHED_LAYOUTS ? "Published" : "Created",
    flex: 1,
    renderCell: (params: GridCellParams) => {
      const rowData: Layout = params.row;
      const date: string =
        pathName === PUBLISHED_LAYOUTS
          ? moment(rowData.publishedOn as Date).format("L")
          : moment(rowData.createdDate as Date).format("L");
      return `${date}`;
    },
    valueGetter: (params) => params.row.createdDate as Date,
    sortingOrder: ["asc", "desc"],
    sortComparator: sortByDate,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "modifiedDate",
    headerName: "Modified",
    flex: 1,
    renderCell: (params: GridCellParams) => {
      const rowData: Layout = params.row;
      const date: string = moment(rowData.modifiedDate as Date).format("L");
      return `${date}`;
    },
    valueGetter: (params) => params.row.modifiedDate as Date,
    sortingOrder: ["asc", "desc"],
    sortComparator: sortByDate,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "actions",
    headerName: "",
    flex: 1,
    sortable: false,
    align: "left",
    headerAlign: "left",
    renderCell: (params: GridCellParams) => {
      return (
        <LayoutActionColumnsRenderer params={params} handleMenuItemClick={handleMenuItemClick} />
      );
    },
  },
];
