import { initialState } from "src/slices/facility-forms-management/layouts/layouts-initial-state";
import {
  facilityLayoutsStoreSelector,
  facilityLayoutsSelector,
} from "src/slices/facility-forms-management/layouts/layouts-selector";
import { facilityLayoutsSlice } from "src/slices/facility-forms-management/layouts/layouts-slices";
export { initialState };
export const { resetLayoutsData } = facilityLayoutsSlice.actions;
export default facilityLayoutsSlice.reducer;

export { facilityLayoutsStoreSelector, facilityLayoutsSelector };
