import styled from "styled-components";

export const DialogFacilityDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 1rem;
`;
export const DialogFacilityBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #034a93;
  color: white;
  border-radius: 20px;
  padding: 4px 12px;
  margin: 4px;
`;
