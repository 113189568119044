import axios from "axios";
import qs from "qs";
import { BASE_URL } from "src/constants/app-config-constants";
import { LayoutSearchParams, PublishLayout } from "src/interfaces/layouts.interface";
import { HTTPResponse } from "src/interfaces/httpResponse.interface";
import { LayoutDetails } from "src/interfaces/layout-details.interface";

export async function getLayouts(params: LayoutSearchParams): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts`;
  return axios.get(url, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
  });
}

export async function getLayoutsFilter(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/filters`;
  return axios.get(url);
}

export async function createLayout(layoutDetails: LayoutDetails): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts`;
  return axios.post(url, layoutDetails);
}

export async function deleteCustomLayout(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${id}`;
  return axios.delete(url);
}

export async function getLayoutCounts(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/counts`;
  return axios.get(url);
}

export async function getPublishLayout(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${id}/publish`;
  return axios.get(url);
}

export async function updatePublishLayout(id: string, body: PublishLayout): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${id}/publish`;
  return axios.put(url, body);
}
export async function unPublishLayout(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${id}/publish`;
  return axios.delete(url);
}

export async function duplicateLayout(data: {
  id: string;
  layoutName: string;
}): Promise<HTTPResponse> {
  const { id, layoutName } = data;
  const url = `${BASE_URL}/layouts/${id}/duplicate`;
  return axios.post(url, { layoutName });
}

export async function checkShareLayoutValidity(
  facilityId: string,
  layoutId: string
): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${layoutId}/facility/${facilityId}/share`;
  return axios.get(url);
}

export async function acceptSharedLayout(
  facilityId: string,
  layoutId: string
): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${layoutId}/facility/${facilityId}/share`;
  return axios.post(url);
}
