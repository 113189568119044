import styled from "styled-components";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const UserProfileDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const UserLogo = styled.div`
  margin-right: 1.5rem;
  background: white;
  height: 48px;
  width: 48px;
  border-radius: 6px;
  color: #034a93;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserName = styled(Box)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  color: #034a93;
  text-transform: none !important;
`;

export const NavLinkMD = styled(NavLink)<{ fontWeight: number }>`
  color: white;
  font-weight: ${(props) => props.fontWeight};
`;
