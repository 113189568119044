import { Box, Typography } from "@mui/material";
import { ImpersonationButton, StyledImpersonationBar } from "src/shared/header/impersonation/style";
import * as React from "react";
interface ImpersonationBarProps {
  isImpersonating: boolean;
  userName: string | null;
  onLogout: () => void;
}

function ImpersonationBar({ isImpersonating, userName, onLogout }: ImpersonationBarProps) {
  return (
    <StyledImpersonationBar>
      {isImpersonating && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            width: { xs: "100%", md: "auto" },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "normal",
              fontSize: {
                xs: "0.8rem",
                md: "1rem",
              },
              textAlign: "center",
            }}
          >
            Logged in as :
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "0.8rem",
                md: "1rem",
              },
              textAlign: "center",
              paddingLeft: {
                xs: 0,
                md: "0.5rem",
              },
              paddingRight: {
                xs: 0,
                md: "1rem",
              },
            }}
          >
            {userName}
          </Typography>

          <ImpersonationButton
            variant="contained"
            onClick={onLogout}
            data-testid="impersonateBtn"
            sx={{
              width: { xs: "80%", md: "auto" },
              mt: { xs: 0.3, md: 0 },
              fontSize: {
                xs: "0.8rem",
                md: "1rem",
              },
            }}
          >
            Logout
          </ImpersonationButton>
        </Box>
      )}
    </StyledImpersonationBar>
  );
}

export default ImpersonationBar;
