import axios from "axios";
import { BASE_URL } from "src/constants/app-config-constants";
import { HTTPResponse } from "src/interfaces/httpResponse.interface";
import { ChangePassword, User } from "src/interfaces/user.interface";

export async function getLoggedInUser(loginData: User): Promise<HTTPResponse> {
  const url = `${BASE_URL}/auth/login`;
  return axios.post(url, loginData);
}

export async function resetPasswordService(req: ChangePassword): Promise<HTTPResponse> {
  const { type, ...rest } = req;
  const url =
    type === "reset" ? `${BASE_URL}/auth/resetPassword` : `${BASE_URL}/users/createPassword`;
  return axios.post(url, rest);
}

export async function getForgotPasswordLink(email: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/auth/forgotPassword`;
  return axios.post(url, { email });
}

export async function getCurrentLoggedInUser(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/current`;
  return axios.post(url);
}

export async function logoutUser(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/auth/logout`;
  return axios.post(url);
}
