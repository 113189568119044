import React from "react";
import Dialog from "@mui/material/Dialog";
import { CloseIcon, DialogDiv } from "src/shared/modal/styles";
import { Breakpoint } from "@mui/material";
interface DialogProps {
  open: boolean;
  handleOpen: (open: boolean) => void;
  handleClear?: (
    e: React.MouseEvent<HTMLButtonElement | SVGSVGElement | HTMLDivElement, MouseEvent>
  ) => void;
  maxWidth: Breakpoint;
  children: React.ReactNode;
}
const CustomDialog: React.FC<DialogProps> = ({
  open,
  maxWidth,
  handleOpen,
  handleClear,
  children,
}) => {
  const handleCloseDialog = (
    e: React.MouseEvent<HTMLButtonElement | SVGSVGElement | HTMLDivElement, MouseEvent>
  ) => {
    if (handleClear) {
      handleClear(e);
    }
    handleOpen(false);
  };
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth>
      <DialogDiv>
        <CloseIcon onClick={(e) => handleCloseDialog(e)} data-testid="close" />
      </DialogDiv>
      {children}
    </Dialog>
  );
};

export default CustomDialog;
