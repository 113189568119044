import { Accordion, AccordionSummary } from "@mui/material";
import styled from "styled-components";

export const AccordionHeading = styled.p`
  font-weight: 600;
  font-size: 20px;
  color: #034a93;
  margin: 0;
  margin-left: 0.5rem;
`;

export const AccordionDiv = styled(Accordion)`
  background: #ffffff !important;
  border-radius: 6px !important;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #f7f7f7;
  font-size: 18px;
  font-weight: bold;
  background: #ffffff !important;
  border-radius: 6px !important;
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    order: -1;
  }
`;
