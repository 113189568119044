import React from "react";
import Header from "src/shared/header";
import SideBar from "src/shared/side-bar";
import { LayoutContainer } from "src/layouts/app-layout/styles";
import { Box } from "@mui/system";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useLocalStorage } from "src/utils/storage.hook";
import { useSelector } from "react-redux";
import { LOCALSTORAGE_USER_TOKEN_KEY } from "src/constants/auth-constants";
import { tokenSelector } from "src/slices/shared/user";
import { LOGIN } from "src/constants/route-constants";

function AppLayout(): React.ReactElement {
  const location = useLocation();
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(false);
  const [storedToken] = useLocalStorage(LOCALSTORAGE_USER_TOKEN_KEY);
  const token = useSelector(tokenSelector) || storedToken;

  const handleSidebarView = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  if (!token) {
    // user is not authenticated
    return <Navigate to={LOGIN} state={{ redirectUrl: location.pathname }} />;
  }

  return (
    <Box sx={{ height: "100vh", width: "100vw" }}>
      <Header handleSidebarView={handleSidebarView} />
      <LayoutContainer>
        <Box
          sx={{
            width: { xl: "15vw", lg: "15vw", md: "20vw", sm: 0, xs: 0 },
            height: "91vh",
          }}
        >
          <SideBar handleSidebarView={handleSidebarView} isSideBarOpen={isSideBarOpen} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { xl: "85vw", lg: "85vw", md: "80vw", sm: "100vw", xs: "100vw" },
            background: "#F5F5F5",
            height: "91vh",
          }}
        >
          <Box sx={{ p: "1.5rem", background: "#F5F5F5", width: "100%", height: "91vh" }}>
            <Outlet />
          </Box>
        </Box>
      </LayoutContainer>
    </Box>
  );
}

export default AppLayout;
