import React, { useEffect, useMemo, useState } from "react";
import {
  InputLabelText,
  InputTextField,
  SubmitButton,
  Heading,
  RequiredText,
  ShowPasswordIcon,
  HidePasswordIcon,
  PasswordDiv,
  ErrorIconDiv,
  ErrorDiv,
  ErrorText,
  DoneIcon,
} from "src/pages/shared/auth/reset-password/styles";
import { ErrorsInterface, ForgotPassword, ShowForgotPassword } from "src/interfaces/user.interface";
import AuthLayout from "src/shared/auth-layout";
import { Grid } from "@mui/material";

import { errorConstants } from "src/constants/data";
import { PASSWORD_MUST_MATCH } from "src/constants/error-constants";
import { useNavigate, useLocation } from "react-router-dom";
import { LOGIN, USER_MANAGEMENT } from "src/constants/route-constants";
import { resetPasswordService } from "src/services/user-services";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LOCALSTORAGE_USER_TOKEN_KEY } from "src/constants/auth-constants";
import { tokenSelector, setToken } from "src/slices/shared/user";
import { useLocalStorage } from "src/utils/storage.hook";

function ResetPassword() {
  const inputRef = React.useRef<HTMLHeadingElement>();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParameters = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [resetPasswordDetails, setResetPasswordDetails] = useState<ForgotPassword>({
    password: "",
    confirmPassword: "",
  });

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false);

  const [passwordErrors, setPasswordErrors] = useState<ShowForgotPassword>({
    password: false,
    confirmPassword: false,
  });

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [errors, setErrors] = useState<ErrorsInterface[]>([]);
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = useSelector(tokenSelector);
  const [storedToken] = useLocalStorage(LOCALSTORAGE_USER_TOKEN_KEY);
  const dispatch = useDispatch();

  useEffect(() => {
    setErrors(errorConstants);
  }, []);

  useEffect(() => {
    dispatch(setToken(storedToken));
  }, [dispatch, storedToken]);

  useEffect(() => {
    if (token) {
      navigate(USER_MANAGEMENT);
    }
  }, [token, navigate]);

  useEffect(() => {
    if (!queryParameters.get("email")) {
      navigate(LOGIN);
    } else if (token) {
      navigate(USER_MANAGEMENT);
    }
  }, [queryParameters, navigate, token]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const { password, confirmPassword } = resetPasswordDetails;
    setResetPasswordDetails({ ...resetPasswordDetails, [id]: value });
    if (id === "password") {
      errors.map((item, i) => {
        if (item.func.test(value)) {
          errors[i].status = true;
          passwordErrors.password = true;
        } else {
          errors[i].status = false;
          passwordErrors.password = false;
        }
      });
      setErrors([...errors]);
      passwordErrors.password =
        value !== "" && errors.map((item) => item.status).includes(false) ? true : false;
    }
    if (
      (id === "confirmPassword" && password !== value) ||
      (id === "password" && value !== confirmPassword && confirmPassword.length > 0)
    ) {
      setConfirmPasswordError(PASSWORD_MUST_MATCH);
      passwordErrors.confirmPassword = true;
    } else {
      setConfirmPasswordError("");
      passwordErrors.confirmPassword = false;
    }
    setPasswordErrors({ ...passwordErrors });
  };

  const togglePasswordVisibility = (value: boolean) => {
    setIsShowPassword(value ? false : true);
  };
  const submitLoginData = async () => {
    setIsLoading(true);
    const data = {
      type: queryParameters.get("type") as string,
      password: resetPasswordDetails.password,
      email: queryParameters.get("email") as string,
      code: queryParameters.get("code") as string,
    };
    setIsLoading(true);
    try {
      const result = await resetPasswordService(data);
      if (result.data) {
        toast.success("Password Updated Successfully!");
        setIsLoading(false);
        navigate(LOGIN);
      } // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(resetPasswordDetails)) {
      if (value === "" || passwordErrors.password || passwordErrors.confirmPassword) {
        setIsDisabled(true);
        return;
      }
      setIsDisabled(false);
    }
  }, [resetPasswordDetails, passwordErrors]);

  return (
    <AuthLayout>
      <Grid container item xs={12} paddingTop="4rem" height={"100%"}>
        <Grid item xs={1} md={1} lg={2}></Grid>
        <Grid item xs={10} md={10} lg={8}>
          <Heading data-testid="heading">Password Creation</Heading>
          <InputLabelText>
            Create Password <RequiredText>*</RequiredText>
          </InputLabelText>
          <PasswordDiv>
            {isShowPassword ? (
              <ShowPasswordIcon
                data-testid="hideText"
                onClick={() => togglePasswordVisibility(isShowPassword)}
              />
            ) : (
              <HidePasswordIcon
                data-testid="showText"
                onClick={() => togglePasswordVisibility(isShowPassword)}
              />
            )}
            <InputTextField
              id="password"
              variant="outlined"
              size="small"
              type={isShowPassword ? "text" : "password"}
              placeholder="Password"
              onChange={handleInput}
              inputProps={{ "data-testid": "newPassword" }}
              inputRef={inputRef}
              error={Boolean(passwordErrors.password)}
            />
          </PasswordDiv>
          <InputLabelText>
            Confirm Password <RequiredText>*</RequiredText>
          </InputLabelText>
          <PasswordDiv>
            {isShowConfirmPassword ? (
              <ShowPasswordIcon
                data-testid="hideConfirmPassText"
                onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
              />
            ) : (
              <HidePasswordIcon
                data-testid="showConfirmPassText"
                onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
              />
            )}
            <InputTextField
              id="confirmPassword"
              variant="outlined"
              size="small"
              placeholder="Confirm Password"
              onChange={handleInput}
              type={isShowConfirmPassword ? "text" : "password"}
              inputRef={inputRef}
              inputProps={{ "data-testid": "confirmPassword" }}
              helperText={confirmPasswordError}
              error={Boolean(passwordErrors.confirmPassword)}
            />
          </PasswordDiv>
          <Grid container item xs={12} marginTop="1rem">
            {errors.map((item, i) => {
              const color =
                resetPasswordDetails.password === ""
                  ? "#03478C"
                  : item.status
                  ? "#00B03C"
                  : "#F10008";
              return (
                <Grid key={i} item xs={6} sm={12} md={6} paddingBottom="0.8rem">
                  <ErrorDiv>
                    {item.status ? <DoneIcon /> : <ErrorIconDiv style={{ color: color }} />}
                    <ErrorText color={color}>{item.text}</ErrorText>
                  </ErrorDiv>
                </Grid>
              );
            })}
          </Grid>
          <SubmitButton
            variant="contained"
            size="large"
            onClick={submitLoginData}
            disabled={isDisabled}
            data-testid="submit"
            loading={isLoading}
          >
            Create Password
          </SubmitButton>
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default ResetPassword;
