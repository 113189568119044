import React from "react";
import {
  StyledFooter,
  StyledFooterNextButton,
} from "src/pages/facility-forms-management/user-impersonation/components/footer/styles";
import { PaginationFormat } from "src/interfaces/pagination.interface";
import { SaveUserIcon } from "src/pages/opie-forms-management/user-management/components/create-user/styles";

interface Props {
  handleUserSelect: () => void;
  handleFacilityTab: () => void;
  setPrevFacilityCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isFacilityTabSelected: boolean;
  pagination: PaginationFormat;
  isDisable: boolean;
}

function Footer({
  handleUserSelect,
  handleFacilityTab,
  setPrevFacilityCurrentPage,
  pagination,
  isFacilityTabSelected,
  isDisable,
}: Props) {
  return (
    <>
      {isFacilityTabSelected && (
        <StyledFooter>
          <StyledFooterNextButton
            variant="contained"
            data-testid={"next-btn"}
            disabled={isDisable}
            onClick={() => {
              setPrevFacilityCurrentPage(pagination.currentPage);
              handleUserSelect();
            }}
          >
            <SaveUserIcon />
            Next
          </StyledFooterNextButton>
        </StyledFooter>
      )}
      {!isFacilityTabSelected && (
        <StyledFooter>
          <StyledFooterNextButton
            data-testid="back-btn"
            sx={{ background: "white !important", color: "#034a93", border: "1px solid" }}
            variant="contained"
            onClick={handleFacilityTab}
          >
            Back
          </StyledFooterNextButton>
        </StyledFooter>
      )}
    </>
  );
}

export default Footer;
