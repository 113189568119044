import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { GROUPS } from "src/constants/route-constants";
import BreadCrumb from "src/shared/ui/bread-crumb";
import { DataGridPro, GridCellParams, GridColDef } from "@mui/x-data-grid-pro";

import {
  createGroupThunk,
  getFacilitiesThunk,
  searchFacilityFromGroupThunk,
} from "src/slices/opie-forms-management/groups/groups-thunk";

import SortingIcon from "src/shared/icons/sorting-icon";
import { dataGridStyles } from "src/constants/datagrid-styles";
import AccordionTemplate from "src/shared/ui/accordion";
import { HeaderDiv, Heading } from "src/pages/opie-forms-management/user-management/styles";
import { StyledGroupDiv } from "src/pages/opie-forms-management/groups/components/create-group/styles";

import {
  SaveUserDiv,
  SaveUserIcon,
} from "src/pages/opie-forms-management/user-management/components/create-user/styles";

import { CreateGroupInterface, Facility } from "src/interfaces/groups.interface";
import SearchBar from "src/shared/ui/search-bar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/redux/store";
import { groupSelector } from "src/slices/opie-forms-management/groups";
import FormDataComponent from "src/pages/opie-forms-management/groups/components/form-data";
import Checkbox from "@mui/material/Checkbox";
import ChipContainer from "src/shared/ui/chip";
import { useNavigate } from "react-router-dom";
import {
  mapFacilitiesWithCheckedKey,
  filterCheckedFacilitiesFromList,
} from "src/pages/opie-forms-management/groups/utils/methods";

const initialState: CreateGroupInterface = {
  name: "",
  description: "",
  facilities: [],
  is_default: false,
};

function CreateGroup() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const groupList = useSelector(groupSelector);
  const [groupData, setGroupData] = useState<CreateGroupInterface>(initialState);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<Facility[]>([]);
  const [selected, setSelected] = useState<Facility[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    dispatch(getFacilitiesThunk());
  }, [dispatch]);

  useEffect(() => {
    const list = mapFacilitiesWithCheckedKey(groupList.facilities);
    setRows(list);
  }, [groupList.facilities]);

  useEffect(() => {
    setGroupData({ ...initialState });
  }, []);

  const handleModalEmpty = () => {
    setGroupData({ ...initialState });
    const res = mapFacilitiesWithCheckedKey(groupList.facilities);
    setRows(res);
    setSelected([]);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id, checked } = e.target;
    if (id === "checkbox") {
      setGroupData({ ...groupData, is_default: checked });
    } else {
      setGroupData({ ...groupData, [id]: value });
    }
  };

  useEffect(() => {
    if (searchValue) {
      if (groupList.searchFacilities.length > 0) {
        const list = mapFacilitiesWithCheckedKey(groupList.searchFacilities);
        const mappedExistingFilters = filterCheckedFacilitiesFromList(list, selected);
        if (mappedExistingFilters.length > 0) {
          setRows(mappedExistingFilters);
        } else {
          setRows(list);
        }
      } else {
        setRows([]);
      }
    }
    // eslint-disable-next-line
  }, [groupList.searchFacilities]);

  const handleSearchFacilities = () => {
    dispatch(searchFacilityFromGroupThunk(searchValue));
  };

  const resetFacilitiesList = () => {
    const list = mapFacilitiesWithCheckedKey(groupList.facilities);
    const mappedExistingFilters = filterCheckedFacilitiesFromList(list, selected);
    if (mappedExistingFilters.length > 0) {
      setRows(mappedExistingFilters);
    } else {
      setRows(list);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value === "") {
      resetFacilitiesList();
    }
  };

  const handleFilterList = () => {
    if (searchValue.length > 0) {
      handleSearchFacilities();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === "Enter" && searchValue) {
      handleSearchFacilities();
    }
  };

  const handleCreateGroup = () => {
    setIsLoading(true);
    dispatch(createGroupThunk(groupData))
      .unwrap()
      .then(() => {
        handleModalEmpty();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //add selected Facility from Grid
  const handleRowSelection = (rowData: Facility) => {
    const { id } = rowData;
    //Add id to the selected list and initial state
    if (!groupData.facilities.includes(id)) {
      setGroupData({ ...groupData, facilities: [...groupData.facilities, id] });
      const updatedStatus = { ...rowData };
      updatedStatus.isChecked = true;
      setSelected([...selected, updatedStatus]);
    } else {
      const filtered = groupData.facilities.filter((itemId) => itemId !== id);
      setGroupData({ ...groupData, facilities: [...filtered] });
      const filteredFacilities = selected.filter((item) => item.id !== id);
      setSelected([...filteredFacilities]);
    }
    const updatedList = rows.map((item) => {
      const copy = { ...item };
      if (copy.id == id) {
        copy.isChecked = !copy.isChecked;
      }
      return copy;
    });
    setRows(updatedList);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Facility Name",
      flex: 1,
      sortingOrder: ["asc", "desc"],
      renderCell: (params: GridCellParams) => {
        const rowData: Facility = params.row;
        return (
          <Box>
            <Checkbox
              data-testid="checkbox"
              checked={rowData.isChecked}
              onChange={() => {
                handleRowSelection(rowData);
              }}
            />
            {rowData.name}
          </Box>
        );
      },
    },
    {
      field: "id",
      headerName: "Facility Id",
      flex: 1,
      sortingOrder: ["asc", "desc"],
    },
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(groupData)) {
      if (key !== "facilities" && value === "") {
        setIsDisabled(true);
        return;
      }
      setIsDisabled(false);
    }
    // eslint-disable-next-line
  }, [groupData]);

  return (
    <>
      <HeaderDiv>
        <Heading>Facility Groups</Heading>
      </HeaderDiv>
      <BreadCrumb
        linkList={[
          {
            url: GROUPS,
            heading: "Facility Groups",
          },
        ]}
      />
      <StyledGroupDiv>
        <FormDataComponent
          handleInput={handleInput}
          groupData={{
            name: groupData.name,
            description: groupData.description,
            is_default: groupData.is_default,
          }}
        />
        <AccordionTemplate heading="Facilities">
          <>
            {selected.length > 0 && (
              <ChipContainer handleRemoveMember={handleRowSelection} facilities={selected} />
            )}
            <SearchBar
              handleSearch={handleSearch}
              handleKeyPress={handleKeyPress}
              handleFilterList={handleFilterList}
              styles={{ padding: "0 1rem 0.5rem" }}
            />
            <DataGridPro
              disableColumnMenu
              autoHeight
              rows={!groupList.isSearchLoading ? rows : []}
              columns={columns}
              hideFooterRowCount
              filterMode="client"
              sx={{ ...dataGridStyles, borderRadius: "0px" }}
              sortingOrder={["asc", "desc"]}
              slots={{
                columnSortedAscendingIcon: SortingIcon,
                columnSortedDescendingIcon: SortingIcon,
              }}
              hideFooterSelectedRowCount
              loading={groupList.isSearchLoading}
            />
          </>
        </AccordionTemplate>
      </StyledGroupDiv>
      <Box sx={{ display: "flex", gap: "15px", margin: "1rem 0" }}>
        <SaveUserDiv
          data-testid="save-data"
          loading={isLoading}
          variant="contained"
          onClick={handleCreateGroup}
          disabled={isDisabled}
        >
          <SaveUserIcon />
          Create Group
        </SaveUserDiv>
        <SaveUserDiv
          data-testid="cancel"
          onClick={() => {
            handleModalEmpty();
            navigate(GROUPS);
          }}
          variant="outlined"
          sx={{ background: "white !important", color: "#034a93" }}
        >
          Cancel
        </SaveUserDiv>
      </Box>
    </>
  );
}

export default CreateGroup;
