import axios from "axios";
import { BASE_URL } from "src/constants/app-config-constants";
import { HTTPResponse } from "src/interfaces/httpResponse.interface";
import { LayoutDetails } from "src/interfaces/layout-details.interface";

export async function getLayoutById(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${id}/fields`;
  return axios.get(url);
}

export async function updateLayoutById(layoutData: LayoutDetails): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${layoutData.id}`;
  return axios.put(url, layoutData);
}

export async function updateHideFormById(layoutData: LayoutDetails): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts/${layoutData.id}/visibility`;
  return axios.put(url, layoutData);
}
