import * as React from "react";
import { Box } from "@mui/material";
interface Props {
  color?: string;
}
const MoreIcon = ({ color }: Props) => (
  <Box sx={{ cursor: "pointer" }}>
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none">
      <path
        fill={color ? color : "#000"}
        d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
      ></path>
    </svg>
  </Box>
);
export default MoreIcon;
