import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

function Loader() {
  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <CircularProgress />
    </Box>
  );
}

export default Loader;
