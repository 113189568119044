import * as React from "react";
const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="m15.027 13.848 3.57 3.568-1.18 1.18-3.568-3.57a7.467 7.467 0 0 1-4.681 1.641c-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5 4.14 0 7.5 3.36 7.5 7.5a7.466 7.466 0 0 1-1.64 4.68Zm-1.671-.619A5.814 5.814 0 0 0 15 9.168a5.832 5.832 0 0 0-5.833-5.833 5.831 5.831 0 0 0-5.833 5.833A5.832 5.832 0 0 0 9.168 15a5.814 5.814 0 0 0 4.063-1.646l.124-.124Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SearchIcon;
