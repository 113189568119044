import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Layout } from "src/interfaces/layouts.interface";
import { User } from "src/interfaces/user-management.interface";
import {
  getUsers,
  updateUser,
  createUser,
  deleteUser,
  updateUserStatus,
  getRoles,
  getUserById,
  userLayouts,
  addUserLayout,
  deleteUserLayout,
} from "src/services/user-management-services";
import { getConvertedSentenceToUpperCase } from "src/utils/convert-strings-case";

export const getUsersThunk = createAsyncThunk("getUsersThunk", async (_, { rejectWithValue }) => {
  try {
    const res = await getUsers();
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const getUserByIdThunk = createAsyncThunk(
  "getUserByIdThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getUserById(id);
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserThunk = createAsyncThunk(
  "createUserThunk",
  async (user: User, { rejectWithValue }) => {
    try {
      const res = await createUser(user);
      toast.success("User Created Successfully!");
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserThunk = createAsyncThunk(
  "updateUserThunk",
  async (user: User, { rejectWithValue }) => {
    try {
      await updateUser(user);
      toast.success("User Updated Successfully!");
      return { data: user };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserThunk = createAsyncThunk(
  "deleteUserThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteUser(id);
      toast.success("User Deleted Successfully!");
      return { id: id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateUserStatusThunk = createAsyncThunk(
  "updateUserStatusThunk",
  async (
    data: {
      id: string;
      status: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      await updateUserStatus(data);
      toast.success("Updated Status Successfully!");
      return { id: data.id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRolesThunk = createAsyncThunk("getRolesThunk", async (_, { rejectWithValue }) => {
  try {
    const res = await getRoles();
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
    return rejectWithValue(err.response.data);
  }
});

export const getUserLayoutThunk = createAsyncThunk(
  "getUserLayoutThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await userLayouts(id);

      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserLayoutThunk = createAsyncThunk(
  "updateUserLayoutThunk",
  async (req: { id: string; layouts: Layout[] }, { rejectWithValue }) => {
    const layoutsId: string[] = req.layouts.map((layout: Layout) => layout.id);

    try {
      const res = await addUserLayout(req.id, layoutsId);
      toast.success("Assigned Private Layouts Successfully To The User!");
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserLayoutThunk = createAsyncThunk(
  "deleteUserLayoutThunk",
  async (req: { id: string; layoutId: string }, { rejectWithValue }) => {
    try {
      await deleteUserLayout(req.id, req.layoutId);
      toast.success("User Layout Deleted Successfully!");
      return { id: req.id, layoutId: req.layoutId };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
