import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  StyledFilterForm,
  StyledDiv,
  StyledClearButton,
  StyledButton,
} from "src/pages/opie-forms-management/groups/components/filter/styles";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { FilterOptions } from "src/interfaces/groups.interface";
import { useDispatch, useSelector } from "react-redux";

import RangeCalendar from "src/shared/calendar";
import { Autocomplete, TextField } from "@mui/material";
import { User } from "src/interfaces/user-management.interface";
import { userManagementSelector } from "src/slices/opie-forms-management/user-management";
import { getUsersThunk } from "src/slices/opie-forms-management/user-management/user-management-thunks";
import { AppDispatch } from "src/redux/store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface Props {
  filterOptions: FilterOptions;
  handleCreatedBy: (event: React.SyntheticEvent<Element, Event>, value: User | null) => void;
  handleDate: (from: string, to: string) => void;
  handleApplyFilters: () => void;
  handleClearFilter: () => void;
  selectedDate: string;
}

const GroupsFilter = ({
  filterOptions,
  handleCreatedBy,
  handleDate,
  handleApplyFilters,
  handleClearFilter,
  selectedDate,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState<boolean>(false);
  const users = useSelector(userManagementSelector);

  useEffect(() => {
    dispatch(getUsersThunk());
  }, [dispatch]);

  return (
    <>
      <StyledFilterForm>
        <StyledDiv>
          <FilterListOutlinedIcon />
          <Box
            onClick={() => {
              setOpen(false);
            }}
          >
            <RangeCalendar
              handleDate={handleDate}
              selectedDate={selectedDate}
              isDisableFuture={true}
              placeholderText="Creation Date Range"
              handleClose={() => setOpen(false)}
            />
          </Box>
          <Box sx={{ display: "flex", position: "relative", gap: "10px" }}>
            <Autocomplete
              id="users"
              open={open}
              options={users}
              onChange={(e, value) => {
                setOpen(!open);
                handleCreatedBy(e, value);
              }}
              value={filterOptions.createdBy}
              size="small"
              popupIcon={""}
              disableClearable
              disabled
              clearIcon={null}
              sx={{
                width: "17rem",
                border: "1px solid #034A93",
                borderRadius: "6px",
                height: "40px",
                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
                  textAlign: "center",
                  color: "#000A14 !important",
                  opacity: 1,
                },
                "& .MuiAutocomplete-inputRoot": {
                  padding: "6px 0 !important",
                },
                "& .Mui-focused": {
                  border: "none",
                },
                "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                  width: 0,
                  minWidth: 10,
                },
              }}
              isOptionEqualToValue={(option: User, value: User) => option.id === value.id || false}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  onClick={() => setOpen(!open)}
                  {...params}
                  className="text-field"
                  data-testid="select-role"
                  placeholder="Created By"
                  sx={{
                    "input::placeholder": {
                      opacity: 1,
                      color: "#000A14 !important",
                      fontWeight: 400,
                      fontSize: "16px",
                      textAlign: "center",
                    },
                    "& .Mui-focused": {
                      border: "none",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000A14",
                      cursor: "pointer",
                    },
                  }}
                />
              )}
              data-testid="autocomplete-select"
            />
            <Box
              onClick={() => setOpen(!open)}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px",
                border: "1px solid #034A93",
                borderRadius: "6px",
                cursor: "pointer",
              }}
              data-testid="open-dropdown"
            >
              <KeyboardArrowDownIcon htmlColor="#034A93" />
            </Box>
          </Box>
        </StyledDiv>
        <StyledDiv>
          <StyledButton variant="outlined" onClick={handleApplyFilters} data-testid="apply-filters">
            Apply
          </StyledButton>
          <StyledClearButton onClick={handleClearFilter} data-testid="clear-filters">
            Clear
          </StyledClearButton>
        </StyledDiv>
      </StyledFilterForm>
    </>
  );
};

export default GroupsFilter;
