import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LOCALSTORAGE_FACILITY_ID, LOCALSTORAGE_USER_ID } from "src/constants/auth-constants";
import { CUSTOM_LAYOUTS, DASHBOARD, FACILITY, LAYOUT, SHARE } from "src/constants/route-constants";
import { SharedLayoutDetail } from "src/interfaces/layouts.interface";
import {
  StyledSharedLayoutHeading,
  StyledSharedLayoutContent,
  StyledBox,
  StyledName,
  StyledParagraph,
  StyledAcceptButton,
  StyledShareLayoutFooter,
  StyledFacilityName,
  StyledLayoutName,
} from "src/pages/facility-forms-management/shared-layout/styles";
import { acceptSharedLayout, checkShareLayoutValidity } from "src/services/layouts-service";
import ValidateImpersonateUser from "src/shared/hoc/validate-impersonate-user";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";
import { getValueFromStorage } from "src/utils/storage";
import Loader from "src/shared/loader";

function SharedLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [accept, setAccept] = useState<boolean>(false);
  const [layoutExist, setLayoutExist] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [alreadySharedMessage, setAlreadyMessage] = useState<string>(
    "Layout is either not available or not shareable or deleted"
  );
  const [sharedLayoutDetail, setSharedDetail] = useState<SharedLayoutDetail>({
    layout: {
      defaultLayoutGuid: "",
      defaultLayoutName: "",
      id: "",
    },
    facility: {
      id: "",
      name: "",
    },
    isShared: false,
  });

  const facilityId = location.pathname.split("/")[3];
  const layoutId = location.pathname.split("/")[5];

  const getSharedLayoutDetail = async () => {
    try {
      const response = await checkShareLayoutValidity(facilityId, layoutId);

      const data = (await response.data.data) as SharedLayoutDetail;
      if (response.data.statusCode === 200) {
        setSharedDetail(data);
        setLayoutExist(true);
        setLoader(false);
      }

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = error.response.data.message as string;
      setAlreadyMessage(message);
      setLoader(false);
    }
  };

  useEffect(() => {
    const facilityIdExitInLocalStorage = getValueFromStorage(LOCALSTORAGE_FACILITY_ID);
    const userImpersonation = getValueFromStorage(LOCALSTORAGE_USER_ID);
    if (facilityIdExitInLocalStorage && userImpersonation) {
      getSharedLayoutDetail();
    } else {
      const redirectUrl = `${SHARE}${FACILITY}/${facilityId}${LAYOUT}/${layoutId}`;
      navigate("/", { state: { redirectUrl: redirectUrl } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccept = async () => {
    try {
      const response = await acceptSharedLayout(facilityId, layoutId);
      if (response.data.statusCode === 200) {
        toast.success(getConvertedUpperCaseString(response.data.message));
        setAccept(true);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = error.response.data.message as string;
      toast.error(getConvertedUpperCaseString(message));
    }
  };

  const handleCancel = () => {
    navigate(DASHBOARD);
  };

  const handleGoToLayouts = () => {
    navigate(CUSTOM_LAYOUTS);
  };

  return (
    <>
      {layoutExist ? (
        <StyledBox>
          {sharedLayoutDetail.isShared || accept ? (
            <>
              <Box style={{ fontSize: "2rem", margin: "1rem" }}>
                <StyledName>{sharedLayoutDetail.layout.defaultLayoutFriendlyName}</StyledName>
                {accept ? "shared with" : "has already been shared with"}
                <StyledFacilityName>{sharedLayoutDetail.facility.name}</StyledFacilityName>
              </Box>
              <StyledAcceptButton
                data-testid="go-to-layouts"
                variant="contained"
                bgColor="#034a93"
                textColor="white"
                onClick={handleGoToLayouts}
              >
                Go To Layout
              </StyledAcceptButton>
            </>
          ) : (
            <>
              <StyledSharedLayoutHeading>New Form For You</StyledSharedLayoutHeading>
              <StyledSharedLayoutContent>
                <StyledParagraph>
                  <StyledLayoutName>{sharedLayoutDetail.facility.name} </StyledLayoutName> just
                  shared a layout
                  <StyledLayoutName>
                    {" "}
                    {sharedLayoutDetail.layout.defaultLayoutFriendlyName}
                  </StyledLayoutName>{" "}
                  with you
                </StyledParagraph>
              </StyledSharedLayoutContent>
              <StyledShareLayoutFooter>
                <StyledAcceptButton
                  data-testid="accept"
                  variant="contained"
                  bgColor="#034a93"
                  textColor="white"
                  onClick={handleAccept}
                >
                  Accept
                </StyledAcceptButton>
                <StyledAcceptButton
                  data-testid="reject"
                  variant="outlined"
                  bgColor="white"
                  textColor="#034a93"
                  onClick={handleCancel}
                >
                  Reject
                </StyledAcceptButton>
              </StyledShareLayoutFooter>
            </>
          )}
        </StyledBox>
      ) : (
        <StyledBox>
          <Box style={{ fontSize: "2rem", margin: "1rem" }}>
            {loader && <Loader />}
            {!loader && alreadySharedMessage}
          </Box>
          <StyledAcceptButton
            data-testid="go-to-layouts"
            variant="contained"
            bgColor="#034a93"
            textColor="white"
            onClick={handleCancel}
          >
            Go To Dashboard
          </StyledAcceptButton>
        </StyledBox>
      )}
    </>
  );
}

export default ValidateImpersonateUser(SharedLayout);
