import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Header,
  HeadingText,
  ParentDiv,
  SearchDiv,
  ButtonDiv,
  ButtonText,
  StyledDataGridPro,
} from "src/pages/facility-forms-management/layouts/styles";
import Switch from "@mui/material/Switch";
import SearchBar from "src/shared/ui/search-bar";
import { getLayoutColumns } from "src/pages/facility-forms-management/layouts/table/columns";
import { Layout, LayoutSearchParams, LayoutPageProps } from "src/interfaces/layouts.interface";
import { dataGridStyles } from "src/constants/datagrid-styles";
import SortingIcon from "src/shared/icons/sorting-icon";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/redux/store";
import {
  facilityLayoutsSelector,
  facilityLayoutsStoreSelector,
} from "src/slices/facility-forms-management/layouts/layouts-selector";
import InfiniteScroll from "react-infinite-scroll-component";
import validateImpersonateUser from "src/shared/hoc/validate-impersonate-user";
import FilterComponent from "src/pages/facility-forms-management/layouts/components/filter";
import {
  deleteCustomLayoutThunk,
  getImpersonationLayoutsThunk,
  unPublishLayoutThunk,
} from "src/slices/facility-forms-management/layouts/layouts-thunks";
import { resetLayoutsData } from "src/slices/facility-forms-management/layouts";
import { SelectChangeEvent } from "@mui/material";
import { layoutInitialFilterValues } from "src/constants/filter-constants";
import { EMPTY_SEARCH_VALUE, NO_FILTERS } from "src/constants/error-constants";
import { toast } from "react-toastify";
import { CUSTOM_LAYOUTS, OPIE_LAYOUTS, PUBLISHED_LAYOUTS } from "src/constants/route-constants";
import { useLocation, useNavigate } from "react-router-dom";
import pageLimitContext from "src/context-api";
import PublishModal from "src/pages/facility-forms-management/layouts/components/PublishModal";
import { layoutsMenuItem } from "src/pages/facility-forms-management/layouts/constants";
import DeleteFormIcon from "src/shared/icons/delete-form-icon";
import ShareModal from "src/pages/facility-forms-management/shared/share-modal";
import ConfirmationModal from "src/shared/delete-confirmation-modal";
import CopyConfirmationModal from "src/pages/facility-forms-management/layouts/components/copy-confirmation-modal";

const Layouts = (props: LayoutPageProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isDisableVirtualization } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [filterValues, setFilterValues] = useState(layoutInitialFilterValues);
  const layoutsList: Layout[] = useSelector(facilityLayoutsSelector);
  const layoutsStore = useSelector(facilityLayoutsStoreSelector);
  const [initialPage, setInitialPage] = useState<number>(1);
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<LayoutSearchParams>({});
  const [layoutName, setLayoutName] = useState<string>("");
  const [toggle, setToggle] = useState<boolean>(false);
  const [menuItemRow, setMenuItemRow] = useState<Layout | null>();
  const [menuItemModal, setMenuItemModal] = useState<string | null>(null);
  const [selectedLayout, setSelectedLayout] = useState<Layout>();

  const noFiltersSelected =
    filterValues.associatedType.length === 0 &&
    filterValues.deviceType.length === 0 &&
    filterValues.visitType.length === 0 &&
    filterValues.formCategories.length === 0 &&
    filterValues.associatedAnatomy.length === 0;
  const { layoutsPageLimit } = useContext(pageLimitContext);

  const resetLayouts = useCallback(() => {
    dispatch(resetLayoutsData());
    setInitialPage(1);
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === CUSTOM_LAYOUTS) {
      setLayoutName("Custom Layouts");
    }
    if (location.pathname === OPIE_LAYOUTS) {
      setLayoutName("Layouts");
    }
  }, [location.pathname]);

  const fetchLayouts = () => {
    const params = {
      limit: layoutsPageLimit,
      page: initialPage,
      ...(isSearchActive || isFilterActive ? queryParams : {}),
    };
    if (location.pathname === CUSTOM_LAYOUTS) params.isCustom = true;
    if (location.pathname === OPIE_LAYOUTS) params.isCustom = false;
    if (location.pathname === PUBLISHED_LAYOUTS) params.isPublished = true;

    if (!toggle) params.isHidden = false;
    dispatch(getImpersonationLayoutsThunk(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  useEffect(() => {
    fetchLayouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    initialPage,
    resetLayouts,
    isSearchActive,
    layoutsPageLimit,
    isFilterActive,
    queryParams,
    location.pathname,
  ]);

  useEffect(() => {
    return () => resetLayouts();
  }, [resetLayouts]);

  const removeSearchFromParams = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { searchTerm, ...newQueryParams } = queryParams;
    setQueryParams(newQueryParams);
  };

  const handleParams = () => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      searchTerm: filterValues.searchValue.toLowerCase(),
      visitType: filterValues.visitType,
      associatedType: filterValues.associatedType,
      deviceType: filterValues.deviceType,
      associatedAnatomy: filterValues.associatedAnatomy,
      formCategories: filterValues.formCategories,
    }));
  };

  const handleFilterList = () => {
    if (filterValues.searchValue) {
      handleParams();
      setIsSearchActive(true);
      resetLayouts();
    } else {
      toast.error(EMPTY_SEARCH_VALUE);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterValues((prevFilterValues) => ({ ...prevFilterValues, searchValue: value }));
    if (!value && (noFiltersSelected || isFilterActive)) {
      resetLayouts();
      setIsSearchActive(false);
      removeSearchFromParams();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === "Enter" && filterValues.searchValue) {
      handleFilterList();
    } else if (event.nativeEvent.key === "Enter") {
      toast.error(EMPTY_SEARCH_VALUE);
    }
  };

  const fetchMoreData = () => {
    setInitialPage(initialPage + 1);
  };

  const handleFilterChange = (event: SelectChangeEvent<unknown>, type: string) => {
    event.preventDefault();
    setFilterValues({ ...filterValues, [type]: event.target.value as string[] });
  };

  const handleApplyFilter = () => {
    if (noFiltersSelected) {
      toast.error(NO_FILTERS);
    } else {
      handleParams();
      resetLayouts();
      setIsFilterActive(true);
    }
  };

  const handleClear = () => {
    resetLayouts();
    setIsSearchActive(false);
    setIsFilterActive(false);
    setQueryParams({});
    setFilterValues(layoutInitialFilterValues);
  };

  const handleHiddenLayout = () => {
    setToggle(!toggle);
    if (toggle) {
      setQueryParams({ ...queryParams, isHidden: false });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isHidden, ...rest } = queryParams;
      setQueryParams({ ...rest });
    }
    setIsFilterActive(true);
    resetLayouts();
  };

  const handleMenuItemClick = async (menuItem: string, rowData: Layout) => {
    switch (menuItem) {
      case layoutsMenuItem.PREVIEW_FORM: {
        navigate(`${PUBLISHED_LAYOUTS}/${rowData.id}`);
        break;
      }
      case layoutsMenuItem.EDIT: {
        !rowData.isHidden && navigate(`${location.pathname}/${rowData.id}`);
        break;
      }
      case layoutsMenuItem.COPY: {
        setMenuItemModal(menuItem);
        setSelectedLayout(rowData);
        break;
      }
      default:
        setMenuItemModal(menuItem);
        setMenuItemRow(rowData);
        break;
    }
  };

  const deleteCustomLayout = async () => {
    if (menuItemRow && menuItemRow.id) {
      await dispatch(deleteCustomLayoutThunk(menuItemRow.id));
      resetLayouts();
      fetchLayouts();
      closeDeleteModalHandler();
    }
  };
  const unPublishLayoutHandler = async () => {
    if (menuItemRow && menuItemRow.id) {
      await dispatch(unPublishLayoutThunk(menuItemRow.id));
      resetLayouts();
      fetchLayouts();
      closeDeleteModalHandler();
    }
  };
  const closeDeleteModalHandler = () => {
    setMenuItemRow(null);
    setMenuItemModal(null);
  };

  return (
    <>
      <Header>
        <HeadingText>{layoutName}</HeadingText>
      </Header>
      <ParentDiv>
        <SearchDiv>
          <SearchBar
            handleSearch={handleSearch}
            handleKeyPress={handleKeyPress}
            handleFilterList={handleFilterList}
            searchValue={filterValues.searchValue}
          />
        </SearchDiv>
        <ButtonDiv>
          <Switch data-testid="toggle-layout" onClick={handleHiddenLayout} checked={toggle} />
          <ButtonText>Show Hidden Layouts</ButtonText>
        </ButtonDiv>
      </ParentDiv>
      <FilterComponent
        filterValues={filterValues}
        handleFilterChange={handleFilterChange}
        handleApplyFilter={handleApplyFilter}
        handleClear={handleClear}
        isLoading={layoutsStore.isLoading}
      />
      <InfiniteScroll
        dataLength={layoutsList.length}
        next={fetchMoreData}
        hasMore={layoutsStore.fetchMore}
        loader={layoutsStore.isLoading ? <h4>Loading...</h4> : null}
        scrollThreshold={0.9}
      >
        <StyledDataGridPro
          disableColumnMenu
          disableVirtualization={isDisableVirtualization ? true : false}
          rows={layoutsList.length === 0 ? [] : layoutsList}
          columns={getLayoutColumns({ handleMenuItemClick, pathName: location.pathname })}
          disableRowSelectionOnClick
          hideFooterRowCount
          sx={dataGridStyles}
          autoHeight
          style={{ border: "none" }}
          loading={layoutsStore.isLoading}
          slots={{
            columnSortedAscendingIcon: SortingIcon,
            columnSortedDescendingIcon: SortingIcon,
          }}
          getRowClassName={(params) => (params.row.isHidden ? "hidden-row" : "")}
        />
      </InfiniteScroll>

      {(menuItemModal === layoutsMenuItem.PUBLISH_TO_OA ||
        menuItemModal === layoutsMenuItem.UPDATE_ACCESS) && (
        <PublishModal
          open={true}
          handleClose={closeDeleteModalHandler}
          id={menuItemRow ? menuItemRow.id : ""}
          title={menuItemModal}
        />
      )}

      {menuItemModal === layoutsMenuItem.DELETE && (
        <ConfirmationModal
          heading={"Delete Layout"}
          open={true}
          type={""}
          subHeading={"Are you sure you want to delete the Layout?"}
          handleOpen={closeDeleteModalHandler}
          handleDeleteUser={deleteCustomLayout}
          isLoading={false}
          name={menuItemRow && menuItemRow.defaultLayoutFriendlyName}
          icon={<DeleteFormIcon />}
        />
      )}
      {menuItemModal === layoutsMenuItem.REMOVE && (
        <ConfirmationModal
          heading={"Un Publish Layout"}
          open={true}
          subHeading={"Are you sure you want to un-publish the Layout?"}
          handleOpen={closeDeleteModalHandler}
          handleDeleteUser={unPublishLayoutHandler}
          isLoading={false}
          name={menuItemRow && menuItemRow.defaultLayoutFriendlyName}
          icon={<DeleteFormIcon />}
          type={""}
        />
      )}
      {menuItemModal === layoutsMenuItem.SHARE && (
        <ShareModal
          layoutId={menuItemRow && menuItemRow.id}
          open={true}
          handleClose={closeDeleteModalHandler}
        />
      )}
      {menuItemModal === layoutsMenuItem.COPY && (
        <CopyConfirmationModal
          open={true}
          handleOpen={closeDeleteModalHandler}
          selectedLayout={selectedLayout as Layout}
        />
      )}
    </>
  );
};
export default validateImpersonateUser(Layouts);
