import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import TransparentOpieLogo from "src/shared/icons/transparentOpie-icon";
import {
  HeaderContent,
  UserProfileDiv,
  UserLogo,
  UserName,
  NavLink,
  NavLinkMD,
} from "src/shared/header/styles";
import { Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  USER_MANAGEMENT,
  GROUPS,
  HOME,
  OPIE_LAYOUTS,
  CUSTOM_LAYOUTS,
  PUBLISHED_LAYOUTS,
  DASHBOARD,
} from "src/constants/route-constants";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { currentUserSelector } from "src/slices/shared/user";
import { appEnv } from "src/constants/get-env";
import { MANAGEMENT_KEY } from "src/constants/app-config-constants";
import SettingsMenu from "src/shared/header/settings";
import ImpersonationBar from "src/shared/header/impersonation";
import {
  getUserImpersonationFromSession,
  removeUserImpersonationFromSession,
} from "src/utils/storage";
import {
  LOCALSTORAGE_FACILITY_ID,
  LOCALSTORAGE_IDP_USER_FLAG,
  LOCALSTORAGE_IMPERSONATION_TOKEN,
  LOCALSTORAGE_IMPERSONATION_USER_DATA,
  LOCALSTORAGE_USER_ID,
} from "src/constants/auth-constants";

interface Props {
  handleSidebarView: () => void;
}

export default function ResponsiveDrawer({ handleSidebarView }: Props) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const location = useLocation();
  const [selectedPath, setSelectedPath] = React.useState<string>();
  const currentUser = useSelector(currentUserSelector);
  const isImpersonating = getUserImpersonationFromSession(LOCALSTORAGE_IMPERSONATION_TOKEN)
    ? true
    : false;
  const impersonatedUserData = getUserImpersonationFromSession(
    LOCALSTORAGE_IMPERSONATION_USER_DATA
  );
  React.useEffect(() => {
    setSelectedPath(`/${location.pathname.slice(1)}`);
  }, [location.pathname]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const logOutImpersonatedUser = () => {
    removeUserImpersonationFromSession(LOCALSTORAGE_IMPERSONATION_TOKEN);
    removeUserImpersonationFromSession(LOCALSTORAGE_IMPERSONATION_USER_DATA);
    removeUserImpersonationFromSession(LOCALSTORAGE_USER_ID);
    removeUserImpersonationFromSession(LOCALSTORAGE_FACILITY_ID);
    removeUserImpersonationFromSession(LOCALSTORAGE_IDP_USER_FLAG);
    window.location.replace("/");
  };
  const isSuperAdmin =
    currentUser && currentUser.role && currentUser.role.role_name === "super_admin" ? true : false;
  const isAdmins =
    currentUser &&
    currentUser.role &&
    (currentUser.role.role_name === "super_admin" || currentUser.role.role_name === "admin");
  const shouldRenderSettingsMenu = appEnv === MANAGEMENT_KEY.OPIE && isSuperAdmin;
  const menuLinks =
    appEnv === MANAGEMENT_KEY.OPIE
      ? [
          {
            text: "Home",
            link: HOME,
            show: true,
          },
          {
            text: "Facility Groups",
            link: GROUPS,
            show: true,
          },
          {
            text: "Users",
            link: USER_MANAGEMENT,
            show: isSuperAdmin,
          },
        ]
      : [
          {
            text: "Home",
            link: isAdmins === true ? HOME : DASHBOARD,
            show: true,
          },
          {
            text: "OPIE Layouts",
            link: OPIE_LAYOUTS,
            show: true,
          },
          {
            text: "Custom Layouts",
            link: CUSTOM_LAYOUTS,
            show: isSuperAdmin,
          },
          {
            text: "Published Layouts",
            link: PUBLISHED_LAYOUTS,
            show: isSuperAdmin,
          },
        ];
  return (
    <Box sx={{ display: "flex", height: "9vh", width: "100%", justifyContent: "center" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          height: "inherit",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            data-testid="open-sidebar"
            onClick={handleSidebarView}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <HeaderContent>
            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
              >
                <TransparentOpieLogo width={80} height={76} />
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "flex", md: "none" } }}>
                <IconButton
                  data-testid="openMenu"
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                  }}
                >
                  <p style={{ paddingTop: "0.1rem", marginRight: "0.5rem" }}>Home</p>
                  <ArrowDropDownIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { sm: "block", md: "none" },
                  }}
                >
                  {menuLinks.map((page, i) => (
                    <MenuItem
                      sx={{ minHeight: "30px" }}
                      key={i}
                      data-testid="menuAppbar"
                      onClick={handleCloseNavMenu}
                    >
                      <NavLink to={page.link}>{page.text}</NavLink>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box
                sx={{
                  flexGrow: 0.5,
                  marginLeft: { sm: "10%" },
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
              >
                {menuLinks.map((page, i) => (
                  <Box
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      marginRight: "3rem",
                      textTransform: "none",
                      fontSize: { md: "1rem", xl: "1.2rem" },
                    }}
                    key={i}
                  >
                    {page.show && (
                      <NavLinkMD
                        fontWeight={selectedPath === page.link ? 600 : 400}
                        to={page.link}
                        onClick={handleCloseNavMenu}
                      >
                        {page.text}
                      </NavLinkMD>
                    )}
                  </Box>
                ))}
              </Box>
              <ImpersonationBar
                isImpersonating={isImpersonating}
                userName={impersonatedUserData}
                onLogout={logOutImpersonatedUser}
              ></ImpersonationBar>
            </Box>

            <UserProfileDiv>
              {shouldRenderSettingsMenu && <SettingsMenu />}
              <UserLogo>
                {currentUser && currentUser.first_name
                  ? currentUser.first_name.slice(0, 1).toUpperCase()
                  : ""}
                {currentUser && currentUser.last_name
                  ? currentUser.last_name.slice(0, 1).toUpperCase()
                  : ""}
              </UserLogo>
              <UserName
                sx={{ display: { xs: "none", sm: "block" }, paddingRight: { xs: 0, sm: "4rem" } }}
              >
                {currentUser && currentUser.first_name} <br />
                {currentUser && currentUser.last_name}
              </UserName>
            </UserProfileDiv>
          </HeaderContent>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
