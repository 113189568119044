import React, { useEffect, useState } from "react";
import Router from "src/router";
import "src/interceptors/request";
import "src/interceptors/response";
import { limit } from "src/constants/pagination-limit";
import PageLimitContext from "src/context-api";
import useWindowDimensions from "src/shared/window-dimensions";

function App() {
  const { height } = useWindowDimensions();
  const [defaultLimit, setDefaultLimit] = useState<number>(limit);
  const [layoutsLimit, setLayoutsLimit] = useState<number>(limit);

  useEffect(() => {
    if (height >= 1000 && height < 1550) {
      setLayoutsLimit(16);
      setDefaultLimit(14);
    } else if (height >= 1550) {
      setLayoutsLimit(21);
      setDefaultLimit(limit * 2);
    } else {
      setDefaultLimit(limit);
      setLayoutsLimit(12);
    }
  }, [height]);

  return (
    <PageLimitContext.Provider value={{ limit: defaultLimit, layoutsPageLimit: layoutsLimit }}>
      <Router />
    </PageLimitContext.Provider>
  );
}

export default App;
