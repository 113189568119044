import React, { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import CustomDialog from "src/shared/modal";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomSelect from "src/shared/ui/select";
import { AppDispatch } from "src/redux/store";
import { useDispatch } from "react-redux";
import { updatePublishLayoutThunk } from "src/slices/facility-forms-management/layouts/layouts-thunks";
import {
  StyledCancelButton,
  StyledDialogContainer,
  StyledDialogFooter,
  StyledPublishButton,
  StyledDialogTitle,
  StyledBox,
  StyledLabel,
} from "src/pages/facility-forms-management/layouts/components/PublishModal/styles";
import { getBranches } from "src/services/branch-service";
import { getFacilityUsers } from "src/services/user-impersonation-service";
import { FacilityUser } from "src/interfaces/user-impersonation.interface";
import { Options, PublishLayout } from "src/interfaces/layouts.interface";
import { Branch } from "src/interfaces/branch.interfce";
import { getValueFromStorage } from "src/utils/storage";
import { LOCALSTORAGE_FACILITY_ID } from "src/constants/auth-constants";
import { renderValues } from "src/utils/layout-method";
import {
  BRANCHES,
  PUBLISH_BRANCH,
  PUBLISH_FACILITY,
  PUBLISH_USERS,
  SELECT_BRANCHES,
  SELECT_USERS,
  USERS,
} from "src/pages/facility-forms-management/layouts/constants";
import { getPublishLayout } from "src/services/layouts-service";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  id: string;
  title?: string | null;
}
const PublishModal: React.FC<ModalProps> = ({ open, handleClose, id, title = "Publish to OA" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [publishLayout, setPublishLayout] = useState<PublishLayout>({ publishedTo: "", ids: [] });

  const [userList, setUserList] = useState<Options[]>([]);
  const [branchList, setBranchList] = useState<Options[]>([]);
  const [selectedPublishMethod, setSelectedPublishMethod] = useState<string | null>(
    PUBLISH_FACILITY
  );
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string[]>([]);

  const isDisabled = () => {
    const isPublishedToUsers = publishLayout.publishedTo === USERS;
    const isPublishedToBranches = publishLayout.publishedTo === BRANCHES;
    const isFacilityPublishMethod =
      publishLayout.publishedTo === "facility" && selectedPublishMethod === PUBLISH_FACILITY;

    if (isPublishedToUsers || isPublishedToBranches) {
      return (
        JSON.stringify(publishLayout.ids) ===
        JSON.stringify(isPublishedToUsers ? selectedUsers : selectedBranch)
      );
    } else if (isFacilityPublishMethod) {
      return true;
    }

    return false;
  };

  const handleSelectedUser = (e: SelectChangeEvent<string | string[] | unknown>) => {
    setSelectedUsers(e.target.value as string[]);
  };

  const handleSelectedBranch = (e: SelectChangeEvent<string | string[] | unknown>) => {
    setSelectedBranch(e.target.value as string[]);
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValues();
    setSelectedPublishMethod(e.target.value);
  };

  useEffect(() => {
    if (publishLayout.publishedTo === USERS) {
      setSelectedPublishMethod(PUBLISH_USERS);
      if (publishLayout.ids.length > 0) {
        setSelectedUsers(publishLayout.ids);
      }
    } else if (publishLayout.publishedTo === BRANCHES) {
      setSelectedPublishMethod(PUBLISH_BRANCH);
      if (publishLayout.ids.length > 0) {
        setSelectedBranch(publishLayout.ids);
      }
    } else {
      setSelectedPublishMethod(PUBLISH_FACILITY);
      setSelectedUsers([]);
      setSelectedBranch([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishLayout]);

  const getBranchList = async () => {
    const response = await getBranches();
    const data = response.data.data.map((branch: Branch) => {
      return {
        id: branch.branchId.toString(),
        name: branch.branchName,
      };
    });
    setBranchList(data);
  };

  const getUserList = async () => {
    const facilityId = (await getValueFromStorage(LOCALSTORAGE_FACILITY_ID)) as string;

    const response = await getFacilityUsers(facilityId);
    const list = response.data.data.map((user: FacilityUser) => {
      return {
        id: user.userID.toString(),
        name: user.firstName + user.middleName + user.lastName,
      };
    });
    setUserList(list);
  };

  useEffect(() => {
    getBranchList();
    getUserList();
    if (id) getPublishedLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getPublishedLayout = async () => {
    try {
      const res = await getPublishLayout(id);
      if (res.data.statusCode === 200) {
        setPublishLayout(res.data.data);
      }
    } catch (err) {
      setPublishLayout({ publishedTo: "", ids: [] });
    }
  };

  const handleChecked = (item: string, type: string): boolean => {
    switch (type) {
      case USERS:
        return selectedUsers.includes(item);
      case BRANCHES:
        return selectedBranch.includes(item);
      default:
        return false;
    }
  };

  const resetValues = () => {
    setSelectedPublishMethod("Publish to everyone at the Facility");
    setSelectedBranch([]);
    setSelectedUsers([]);
  };

  const handlePublishLayout = async () => {
    let response;
    if (selectedPublishMethod === "Publish to Branch(es)") {
      response = {
        publishedTo: BRANCHES,
        ids: selectedBranch,
      };
    } else if (selectedPublishMethod === "Publish to User(s)") {
      response = {
        publishedTo: USERS,
        ids: selectedUsers,
      };
    } else {
      response = {
        publishedTo: "facility",
        ids: [],
      };
    }
    dispatch(updatePublishLayoutThunk({ id: id, body: response }));

    handleClose();
  };

  const customUserRenderValue = (val: unknown) => {
    const nameArr: string[] = [];
    (val as string[]).forEach((v) => {
      const optn = userList.find((opt) => opt.id === v);
      if (optn) {
        nameArr.push(optn.name);
      }
    });
    return renderValues(nameArr);
  };

  const customBranchRenderValue = (val: unknown) => {
    const nameArr: string[] = [];
    (val as string[]).forEach((v) => {
      const optn = branchList.find((opt) => opt.id === v);
      if (optn) {
        nameArr.push(optn.name);
      }
    });
    return renderValues(nameArr);
  };

  const closeHandler = () => {
    resetValues();
    handleClose();
  };

  return (
    <CustomDialog
      data-testid="confirmation-dialog"
      open={open}
      maxWidth={"md"}
      handleOpen={handleClose}
    >
      <StyledDialogContainer>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <StyledBox>
          <RadioGroup
            defaultValue={PUBLISH_FACILITY}
            onChange={handleRadioChange}
            value={selectedPublishMethod}
            style={{ width: "100%" }}
          >
            <FormControlLabel
              value={PUBLISH_FACILITY}
              control={<Radio />}
              label={PUBLISH_FACILITY}
              data-testid={"publishId"}
            />
            <FormControlLabel
              value={PUBLISH_BRANCH}
              control={<Radio />}
              label={PUBLISH_BRANCH}
              data-testid="branchId"
            />
            <StyledLabel>{SELECT_BRANCHES}</StyledLabel>
            <CustomSelect
              multiple={true}
              value={selectedBranch}
              options={branchList}
              handleChange={handleSelectedBranch}
              disabled={selectedPublishMethod !== PUBLISH_BRANCH}
              label={SELECT_BRANCHES}
              handleChecked={handleChecked}
              customRenderValue={customBranchRenderValue}
              type={BRANCHES}
            />
            <FormControlLabel
              value={PUBLISH_USERS}
              control={<Radio />}
              label={PUBLISH_USERS}
              data-testid="userId"
            />
            <StyledLabel>{SELECT_USERS}</StyledLabel>
            <CustomSelect
              multiple={true}
              value={selectedUsers}
              disabled={selectedPublishMethod !== PUBLISH_USERS}
              options={userList}
              handleChange={handleSelectedUser}
              label={SELECT_USERS}
              handleChecked={handleChecked}
              customRenderValue={customUserRenderValue}
              type={USERS}
            />
          </RadioGroup>
        </StyledBox>
        <StyledDialogFooter>
          <StyledPublishButton
            variant="contained"
            data-testid="publish-layout"
            onClick={handlePublishLayout}
            disabled={isDisabled()}
          >
            Publish Layout
          </StyledPublishButton>
          <StyledCancelButton
            variant="outlined"
            data-testid="cancel-publish-layout"
            onClick={closeHandler}
          >
            Cancel
          </StyledCancelButton>
        </StyledDialogFooter>
      </StyledDialogContainer>
    </CustomDialog>
  );
};

export default PublishModal;
