import { Box, Select, Button, InputLabel } from "@mui/material";
import styled from "styled-components";
import LoadingButton from "@mui/lab/LoadingButton";
import Close from "@mui/icons-material/Close";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export const CloseIcon = styled(Close)``;

export const DialogHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 1.2rem 1.5rem 0 0;
  cursor: pointer;
`;

export const LayoutMetaDataContainer = styled(Box)`
  background-color: white;
  padding: 1rem;
  margin-top: 1rem;
`;

export const StyledSwitch = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
`;

export const InputLabelText = styled(InputLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #011428;
`;

export const StyledButton = styled(Button)`
  font-weight: 600;
  font-size: 16px;
  padding: 4px 20px;
  text-transform: none;
  border: 1px solid #034a93;
  border-radius: 6px;
  width: fit-content;
`;

export const ButttonSection = styled.div`
  display: flex;
  gap: 2rem;
  margin: 2rem;
`;

export const CustomDialogText = styled.span`
  margin: 2rem;
  font-size: xx-large;
`;

export const CustomSelect = styled(Select)(() => ({
  width: "100%",
  padding: 0,
  "& .MuiSelect-icon": {
    color: "#034a93",
  },
}));

export const ExpandableSection = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-top: 1rem;
  gap: 1rem;
`;

export const DialogPara = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(17, 20, 45, 0.85);
  text-align: center;
`;

export const ActionHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #034a93;
  margin: 0;
  margin-top: 0.5rem;
`;

export const DialogActionDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 70px;
  margin-top: 4rem;
  margin-bottom: 1rem;
`;

export const StyledSelect = styled(Select)(() => ({
  width: "100%",
  padding: 0,
  "& .MuiSelect-select": {
    padding: "8.5px 14px !important",
  },
  "& .MuiSelect-icon": {
    color: "#034a93",
  },
}));

export const DeleteUserDiv = styled(LoadingButton)`
  text-transform: none;
  background: #034a93;
  color: white !important;
  font-weight: bold;
  padding: 2% 4%;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const ApplyUserDiv = styled(Button)`
  text-transform: none;
  background: white;
  font-weight: bold;
  padding: 2% 4%;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #034a93;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const ToggleGroup = styled.div`
  display: flex;
`;
