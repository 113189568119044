import axios from "axios";
import { BASE_URL } from "src/constants/app-config-constants";
import { HTTPResponse } from "src/interfaces/httpResponse.interface";
import { FacilityUser, UserImpersonation } from "src/interfaces/user-impersonation.interface";

export async function getFacilityUsers(facilityId: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/facility/${facilityId}/users`;
  return axios.get(url);
}

export async function addZeusUser(user: FacilityUser): Promise<HTTPResponse> {
  const url = `${BASE_URL}/facility/${user.facilityID}/users`;
  return axios.post(url, [user]);
}

export async function impersonateUser(userData: UserImpersonation): Promise<HTTPResponse> {
  const url = `${BASE_URL}/auth/impersonate`;
  return axios.post(
    url,
    { token: userData.token, username: userData.user.username },
    {
      headers: {
        is_idp_user: true,
      },
    }
  );
}
