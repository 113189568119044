import { InputLabel, TextField } from "@mui/material";
import styled from "styled-components";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import { LoadingButton } from "@mui/lab";

export const Heading = styled.h1`
  text-align: center;
  font-weight: 600;
  color: #000a14;
  @media only screen and (min-width: 796px) {
    font-size: 34px;
  }
  font-size: 28px;
`;

export const OuterHeadingText = styled.h3`
  margin: 0;
  color: #c8cbce;
`;

export const InputTextField = styled(TextField)`
  width: 100%;
  white-space: pre-line;
  margin-bottom: 1.5rem;
`;
export const InputLabelText = styled(InputLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #011428;
`;
export const SubmitButton = styled(LoadingButton)`
  text-transform: none;
  background: #034a93;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 3.5rem;
  border-radius: 8px;
  margin-top: 1rem;
`;

export const FooterText = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 500px;
  text-align: center;
  opacity: 0.5;
`;

export const RequiredText = styled.sup`
  color: #034a93;
`;

export const ShowPasswordIcon = styled(VisibilityOutlined)`
  color: #034a93;
  font-size: 1.2em !important;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  z-index: 2;
`;

export const HidePasswordIcon = styled(VisibilityOffOutlined)`
  color: #034a93;
  font-size: 1.2em !important;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  z-index: 2;
`;

export const PasswordDiv = styled.div`
  position: relative;
`;

export const ErrorDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorIconDiv = styled(ErrorIcon)`
  height: 14px;
  width: 14px;
`;

export const DoneIcon = styled(CheckCircleSharpIcon)`
  height: 15px;
  width: 15px;
  color: #00b03c;
`;

export const ErrorText = styled.span`
  color: ${(props) => props.color};
  margin-left: 0.2rem;
  font-size: 12px;
  font-weight: 400;
`;
