import axios from "axios";
import { TYPE_LOGOUT } from "src/constants/action-types-constants";
import { LOCALSTORAGE_USER_TOKEN_KEY } from "src/constants/auth-constants";
import { setupStore } from "src/redux/store";
import { clearToken } from "src/slices/shared/user";
import { removeValueFromStorage } from "src/utils/storage";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const store = setupStore();
    if (err?.response.status === 403 || err?.response.statusText === "Forbidden") {
      removeValueFromStorage(LOCALSTORAGE_USER_TOKEN_KEY);
      store.dispatch(clearToken());
      store.dispatch({ type: TYPE_LOGOUT });
      window.location.href = "/login";
    }
    return Promise.reject(err);
  }
);
