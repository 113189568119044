import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  LOCALSTORAGE_FACILITY_ID,
  LOCALSTORAGE_IDP_USER_FLAG,
  LOCALSTORAGE_IMPERSONATION_TOKEN,
  LOCALSTORAGE_IMPERSONATION_USER_DATA,
  LOCALSTORAGE_USER_ID,
} from "src/constants/auth-constants";
import { IMPERSONATION_FAILED } from "src/constants/error-constants";

import { FacilityUser, UserImpersonation } from "src/interfaces/user-impersonation.interface";
import {
  getFacilityUsers,
  addZeusUser,
  impersonateUser,
} from "src/services/user-impersonation-service";
import { getConvertedSentenceToUpperCase } from "src/utils/convert-strings-case";
import { storeUserImpersonationInSession } from "src/utils/storage";
export const getFacilityUsersThunk = createAsyncThunk(
  "getFacilityUsersThunk",
  async (facilityId: string, { rejectWithValue }) => {
    try {
      const res = await getFacilityUsers(facilityId);
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addZeusUserThunk = createAsyncThunk(
  "addZeusUserThunk",
  async (user: FacilityUser, { rejectWithValue }) => {
    try {
      const res = await addZeusUser(user);
      toast.success(
        getConvertedSentenceToUpperCase(
          `${user.firstName} ${user.lastName} was successfully added!`
        )
      );
      return {
        user: user,
        message: res.data.message,
        statusCode: res.data.statusCode,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const impersonateUserThunk = createAsyncThunk(
  "impersonateUser",
  async (payload: UserImpersonation, { rejectWithValue }) => {
    try {
      const res = await impersonateUser(payload);
      const userDetails = `${payload.user.firstName} ${payload.user.lastName}`;
      toast.success(
        getConvertedSentenceToUpperCase(` ${userDetails} was successfully impersonated!`)
      );
      storeUserImpersonationInSession(LOCALSTORAGE_FACILITY_ID, payload.user.facilityID);
      storeUserImpersonationInSession(LOCALSTORAGE_USER_ID, payload.user.userID);
      storeUserImpersonationInSession(LOCALSTORAGE_IMPERSONATION_TOKEN, res.data.data.token);
      storeUserImpersonationInSession(LOCALSTORAGE_IDP_USER_FLAG, "true");
      storeUserImpersonationInSession(LOCALSTORAGE_IMPERSONATION_USER_DATA, userDetails);
      return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.response.data.message.error_description) {
        toast.error(
          getConvertedSentenceToUpperCase(`${err.response.data.message.error_description}`)
        );
      } else {
        toast.error(IMPERSONATION_FAILED);
      }

      return rejectWithValue(err.response.data);
    }
  }
);
