import { Box } from "@mui/material";
import styled from "styled-components";

export const SearchIconDiv = styled(Box)`
  width: 2rem;
  height: 1.8rem;
  cursor: pointer;
  background: #034a93;
  color: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
