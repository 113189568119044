import styled from "styled-components";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";

export const DialogHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 1.2rem 1.5rem 0 0;
  cursor: pointer;
`;
export const CloseIcon = styled(Close)``;
export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const DialogActionDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;
export const DialogPara = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(17, 20, 45, 0.85);
  text-align: center;
`;
export const DialogSpan = styled.span`
  font-weight: bold;
`;
export const DeleteUserDiv = styled(LoadingButton)`
  text-transform: none;
  background: #034a93;
  color: white !important;
  font-weight: bold;
  padding: 2% 4%;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const CancelUserDiv = styled(Button)`
  text-transform: none;
  background: white;
  font-weight: bold;
  padding: 2% 4%;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #034a93;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const DeleteUserHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #034a93;
  margin: 0;
  margin-top: 0.5rem;
`;
