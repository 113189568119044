import { Box, DialogTitle } from "@mui/material";
import { AddGroupDiv } from "src/pages/opie-forms-management/groups/styles";
import styled from "styled-components";

export const StyledDialogContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.625rem 1rem;
`;
export const StyledDialogFooter = styled.div`
  display: flex;
  width: 80%;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledPublishButton = styled(AddGroupDiv)`
  display: flex;
  gap: 0.4rem;
  padding: 0.6rem 3rem;
`;

export const StyledCancelButton = styled(AddGroupDiv)`
  display: flex;
  gap: 0.4rem;
  padding: 0.6rem 4rem;
  background-color: white;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  color: rgb(3, 74, 147);
  font-weight: 600;
  font-size: 2rem;
  margin-top: 1rem;
`;

export const StyledBox = styled(Box)`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLabel = styled(Box)`
  color: gray;
  font-size: 0.8rem;
`;
