import React, { ReactNode } from "react";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { StyledTooltip } from "src/shared/ui/Tooltip/style";
import styled from "styled-components";

interface Props {
  title: ReactNode;
  children: JSX.Element;
}
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <StyledTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    fontSize: 12,
    paddingLeft: "0",
    padding: "3px 10px 3px 3px !important",
    borderRadius: "6px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
  },
}));

const Tooltip = ({ title, children }: Props) => {
  return (
    <LightTooltip title={title} arrow placement="right" data-testid="tooltip">
      {children}
    </LightTooltip>
  );
};

export default Tooltip;
