import { RootState } from "src/redux/store";

export const userManagementSelector = (state: RootState) => state.userManagementStore.users;

export const rolesSelector = (state: RootState) => state.userManagementStore.roles;
export const currentSelectedUserSelector = (state: RootState) =>
  state.userManagementStore.currentUser;
export const currentUserPrivateLayoutsSelector = (state: RootState) =>
  state.userManagementStore.privateLayouts;

export const userManagementData = (state: RootState) => {
  return state.userManagementStore;
};
