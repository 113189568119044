import React, { useState } from "react";
import {
  StyledTable,
  StyleTableHeading,
} from "src/pages/facility-forms-management/user-impersonation/components/table/styles";
import { AddGroupDiv } from "src/pages/opie-forms-management/groups/styles";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid-pro";
import DataGridProTable from "src/shared/datagridpro-table";
import { FacilityUser } from "src/interfaces/user-impersonation.interface";
import Page from "src/shared/pagination/page";
import { PaginationFormat } from "src/interfaces/pagination.interface";
import { Facility } from "src/interfaces/groups.interface";
import AddZeusModal from "src/pages/facility-forms-management/user-impersonation/components/zeus-user-modal/index";
import { GridColDef } from "@mui/x-data-grid-pro";

interface TableProps {
  isFacilityTabSelected: boolean;
  facilityDataLoading?: boolean;
  facilityUserLoading?: boolean;
  isDisableVirtualization?: boolean;
  sortModel: GridSortItem[];
  handleSortModelChange: (incomingModel: GridSortModel) => void;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  dataSetSize?: number;
  currentPage?: number;
  handlePagination?: (event: React.ChangeEvent<unknown>, value: number) => void;
  showPagination?: boolean;
  pagination: PaginationFormat;
  rows: Array<Facility | FacilityUser>;
  columns: GridColDef[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowId?: (row: any) => string | number;
}

function Table({
  isFacilityTabSelected,
  pagination,
  sortModel,
  handleSortModelChange,
  isDisableVirtualization,
  handleNextPage,
  handlePreviousPage,
  facilityUserLoading,
  facilityDataLoading,
  dataSetSize,
  currentPage,
  handlePagination,
  showPagination,
  rows,
  rowId,
  columns,
}: TableProps) {
  const [openZeusModal, setOpenZeusModal] = useState<boolean>(false);
  const handleOpenZeusModal = (open: boolean) => {
    setOpenZeusModal(open);
  };

  return (
    <>
      <StyledTable>
        <StyleTableHeading style={{ marginBottom: !isFacilityTabSelected ? "3rem" : "" }}>
          <h2 style={{ margin: 0 }}>{isFacilityTabSelected ? "Facilities" : "Users"}</h2>
          {!isFacilityTabSelected && (
            <AddGroupDiv
              variant="contained"
              data-testid="add-zeus-user"
              onClick={() => {
                handleOpenZeusModal(true);
              }}
            >
              Add Forms Admin
            </AddGroupDiv>
          )}
          {facilityUserLoading || facilityDataLoading || pagination.dataCount === 0 ? null : (
            <Page
              paginationData={{
                endNumber: pagination.paginationSize,
                totalSetCount: pagination.dataCount,
                currentPage: pagination.currentPage,
                handlePreviousPage: handlePreviousPage,
                handleNextPage: handleNextPage,
              }}
              styles={{ top: isFacilityTabSelected ? "" : "4.8rem" }}
            />
          )}
        </StyleTableHeading>
      </StyledTable>

      <DataGridProTable
        disableVirtualization={isDisableVirtualization ? true : false}
        handleSortModelChange={handleSortModelChange}
        sortModel={sortModel}
        rows={rows}
        columns={columns}
        extraStyles={{ border: "none", borderRadius: "0 0 10px 10px" }}
        isLoading={facilityUserLoading || (facilityDataLoading as boolean)}
        rowId={rowId}
        dataSetSize={dataSetSize}
        currentPage={currentPage}
        handlePagination={handlePagination}
        showPagination={showPagination}
      />
      {openZeusModal && <AddZeusModal open={openZeusModal} handleOpen={handleOpenZeusModal} />}
    </>
  );
}

export default Table;
