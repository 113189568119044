export const LOGIN = "/login";
export const CREATE_PASSWORD = "/create-password";
export const RESET_PASSWORD = "/password";
export const RECOVER_PASSWORD = "/recovery-password";
export const GROUPS = "/groups";
export const USER_MANAGEMENT = "/users";
export const CREATE_USER = "/users/create-user";
export const EDIT_USER = "/users/:id";
export const CREATE_GROUP = "/groups/create-group";
export const EDIT_GROUP = "/groups/:id";

// Facility Layout Management
export const HOME = "/";
export const DASHBOARD = "/dashboard";
export const OPIE_LAYOUTS = "/opie-layouts";
export const CUSTOM_LAYOUTS = "/custom-layouts";
export const PUBLISHED_LAYOUTS = "/published-layouts";

export const SHARE = "/share";
export const FACILITY = "/facility";
export const LAYOUT = "/layout";
