import axios, { AxiosError, AxiosResponse } from "axios";
const efiBaseURL = process.env.REACT_APP_EFI_BASE_URL;

export interface UploadResponse {
  signedURL?: string;
  fileName: string;
  fileUID: string;
  location: string;
  mimeType: string;
}

export interface DownloadRequestBody {
  FileUID: string;
  FileName: string;
  Location: string;
  MimeType: string;
}

export interface DownloadResponse {
  signedURL?: string;
  fileName: string;
  fileUID: string;
  location: string;
  mimeType: string;
}

export interface UploadRequest {
  files: {
    file?: Blob;
    fileName: string;
    mimeType: string;
    location?: string;
    fileUID?: string;
  }[];
}
export interface S3DeleteRequest {
  FileName: string;
  FileUID: string;
  MimeType: string;
}

export interface S3CopyRequest {
  FileName: string;
  Location: string;
  FileUID: string;
  NewFileName: string;
  NewLocation: string;
}
export interface S3CopyResponse {
  status: number;
}

export async function uploadFiles(uploadRequest: UploadRequest): Promise<UploadResponse[] | null> {
  try {
    const requestBody = uploadRequest.files.map(({ fileName, mimeType }) => ({
      FileName: fileName,
      Location: "Files/OPIEForms/Responses/Temp",
      MimeType: mimeType,
    }));

    const response = await axios.post<UploadResponse[]>(
      `${efiBaseURL}/S3FileStorage/GetUploadURL`,
      requestBody
    );

    const uploadResponses = await Promise.all(
      response.data.map(async (data) => {
        const { signedURL, fileName: responseFileName, fileUID } = data;
        const { file, fileName, mimeType } = uploadRequest.files.find(
          (file) => file.fileName === responseFileName
        )!; // Add a non-null assertion here

        // Upload file to S3 using signedURL via PUT request
        await axios.put(signedURL || "", file, {
          headers: {
            "Content-Type": mimeType,
          },
        });

        return {
          signedURL: signedURL,
          fileUID: fileUID,
          fileName: fileName,
          location: "Files/OPIEForms/Responses/Temp",
          mimeType: mimeType,
        };
      })
    );

    return uploadResponses;
  } catch (error) {
    console.error("Error uploading files:", error);
    return null;
  }
}

export async function downloadFile(
  fileName: string,
  fileUID: string,
  mimeType: string
): Promise<DownloadResponse> {
  try {
    const requestBody: DownloadRequestBody = {
      FileName: fileName,
      FileUID: fileUID,
      MimeType: mimeType,
      Location: "Files/OPIEForms/Responses",
    };

    // Make the POST request to get the signed URL for download
    const response = await axios.post<DownloadResponse[]>(
      `${efiBaseURL}/S3FileStorage/GetDownloadURL`,
      [requestBody]
    );

    // Extract the first element of the array
    const responseData: DownloadResponse | undefined = response.data?.[0];

    if (responseData) {
      return {
        signedURL: responseData.signedURL,
        fileName: responseData.fileName,
        fileUID,
        location: requestBody.Location,
        mimeType,
      };
    } else {
      // If responseData is undefined, handle the error
      throw new Error("Download response is empty");
    }
  } catch (error) {
    // Handle errors and log them
    console.error("Error downloading file:", error);
    throw error; // Throw the error to be handled by the caller
  }
}

function convertToS3CopyRequest(uploadRequest: UploadRequest): S3CopyRequest[] {
  return uploadRequest.files.map((file) => ({
    FileName: file.fileName,
    Location: "Files/OPIEForms/Responses/Temp",
    FileUID: file.fileUID || "",
    NewFileName: file.fileName,
    NewLocation: "Files/OPIEForms/Responses",
  }));
}

export async function s3CopyFile(uploadRequest: UploadRequest): Promise<S3CopyResponse> {
  try {
    const s3CopyRequests = convertToS3CopyRequest(uploadRequest);

    // Make a POST request to the S3 Copy endpoint
    const response: AxiosResponse<S3CopyResponse> = await axios.post(
      `${efiBaseURL}/S3FileStorage/S3Copy`,
      s3CopyRequests
    );

    return { status: response.status };
  } catch (error) {
    console.error("Error copying files:", error);
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      return { status: axiosError.response ? axiosError.response.status : 500 };
    } else {
      return { status: 500 };
    }
  }
}
export async function s3DeleteFiles(deleteRequests: S3DeleteRequest[]): Promise<void> {
  try {
    const requetData = deleteRequests.map((file) => ({
      FileName: file.FileName,
      Location: "Files/OPIEForms/Responses",
      FileUID: file.FileUID,
    }));
    await axios.post<boolean[]>(`${efiBaseURL}/S3FileStorage/S3Delete`, requetData);
  } catch (error) {
    console.error("Error deleting files:", error);
  }
}
