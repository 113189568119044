import { createSelector } from "@reduxjs/toolkit";
import { Facility } from "src/interfaces/groups.interface";
import { RootState } from "src/redux/store";

export const groupSelector = (state: RootState) => state.groupsStore;

export const groupFacilities = (state: RootState) => state.groupsStore.facilities;

export const currentGroup = (state: RootState) => state.groupsStore.currentGroup;

export const mappedFacilities = createSelector(
  (state: RootState) => state.groupsStore,
  (items) => {
    return items.facilities.map((item: Facility) => {
      const newItem = { ...item };
      newItem.isChecked = false;
      return newItem;
    });
  }
);
