import React, { ReactElement, ReactNode } from "react";

import {
  HeaderLogo,
  LeftContainer,
  LoginContainer,
  LogoDiv,
  RightContainer,
} from "src/shared/auth-layout/styles";

import transparentLogo from "src/assets/transparent-logo.png";
import logo from "src/assets/logo.png";
import { Grid } from "@mui/material";
interface Props {
  children: ReactNode;
}

function AuthLayout({ children }: Props): ReactElement {
  return (
    <LoginContainer container>
      <LeftContainer item md={5.5} sm={5.5} xs={12}>
        <Grid container item direction="column" xs={12} display={{ height: "100%" }}>
          <Grid item xs={3} display={{ width: "100%" }}>
            <LogoDiv item xs={12} display={{ sm: "none" }}>
              <HeaderLogo alt="logo" src={transparentLogo} />
            </LogoDiv>
            <Grid
              item
              sm={12}
              display={{
                xs: "none",
                sm: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img alt="icon" src={logo}></img>
            </Grid>
          </Grid>
          <Grid item xs={8.5}>
            {children}
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>
      </LeftContainer>
      <RightContainer item md={6.5} sm={6.5} xs={0}></RightContainer>
    </LoginContainer>
  );
}

export default AuthLayout;
