import React from "react";
import { Navigate } from "react-router-dom";
import {
  CREATE_GROUP,
  CREATE_PASSWORD,
  CREATE_USER,
  CUSTOM_LAYOUTS,
  EDIT_GROUP,
  EDIT_USER,
  GROUPS,
  HOME,
  DASHBOARD,
  LOGIN,
  OPIE_LAYOUTS,
  PUBLISHED_LAYOUTS,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  USER_MANAGEMENT,
  SHARE,
  FACILITY,
  LAYOUT,
} from "src/constants/route-constants";
import { NavRoute, Route, UserRoles } from "src/interfaces/sidebarRoutes.interface";
import Login from "src/pages/shared/auth/login";
import PasswordRecovery from "src/pages/shared/auth/password-recovery";
import ResetPassword from "src/pages/shared/auth/reset-password";
import GroupsManagement from "src/pages/opie-forms-management/groups";
import CreateGroup from "src/pages/opie-forms-management/groups/components/create-group";
import EditGroup from "src/pages/opie-forms-management/groups/components/edit-group";
import UserManagement from "src/pages/opie-forms-management/user-management";
import CreateUser from "src/pages/opie-forms-management/user-management/components/create-user";
import UserIcon from "src/shared/icons/user-icon";
import OpieLayoutIcon from "src/shared/icons/opie-layout-icon";
import CustomLayoutIcon from "src/shared/icons/custom-layout-icon";
import PublishedLayoutIcon from "src/shared/icons/published-layout-icon";
import LayoutDetails from "src/pages/facility-forms-management/layout-details";
import Layouts from "src/pages/facility-forms-management/layouts";
import { layoutManagementURL } from "src/utils/get_env";
import Dashboard from "src/pages/facility-forms-management/dashboard";
import LayoutPreview from "src/pages/facility-forms-management/shared/preview-layout";
import SharedLayout from "src/pages/facility-forms-management/shared-layout";
import UserImpersonation from "src/pages/facility-forms-management/user-impersonation";
import FacilityGroupIcon from "src/shared/icons/facility-group-icon";

export const publicRoutes: Route[] = [
  {
    path: LOGIN,
    element: <Login />,
  },
  {
    path: CREATE_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: RECOVER_PASSWORD,
    element: <PasswordRecovery />,
  },
];

export const opiePrivateRoutes = (userType: UserRoles | string): NavRoute[] => {
  return [
    {
      path: HOME,
      element:
        userType === "super_admin" ? (
          <Navigate to={USER_MANAGEMENT} replace />
        ) : (
          <Navigate to={GROUPS} replace />
        ),
      showInNav: false,
    },
    {
      navText: "Users",
      icon: <UserIcon />,
      iconActive: <UserIcon color="#034A93" />,
      path: USER_MANAGEMENT,
      showInNav: userType === "super_admin",
      element: <UserManagement isDisableVirtualization={false} />,
      access: ["super_admin"],
    },
    {
      path: CREATE_USER,
      showInNav: false,
      element: <CreateUser />,
      access: ["super_admin"],
    },
    {
      path: EDIT_USER,
      showInNav: false,
      element: <CreateUser />,
      access: ["super_admin"],
    },
    {
      navText: "Facility Groups",
      icon: <FacilityGroupIcon color="#FFFFFF" />,
      iconActive: <FacilityGroupIcon color="#034A93" />,
      path: "groups",
      showInNav: true,
      element: <GroupsManagement />,
      access: ["super_admin", "user"],
    },
    {
      path: CREATE_GROUP,
      showInNav: false,
      element: <CreateGroup />,
      access: ["super_admin", "user"],
    },
    {
      path: EDIT_GROUP,
      showInNav: false,
      element: <EditGroup />,
      access: ["super_admin", "user"],
    },
    {
      navText: "Layout Management",
      icon: <OpieLayoutIcon color="#FFFFFF" />,
      iconActive: <OpieLayoutIcon color="#034A93" />,
      path: `${layoutManagementURL}`,
      showInNav: true,
      element: <></>,
      access: ["super_admin", "user"],
    },
  ];
};

export const facilityPrivateRoutes = (userType: UserRoles | string): NavRoute[] => {
  return [
    {
      path: HOME,
      element:
        userType === "super_admin" || userType === "user" ? (
          <UserImpersonation />
        ) : (
          <Navigate to={DASHBOARD} replace />
        ),
      showInNav: false,
    },
    {
      path: DASHBOARD,
      element: <Dashboard />,
      showInNav: false,
    },
    {
      path: OPIE_LAYOUTS,
      showInNav: true,
      element: <Layouts key={OPIE_LAYOUTS} isDefault={true} />,
      icon: <OpieLayoutIcon color="#FFFFFF" />,
      iconActive: <OpieLayoutIcon color="#034A93" />,
      navText: "OPIE Layouts",
    },
    {
      path: `${OPIE_LAYOUTS}/:id`,
      showInNav: false,
      element: <LayoutDetails />,
      icon: <CustomLayoutIcon color="#FFFFFF" />,
      iconActive: <CustomLayoutIcon color="#034A93" />,
      navText: "Custom Layouts",
    },
    {
      path: CUSTOM_LAYOUTS,
      showInNav: true,
      element: <Layouts key={CUSTOM_LAYOUTS} />,
      icon: <CustomLayoutIcon color="#FFFFFF" />,
      iconActive: <CustomLayoutIcon color="#034A93" />,
      navText: "Custom Layouts",
    },
    {
      path: `${CUSTOM_LAYOUTS}/:id`,
      showInNav: false,
      element: <LayoutDetails />,
      icon: <CustomLayoutIcon color="#FFFFFF" />,
      iconActive: <CustomLayoutIcon color="#034A93" />,
      navText: "Custom Layouts",
    },
    {
      path: PUBLISHED_LAYOUTS,
      showInNav: true,
      element: <Layouts key={PUBLISHED_LAYOUTS} />,
      icon: <PublishedLayoutIcon color="#FFFFFF" />,
      iconActive: <PublishedLayoutIcon color="#034A93" />,
      navText: "Published Layouts",
    },
    {
      path: `${PUBLISHED_LAYOUTS}/:id`,
      showInNav: false,
      element: <LayoutPreview key={PUBLISHED_LAYOUTS} editMode={false} />,
      icon: <PublishedLayoutIcon color="#FFFFFF" />,
      iconActive: <PublishedLayoutIcon color="#034A93" />,
      navText: "Preview Layout",
    },
    {
      path: `${SHARE}${FACILITY}/:id${LAYOUT}/:id`,
      showInNav: false,
      element: <SharedLayout />,
      icon: <PublishedLayoutIcon color="#FFFFFF" />,
      iconActive: <PublishedLayoutIcon color="#034A93" />,
      navText: "Share Layout",
    },
  ];
};
