import { GridSortDirection } from "@mui/x-data-grid-pro";
import { Facility } from "src/interfaces/groups.interface";
import { FacilityUser } from "src/interfaces/user-impersonation.interface";

export const mapSelectedFacilityToList = (list: Facility[], selectedFacility: Facility) => {
  return list.map((facility: Facility) => {
    if (facility.id === selectedFacility.id && selectedFacility.isChecked) {
      facility.isChecked = true;
    } else {
      facility.isChecked = false;
    }
    return facility;
  });
};

export const getFacilitySearchData = (facilityList: Facility[], value: string) => {
  return facilityList.filter(
    (facility: Facility) =>
      facility.name.toLowerCase().includes(value) ||
      facility.id.toLowerCase().includes(value) ||
      facility?.net_suite_id?.toLowerCase().includes(value)
  );
};

export const getUserSearchData = (userList: FacilityUser[], value: string) => {
  return userList.filter(
    (user: FacilityUser) =>
      user.name.toLowerCase().includes(value) ||
      user?.emailAddress?.toLowerCase().includes(value) ||
      user.opieClassification.toLowerCase().includes(value)
  );
};

export const sortArray = <T>(data: T[], field: keyof T, sort: GridSortDirection) => {
  const condition = sort === "desc" ? -1 : 1;
  return data.sort((a, b) => {
    const aValue = a[field] as string;
    const bValue = b[field] as string;
    if (aValue?.toLowerCase() < bValue?.toLowerCase()) return -1 * condition;
    if (aValue?.toLowerCase() > bValue?.toLowerCase()) return 1 * condition;

    return 0;
  });
};
