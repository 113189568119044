import { initialState } from "src/slices/shared/layouts/layouts-initial-state";
import {
  sharedLayoutsStoreSelector,
  sharedLayoutsLoaderSelector,
  associatedLayoutTypes,
  visitedLayoutTypes,
  sharedLayoutsFiltersSelector,
  associatedDeviceTypesSelector,
  associatedVisitTypesSelector,
} from "src/slices/shared/layouts/layouts-selector";
import { sharedLayoutsSlice } from "src/slices/shared/layouts/layouts-slices";

export { initialState };
export default sharedLayoutsSlice.reducer;
export {
  sharedLayoutsStoreSelector,
  sharedLayoutsLoaderSelector,
  associatedLayoutTypes,
  visitedLayoutTypes,
  sharedLayoutsFiltersSelector,
  associatedDeviceTypesSelector,
  associatedVisitTypesSelector,
};
