import * as React from "react";
const DeleteFormIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={190} height={190} fill="none">
    <path
      fill="#EBF4FF"
      d="M95 190c52.467 0 95-42.533 95-95S147.467 0 95 0 0 42.533 0 95s42.533 95 95 95Z"
    />
    <path
      fill="#fff"
      d="M152 190H38V67.133a20.29 20.29 0 0 0 20.267-20.267h73.466a20.143 20.143 0 0 0 5.942 14.324A20.127 20.127 0 0 0 152 67.133V190Z"
    />
    <path
      fill="#F10008"
      d="M95.002 129.2c16.789 0 30.4-13.61 30.4-30.4s-13.611-30.4-30.4-30.4c-16.79 0-30.4 13.61-30.4 30.4s13.61 30.4 30.4 30.4Z"
    />
    <path
      fill="#fff"
      d="M105.748 113.131 95 102.383l-10.748 10.748-3.583-3.583L91.417 98.8 80.67 88.052l3.583-3.582L95 95.218l10.748-10.748 3.583 3.582L98.583 98.8l10.748 10.748-3.583 3.583Z"
    />
    <path
      fill="#EBF4FF"
      d="M111.468 136.8H78.534a3.8 3.8 0 1 0 0 7.6h32.934a3.8 3.8 0 0 0 0-7.6ZM122.865 152H67.132a3.8 3.8 0 1 0 0 7.6h55.733a3.8 3.8 0 1 0 0-7.6Z"
    />
  </svg>
);
export default DeleteFormIcon;
