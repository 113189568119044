/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { Field, LayoutDetailsFilterInterface } from "src/interfaces/layout-details.interface";
import { initialState } from "src/slices/facility-forms-management/layout-details";
import {
  LayoutDetailStore,
  LayoutDetailResponse,
} from "src/slices/facility-forms-management/layout-details/layout-detail-interface";
import {
  getLayoutDetailThunk,
  updateLayoutDetailThunk,
  updateHideFormLayout,
} from "src/slices/facility-forms-management/layout-details/layout-detail-thunk";
import {
  initialStateData,
  initialFilterData,
} from "src/slices/facility-forms-management/layout-details/layout-detail-initial-state";
import { handleFieldIncludeStatus } from "src/pages/facility-forms-management/layout-details/utils/layout-details-methods";

export const layoutDetailSlice = createSlice({
  name: "layoutDetailSlice",
  initialState,
  reducers: {
    resetLayoutDetails: (state) => {
      state.layoutDetail.details = { ...initialStateData };
    },
    resetFilter: (state) => {
      state.filter = { ...initialFilterData, delegates: state.filter.delegates };
    },
    updateLayoutDetails: (
      state,
      action: PayloadAction<{
        field: string;
        value: string | string[] | number[] | boolean | Field[];
      }>
    ) => {
      const { field, value } = action.payload;
      const copy = {
        ...state.layoutDetail.details,
        [field]: value,
      };
      state.layoutDetail.details = copy;
    },
    updateLayoutField: (state, action: PayloadAction<{ fieldId: string; values: object }>) => {
      const { fieldId, values } = action.payload;
      state.layoutDetail.fields = state.layoutDetail.fields.map((field) =>
        field.fieldGUID === fieldId ? { ...field, ...values } : field
      );
    },
    reArrangeLayoutFields: (
      state,
      action: PayloadAction<{
        sourcePosition: number;
        destinationPosition: number;
        draggableId: string;
      }>
    ) => {
      const { sourcePosition, destinationPosition, draggableId } = action.payload;

      let updatedFields = [...state.layoutDetail.fields];
      updatedFields = updatedFields.map((field: Field) => {
        // updating the position
        if (field.fieldGUID === draggableId) {
          return { ...field, position: destinationPosition };
        }

        // moving up && in-range(>=destination && <source)
        if (
          sourcePosition > destinationPosition &&
          field.position >= destinationPosition &&
          field.position < sourcePosition
        ) {
          return { ...field, position: field.position + 1 };
        }

        // moving down && in-range(>=destination && <source)
        if (
          sourcePosition < destinationPosition &&
          field.position <= destinationPosition &&
          field.position > sourcePosition
        ) {
          return { ...field, position: field.position - 1 };
        }
        return field;
      });

      state.layoutDetail.fields = updatedFields;
    },
    updateLayoutFieldDelegateTypes: (
      state,
      action: PayloadAction<{ fieldId: string; values: string[] }>
    ) => {
      const { fieldId, values } = action.payload;

      const layoutFields = [...state.layoutDetail.fields];

      function getLayoutByFieldId(id: string) {
        return layoutFields.find((item) => item.fieldGUID === id) as Field;
      }

      const selectedLayout = getLayoutByFieldId(fieldId);

      const unSelectedDelegate: string[] = selectedLayout.delegateTypes.filter(
        (item) => !values.includes(item)
      );

      function removeDeSelectedChildDelegateFromParent(id: string) {
        const currentField = getLayoutByFieldId(id) as Field;
        const parentField = getLayoutByFieldId(currentField.parentGUID as string);
        if (parentField) {
          const children = layoutFields.filter((item) => item.parentGUID === parentField.fieldGUID);
          if (children.length > 0) {
            const status = children?.every(
              (item) => !item.delegateTypes.includes(unSelectedDelegate[0])
            );
            if (status) {
              parentField.delegateTypes = [
                ...parentField.delegateTypes.filter((item) => item !== unSelectedDelegate[0]),
              ];
            }
          }
          removeDeSelectedChildDelegateFromParent(currentField.parentGUID as string);
        }
        return parentField;
      }

      function updateChildDelegateToParent(id: string) {
        const field = getLayoutByFieldId(id) as Field;
        if (field) {
          if (field.fieldGUID === fieldId) {
            field.delegateTypes = [...values];
          } else {
            field.delegateTypes = Array.from(new Set([...field.delegateTypes, ...values]));
          }
          if (field.parentGUID) updateChildDelegateToParent(field.parentGUID);
        }
      }

      if (unSelectedDelegate.length > 0) {
        //update the specific field directly with updated delegates
        const data = selectedLayout.delegateTypes.filter((item) => item !== unSelectedDelegate[0]);
        layoutFields.map((item) => {
          if (item.fieldGUID === selectedLayout.fieldGUID) {
            item.delegateTypes = data;
          }
          return item;
        });
        removeDeSelectedChildDelegateFromParent(fieldId);
      } else {
        updateChildDelegateToParent(fieldId);
      }

      state.layoutDetail.fields = layoutFields;
    },

    updateFilter: (state, action: PayloadAction<LayoutDetailsFilterInterface>) => {
      state.filter.delegates = action.payload.delegates;
      state.filter.isRequired = action.payload.isRequired;
      state.filter.isApplied = action.payload.isApplied;
      state.filter.isVisible = action.payload.isVisible;
    },
    updateLayoutAllFileds: (state, action: PayloadAction<{ fields: Field[] }>) => {
      const { fields } = action.payload;
      state.layoutDetail.fields = fields;
    },
    bulkUpdateFields: (
      state,
      action: PayloadAction<{
        option: string;
        fieldGuid: string;
        actionType: "update" | "getCount";
      }>
    ) => {
      const { option, fieldGuid, actionType } = action.payload;

      const selectedParent = state.layoutDetail.fields.filter(
        (field: Field) => field.fieldGUID === fieldGuid
      );
      const fieldCount = { count: 0 };

      if (selectedParent.length > 0) {
        const { updatedData, updatedCount } = handleFieldIncludeStatus(
          selectedParent[0],
          option,
          state.layoutDetail.fields,
          actionType,
          fieldCount
        );
        if (actionType == "getCount") {
          state.affectedNoOfFields = fieldCount.count;
        } else {
          state.affectedNoOfFields = 0;
          state.layoutDetail.fields = updatedData;
        }
      }
    },
  },

  extraReducers: (builder) => {
    //Get Layout By ID
    builder.addCase(
      getLayoutDetailThunk.fulfilled,
      (state: LayoutDetailStore, { payload }: PayloadAction<LayoutDetailResponse>) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { modifiedDate, ...rest } = payload.data;
        state.isLoading = false;
        state.layoutDetail.details = {
          ...rest,
          defaultLayoutFriendlyName: rest.defaultLayoutFriendlyName
            ? rest.defaultLayoutFriendlyName
            : rest.defaultLayoutName,
          layoutDescription: rest.layoutDescription ? rest.layoutDescription : "",
          selectedFieldOnViewport: "",
        };
        state.layoutDetail.fields = rest.fields.map((item) => ({
          isOpen: false,
          ...item,
        }));
      }
    );
    builder.addCase(getLayoutDetailThunk.pending, (state: LayoutDetailStore) => {
      state.isLoading = true;
    });
    builder.addCase(getLayoutDetailThunk.rejected, (state: LayoutDetailStore) => {
      state.isLoading = false;
    });
    //update Layout By ID
    builder.addCase(updateLayoutDetailThunk.fulfilled, (state: LayoutDetailStore) => {
      state.isUpdateLoading = false;
    });
    builder.addCase(updateLayoutDetailThunk.pending, (state: LayoutDetailStore) => {
      state.isUpdateLoading = true;
    });
    builder.addCase(updateLayoutDetailThunk.rejected, (state: LayoutDetailStore) => {
      state.isUpdateLoading = false;
    });

    //update hidden By ID
    builder.addCase(updateHideFormLayout.fulfilled, (state: LayoutDetailStore) => {
      state.isUpdateLoading = false;
    });
    builder.addCase(updateHideFormLayout.pending, (state: LayoutDetailStore) => {
      state.isUpdateLoading = true;
    });
    builder.addCase(updateHideFormLayout.rejected, (state: LayoutDetailStore) => {
      state.isUpdateLoading = false;
    });
  },
});
