import React from "react";

import { KeyboardArrowDown } from "@mui/icons-material";

import {
  AccordionDiv,
  StyledAccordionSummary,
  AccordionHeading,
} from "src/shared/ui/accordion/styles";

interface Props {
  children: React.ReactElement;
  heading: string | React.ReactElement;
  expanded?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleExpand?: (event?: any) => void;
  className?: string;
}

export default function AccordionTemplate({
  children,
  heading,
  expanded,
  handleExpand,
  className,
}: Props) {
  return (
    <AccordionDiv expanded={expanded} className={className}>
      <StyledAccordionSummary
        sx={{ height: "64px" }}
        onClick={handleExpand}
        data-testid="expand-accordion"
        expandIcon={<KeyboardArrowDown sx={{ color: "#034A93" }} />}
      >
        <AccordionHeading>{heading}</AccordionHeading>
      </StyledAccordionSummary>
      {children}
    </AccordionDiv>
  );
}
