import styled from "styled-components";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const DialogHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 1.2rem 1.5rem 0 1.75rem;
  cursor: pointer;
`;
export const CloseIcon = styled(Close)``;
export const CopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const DialogActionDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;
export const UrlBox = styled.div`
  color: #034a93;
  margin: 1.5rem 0 0;
  display: flex;
  align-items: center;
  border: 1px solid #dededf;
  justify-content: space-between;
  padding: 0 0.5rem;
  width: 80%;
  border-radius: 8px;
`;
export const UrlLink = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 10rem;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DialogButton = styled(Button)`
  text-transform: none;
  background: #034a93;
  color: white !important;
  font-weight: bold;
  padding: 2% 4%;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 15rem;
`;

export const DialogHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: #034a93;
  margin: 0;
  margin-bottom: 1.5rem;
`;
