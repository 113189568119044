import { Button } from "@mui/material";
import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #034a93;
  color: #ffffff;
  height: 60%;
  padding: 1rem;
`;
export const ActionContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 1.5rem;
`;

export const Count = styled.div`
  font-size: 4.5rem;
`;
export const Text = styled.div`
  font-size: 2rem;
  padding-left: 0.4rem;
`;

export const Open = styled(Button)`
  background: #034a93;
  font-weight: bold;
  border-radius: 8px;
  color: #ffffff;
  width: 8rem;
`;
