import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OpieLayout, LayoutResponse } from "src/interfaces/layouts.interface";
import { initialState } from "src/slices/opie-forms-management/layouts";
import { LayoutsStore } from "src/slices/opie-forms-management/layouts/layouts-interface";
import { getLayoutsThunk } from "src/slices/opie-forms-management/layouts/layouts-thunks";

export const opieLayoutsSlice = createSlice({
  name: "opieLayoutsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get layouts
    builder.addCase(
      getLayoutsThunk.fulfilled,
      (state: LayoutsStore, { payload }: PayloadAction<LayoutResponse>) => {
        const copy = payload.data.map((layout: OpieLayout) => {
          return {
            id: layout.id,
            defaultLayoutGuid: layout.defaultLayoutGuid,
            defaultLayoutName: layout.defaultLayoutName,
            isPublic: layout.isPublic,
          };
        });
        state.isLoading = false;
        state.layouts = [...copy];
      }
    );
    builder.addCase(getLayoutsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLayoutsThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
