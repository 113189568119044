import styled from "styled-components";
import { HeaderDiv, Heading } from "src/pages/opie-forms-management/groups/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const Header = styled(HeaderDiv)`
  margin-bottom: 1rem;
`;

export const HeadingText = styled(Heading)``;

export const ParentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.6rem;
`;

export const SearchDiv = styled.div`
  flex: 1;
  height: 100%;
`;

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 0 1rem;
  margin-left: 0.938rem;
  flex-wrap: wrap;
  height: 100%;
`;

export const ButtonText = styled.span``;

export const StyledDataGridPro = styled(DataGridPro)`
  .hidden-row {
    color: rgb(150 141 141) !important;
  }
`;

export const StyledEllipse = styled.div`
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
`;

export const StyledBadge = styled.div`
  margin-left: 1rem;
`;
