import React from "react";
import {
  StyledArrow,
  StyledHeading,
  StyledTabDiv,
  StyledSelectTab,
} from "src/pages/facility-forms-management/user-impersonation/components/tab/styles";
import RightIcon from "src/shared/icons/right-icon";

interface Props {
  handleFacilityTab: () => void;
  handleUserTab: () => void;
  isFacilityTabSelected: boolean;
}

function Tab({ handleFacilityTab, handleUserTab, isFacilityTabSelected }: Props) {
  const rightArrowIcon = !isFacilityTabSelected ? "white" : "#034A93";

  return (
    <>
      <StyledHeading>Welcome to OPIE Forms</StyledHeading>
      <StyledTabDiv
        sx={{ fontSize: { sm: "1rem", md: "1.6rem" }, width: { sm: "80%", xs: "90%" } }}
      >
        <StyledSelectTab
          data-testid="handle-facility"
          onClick={handleFacilityTab}
          isfacility={String(isFacilityTabSelected as boolean)}
        >
          <RightIcon color={isFacilityTabSelected ? "white" : "#034A93"} />
          Select a Facility
          <StyledArrow isfacility={String(isFacilityTabSelected)}></StyledArrow>
        </StyledSelectTab>
        <StyledSelectTab
          data-testid={"handle-user"}
          onClick={handleUserTab}
          isfacility={String(!isFacilityTabSelected)}
        >
          <RightIcon color={rightArrowIcon} />
          Select a User
        </StyledSelectTab>
      </StyledTabDiv>
    </>
  );
}

export default Tab;
