import { useState } from "react";
import { getValueFromStorage, removeValueFromStorage } from "src/utils/storage";

export const useLocalStorage = (key: string, initialValue = "") => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = getValueFromStorage(key);
    return item ? item : initialValue;
  });

  const clearValue = () => {
    removeValueFromStorage(key);
    setStoredValue("");
  };

  return [storedValue, clearValue] as const;
};
