import * as React from "react";
const UserListIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={238} height={202} fill="none">
    <path
      fill="#D7EAFE"
      fillRule="evenodd"
      d="M121.086 0c23.776 0 45.628 8.23 62.882 22h27.887c3.623 0 6.588 2.97 6.588 6.6 0 3.63-2.965 6.6-6.588 6.6h-18.351c-3.623 0-6.588 2.97-6.588 6.6a6.58 6.58 0 0 0 1.937 4.66 6.554 6.554 0 0 0 4.651 1.94h37.674c3.623 0 6.588 2.97 6.588 6.6 0 3.63-2.965 6.6-6.588 6.6h-17.111c5.165 12.163 8.026 25.546 8.026 39.601 0 5.1-.377 10.111-1.102 15.009a99.299 99.299 0 0 1-1.094 6.08c-12.428-1.905-25.611-3.436-39.375-4.554l-1.036.946-.975-1.104c-16.534-1.289-34.359 4.712-52.259 4.712-38.031 0-73.152-3.59-103.582 1.796a101.356 101.356 0 0 1-2.336-15.695 99.99 99.99 0 0 1-.255-7.19C20.08 45.307 65.3 0 121.086 0Z"
      clipRule="evenodd"
    />
    <mask
      id="a"
      width={221}
      height={85}
      x={0}
      y={117}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" fillRule="evenodd" d="M.593 117v85h220.376v-85H.593Z" clipRule="evenodd" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#D7EAFE"
        fillRule="evenodd"
        d="M.596 146.994c0 3.75 2.99 6.819 6.646 6.819h42.451c1.826 0 3.491.768 4.692 2.004a6.881 6.881 0 0 1 1.954 4.815c0 3.75-2.99 6.819-6.646 6.819h-7.887a6.538 6.538 0 0 0-4.692 2.004 6.882 6.882 0 0 0-1.954 4.815c0 3.75 2.99 6.819 6.646 6.819h18.135C76.978 194.217 98.147 202 121.09 202c48.999 0 89.921-35.481 99.691-82.769-12.539-1.968-25.84-3.55-39.726-4.705l-1.045.977-.984-1.141c-16.681-1.332-34.192-2.041-52.252-2.041-38.37 0-74.276 3.201-104.977 8.765a105.406 105.406 0 0 0 6.26 19.089H7.242c-1.83 0-3.487.768-4.692 2.004a6.881 6.881 0 0 0-1.954 4.815Z"
        clipRule="evenodd"
      />
    </g>
    <g clipPath="url(#b)">
      <path
        fill="#fff"
        d="M178.812 116c0 3-6 5-13 6-4.5-8.5-13.5-15-24-19.5 1-1.5 2-2.5 3-4h4c15.5-.5 30 9 30 17.5Zm-86-18h-4c-15.5 0-30 9.5-30 18 0 3 6 5 13 6 4.5-8.5 13.5-15 24-19.5l-3-4.5Zm26 5c11 0 20-9 20-20s-9-20-20-20-20 9-20 20 9 20 20 20Zm0 5c-20.5 0-40 13-40 25 0 10 40 10 40 10s40 0 40-10c0-12-19.5-25-40-25Zm28.5-15h1.5c8.5 0 15-6.5 15-15s-6.5-15-15-15c-2.5 0-4.5.5-6.5 1.5 4 5 6.5 11.5 6.5 18.5 0 3.5-.5 7-1.5 10Zm-58.5 0h1.5c-1-3-1.5-6.5-1.5-10 0-7 2.5-13.5 6.5-18.5-2-1-4-1.5-6.5-1.5-8.5 0-15 6.5-15 15s6.5 15 15 15Z"
      />
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" d="M58.813 43h120v120h-120z" />
      </clipPath>
    </defs>
  </svg>
);
export default UserListIcon;
