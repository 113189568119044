import React, { useEffect, useState } from "react";
import { Form, FormModes } from "@opie-software/forms-viewer";
import { StyledSelectButton } from "src/pages/facility-forms-management/user-impersonation/components/table/styles";
import {
  StyledKeyboardBackspaceOutlinedIcon,
  StyledLayoutHeading,
} from "src/pages/facility-forms-management/layout-details/components/header/styles";
import BreadCrumb from "src/shared/ui/bread-crumb";
import {
  StyledPreviewContainer,
  StyledPreviewHeader,
} from "src/pages/facility-forms-management/shared/preview-layout/styles";
import { LayoutDetails } from "src/interfaces/layout-details.interface";
import { AppDispatch } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getLayoutDetailThunk } from "src/slices/facility-forms-management/layout-details/layout-detail-thunk";
import {
  layoutDetailsByIdSelector,
  layoutDetailsSelectorIsLoading,
  layoutFieldDetailsByIdSelector,
} from "src/slices/facility-forms-management/layout-details";
import Loader from "src/shared/loader";
import { pdfApiUrl, pdfApiUrlApiKey } from "src/utils/get_env";
import { UploadRequest, UploadResponse } from "src/utils/s3-file-upload";
import { Box } from "@mui/material";
import { fileRemoveFn } from "src/modulefederation/components/form-editor/s3-file-operations";

interface Props {
  handlePreviewLayout?: (status: boolean) => void;
  layoutDetailData?: LayoutDetails;
  editMode: boolean;
}

interface FieldData {
  fieldType: string;
}

function LayoutPreview({ handlePreviewLayout, layoutDetailData, editMode }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedDelegate] = useState<string>("");
  const initialLayoutDetailData = useSelector(layoutDetailsByIdSelector);
  const fieldsData = useSelector(layoutFieldDetailsByIdSelector);
  const layoutDetailDataIsLoading = useSelector(layoutDetailsSelectorIsLoading);
  const [data, setData] = useState(layoutDetailData);
  const [pdfDataLoading, setPdfDataLoading] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState();

  const getLayoutDetails = () => {
    if (id) {
      dispatch(getLayoutDetailThunk(id));
    }
  };

  useEffect(() => {
    if (!editMode) {
      getLayoutDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fieldsData) {
      if (fieldsData && fieldsData.length > 0) {
        const hasPdfJsonField = fieldsData.some((field: FieldData) => field.fieldType === "pdfapi");
        if (hasPdfJsonField) {
          setPdfDataLoading(true);
          fetch(`${pdfApiUrl}`, {
            method: "GET",
            headers: {
              "x-api-key": `${pdfApiUrlApiKey}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setPdfData(data);
              setPdfDataLoading(false);
            })
            .catch((error) => {
              setPdfDataLoading(false);
              console.error("Error fetching pdfs:", error);
            });
        }
      }
    }
  }, [fieldsData, setPdfData, setPdfDataLoading]);

  useEffect(() => {
    setData({ ...initialLayoutDetailData, fields: fieldsData });
  }, [initialLayoutDetailData, fieldsData]);

  const handlePreviewBack = () => {
    navigate(-1);
  };

  const layoutPathName = () => location.pathname.split("/")[1];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function uploadFn(data: UploadRequest): Promise<UploadResponse[] | null> {
    return Promise.reject(null);
  }

  return (
    <>
      <StyledPreviewContainer>
        <StyledSelectButton
          variant="outlined"
          data-testid="back-preview-button"
          bgcolor={"white"}
          border={"#034A93"}
          onClick={() =>
            editMode ? handlePreviewLayout && handlePreviewLayout(false) : handlePreviewBack()
          }
        >
          <StyledKeyboardBackspaceOutlinedIcon />
          Back
        </StyledSelectButton>
        <StyledPreviewHeader>
          <StyledLayoutHeading>
            {data && data.defaultLayoutFriendlyName} Preview
          </StyledLayoutHeading>
        </StyledPreviewHeader>
        <BreadCrumb
          linkList={[
            {
              url: `/${layoutPathName()}`,
              heading: "Layouts",
            },
          ]}
        />
      </StyledPreviewContainer>
      {layoutDetailDataIsLoading || pdfDataLoading ? (
        <Box style={{ textAlign: "center" }}>
          <Loader />
        </Box>
      ) : (
        <Box>
          {data && (
            <Form
              formProps={{
                apiData: {
                  ...data,
                  currentLoggedInDelegate: selectedDelegate,
                  modifiedDate: "",
                  mode: FormModes.PREVIEW,
                },
              }}
              fieldProps={{
                fileUploadHandlerFn: uploadFn,
                additionalData: pdfData,
                fileRemoveHandlerFn: fileRemoveFn,
              }}
            />
          )}
        </Box>
      )}
    </>
  );
}

export default LayoutPreview;
