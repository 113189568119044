import styled from "styled-components";
import { Heading } from "src/pages/opie-forms-management/groups/styles";
import { Box } from "@mui/material";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 85vh;
`;

export const HeadingText = styled(Heading)``;

export const CountsContainer = styled(Box)`
  height: 29vh;
  margin-bottom: 0.5rem;
`;

export const ChangeLogContainer = styled(Box)`
  height: 73vh;
  background: #ffffff;
`;
