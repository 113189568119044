import { UserManagementStore } from "src/slices/opie-forms-management/user-management/user-management-interface";
import { User } from "src/interfaces/user-management.interface";

export const initialState: UserManagementStore = {
  users: [],
  roles: [],
  isLoading: false,
  currentUser: <User>{},
  isUpdateLoading: false,
  isStatusLoading: false,
  privateLayouts: [],
  isUserLoading: true,
};
