import {
  Action,
  AnyAction,
  PreloadedState,
  Reducer,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import groupManagementSlice from "src/slices/opie-forms-management/groups";
import userSlice from "src/slices/shared/user";
import userManagementSlice from "src/slices/opie-forms-management/user-management";
import userImpersonationSlice from "src/slices/facility-forms-management/impersonation";
import opieLayoutsSlice from "src/slices/opie-forms-management/layouts";
import facilityLayoutsSlice from "src/slices/facility-forms-management/layouts";
import layoutDetailSlice from "src/slices/facility-forms-management/layout-details";
import { TYPE_LOGOUT } from "src/constants/action-types-constants";
import sharedLayoutsSlice from "src/slices/shared/layouts";
import dashboardSlice from "src/slices/facility-forms-management/dashboard";

// TODO: need to handle store
// let reducer: Reducer;

// if (process.env.REACT_APP_LAYOUT_MANAGEMENT_KEY === "opie") {
//   reducer = combineReducers({
//     userStore: userSlice,
//     userManagementStore: userManagementSlice,
//     groupsStore: groupManagementSlice,
//   });
// } else {
//   reducer = combineReducers({
//     userStore: userSlice,
//     userImpersonationStore: userImpersonationSlice,
//     layoutsStore: layoutsSlice,
//     layoutDetailStore: layoutDetailSlice,
//   });
// }

const reducer = combineReducers({
  userStore: userSlice,
  userManagementStore: userManagementSlice,
  groupsStore: groupManagementSlice,
  userImpersonationStore: userImpersonationSlice,
  layoutDetailStore: layoutDetailSlice,
  opieLayoutsStore: opieLayoutsSlice,
  facilityLayoutsStore: facilityLayoutsSlice,
  sharedLayoutStore: sharedLayoutsSlice,
  dashboardStore: dashboardSlice,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === TYPE_LOGOUT) {
    state = {} as RootState;
  }
  return reducer(state, action);
};

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== "production",
  });
}

export type AppDispatch = AppStore["dispatch"];
export type RootState = ReturnType<typeof reducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppStore = ReturnType<typeof setupStore>;
