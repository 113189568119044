import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { ChipProps } from "@mui/material";

interface LayoutBadgeProps extends ChipProps {
  layoutBadgeType: LayoutBadgeType;
}

enum LayoutBadgeType {
  NEW = "New",
  UPDATED = "Updated",
}

export const StyledLayoutBadge = styled(Chip)<LayoutBadgeProps>`
  background-color: ${({ layoutBadgeType }) =>
    layoutBadgeType === LayoutBadgeType.NEW ? "#00b03c" : "#03478c"};
  color: white;
  font-weight: bold;
  font-size: 15px;
  border-radius: 6px;
`;

export { LayoutBadgeProps, LayoutBadgeType };
