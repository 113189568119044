import * as React from "react";
import { Facility } from "src/interfaces/groups.interface";
import { DialogFacilityBtn, DialogFacilityDiv } from "src/shared/ui/chip/styles";
import CrossIcon from "src/shared/icons/cross-icon";
import { Box } from "@mui/material";

interface Props {
  facilities: Facility[];
  handleRemoveMember: (member: Facility) => void;
}
const ChipContainer: React.FC<Props> = ({ facilities, handleRemoveMember }) => {
  return (
    <DialogFacilityDiv>
      {facilities.map((member, index) => {
        return (
          <div key={index}>
            {member.isChecked && (
              <DialogFacilityBtn>
                {member.name}
                <Box
                  data-testid="remove-item"
                  sx={{ display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    handleRemoveMember(member);
                  }}
                >
                  <CrossIcon />
                </Box>
              </DialogFacilityBtn>
            )}
          </div>
        );
      })}
    </DialogFacilityDiv>
  );
};

export default ChipContainer;
