import React, { useEffect, useState } from "react";
import CustomDialog from "src/shared/modal";
import { CustomInputField } from "src/pages/facility-forms-management/layouts/components/copy-confirmation-modal/styles";
import { Box, InputLabel } from "@mui/material";
import {
  DeleteUserDiv as CopyButton,
  CancelUserDiv,
} from "src/shared/delete-confirmation-modal/styles";
import { Layout } from "src/interfaces/layouts.interface";
import { toast } from "react-toastify";
import { DUPLICATION_FAILED } from "src/constants/error-constants";
import { CUSTOM_LAYOUTS } from "src/constants/route-constants";
import { duplicateLayout } from "src/services/layouts-service";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  handleOpen: () => void;
  selectedLayout: Layout;
}

export default function CopyConfirmationModal({ open, handleOpen, selectedLayout }: Props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    setName(selectedLayout.defaultLayoutFriendlyName as string);
  }, [selectedLayout]);

  const handleLayout = async () => {
    setLoading(true);
    try {
      const response = await duplicateLayout({
        id: selectedLayout.id,
        layoutName: name,
      });
      if (response.data.statusCode == 201) {
        navigate(`${CUSTOM_LAYOUTS}/${response.data.data.layoutId}`);
        toast.success("Layout Copied Successfully!");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error?.response && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error(DUPLICATION_FAILED);
      }
    }
    setLoading(false);
  };

  return (
    <CustomDialog open={open} handleOpen={handleOpen} maxWidth={"sm"}>
      <Box sx={{ padding: "3rem 2rem" }}>
        <InputLabel sx={{ fontSize: "14px", fontWeight: 500, color: "#011428" }}>
          Name of the Layout <sup>*</sup>
        </InputLabel>
        <CustomInputField
          id="name"
          variant="outlined"
          size="small"
          placeholder="Layout Name"
          onChange={handleInput}
          value={name}
          inputProps={{ "data-testid": "layout-name", maxLength: 50 }}
        />
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
          <CopyButton
            data-testid="handle-copy"
            loading={loading}
            variant="contained"
            sx={{ marginRight: "1rem" }}
            onClick={handleLayout}
            disabled={!name}
          >
            Copy
          </CopyButton>
          <CancelUserDiv data-testid="cancel" onClick={handleOpen}>
            Cancel
          </CancelUserDiv>
        </Box>
      </Box>
    </CustomDialog>
  );
}
