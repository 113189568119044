import styled from "styled-components";

export const PageDiv = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
`;

export const CurrentPageText = styled.div`
  padding: 0rem 0.8rem;
  border: 1px solid rgb(3, 74, 147);
  border-radius: 4px;
`;

export const CountDiv = styled.div``;

export const CurrentPageDiv = styled.div`
  display: flex;
  margin-left: 0.8rem;
`;
