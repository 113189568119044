import React, { useMemo } from "react";
import { StyledHeading, StyledAssociateDiv, StyledDialogRow } from "src/shared/layout-modal/styles";
import { Box, Checkbox } from "@mui/material";
import { FilterType } from "src/interfaces/groups.interface";

interface Props {
  name: string;
  sidebarFilterList: FilterType[];
  selectedItems: string[];
  handleChange: (selected: string[]) => void;
}
function FilterSidebar({ name, sidebarFilterList, selectedItems, handleChange }: Props) {
  const sortedSidebarFilterList = useMemo(
    () => sidebarFilterList.sort((a, b) => a.name.localeCompare(b.name, "en", { numeric: true })),
    [sidebarFilterList]
  );

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    let selected: string[] = [];
    e.stopPropagation();
    if (selectedItems.some((V) => V === name)) {
      selected = selectedItems.filter((v) => v !== name);
    } else {
      selected = [...selectedItems, name];
    }

    return handleChange(selected);
  };

  return (
    <>
      <StyledHeading>{name}</StyledHeading>
      <StyledAssociateDiv>
        {sortedSidebarFilterList.map((data: FilterType, index: number) => {
          return (
            <StyledDialogRow
              sx={{
                gridTemplateColumns: { xs: "40% 60%", sm: "30% 70%" },
                padding: "0.5rem",
              }}
              key={index}
            >
              <Box
                sx={{
                  fontSize: { xs: "12px", sm: "20px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                  checked={selectedItems.some((s) => s === data.name)}
                  data-testid={`checkbox-${name.split(" ")[0].toLowerCase()}`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleClick(e, data.name)}
                />

                <Box sx={{ fontSize: { xs: "12px", sm: "16px" } }}>{data.name}</Box>
              </Box>
            </StyledDialogRow>
          );
        })}
      </StyledAssociateDiv>
    </>
  );
}

export default FilterSidebar;
