import { Select } from "@mui/material";
import styled from "styled-components";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
export const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-icon": {
    color: "#034a93",
  },
}));
