import { Box, Checkbox, Grid } from "@mui/material";
import styled from "styled-components";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CheckIcon from "@mui/icons-material/Check";

interface StyledCheckboxProps {
  index: number;
}

export const StyledLayoutLeftHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledGridLeftHeaderChild = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const StyledGridLayoutRightHeader = styled(Grid)`
  display: flex;
  align-items: center;
  color: rgb(3, 74, 147);
  cursor: pointer;
`;

export const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  && {
    color: ${(props) => (props.index === 0 ? "rgb(3, 74, 147)" : "red")} !important;
    &:hover {
      background-color: transparent; /* Remove hover effect */
    }
    padding: 2px !important;
  }
`;

export const StyledLayoutHeading = styled.h2``;

export const StyledInfoIcon = styled(InfoRoundedIcon)`
  color: rgb(3, 74, 147);
  cursor: pointer;
`;

export const StyledNavigateNextIcon = styled(NavigateNextIcon)`
  color: rgb(3, 74, 147);
  cursor: pointer;
`;

export const StyledTooltipChildContainer = styled.div``;

export const StyledTooltipItemContainer = styled.div``;

export const StyledKeyboardBackspaceOutlinedIcon = styled(KeyboardBackspaceOutlinedIcon)`
  margin-right: 0.4rem;
`;

export const StyleCheckIcon = styled(CheckIcon)`
  color: white;
`;

export const StyledLeftColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
