import axios from "axios";
import { BASE_URL } from "src/constants/app-config-constants";
import { HTTPResponse } from "src/interfaces/httpResponse.interface";
import { User } from "src/interfaces/user-management.interface";

export async function getUsers(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users`;
  return axios.get(url);
}

export async function getUserById(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/${id}`;
  return axios.get(url);
}

export async function createUser(user: User): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/admin/create-user`;
  return axios.post(url, user);
}

export async function updateUser(user: User): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/update/${user.id}`;
  return axios.put(url, user);
}

export async function deleteUser(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/delete/${id}`;
  return axios.delete(url);
}

export async function updateUserStatus(data: {
  id: string;
  status: boolean;
}): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/changeStatus`;
  return axios.post(url, data);
}

export async function getRoles(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/roles`;
  return axios.get(url);
}

export async function userLayouts(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/${id}/layouts`;
  return axios.get(url);
}

export async function addUserLayout(id: string, layouts: Array<string>): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/${id}/layouts`;
  return axios.post(url, { layouts });
}

export async function deleteUserLayout(id: string, layoutsId: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/users/${id}/layout/${layoutsId}`;
  return axios.delete(url);
}
