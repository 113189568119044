import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { User } from "src/interfaces/user.interface";
import {
  InputLabelText,
  InputTextField,
  ForgotPasswordDiv,
  ForgotPasswordText,
  RememberMeText,
  SubmitButton,
  Heading,
  RequiredText,
  CheckBoxText,
  ShowPasswordIcon,
  HidePasswordIcon,
  PasswordDiv,
} from "src/pages/shared/auth/login/styles";
import { AppDispatch } from "src/redux/store";
import { getLoggedInUserThunk } from "src/slices/shared/user/user-thunks";
import { LOCALSTORAGE_EMAIL, LOCALSTORAGE_USER_TOKEN_KEY } from "src/constants/auth-constants";
import {
  getValueFromStorage,
  removeValueFromStorage,
  storeValueInStorage,
} from "src/utils/storage";
import { setToken, tokenSelector, userLoadingSelector } from "src/slices/shared/user";
import { useLocation, useNavigate } from "react-router-dom";
import { HOME, RECOVER_PASSWORD } from "src/constants/route-constants";
import AuthLayout from "src/shared/auth-layout";
import { useLocalStorage } from "src/utils/storage.hook";
import { checkEmail } from "src/utils/regex-functions";
import { IN_VALID_EMAIL } from "src/constants/error-constants";

function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(userLoadingSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(tokenSelector);
  const [storedToken] = useLocalStorage(LOCALSTORAGE_USER_TOKEN_KEY);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<boolean>(false);

  const inputRef = React.useRef<HTMLHeadingElement>();
  const [loginData, setLoginData] = useState<User>({
    email: "",
    password: "",
    isRememberMeChecked: false,
    idpToken: "",
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  useEffect(() => {
    const emailId: string = getValueFromStorage(LOCALSTORAGE_EMAIL) as string;
    if (loginData.email === "" && emailId) {
      setLoginData({ ...loginData, email: emailId, isRememberMeChecked: true });
      inputRef?.current?.focus();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setToken(storedToken));
  }, [dispatch, storedToken]);

  useEffect(() => {
    if (token) {
      const { state } = location;
      navigate(HOME, { state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id, checked } = e.target;
    if (id === "checkbox") {
      setLoginData({ ...loginData, isRememberMeChecked: checked });
      return;
    } else if (id === "email" && !checkEmail.test(value) && value.length > 0) {
      setEmailError(true);
    } else if (id === "email") {
      setEmailError(false);
    }
    setLoginData({ ...loginData, [id]: value });
  };

  const togglePasswordVisibility = () => {
    setIsShowPassword(isShowPassword ? false : true);
  };

  const submitLoginData = () => {
    if (loginData.isRememberMeChecked) {
      storeValueInStorage(LOCALSTORAGE_EMAIL, loginData.email);
    } else {
      removeValueFromStorage(LOCALSTORAGE_EMAIL);
    }
    dispatch(getLoggedInUserThunk(loginData));
  };

  useEffect(() => {
    const { email, password } = loginData;
    if (email !== "" && password !== "" && !emailError) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [loginData, emailError]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !isDisabled) {
      submitLoginData();
    }
  };

  return (
    <AuthLayout>
      <Grid item container height={"100%"}>
        <Grid container item xs={12} paddingTop="3rem">
          <Grid item xs={1} md={1} lg={2} xl={2}></Grid>
          <Grid item xs={10} md={10} lg={8} xl={8}>
            <Heading data-testid="heading">Welcome</Heading>
            <InputLabelText>
              Email <RequiredText>*</RequiredText>
            </InputLabelText>
            <InputTextField
              id="email"
              variant="outlined"
              size="small"
              placeholder="Email"
              onChange={handleInput}
              value={loginData.email}
              inputProps={{ "data-testid": "email", maxLength: 256 }}
              helperText={!emailError ? "" : IN_VALID_EMAIL}
            />
            <br />
            <br />
            <InputLabelText>
              Password <RequiredText>*</RequiredText>
            </InputLabelText>
            <PasswordDiv>
              {isShowPassword ? (
                <ShowPasswordIcon onClick={togglePasswordVisibility} data-testid="hideText" />
              ) : (
                <HidePasswordIcon onClick={togglePasswordVisibility} data-testid="showText" />
              )}
              <InputTextField
                id="password"
                variant="outlined"
                size="small"
                type={isShowPassword ? "text" : "password"}
                placeholder="Password"
                onChange={handleInput}
                inputRef={inputRef}
                onKeyDown={handleKeyPress}
                inputProps={{ "data-testid": "password" }}
              />
            </PasswordDiv>
            <ForgotPasswordDiv>
              <FormControlLabel
                control={
                  <CheckBoxText
                    size="small"
                    checked={loginData.isRememberMeChecked}
                    id="checkbox"
                    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    inputProps={{ "data-testid": "checkbox" }}
                    onChange={handleInput}
                    color="primary"
                  />
                }
                label={<RememberMeText variant="h6">Remember me</RememberMeText>}
                id="rememberMe"
              />
              <ForgotPasswordText to={RECOVER_PASSWORD}>Forgot your password?</ForgotPasswordText>
            </ForgotPasswordDiv>
            <SubmitButton
              size="small"
              onClick={submitLoginData}
              loading={isLoading}
              variant="contained"
              data-testid="submit"
              disabled={isDisabled}
            >
              Log in
            </SubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default Login;
