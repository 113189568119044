import * as React from "react";
const SelectIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={13} fill="none">
    <path
      fill="#fff"
      d="M6.5 10.172 15.694.979l1.415 1.414L6.5 13 .137 6.636 1.55 5.222l4.95 4.95Z"
    />
  </svg>
);
export default SelectIcon;
