import React, { useEffect, useState } from "react";
import { List, ListItem } from "@mui/material";
import { useLocation } from "react-router-dom";
import { NavRoute } from "src/interfaces/sidebarRoutes.interface";
import {
  SideBarDiv,
  NavLink,
  NavText,
  IconDiv,
  LogoutDiv,
} from "src/shared/side-bar/sidebar-items/styles";
import { facilityPrivateRoutes, opiePrivateRoutes } from "src/shared/side-bar/routes";
import LogoutIcon from "src/shared/icons/logout-icon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LOCALSTORAGE_IDP_USER_FLAG,
  LOCALSTORAGE_USER_TOKEN_KEY,
} from "src/constants/auth-constants";
import { LOGIN, USER_MANAGEMENT } from "src/constants/route-constants";
import { currentUserSelector } from "src/slices/shared/user";
import { useLocalStorage } from "src/utils/storage.hook";
import { logoutUserThunk } from "src/slices/shared/user/user-thunks";
import { AppDispatch } from "src/redux/store";
import { appEnv } from "src/constants/get-env";
import { MANAGEMENT_KEY } from "src/constants/app-config-constants";
import { TYPE_LOGOUT } from "src/constants/action-types-constants";
import { getValueFromStorage } from "src/utils/storage";

function SideBarItems() {
  const location = useLocation();
  const [selectedPath, setSelectedPath] = useState<string>("");
  const currentUser = useSelector(currentUserSelector);
  const [navList, setNavList] = useState<NavRoute[]>([]);
  const [, clearTokenFromStorage] = useLocalStorage(LOCALSTORAGE_USER_TOKEN_KEY);

  useEffect(() => {
    setSelectedPath(`/${location.pathname.slice(1)}`);
  }, [location.pathname]);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isIDPUser = getValueFromStorage(LOCALSTORAGE_IDP_USER_FLAG);
  const handleLogout = () => {
    dispatch(logoutUserThunk())
      .unwrap()
      .then(() => {
        clearTokenFromStorage();
        dispatch({ type: TYPE_LOGOUT });
        navigate(LOGIN);
      });
  };
  useEffect(() => {
    const superAdminRoutes = [USER_MANAGEMENT];
    const role = currentUser && currentUser.role && currentUser.role.role_name;
    const isSuperAdmin = role === "super_admin" ? true : false;
    const appRoutes =
      appEnv === MANAGEMENT_KEY.OPIE ? opiePrivateRoutes(role) : facilityPrivateRoutes(role);
    if (!isSuperAdmin) {
      const filteredNavList = appRoutes.filter((item) => !superAdminRoutes.includes(item.path));
      setNavList(filteredNavList);
    } else {
      setNavList(appRoutes);
    }
  }, [currentUser]);

  return (
    <SideBarDiv sx={{ fontSize: { xs: "0.79rem", sm: "0.79rem", lg: "0.8rem", xl: "1rem" } }}>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "90%",
          paddingTop: "1.5rem",
        }}
      >
        {navList.map((item: NavRoute, index: number) => {
          if (!item.showInNav) return null;
          return (
            <ListItem key={item.path} disablePadding data-testid={`nav${index}`}>
              <NavLink
                to={item.path}
                color={selectedPath.includes(item.path) ? "#034A93" : "#FFFFFF"}
              >
                <IconDiv>{selectedPath.includes(item.path) ? item.icon : item.iconActive}</IconDiv>
                <NavText color={selectedPath.includes(item.path) ? "#FFFFFF" : "#034A93"}>
                  {item.navText}
                </NavText>
              </NavLink>
            </ListItem>
          );
        })}
      </List>
      {!isIDPUser && (
        <List>
          <ListItem disablePadding>
            <LogoutDiv onClick={handleLogout} data-testid="logout-btn">
              <IconDiv>
                <LogoutIcon />
              </IconDiv>
              <NavText color={"#034A93"}>Logout</NavText>
            </LogoutDiv>
          </ListItem>
        </List>
      )}
    </SideBarDiv>
  );
}

export default SideBarItems;
