import React from "react";
import { Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { NavLink } from "src/shared/header/styles";

interface Props {
  linkList: {
    url: string;
    heading: string;
  }[];
}
export default function BreadCrumb({ linkList }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        gap: "12px",
        marginTop: "1rem",
        paddingLeft: "0.1rem",
      }}
    >
      {linkList.map((item, i) => (
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: "12px" }}
          key={i}
        >
          <NavLink to={item.url} data-testid="users">
            {item.heading}
          </NavLink>
          <ArrowForwardIosIcon fontSize="inherit" htmlColor="#034A93" />
        </Box>
      ))}
    </Box>
  );
}
