import { appEnv, apiUrl } from "src/utils/get_env";

export enum MANAGEMENT_KEY {
  OPIE = "opie",
  FACILITY = "facility",
}

export const BASE_URL =
  appEnv && appEnv === MANAGEMENT_KEY.OPIE
    ? `${apiUrl}/opie-layout-management/api`
    : `${apiUrl}/facility-layout-management/api`;
