import React from "react";
import {
  IncludeCheckbox,
  RequiredCheckbox,
  FieldItemGrid,
  FieldItemGridContainer,
  StyledSelect,
  GripIcon,
} from "src/pages/facility-forms-management/layout-details/components/fields-metadata/styles";
import { Field } from "src/interfaces/layout-details.interface";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Checkbox, ListItemText, MenuItem, SelectChangeEvent } from "@mui/material";
import { renderValues } from "src/utils/layout-method";
import { AppDispatch } from "src/redux/store";
import { useDispatch } from "react-redux";
import {
  updateLayoutDetails,
  updateLayoutField,
  updateLayoutFieldDelegateTypes,
} from "src/slices/facility-forms-management/layout-details";
import { DraggableProvided } from "react-beautiful-dnd";
import { delegatesList } from "src/constants/delegates";
interface Props {
  field: Field;
  draggableProvided: DraggableProvided;
}

function FieldsMetaDataItem({ field, draggableProvided }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const handleUpdate = (values: object) => {
    dispatch(
      updateLayoutField({
        fieldId: field.fieldGUID,
        values,
      })
    );
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdate({ [event.target.name]: event.target.checked });
  };

  const handleStopPropagation = (event: { stopPropagation: () => void }) => event.stopPropagation();

  const handleChangeSelect = (event: SelectChangeEvent<unknown>) => {
    const values: string[] = Array.isArray(event.target.value)
      ? (event.target.value as string[])
      : ([event.target.value] as string[]);
    dispatch(updateLayoutFieldDelegateTypes({ fieldId: field.fieldGUID, values }));
  };

  const handleSelectedFieldOnViewport = () => {
    const fieldId = field.fieldGUID;
    const values = { field: "selectedFieldOnViewport", value: fieldId };
    dispatch(updateLayoutDetails(values));
  };

  const isSelectedDelegate = (item: string) =>
    field.delegateTypes && field.delegateTypes.includes(item);

  return (
    <FieldItemGridContainer container onClick={handleSelectedFieldOnViewport}>
      <FieldItemGrid item lg={9}>
        <div {...draggableProvided.dragHandleProps}>
          <GripIcon />
        </div>
        <IncludeCheckbox
          disableRipple
          checked={field.include}
          onChange={handleChangeCheckbox}
          data-testid="checkbox-field"
          name="include"
        />
        <RequiredCheckbox
          disableRipple
          checked={field.required}
          onChange={handleChangeCheckbox}
          name="required"
        />
        {field.visibleText}
      </FieldItemGrid>
      <FieldItemGrid item lg={3}>
        <StyledSelect
          labelId="multiple-delegates"
          id="demo-multiple-checkbox"
          name="delegateTypes"
          multiple
          aria-label="my custom select"
          value={field.delegateTypes}
          onChange={handleChangeSelect}
          IconComponent={KeyboardArrowDownIcon}
          renderValue={renderValues}
          inputProps={{ "data-testid": "delegate-type" }}
          MenuProps={{
            BackdropProps: { "aria-label": "backdrop-select" },
          }}
        >
          {field.prefilteredDelegates.map((item) => (
            <MenuItem key={item} value={item} onClick={handleStopPropagation}>
              <Checkbox data-testid={"delegates-checkbox"} checked={isSelectedDelegate(item)} />
              <ListItemText
                primary={
                  delegatesList[item.toLowerCase() as string]
                    ? delegatesList[item.toLowerCase() as string]
                    : item
                }
              />
            </MenuItem>
          ))}
        </StyledSelect>
      </FieldItemGrid>
    </FieldItemGridContainer>
  );
}

export default FieldsMetaDataItem;
