import { initialState } from "src/slices/opie-forms-management/user-management/user-management-initial-state";
import { userManagementSlice } from "src/slices/opie-forms-management/user-management/user-management-slices";
import {
  userManagementSelector,
  rolesSelector,
  currentSelectedUserSelector,
  currentUserPrivateLayoutsSelector,
  userManagementData,
} from "src/slices/opie-forms-management/user-management/user-management-selector";

export { initialState };

export default userManagementSlice.reducer;

export {
  userManagementSelector,
  rolesSelector,
  currentSelectedUserSelector,
  currentUserPrivateLayoutsSelector,
  userManagementData,
};
