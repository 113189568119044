export const dataGridStyles = {
  backgroundColor: "white",
  width: "100%",
  display: "flex",
  borderRadius: "10px",
  fontSize: "1rem",
  "& .MuiDataGrid-root": {
    borderRadius: "10px",
  },
  "& .MuiDataGrid-cell": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader": {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "1.2rem",
    outline: "none !important",
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
  },
  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
    display: "none",
    width: "0 !important",
  },
};
