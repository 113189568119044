import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { LayoutDetails } from "src/interfaces/layout-details.interface";
import { LayoutSearchParams, PublishLayout } from "src/interfaces/layouts.interface";
import {
  createLayout,
  deleteCustomLayout,
  getLayouts,
  updatePublishLayout,
  unPublishLayout,
} from "src/services/layouts-service";
import { getConvertedSentenceToUpperCase } from "src/utils/convert-strings-case";

export const getLayoutsThunk = createAsyncThunk(
  "getfacilityLayoutsThunk",
  async (req: LayoutSearchParams, { rejectWithValue }) => {
    try {
      const res = await getLayouts(req);
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const getImpersonationLayoutsThunk = createAsyncThunk(
  "getImpersonationLayoutsThunk",
  async (req: LayoutSearchParams, { rejectWithValue }) => {
    try {
      const res = await getLayouts(req);
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const createLayoutThunk = createAsyncThunk(
  "createLayoutThunk",
  async (layoutDetails: LayoutDetails, { rejectWithValue }) => {
    try {
      const res = await createLayout(layoutDetails);
      toast.success(getConvertedSentenceToUpperCase(res.data.message));
      return {
        data: res.data.data,
        message: res.data.message,
        statusCode: res.data.statusCode,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteCustomLayoutThunk = createAsyncThunk(
  "deleteCustomLayoutThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await deleteCustomLayout(id);
      toast.success(getConvertedSentenceToUpperCase(res.data.message));
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePublishLayoutThunk = createAsyncThunk(
  "updatePublishLayoutThunk",
  async (req: { id: string; body: PublishLayout }, { rejectWithValue }) => {
    try {
      const res = await updatePublishLayout(req.id, req.body);
      toast.success(getConvertedSentenceToUpperCase(res.data.message));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const unPublishLayoutThunk = createAsyncThunk(
  "unPublishLayoutThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await unPublishLayout(id);
      toast.success(getConvertedSentenceToUpperCase(res.data.message));

      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
