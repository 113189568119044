import styled from "styled-components";

export const DialogHeader = styled.div`
  display: flex;
  justify-content: center;
  color: #034a93;
  padding: 20px;
  cursor: pointer;
  font-size: 28px;
  font-weight: 600;
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 35rem;
  border: 1px solid #d1d3d4;
  margin: 10px;
  overflow-y: auto;
  border-radius: 6px;
  margin: 0rem 1rem 1.5rem;

  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1d3d4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

export const DialogRow = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: 35% 55%;
`;
export const DialogColumn = styled.div`
  flex: 1;
  border-right: 1px solid #d1d3d4;
  padding: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const DialogSubText = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: #000a14;
  padding: 0;
  margin: 0;
  margin-left: 0.5rem;
`;

export const DialogDescriptionColumn = styled(DialogSubText)`
  margin-top: 0.5rem;
`;

export const EmptyDataText = styled.p`
  text-align: center;
  font-size: 18px;
`;
