import { delegatesList } from "src/constants/delegates";

export const getIsCheckedMappedData = (data: string[]) => {
  return data.map((value: string) => {
    return { name: value, isChecked: false };
  });
};

export const getUniqueValuesFromArray = (data: string[]) => {
  return Array.from(new Set(data));
};

export const renderValues = (selected: unknown) =>
  Array.isArray(selected)
    ? selected.length > 1
      ? `${
          selected[0].length > 18
            ? `${selected[0].slice(0, 18)}...`
            : delegatesList[selected[0].toLowerCase() as string]
            ? delegatesList[selected[0].toLowerCase() as string]
            : selected[0]
        } + ${selected.length - 1}`
      : delegatesList[selected[0].toLowerCase() as string]
      ? delegatesList[selected[0].toLowerCase() as string]
      : selected[0]
    : "";

export const getLabelValuePair = (array: string[]) => array.map((d) => ({ label: d, value: d }));
export const isEmptyArray = (arr: string[]) => arr.length === 0;
