import React from "react";
import { Form, FormModes } from "@opie-software/forms-viewer";
import { Field } from "src/interfaces/layout-details.interface";
import { useSelector } from "react-redux";
import {
  layoutDetailsByIdSelector,
  filterSelector,
  layoutFieldDetailsByIdSelector,
  updateLayoutDetails,
  updateLayoutField,
} from "src/slices/facility-forms-management/layout-details";
import { useDispatch } from "react-redux";

interface Props {
  localLayoutDetailData: Field[];
}

export default function FormCom({ localLayoutDetailData }: Props) {
  const dispatch = useDispatch();
  const layoutDetailData = useSelector(layoutDetailsByIdSelector);
  const filter = useSelector(filterSelector);
  const layoutFieldDetailData = useSelector(layoutFieldDetailsByIdSelector);

  const handleUpdateField = (fieldId: string, payload: object, resetSelectedField?: boolean) => {
    const data = layoutFieldDetailData.filter((item) => item.fieldGUID === fieldId)[0];
    if (resetSelectedField) {
      const values = { field: "selectedFieldOnViewport", value: "" };
      dispatch(updateLayoutDetails(values));
    } else {
      const values = { fieldTypeOptions: { ...data.fieldTypeOptions, Options: payload } };
      dispatch(updateLayoutField({ fieldId, values }));
    }
  };

  return (
    <Form
      formProps={{
        apiData: {
          ...layoutDetailData,
          fields:
            filter.isApplied && localLayoutDetailData
              ? [...localLayoutDetailData]
              : [...layoutFieldDetailData],
          currentLoggedInDelegate: null,
          mode: FormModes.CONFIGURE,
          modifiedDate: "",
        },
        showRequiredDropdown: false,
        onFieldDataUpdate: handleUpdateField,
        showDelegateDropdown: false,
      }}
    />
  );
}
