import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getGroupsThunk,
  getFacilitiesThunk,
  getGroupByIdThunk,
  deleteGroupThunk,
  deleteFacilityFromGroupThunk,
  getAssignedLayoutsThunk,
  getGroupsBySearchThunk,
  searchFacilityFromGroupThunk,
  getHistoryDetailsThunk,
} from "src/slices/opie-forms-management/groups/groups-thunk";
import { GroupManagementStore } from "src/slices/opie-forms-management/groups/groups-interface";
import { initialState } from "src/slices/opie-forms-management/groups";
import {
  FacilityResponse,
  GroupInterface,
  GroupResponse,
  GroupResponseById,
  History,
  AssignedLayoutResponse,
} from "src/interfaces/groups.interface";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";

export const groupManagementSlice = createSlice({
  name: "groupManagementSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Groups
    builder.addCase(
      getGroupsThunk.fulfilled,
      (state: GroupManagementStore, { payload }: PayloadAction<GroupResponse>) => {
        state.isSearchLoading = false;
        state.groups = [...payload.data];
      }
    );
    builder.addCase(getGroupsThunk.pending, (state) => {
      state.isSearchLoading = true;
    });
    builder.addCase(getGroupsThunk.rejected, (state) => {
      state.isSearchLoading = false;
    });
    //get facilities
    builder.addCase(
      getFacilitiesThunk.fulfilled,
      (state: GroupManagementStore, { payload }: PayloadAction<FacilityResponse>) => {
        state.isLoading = false;
        const facilities = [...payload.data];
        state.facilities = facilities.map((item) => {
          const newItem = { ...item };
          newItem.name = getConvertedUpperCaseString(newItem.name);
          return newItem;
        });
      }
    );
    builder.addCase(getFacilitiesThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFacilitiesThunk.rejected, (state) => {
      state.isLoading = false;
    });

    // get By id
    builder.addCase(
      getGroupByIdThunk.fulfilled,
      (state: GroupManagementStore, { payload }: PayloadAction<GroupResponseById>) => {
        state.isLoading = false;
        const selectedGroup = payload.data;
        const groupData = {
          id: selectedGroup.id as string,
          name: selectedGroup.name as string,
          description: selectedGroup.description as string,
          facilities: selectedGroup.facilities,
          is_default: selectedGroup.is_default,
          is_system_generated: selectedGroup.is_system_generated,
          created_time: selectedGroup.created_time as Date,
          updated_time: selectedGroup.updated_time as Date,
        };
        state.currentGroup = groupData;
      }
    );
    builder.addCase(getGroupByIdThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGroupByIdThunk.rejected, (state) => {
      state.isLoading = false;
    });
    //delete User
    builder.addCase(
      deleteGroupThunk.fulfilled,
      (
        state: GroupManagementStore,
        {
          payload,
        }: PayloadAction<{
          data: { data: {}; message: string; statusCode: number };
          id: string;
        }>
      ) => {
        state.isLoading = false;
        const groupData = [...state.groups];
        const updatesUsers = groupData
          .map((item: GroupInterface) => {
            const newItem = { ...item };
            newItem.is_deleted =
              newItem.id === payload.id ? !newItem.is_deleted : newItem.is_deleted;
            return newItem;
          })
          .filter((group) => !group.is_deleted);
        state.groups = [...updatesUsers];
        const filteredSearchResults = state.groupSearchData
          .map((item: GroupInterface) => {
            const newItem = { ...item };
            newItem.is_deleted =
              newItem.id === payload.id ? !newItem.is_deleted : newItem.is_deleted;
            return newItem;
          })
          .filter((group) => !group.is_deleted);
        state.groupSearchData = [...filteredSearchResults];
      }
    );
    builder.addCase(deleteGroupThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteGroupThunk.rejected, (state) => {
      state.isLoading = false;
    });
    //delete facility from group
    builder.addCase(
      deleteFacilityFromGroupThunk.fulfilled,
      (
        state: GroupManagementStore,
        {
          payload,
        }: PayloadAction<{
          data: { data: {}; message: string; statusCode: number };
          id: string;
        }>
      ) => {
        state.isLoading = false;
        const copy = state.currentGroup;
        copy.facilities = copy.facilities.filter((data) => data.id !== payload.id);
        state.currentGroup = { ...copy };
      }
    );
    builder.addCase(deleteFacilityFromGroupThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFacilityFromGroupThunk.rejected, (state) => {
      state.isLoading = false;
    });
    // get Assigned layouts
    builder.addCase(
      getAssignedLayoutsThunk.fulfilled,
      (state: GroupManagementStore, { payload }: PayloadAction<AssignedLayoutResponse>) => {
        state.isLoading = false;
        state.assignedLayouts = payload.data;
      }
    );
    builder.addCase(getAssignedLayoutsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAssignedLayoutsThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      getGroupsBySearchThunk.fulfilled,
      (state: GroupManagementStore, { payload }: PayloadAction<GroupResponse>) => {
        state.isSearchLoading = false;
        state.groupSearchData = [...payload.data];
      }
    );
    builder.addCase(getGroupsBySearchThunk.pending, (state) => {
      state.isSearchLoading = true;
    });
    builder.addCase(getGroupsBySearchThunk.rejected, (state) => {
      state.isSearchLoading = false;
    });
    // facility search from group
    builder.addCase(
      searchFacilityFromGroupThunk.fulfilled,
      (state: GroupManagementStore, { payload }: PayloadAction<FacilityResponse>) => {
        state.isSearchLoading = false;
        state.searchFacilities = payload.data;
      }
    );
    builder.addCase(searchFacilityFromGroupThunk.pending, (state) => {
      state.isSearchLoading = true;
    });
    builder.addCase(searchFacilityFromGroupThunk.rejected, (state) => {
      state.isSearchLoading = false;
    });

    // get history
    builder.addCase(
      getHistoryDetailsThunk.fulfilled,
      (state: GroupManagementStore, { payload }: PayloadAction<{ data: History[] }>) => {
        state.isLoading = false;
        state.auditHistory = [...payload.data];
      }
    );
    builder.addCase(getHistoryDetailsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHistoryDetailsThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
