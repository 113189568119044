import { initialState } from "src/slices/opie-forms-management/layouts/layouts-initial-state";
import {
  opieLayoutsSelector,
  opieLayoutsLoaderSelector,
} from "src/slices/opie-forms-management/layouts/layouts-selector";
import { opieLayoutsSlice } from "src/slices/opie-forms-management/layouts/layouts-slices";
export { initialState };
export default opieLayoutsSlice.reducer;

export { opieLayoutsSelector, opieLayoutsLoaderSelector };
