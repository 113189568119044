import React, { ReactElement } from "react";
import Pagination from "@mui/material/Pagination";
import { PaginationDiv } from "src/shared/pagination/styles";

interface Props {
  handleChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  dataSize?: number;
  pageNumber?: number;
}

export default function PaginationPage({
  handleChange,
  dataSize,
  pageNumber,
}: Props): ReactElement {
  return (
    <PaginationDiv>
      <Pagination
        page={pageNumber}
        size="large"
        data-testid="handle-page"
        count={dataSize}
        onChange={handleChange}
        shape="rounded"
        color="primary"
      />
    </PaginationDiv>
  );
}
