import React from "react";
import {
  GripIcon,
  Accordion,
  AccordionHeading,
  MoreOptions,
} from "src/pages/facility-forms-management/layout-details/components/fields-metadata/styles";

import { Field } from "src/interfaces/layout-details.interface";
import FieldsMetaDataItem from "src/pages/facility-forms-management/layout-details/components/fields-metadata/components/fields-metadata-item";
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
  DraggableProvided,
} from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/redux/store";
import { updateLayoutField } from "src/slices/facility-forms-management/layout-details";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface Props {
  field: Field;
  onDragEnd: (value: DropResult) => void;
  onActionMenuItemSelectHandler?: (option: string, fieldGuid: string) => void;
}
type Options =
  | "Include All"
  | "Exclude All"
  | "Clear Requirements"
  | "Require All Fields"
  | "Require All Included Fields";

function FieldsMetaDataList({ field, onDragEnd, onActionMenuItemSelectHandler }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    event.nativeEvent.stopImmediatePropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleAccordion = (event: any) => {
    event.stopPropagation();
    const fieldId = field.fieldGUID;
    const values = { isOpen: !field.isOpen };
    dispatch(updateLayoutField({ fieldId, values }));
  };

  const options: Options[] = [
    "Include All",
    "Exclude All",
    "Clear Requirements",
    "Require All Fields",
    "Require All Included Fields",
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const renderAccordionHeading = (draggableProvided: DraggableProvided) => (
    <Box onClick={handleStopPropagation} sx={{ display: "flex", alignItems: "center" }}>
      <span {...draggableProvided.dragHandleProps}>
        <GripIcon />
      </span>
      <AccordionHeading parentGUID={field.parentGUID}>{field.visibleText}</AccordionHeading>
      <MoreOptions>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-haspopup="true"
          onClick={handleClick}
          data-testid="open-bulk-options-menu"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              data-testid="select-bulk-option"
              onClick={() => {
                onActionMenuItemSelectHandler &&
                  onActionMenuItemSelectHandler(option, field.fieldGUID);
                handleClose();
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </MoreOptions>
    </Box>
  );

  return (
    <>
      <div style={{ flex: 1 }}>
        <Draggable
          key={field.fieldGUID}
          index={field.position}
          draggableId={field.fieldGUID}
          data-testid="data-item"
        >
          {(draggableProvided) => (
            <div {...draggableProvided.draggableProps} ref={draggableProvided.innerRef}>
              {field.children ? (
                <div>
                  <Accordion
                    heading={renderAccordionHeading(draggableProvided)}
                    expanded={field.isOpen}
                    handleExpand={toggleAccordion}
                    data-testid="drag-drop-fields"
                  >
                    <div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={field.fieldGUID} type="task">
                          {(droppableProvided) => (
                            <div>
                              <section
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                              >
                                {field.children &&
                                  field.children.map((child) => (
                                    <FieldsMetaDataList
                                      key={child.fieldGUID}
                                      field={child}
                                      onDragEnd={onDragEnd}
                                      onActionMenuItemSelectHandler={onActionMenuItemSelectHandler}
                                    />
                                  ))}
                              </section>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Accordion>
                </div>
              ) : (
                <FieldsMetaDataItem field={field} draggableProvided={draggableProvided} />
              )}
            </div>
          )}
        </Draggable>
      </div>
    </>
  );
}

export default FieldsMetaDataList;
