import React, { useEffect } from "react";
import { AppDispatch } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { History } from "src/interfaces/groups.interface";

import {
  DialogHeader,
  DialogContent,
  DialogRow,
  DialogColumn,
  DialogSubText,
  DialogDescriptionColumn,
  EmptyDataText,
} from "src/pages/opie-forms-management/groups/components/history-modal/styles";
import { getHistoryDetailsThunk } from "src/slices/opie-forms-management/groups/groups-thunk";
import { groupSelector } from "src/slices/opie-forms-management/groups";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";
import moment from "moment";
import Loader from "src/shared/loader";
import CustomDialog from "src/shared/modal";
interface Props {
  open: boolean;
  handleOpen: (open: boolean) => void;
  groupId: string;
  groupName: string;
}
const HistoryModal: React.FC<Props> = ({ open, handleOpen, groupId, groupName }) => {
  const dispatch = useDispatch<AppDispatch>();
  const groupData = useSelector(groupSelector);

  useEffect(() => {
    dispatch(getHistoryDetailsThunk({ entity_id: groupId }));
  }, [dispatch, groupId]);

  return (
    <CustomDialog open={open} handleOpen={handleOpen} maxWidth="md">
      <DialogHeader>{getConvertedUpperCaseString(groupName)} Updates History</DialogHeader>
      <DialogContent>
        {groupData.auditHistory.length > 0 && !groupData.isLoading ? (
          groupData.auditHistory.map((history: History, index: number) => {
            const date = moment(history.created_time as Date).format("L");
            const time = moment(history.created_time as Date).format("LT");
            return (
              <DialogRow
                key={index}
                style={
                  index == groupData.auditHistory.length - 1
                    ? { borderBottom: "none" }
                    : { borderBottom: "1px solid #d1d3d4" }
                }
              >
                <DialogColumn>
                  <DialogSubText>
                    {date} {time}
                  </DialogSubText>
                  <DialogSubText>{getConvertedUpperCaseString(history.user)}</DialogSubText>
                </DialogColumn>
                <div>
                  <DialogDescriptionColumn>{history.description}</DialogDescriptionColumn>
                </div>
              </DialogRow>
            );
          })
        ) : groupData.isLoading ? (
          <Loader />
        ) : (
          <EmptyDataText>No Audits Are Available!</EmptyDataText>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default HistoryModal;
