import styled from "styled-components";
import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import { Box } from "@mui/material";

export const UserManagementDiv = styled.div``;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
`;

export const UserButtonDiv = styled.div`
  display: flex;
  gap: 20px;
`;

export const AddUserDiv = styled(Button)`
  text-transform: none;
  background: #034a93;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
`;
export const AddUserIcon = styled(AddIcon)``;

export const DeactivateUserDiv = styled(AddUserDiv)<{ textcolor: string }>`
  background: #fff;
  color: ${(props) => props.textcolor};
`;

export const DeactivateUserIcon = styled(RemoveCircleOutline)`
  color: #f10008;
  margin-right: 0.5rem;
`;

export const EditUserIconDiv = styled.div`
  margin-right: 1rem;
  cursor: pointer;
`;

export const DeleteUserIconDiv = styled.div`
  margin-right: 1rem;
  cursor: pointer;
`;

export const SearchIconDiv = styled(Box)`
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  background: #034a93;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
`;

export const RemoveIconDiv = styled.div`
  position: absolute;
  top: 41%;
  left: 48.5%;
`;
