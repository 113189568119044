import { useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
  TextField,
} from "@mui/material";
import * as React from "react";
import { StyledDropDownBox, StyledKeyboardArrowDownIcon } from "src/shared/layout-modal/styles";
import { CustomIcon, StyledSelect } from "src/shared/ui/multi-select/styles";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
type Option = { label: string; value: string };

type IMultiSelect = SelectProps & {
  id: string;
  placeholder: string;
  options: Option[];
  value: string[];
  handleChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
  renderValue: (selected: unknown) => JSX.Element | string;
  extraStyles?: { [key: string]: string | number };
  enableSearch?: boolean;
};

const MultiSelect = (props: IMultiSelect) => {
  const { id, placeholder, options, value, handleChange, renderValue, extraStyles, enableSearch } =
    props;
  const [searchText, setSearchText] = useState<string>("");
  const [open, toggleOpen] = useState<boolean>(false);
  const sortedOptions = useMemo(
    () => options.sort((a, b) => a.value.localeCompare(b.value)),
    [options]
  );

  const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => sortedOptions.filter((option) => containsText(option.label, searchText)),
    [searchText, sortedOptions]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e: any, value: boolean) => {
    e.preventDefault();
    toggleOpen(value);
  };

  const handleClearText = () => {
    setSearchText("");
  };
  return (
    <Box sx={{ display: "flex", position: "relative", gap: "10px" }}>
      <StyledSelect
        multiple
        renderValue={(selected: unknown) => {
          if (Array.isArray(selected) && selected.length === 0) {
            return <div>{placeholder}</div>;
          }
          return renderValue(selected);
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              width: 250,
            },
          },
          style: { zIndex: 35001 },
          BackdropProps: { "aria-label": "backdrop-select" },
          autoFocus: false,
        }}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        displayEmpty
        inputProps={{
          "aria-label": "Select an option",
        }}
        open={open}
        onClose={(e) =>
          setTimeout(() => {
            handleOnChange(e, false);
            if (enableSearch) handleClearText();
          }, 0)
        }
        IconComponent={CustomIcon}
        sx={{ textAlign: "center", ...extraStyles }}
        onClick={(e) => handleOnChange(e, true)}
      >
        {enableSearch && (
          <ListSubheader>
            <TextField
              value={searchText}
              size="small"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && (
                      <ClearIcon
                        style={{ cursor: "pointer", fontSize: 20 }}
                        onClick={handleClearText}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {displayedOptions.map((opt: Option) => {
          return (
            <MenuItem key={opt.value} value={opt.value}>
              <Checkbox
                data-testid="filter-checkbox"
                checked={value.some((ix: string) => ix === opt.value)}
              />
              <ListItemText style={{ whiteSpace: "normal" }} primary={opt.label} />
            </MenuItem>
          );
        })}
      </StyledSelect>
      <StyledDropDownBox
        style={{ zIndex: 1000, cursor: "pointer" }}
        className="select-btn"
        onClick={(e) => handleOnChange(e, !open)}
        data-testid={id}
      >
        <StyledKeyboardArrowDownIcon />
      </StyledDropDownBox>
    </Box>
  );
};

export default MultiSelect;
