import { Box } from "@mui/material";
import React from "react";
import { Title } from "src/pages/facility-forms-management/dashboard/components/changelog/styles";
import SearchBar from "src/shared/ui/search-bar";

function ChangeLog() {
  const handleFilterList = () => {
    return;
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    return value;
  };
  const handleKeyPress = () => {
    return;
  };
  return (
    <Box sx={{ display: "flex", p: "1.5rem", flexDirection: "column" }}>
      <Title>Change Log</Title>
      <SearchBar
        handleSearch={handleSearch}
        handleKeyPress={handleKeyPress}
        handleFilterList={handleFilterList}
        placeholderText=""
      ></SearchBar>
    </Box>
  );
}

export default ChangeLog;
