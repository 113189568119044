import { Box, Grid } from "@mui/material";
import { AddGroupDiv } from "src/pages/opie-forms-management/groups/styles";
import styled from "styled-components";

export const StyledContainer = styled(Box)`
  padding: 2rem;
`;

export const StyledFilterHeading = styled.h1`
  text-align: center;
  color: rgb(3, 74, 147);
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledGridItem = styled(Grid)`
  padding-top: 1rem !important;
`;

export const StyledDelegateHeading = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
`;

export const StyledFilterFooter = styled(Box)`
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StyledSelectApplyButton = styled(AddGroupDiv)`
  display: flex;
  gap: 0.4rem;
  padding: 0.6rem 6rem;
`;

export const StyledCheckBoxText = styled.div`
  font-size: 18px;
`;
