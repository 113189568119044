import * as React from "react";

interface Props {
  color?: string;
}
const PublishedLayoutIcon = ({ color }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill={color ? color : "#FFFFFF"}
      d="M21 3C21 2.448 20.552 2 20 2H4C3.448 2 3 2.448 3 3V21C3 21.552 3.448 22 4 22H20C20.552 22 21 21.552 21 21V15.242L19 17.242V20H5V4H19V8.757L21 6.757V3Z"
    />
    <path
      fill={color ? color : "#FFFFFF"}
      stroke={color ? color : "#FFFFFF"}
      d="M15.9207 15.0367L21.5837 9.63184L22.4555 10.4632L15.9207 16.6994L12 12.9578L12.8711 12.1264L15.9207 15.0367Z"
      strokeWidth="0.6"
    />
  </svg>
);
export default PublishedLayoutIcon;
