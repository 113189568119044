import styled from "styled-components";
import ImpersonateIcon from "src/shared/icons/ImpersonateUserIcon";
import { Box, Button } from "@mui/material";

interface AddUserIconDivProps {
  bgcolor?: string;
  iconColor?: string;
}

export interface StyledSelectButtonProps {
  bgcolor?: string;
  border?: string;
  textColor?: string;
}

export const AddUserIconDiv = styled(ImpersonateIcon)<AddUserIconDivProps>`
  cursor: pointer;
`;

export const StyledSelectButton = styled(Button)<StyledSelectButtonProps>`
  font-weight: 500;
  font-size: 16px;
  padding: 6px 24px;
  text-transform: none;
  border-radius: 6px;
  width: 6rem;
  height: 2rem;
  background-color: ${(props) => props.bgcolor};
  border: 1px solid ${(props) => props.border};
`;

export const StyledTable = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 0.6rem 0.6rem 0 0;
  margin-top: 1rem;
`;

export const StyleTableHeading = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0.8rem 0.8rem 0 0.6rem;
  align-items: center;
`;
