export enum layoutsMenuItem {
  PUBLISH_TO_OA = "Publish to OA",
  SHARE = "Share",
  COPY = "Copy",
  DELETE = "Delete",
  PREVIEW_FORM = "Preview Form",
  UPDATE_ACCESS = "Update Access",
  EDIT = "Edit",
  REMOVE = "Remove",
}

export const PUBLISH_FACILITY = "Publish to everyone at the Facility";
export const PUBLISH_BRANCH = "Publish to Branch(es)";
export const PUBLISH_USERS = "Publish to User(s)";
export const SELECT_BRANCHES = "Select Branch(es)";
export const SELECT_USERS = "Select User(s)";
export const USERS = "users";
export const BRANCHES = "branches";
