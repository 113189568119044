import { Box, styled } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const StyledFooter = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 1rem;
`;

export const StyledFooterNextButton = styled(LoadingButton)`
  padding: 0 2rem;
  text-transform: none;
  background: #034a93;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  height: 56px;
`;
