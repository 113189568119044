import React from "react";
import {
  EmptyListDiv,
  Heading,
  SubHeading,
  EmptyUsersList,
  UserIconDIv,
  CountDiv,
} from "src/shared/empty-list/styles";
import { AddUserDiv, AddUserIcon } from "src/pages/opie-forms-management/user-management/styles";

interface Props {
  heading: string;
  subHeading: string;
  hideButton?: boolean;
  handleNavigate?: () => void;
  buttonText?: string;
}
function EmptyList({ heading, subHeading, hideButton, handleNavigate, buttonText }: Props) {
  return (
    <EmptyListDiv>
      <UserIconDIv>
        <EmptyUsersList />
        <CountDiv>0</CountDiv>
      </UserIconDIv>
      <Heading data-testid="heading">{heading}</Heading>
      <SubHeading>{subHeading}</SubHeading>
      {!hideButton && (
        <AddUserDiv
          variant="contained"
          sx={{
            fontSize: { xs: "14px", sm: "16px" },
            height: "3rem",
            marginTop: "1rem",
          }}
          onClick={handleNavigate}
          data-testid="handle-create"
        >
          <AddUserIcon />
          {buttonText}
        </AddUserDiv>
      )}
    </EmptyListDiv>
  );
}

export default EmptyList;
