import styled from "styled-components";

export const FormDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`;

export const FormHeading = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-decoration-line: underline;
  color: #034a93;
`;

export const FormsParentDiv = styled.div`
  background: #ffffff;
  border: 1px solid #dededf;
  border-radius: 6px;
  margin: 0 1rem 1rem;
`;
export const NoAssignFormDiv = styled.div`
  padding: 10px;
`;
