import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  UpdateGroup,
  CreateGroupInterface,
  FilterOptions,
  HistoryReq,
} from "src/interfaces/groups.interface";
import {
  getGroups,
  createGroup,
  getFacilities,
  getGroupById,
  updateGroup,
  deleteGroup,
  deleteFacilityFromGroup,
  addGroupLayout,
  getAssignedLayout,
  getGroupsBySearch,
  searchFacility,
  getHistoryDetails,
  deleteLayoutFromGroup,
} from "src/services/groups-service";
import { getConvertedSentenceToUpperCase } from "src/utils/convert-strings-case";
import { Layout } from "src/interfaces/layouts.interface";

export const createGroupThunk = createAsyncThunk(
  "createGroupThunk",
  async (group: CreateGroupInterface, { rejectWithValue }) => {
    try {
      const res = await createGroup(group);
      toast.success("Facility Group Created Successfully!");
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const getGroupsThunk = createAsyncThunk("getGroupsThunk", async (_, { rejectWithValue }) => {
  try {
    const res = await getGroups();
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const getGroupByIdThunk = createAsyncThunk(
  "getGroupByIdThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getGroupById(id);

      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGroupThunk = createAsyncThunk(
  "updateGroupThunk",
  async (group: UpdateGroup, { rejectWithValue }) => {
    try {
      const facilitiesId = group.facilities.map((item) => item.id);
      await updateGroup({ ...group, facilities: facilitiesId });
      toast.success("Updated Facility Group Successfully!");
      return { data: group };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFacilitiesThunk = createAsyncThunk(
  "getFacilitiesThunk",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getFacilities();
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteGroupThunk = createAsyncThunk(
  "deleteGroupThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await deleteGroup(id);
      toast.success("Deleted Facility Group Successfully!");
      return { data: res.data, id: id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFacilityFromGroupThunk = createAsyncThunk(
  "deleteFacilityFromGroupThunk",
  async (req: { group_id: string; facility_id: string }, { rejectWithValue }) => {
    try {
      const res = await deleteFacilityFromGroup(req);
      toast.success("Deleted Facility Successfully!");
      return { data: res.data, id: req.facility_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const getGroupsBySearchThunk = createAsyncThunk(
  "getGroupsBySearchThunk",
  async (req: FilterOptions, { rejectWithValue }) => {
    try {
      const res = await getGroupsBySearch(req);
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchFacilityFromGroupThunk = createAsyncThunk(
  "searchFacilityFromGroupThunk",
  async (req: string, { rejectWithValue }) => {
    try {
      const res = await searchFacility(req);
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGroupLayoutThunk = createAsyncThunk(
  "updateGroupLayoutThunk",
  async (req: { id: string; layouts: Layout[] }, { rejectWithValue }) => {
    const layoutsId: string[] = req.layouts.map((layout: Layout) => layout.id);

    try {
      const res = await addGroupLayout(req.id, layoutsId);
      toast.success("Assigned Layouts Successfully To The Facility Group!");
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssignedLayoutsThunk = createAsyncThunk(
  "getAssignedLayoutsThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getAssignedLayout(id);
      return {
        data: res.data.data,
        message: res.data.message,
        statusCode: res.data.statusCode,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
export const getHistoryDetailsThunk = createAsyncThunk(
  "getHistoryDetailsThunk",
  async (req: HistoryReq, { rejectWithValue }) => {
    try {
      const res = await getHistoryDetails(req);
      return { data: res.data.data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteLayoutFromGroupThunk = createAsyncThunk(
  "deleteLayoutFromGroupThunk",
  async (req: { group_id: string; layout_id: string }, { rejectWithValue }) => {
    try {
      const res = await deleteLayoutFromGroup(req);
      toast.success("Deleted Layout Successfully!");
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
