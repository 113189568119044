import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledZeuModalHeading = styled.h1`
  text-align: center;
  color: #034a93;
`;

export const StyledZeuModalBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const StyledZeuModalInputBox = styled(Box)`
  width: 61%;
  display: flex;
  flex-direction: column;
  text-align: start;f
`;

export const StyledZeuModalFooter = styled(Box)`
  display: flex;
  gap: 1rem;
  width: 61%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
