export const layoutInitialFilterValues = {
  searchValue: "",
  associatedType: [],
  deviceType: [],
  visitType: [],
  associatedTrack: [],
  associatedAnatomy: [],
  fromDate: "",
  toDate: "",
  isHidden: false,
  formCategories: [],
};
