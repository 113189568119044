import React, { useState } from "react";
import {
  InputLabelText,
  InputTextField,
  Heading,
  RequiredText,
  EmailDiv,
  KnowPasswordText,
  SubmitButton,
  SubText,
} from "src/pages/shared/auth/password-recovery/style";
import { toast } from "react-toastify";
import AuthLayout from "src/shared/auth-layout";
import { Grid } from "@mui/material";
import { getForgotPasswordLink } from "src/services/user-services";
import { LOGIN } from "src/constants/route-constants";
import { checkEmail } from "src/utils/regex-functions";
import { IN_VALID_EMAIL } from "src/constants/error-constants";

function PasswordRecovery() {
  const inputRef = React.useRef<HTMLHeadingElement>();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!checkEmail.test(value) && value.length > 0) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(value);
  };
  const sendEmail = async () => {
    setIsLoading(true);
    try {
      const result = await getForgotPasswordLink(email);
      if (result) {
        toast.success("Email Sent Successfully!");
        setIsLoading(false);
        setEmail("");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsLoading(false);
      if (error.response.data) {
        toast.error(error.response.data.message);
      }
    }
  };
  return (
    <AuthLayout>
      <Grid container item xs={12} paddingTop="4rem" height={"100%"}>
        <Grid item xs={1} md={1} lg={2}></Grid>
        <Grid item xs={10} md={10} lg={8}>
          <Heading data-testid="heading">Password Recovery</Heading>
          <SubText>
            Enter your email so that we can send you <br /> a reset code
          </SubText>

          <InputLabelText>
            Email <RequiredText>*</RequiredText>
          </InputLabelText>
          <EmailDiv>
            <InputTextField
              id="email"
              variant="outlined"
              size="small"
              placeholder="Email"
              onChange={handleInput}
              inputRef={inputRef}
              value={email}
              inputProps={{
                "data-testid": "email",
              }}
              helperText={!emailError ? "" : IN_VALID_EMAIL}
            />
          </EmailDiv>

          <SubmitButton
            size="small"
            onClick={sendEmail}
            disabled={email.length > 0 && !emailError ? false : true}
            variant="contained"
            loading={isLoading}
            data-testid="submit"
          >
            Recover Password
          </SubmitButton>

          <KnowPasswordText to={LOGIN} data-testid="knowPasswordText">
            I already know my password
          </KnowPasswordText>
        </Grid>
        <Grid item xs={1} md={1} lg={2}></Grid>
      </Grid>
    </AuthLayout>
  );
}

export default PasswordRecovery;
