import React, { FunctionComponent, useMemo } from "react";
import {
  StyledFilter,
  StyledFilterDiv,
  StyledBtn,
  ClearButton,
} from "src/pages/facility-forms-management/layouts/components/filter/styles";
import MultiSelect from "src/shared/ui/multi-select";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { getLabelValuePair, renderValues } from "src/utils/layout-method";
import { placeholderTexts } from "src/pages/facility-forms-management/layouts/components/filter/constants";
import { SelectChangeEvent } from "@mui/material";
import { useSelector } from "react-redux";
import { LayoutFilters } from "src/interfaces/layouts.interface";
import { sharedLayoutsFiltersSelector } from "src/slices/shared/layouts";
interface Props {
  handleFilterChange: (event: SelectChangeEvent<unknown>, type: string) => void;
  filterValues: LayoutFilters;
  handleApplyFilter: () => void;
  handleClear: () => void;
  isLoading: boolean;
}

const FilterComponent: FunctionComponent<Props> = ({
  handleFilterChange,
  filterValues,
  handleApplyFilter,
  handleClear,
  isLoading,
}) => {
  const filtersData = useSelector(sharedLayoutsFiltersSelector);

  const deviceOptions = useMemo(() => {
    if (filtersData && filtersData.associatedTypes) {
      return getLabelValuePair(filtersData.associatedTypes);
    } else {
      return [];
    }
  }, [filtersData]);

  const associatedAnatomyOptions = useMemo(() => {
    if (filtersData && filtersData.associatedAnatomy) {
      return getLabelValuePair(filtersData.associatedAnatomy);
    } else {
      return [];
    }
  }, [filtersData]);

  const deviceTypeOptions = useMemo(() => {
    if (filtersData && filtersData.associatedDeviceTypes) {
      return getLabelValuePair(filtersData.associatedDeviceTypes);
    } else {
      return [];
    }
  }, [filtersData]);

  const visitTypeOptions = useMemo(() => {
    if (filtersData && filtersData.associatedVisitTypes) {
      return getLabelValuePair(filtersData.associatedVisitTypes);
    } else {
      return [];
    }
  }, [filtersData]);

  const formCategoriesOptions = useMemo(() => {
    if (filtersData && filtersData.formCategories) {
      return getLabelValuePair(filtersData.formCategories);
    } else {
      return [];
    }
  }, [filtersData]);

  return (
    <StyledFilter>
      <StyledFilterDiv>
        <FilterListOutlinedIcon />
        <MultiSelect
          placeholder={placeholderTexts.device}
          options={deviceOptions}
          value={filterValues.associatedType}
          handleChange={(e: SelectChangeEvent<unknown>) => handleFilterChange(e, "associatedType")}
          renderValue={renderValues}
          id="device"
          extraStyles={{ color: "#034a93" }}
        />
        <MultiSelect
          placeholder={placeholderTexts.associatedAnatomy}
          options={associatedAnatomyOptions}
          value={filterValues.associatedAnatomy}
          handleChange={(e: SelectChangeEvent<unknown>) =>
            handleFilterChange(e, "associatedAnatomy")
          }
          renderValue={renderValues}
          id="associatedAnatomy"
          extraStyles={{ color: "#034a93" }}
        />
        <MultiSelect
          placeholder={placeholderTexts.deviceType}
          options={deviceTypeOptions}
          value={filterValues.deviceType}
          handleChange={(e: SelectChangeEvent<unknown>) => handleFilterChange(e, "deviceType")}
          renderValue={renderValues}
          id="deviceType"
          extraStyles={{ color: "#034a93" }}
          enableSearch={true}
        />
        <MultiSelect
          placeholder={placeholderTexts.visitType}
          options={visitTypeOptions}
          value={filterValues.visitType}
          handleChange={(e: SelectChangeEvent<unknown>) => handleFilterChange(e, "visitType")}
          renderValue={renderValues}
          id="visitType"
          extraStyles={{ color: "#034a93" }}
          enableSearch={true}
        />
        <MultiSelect
          placeholder={placeholderTexts.formCategories}
          options={formCategoriesOptions}
          value={filterValues.formCategories}
          handleChange={(e: SelectChangeEvent<unknown>) => handleFilterChange(e, "formCategories")}
          renderValue={renderValues}
          id="formCategories"
          extraStyles={{ color: "#034a93" }}
        />
      </StyledFilterDiv>
      <StyledFilterDiv>
        <StyledBtn
          disabled={isLoading}
          variant="outlined"
          data-testid="apply-filters"
          onClick={handleApplyFilter}
        >
          Apply
        </StyledBtn>
        <ClearButton onClick={handleClear} data-testid="clear-filter">
          Clear
        </ClearButton>
      </StyledFilterDiv>
    </StyledFilter>
  );
};

export default FilterComponent;
