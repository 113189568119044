import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGridPro, GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import { Layout } from "src/interfaces/layouts.interface";
import { ActionDiv, DeleteUserIconDiv } from "src/pages/opie-forms-management/groups/styles";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";
import DeleteIcon from "src/shared/icons/delete-icon";
import { dataGridStyles } from "src/constants/datagrid-styles";
import SortingIcon from "src/shared/icons/sorting-icon";
import { sortByDate, sortByName } from "src/utils/sorting-methods";
import DeleteGroup from "src/shared/delete-confirmation-modal";
import { AppDispatch } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledContainer,
  StyledHeader,
  StyledHeading,
  StyledButton,
} from "src/pages/opie-forms-management/user-management/components/layouts/styles";
import LayoutModal from "src/shared/layout-modal";
import { AddUserIcon } from "src/pages/opie-forms-management/user-management/styles";
import {
  deleteUserLayoutThunk,
  getUserLayoutThunk,
} from "src/slices/opie-forms-management/user-management/user-management-thunks";
import {
  currentUserPrivateLayoutsSelector,
  currentSelectedUserSelector,
} from "src/slices/opie-forms-management/user-management";
import moment from "moment";
import { getLayoutsThunk } from "src/slices/opie-forms-management/layouts/layouts-thunks";
interface Props {
  isDisableVirtualization?: boolean;
}
function Layouts({ isDisableVirtualization }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(currentSelectedUserSelector);
  const currentUserPrivateLayouts = useSelector(currentUserPrivateLayoutsSelector);
  const [layoutModal, setOpenLayoutModal] = useState<boolean>(false);
  const [selectedLayout, setSelectedLayout] = useState<Layout>();
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    dispatch(getLayoutsThunk({ public: false }));
  }, [dispatch]);
  useEffect(() => {
    if (currentUser.id) {
      dispatch(getUserLayoutThunk(currentUser.id as string));
    }
  }, [dispatch, currentUser]);
  const handleOpen = (open: boolean) => {
    setOpenLayoutModal(open);
  };
  const handleLayoutModal = (open: boolean) => {
    setIsDeleteModal(open);
  };
  const handleDeleteLayout = () => {
    const userId = currentUser.id as string;
    const layoutId = selectedLayout?.id as string;
    setIsLoading(true);
    dispatch(deleteUserLayoutThunk({ id: userId, layoutId }))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
    handleLayoutModal(false);
  };
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData: Layout = params.row;
        return (
          <Box
            sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            data-testid="data-grid-pro"
          >
            {getConvertedUpperCaseString(rowData.id)}
          </Box>
        );
      },
      sortingOrder: ["asc", "desc"],
      valueGetter: (params) => params.row.defaultLayoutName,
      sortComparator: sortByName,
    },
    {
      field: "published",
      headerName: "Published",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData: Layout = params.row;
        const date: string = moment(rowData.createdDate).format("L");
        return `${date}`;
      },
      valueGetter: (params) => params.row.createdDate as Date,
      sortingOrder: ["asc", "desc"],
      sortComparator: sortByDate,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "modified",
      headerName: "Modified",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData: Layout = params.row;
        const date: string = moment(rowData.modifiedDate).format("L");
        return `${date}`;
      },
      valueGetter: (params) => params.row.modifiedDate as Date,
      sortingOrder: ["asc", "desc"],
      sortComparator: sortByDate,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      align: "center",
      renderCell: (params: GridCellParams) => {
        const rowData: Layout = params.row;
        return (
          <ActionDiv>
            <DeleteUserIconDiv
              data-testid="delete-layout"
              onClick={() => {
                handleLayoutModal(true);
                setSelectedLayout(rowData);
              }}
            >
              <DeleteIcon />
            </DeleteUserIconDiv>
          </ActionDiv>
        );
      },
    },
  ];

  return (
    <>
      <StyledContainer>
        <StyledHeader>
          <StyledHeading>Private Layouts</StyledHeading>
          <StyledButton
            variant="outlined"
            onClick={() => handleOpen(true)}
            data-testid="add-layout-modal"
          >
            <AddUserIcon />
            Add Layout
          </StyledButton>
        </StyledHeader>
        <DataGridPro
          disableColumnMenu
          disableVirtualization={isDisableVirtualization ? true : false}
          autoHeight
          rows={currentUserPrivateLayouts}
          columns={columns}
          disableRowSelectionOnClick
          hideFooterRowCount
          sx={dataGridStyles}
          style={{ border: "none" }}
          slots={{
            columnSortedAscendingIcon: SortingIcon,
            columnSortedDescendingIcon: SortingIcon,
          }}
        />
      </StyledContainer>

      {layoutModal && (
        <LayoutModal
          assignedLayouts={currentUserPrivateLayouts}
          open={layoutModal}
          handleOpen={handleOpen}
          modalName={"Add Layout"}
          type={"user"}
          filters={{
            deviceType: true,
            visitType: true,
            trackType: false,
            date: true,
            associatedType: true,
            formCategory: true,
          }}
        />
      )}
      {isDeleteModal && (
        <DeleteGroup
          open={isDeleteModal}
          handleOpen={handleLayoutModal}
          heading={"Delete Layout"}
          subHeading={"Are you sure you want to delete"}
          handleDeleteUser={handleDeleteLayout}
          isLoading={isLoading}
          name={selectedLayout?.defaultLayoutName as string}
          type=""
        />
      )}
    </>
  );
}
export default Layouts;
