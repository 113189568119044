import React, { ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import {
  CancelUserDiv,
  DeleteUserDiv,
  DeleteUserHeading,
  CloseIcon,
  DialogHeader,
  DialogContent,
  DialogActionDiv,
  DialogPara,
  DialogSpan,
} from "src/shared/delete-confirmation-modal/styles";

interface Props {
  heading: string;
  open: boolean;
  type: string;
  subHeading: string;
  handleOpen: (open: boolean, text: string) => void;
  handleDeleteUser: (type?: string) => void;
  isLoading: boolean;
  name: string | null | undefined;
  icon?: ReactNode;
}

function ConfirmationModal({
  open,
  handleOpen,
  heading,
  subHeading,
  type,
  handleDeleteUser,
  isLoading,
  name,
  icon,
}: Props) {
  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth>
      <DialogHeader>
        <CloseIcon data-testid="close" onClick={() => handleOpen(false, "")} />
      </DialogHeader>
      <DialogContent>
        {icon}
        <DeleteUserHeading>{heading}</DeleteUserHeading>
        <DialogPara>
          {subHeading} <DialogSpan>{name}</DialogSpan>
        </DialogPara>
        <DialogActionDiv>
          <DeleteUserDiv
            data-testid="delete"
            loading={isLoading}
            variant="contained"
            onClick={() => handleDeleteUser(type)}
          >
            {heading}
          </DeleteUserDiv>
          <CancelUserDiv data-testid="cancel" onClick={() => handleOpen(false, "")}>
            Cancel
          </CancelUserDiv>
        </DialogActionDiv>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationModal;
