import React, { useState } from "react";
import {
  StyledFooterLayout,
  StyledSelectButtonHide,
  StyledVisibilityIcon,
} from "src/pages/facility-forms-management/layout-details/components/footer/styles";
import { StyleCheckIcon } from "src/pages/facility-forms-management/layout-details/components/header/styles";
import { StyledLayoutButton } from "src/pages/facility-forms-management/layout-details/components/toolbar/styles";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/redux/store";
import { createLayoutThunk } from "src/slices/facility-forms-management/layouts/layouts-thunks";
import { useNavigate } from "react-router-dom";
import { CreateLayoutResponse } from "src/interfaces/layouts.interface";
import { OPIE_LAYOUTS } from "src/constants/route-constants";
import { updateLayoutDetailThunk } from "src/slices/facility-forms-management/layout-details/layout-detail-thunk";
import { layoutDetailsSelector } from "src/slices/facility-forms-management/layout-details";
import { useAppSelector } from "src/redux/hooks";
import { LayoutDetails } from "src/interfaces/layout-details.interface";

interface Props {
  layoutDetailData: LayoutDetails;
  isLayoutFormInvalid: boolean;
  updateLayoutFormStatus: (status: boolean) => void;
  handlePreviewLayout: (status: boolean) => void;
  isPreviewLayout: boolean;
}

function Footer({
  layoutDetailData,
  isLayoutFormInvalid,
  updateLayoutFormStatus,
  handlePreviewLayout,
  isPreviewLayout,
}: Props) {
  const { isCustom } = layoutDetailData;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const layoutDetailLoading = useAppSelector(layoutDetailsSelector);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createLayout = async () => {
    if (isCustom) {
      await dispatch(updateLayoutDetailThunk(layoutDetailData));
      updateLayoutFormStatus(true);
    } else {
      setIsLoading(true);
      const createRes = await dispatch(
        createLayoutThunk({ ...layoutDetailData, defaultLayoutGuid: layoutDetailData.id })
      );
      const res = createRes?.payload as CreateLayoutResponse;
      if (res.statusCode === 201) {
        const id = res.data.layoutId as string;
        navigate(`${OPIE_LAYOUTS}/${id}`);
      }
      setIsLoading(false);
    }
  };

  return (
    <StyledFooterLayout isPreviewLayout={isPreviewLayout}>
      <StyledSelectButtonHide
        variant="contained"
        data-testid="create-layout"
        disabled={isLayoutFormInvalid}
        onClick={createLayout}
        loading={isLoading || layoutDetailLoading.isUpdateLoading}
        sx={{ height: "2.7rem" }}
      >
        <StyleCheckIcon />
        {isCustom ? "Update Layout" : "Create Layout"}
      </StyledSelectButtonHide>
      {!isPreviewLayout ? (
        <StyledLayoutButton
          variant="outlined"
          data-testid="preview-layout"
          bgcolor={"white"}
          border={"#034A93"}
          onClick={() => handlePreviewLayout(true)}
        >
          <StyledVisibilityIcon />
          Preview Layout
        </StyledLayoutButton>
      ) : (
        <StyledLayoutButton
          variant="outlined"
          data-testid="cancel-preview-layout"
          bgcolor={"white"}
          border={"#034A93"}
          onClick={() => handlePreviewLayout(false)}
        >
          Cancel
        </StyledLayoutButton>
      )}
    </StyledFooterLayout>
  );
}

export default Footer;
