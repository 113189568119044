import React, { useEffect, useState } from "react";
import { DataGridPro, GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import SortingIcon from "src/shared/icons/sorting-icon";
import { dataGridStyles } from "src/constants/datagrid-styles";
import EditIcon from "src/shared/icons/edit-icon";
import DeleteIcon from "src/shared/icons/delete-icon";
import {
  GroupManagementDiv,
  HeaderDiv,
  Heading,
  GroupButtonDiv,
  AddGroupDiv,
  AddGroupIcon,
  EditUserIconDiv,
  DeleteUserIconDiv,
  ActionDiv,
} from "src/pages/opie-forms-management/groups/styles";
import EmptyList from "src/shared/empty-list";
import { groupSelector } from "src/slices/opie-forms-management/groups";
import { FilterOptions, GroupInterface } from "src/interfaces/groups.interface";
import { useNavigate } from "react-router-dom";
import DeleteGroup from "src/shared/delete-confirmation-modal";
import { CREATE_GROUP } from "src/constants/route-constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupsThunk,
  deleteGroupThunk,
  getGroupsBySearchThunk,
} from "src/slices/opie-forms-management/groups/groups-thunk";
import { AppDispatch } from "src/redux/store";
import SearchBar from "src/shared/ui/search-bar";
import { Box, Chip } from "@mui/material";
import { sortByNumber, sortByDate } from "src/utils/sorting-methods";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";
import FilterComponent from "src/pages/opie-forms-management/groups/components/filter";
import { toast } from "react-toastify";
import moment from "moment";
import { EMPTY_SEARCH_VALUE, INVALID_FILTERS, NO_FILTERS } from "src/constants/error-constants";
import { User } from "src/interfaces/user-management.interface";
import UserListIcon from "src/shared/icons/users-list.icon";
import { RemoveIconDiv } from "src/pages/opie-forms-management/user-management/styles";
import DeleteCrossIcon from "src/shared/icons/delete-cross-icon";

interface Props {
  isDisableVirtualization?: boolean;
}

function GroupManagement({ isDisableVirtualization }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const groupList = useSelector(groupSelector);
  const [groups, setGroups] = useState<GroupInterface[]>([]);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupInterface>();
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    createdBy: {} as User,
    fromDate: "",
    toDate: "",
    searchValue: "",
  });

  const handleCreatedBy = (event: React.SyntheticEvent<Element, Event>, value: User | null) => {
    setFilterOptions({ ...filterOptions, createdBy: value as User });
  };

  const handleDate = (from: string, to: string) => {
    setFilterOptions({
      ...filterOptions,
      fromDate: from,
      toDate: to,
    });
  };
  useEffect(() => {
    dispatch(getGroupsThunk());
  }, [dispatch]);

  useEffect(() => {
    setGroups(groupList.groupSearchData);
  }, [groupList.groupSearchData]);

  const handleFilterList = () => {
    if (filterOptions.searchValue) {
      dispatch(getGroupsBySearchThunk(filterOptions));
    } else {
      toast.error(EMPTY_SEARCH_VALUE);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterOptions({ ...filterOptions, searchValue: value });
    if (
      value === "" &&
      filterOptions.createdBy.id === "" &&
      filterOptions.fromDate === "" &&
      filterOptions.toDate === ""
    ) {
      setGroups(groupList.groups);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === "Enter" && filterOptions.searchValue) {
      handleFilterList();
    }
  };

  const handleDeleteModal = (open: boolean) => {
    setIsDeleteModal(open);
  };

  const handleEditGroup = (rowData: GroupInterface) => {
    navigate(`/groups/${rowData.id}`);
    setSelectedGroup(rowData);
  };

  const handleDeleteGroup = () => {
    setIsLoading(true);
    dispatch(deleteGroupThunk(selectedGroup?.id as string))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });

    handleDeleteModal(false);
  };

  const handleApplyFilters = () => {
    if (
      filterOptions.createdBy.id !== "" ||
      Object.values(filterOptions).filter((item) => item !== "").length > 1
    ) {
      if (
        (filterOptions.fromDate && filterOptions.toDate === "") ||
        (filterOptions.toDate && filterOptions.fromDate === "")
      ) {
        toast.error(INVALID_FILTERS);
      } else {
        dispatch(
          getGroupsBySearchThunk({
            ...filterOptions,
          })
        );
      }
    } else {
      toast.error(NO_FILTERS);
    }
  };

  const handleClearFilter = () => {
    setFilterOptions({
      createdBy: {} as User,
      fromDate: "",
      toDate: "",
      searchValue: "",
    });
    setGroups(groupList.groups);
  };

  useEffect(() => {
    setGroups(groupList.groups);
  }, [groupList.groups]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData: GroupInterface = params.row;
        return (
          <Box
            sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            data-testid="data-grid-pro"
          >
            {getConvertedUpperCaseString(rowData.name)}
            {rowData.is_default && (
              <Chip
                sx={{
                  background: "#034A93",
                  fontWeight: 400,
                  fontSize: "18px",
                  color: "white",
                  borderRadius: "6px",
                }}
                label="Default"
                variant="outlined"
              />
            )}
          </Box>
        );
      },
      sortingOrder: ["asc", "desc"],
    },

    {
      field: "facilities",
      headerName: "Facilities",
      flex: 1,
      sortingOrder: ["asc", "desc"],
      valueGetter: (params) => params.row.facilities.length,
      sortComparator: sortByNumber,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData: GroupInterface = params.row;
        const date: string = moment(rowData.created_time as Date).format("L");
        return `${date}`;
      },
      valueGetter: (params) => params.row.created_time as Date,
      sortingOrder: ["asc", "desc"],
      sortComparator: sortByDate,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      align: "center",
      renderCell: (params: GridCellParams) => {
        const rowData: GroupInterface = params.row;
        return (
          <ActionDiv>
            <EditUserIconDiv data-testid="edit-group" onClick={() => handleEditGroup(rowData)}>
              <EditIcon />
            </EditUserIconDiv>
            <DeleteUserIconDiv
              data-testid="delete-group"
              onClick={() => {
                handleDeleteModal(true);
                setSelectedGroup(rowData);
              }}
            >
              <DeleteIcon />
            </DeleteUserIconDiv>
          </ActionDiv>
        );
      },
    },
  ];

  const deleteGroupIcon = () => {
    return (
      <>
        <UserListIcon />
        <RemoveIconDiv>
          <DeleteCrossIcon />
        </RemoveIconDiv>
      </>
    );
  };

  return (
    <GroupManagementDiv>
      <HeaderDiv>
        <Heading>Facility Groups</Heading>
        <GroupButtonDiv>
          <AddGroupDiv
            variant="contained"
            data-testid="create-group"
            onClick={() => {
              navigate(`${CREATE_GROUP}`);
            }}
          >
            <AddGroupIcon />
            Create Facility Group
          </AddGroupDiv>
        </GroupButtonDiv>
      </HeaderDiv>
      <SearchBar
        handleSearch={handleSearch}
        handleKeyPress={handleKeyPress}
        handleFilterList={handleFilterList}
        styles={{ marginBottom: "1rem" }}
        data-testid="content-input"
        searchValue={filterOptions.searchValue}
      />
      <FilterComponent
        filterOptions={filterOptions}
        handleCreatedBy={handleCreatedBy}
        handleDate={handleDate}
        handleApplyFilters={handleApplyFilters}
        handleClearFilter={handleClearFilter}
        selectedDate={filterOptions.fromDate || filterOptions.toDate}
      />
      {!groupList.isSearchLoading && groups.length <= 0 ? (
        <EmptyList
          heading={"OOPS! It’s Empty"}
          subHeading={"Looks like you haven’t created any groups yet..."}
          handleNavigate={() => {
            navigate(CREATE_GROUP);
          }}
          buttonText="Create Facility Group"
        />
      ) : (
        <DataGridPro
          disableColumnMenu
          disableVirtualization={isDisableVirtualization ? true : false}
          autoHeight
          rows={!groupList.isSearchLoading ? groups : []}
          columns={columns}
          disableRowSelectionOnClick
          hideFooterRowCount
          sx={dataGridStyles}
          slots={{
            columnSortedAscendingIcon: SortingIcon,
            columnSortedDescendingIcon: SortingIcon,
          }}
          onCellClick={(params: GridCellParams) => {
            if (params.colDef.field === "actions") {
              return;
            }
            navigate(`/groups/${params?.id as string}`);
          }}
          loading={groupList.isSearchLoading}
        />
      )}

      {isDeleteModal && (
        <DeleteGroup
          open={isDeleteModal}
          handleOpen={handleDeleteModal}
          heading={"Delete Facility Group"}
          subHeading={"Are you sure you want to delete"}
          handleDeleteUser={handleDeleteGroup}
          isLoading={isLoading}
          name={selectedGroup?.name as string}
          icon={deleteGroupIcon()}
          type=""
        />
      )}
    </GroupManagementDiv>
  );
}

export default GroupManagement;
