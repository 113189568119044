import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import { Box, InputLabel, TextField } from "@mui/material";
import HistoryIcon from "src/shared/icons/history-icon";

export const StyledAccordionBody = styled.div`
  padding-left: 0.6rem;
  position: relative;
`;
export const StyledCheckDiv = styled.div``;

export const StyledCheckBox = styled(Checkbox)``;
export const StyledGroupForm = styled.div`
  padding: 0 20px 20px;
`;
export const InputLabelText = styled(InputLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #011428;
`;
export const InputTextField = styled(TextField)`
  width: 100%;
  white-space: pre-line;
`;
export const RequiredText = styled.sup`
  color: #034a93;
`;

export const InputTextAreaField = styled(TextField)`
  width: 100%;
  white-space: pre-line;
  font-family: inter;
  background: #ffffff;
  border: 1px solid #dededf;
  box-shadow: 0px 6px 8px rgba(100, 60, 255, 0.02);
  border-radius: 6px;
`;

export const StyledHistoryIcon = styled(HistoryIcon)``;

export const GroupDefaultText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000a14;
`;

export const StyledDateDiv = styled(Box)`
  position: absolute;
  top: -3rem;
  right: 1rem;
  font-weight: 500;
  color: #000a14;
  font-size: 18px;
  text-align: right;
`;
