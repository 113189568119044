import { initialState } from "src/slices/shared/user/user-initial-state";
import { userSlice } from "src/slices/shared/user/user-slices";
import {
  userLoadingSelector,
  tokenSelector,
  currentUserSelector,
} from "src/slices/shared/user/user-selector";

export const { setToken, clearToken } = userSlice.actions;

export { initialState };

export default userSlice.reducer;

export { userLoadingSelector, tokenSelector, currentUserSelector };
