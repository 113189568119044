import React from "react";
import { Box } from "@mui/material";
import { GridColDef, GridCellParams } from "@mui/x-data-grid-pro";
import { FacilityUser } from "src/interfaces/user-impersonation.interface";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";
import { sortByName } from "src/utils/sorting-methods";
import Switch from "src/shared/switch";
import {
  AddUserIconDiv,
  StyledSelectButton,
} from "src/pages/facility-forms-management/user-impersonation/components/table/styles";
import { Facility } from "src/interfaces/groups.interface";
import SelectIcon from "src/shared/icons/select-icon";

const userColumns = (handleUserSelect: (row: FacilityUser) => void) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
      sortComparator: sortByName,
      renderCell: (params: GridCellParams) => {
        const rowData: FacilityUser = params.row;
        return (
          <Box
            sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            data-testid="data-grid-pro"
          >
            {rowData.name}
          </Box>
        );
      },
      sortingOrder: ["asc", "desc"],
    },

    {
      field: "emailAddress",
      headerName: "Email",
      flex: 1,
      sortingOrder: ["asc", "desc"],
      align: "left",
      headerAlign: "left",
      sortComparator: sortByName,
      renderCell: (params: GridCellParams) => {
        const rowData: FacilityUser = params.row;
        return (
          <Box
            sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            data-testid="data-grid-pro"
          >
            {rowData.emailAddress}
          </Box>
        );
      },
    },
    {
      field: "opieClassification",
      headerName: "OA Role",
      sortComparator: sortByName,
      sortingOrder: ["asc", "desc"],
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData: FacilityUser = params.row;
        return (
          <Box
            sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            data-testid="data-grid-pro"
          >
            {getConvertedUpperCaseString(rowData.opieClassification)}
          </Box>
        );
      },
      align: "left",
      headerAlign: "left",
    },
    {
      field: "active",
      headerName: "Active?",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: renderSwitch,
    },
    {
      field: "actions",
      headerName: "",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      align: "right",
      renderCell: (params: GridCellParams) => {
        const rowData: FacilityUser = params.row;
        return (
          <AddUserIconDiv
            data-testid="impersonation-btn"
            onClick={() => handleUserSelect(rowData)}
            bgcolor={params.row.isActive ? "white" : "#DEDEDF"}
            iconColor={params.row.isActive ? "#034A93" : "#A3A6AA"}
          />
        );
      },
    },
  ];

  return columns;
};

function renderSwitch(params: GridCellParams) {
  const rowData: FacilityUser = params.row;
  const isChecked: boolean = rowData.isActive;
  return (
    <Switch
      handleFunction={(id: string, status: boolean) => {
        console.log(id, status);
      }}
      checked={isChecked}
      rowData={{ id: rowData.userID as string, is_active: rowData.isActive }}
      switchcolor={"#00B03C"}
      disabled={true} //Disabling the button temporarily for OF-1720
    />
  );
}

function facilityColumns(handleSelectData: (row: Facility) => void) {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Facility ID",
      flex: 1,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "name",
      headerName: "Facility Name",
      flex: 1,
      sortingOrder: ["asc", "desc"],
      align: "left",
      headerAlign: "left",
      sortComparator: sortByName,
      renderCell: (params: GridCellParams) => {
        const rowData: Facility = params.row;
        return (
          <Box
            sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            data-testid="data-grid-pro"
          >
            {getConvertedUpperCaseString(rowData.name)}
          </Box>
        );
      },
    },
    {
      field: "net_suite_id",
      headerName: "NetSuite ID",
      flex: 1,
      align: "left",
      headerAlign: "left",
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "actions",
      headerName: "",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      align: "right",
      renderCell: (params: GridCellParams) => {
        const rowData: Facility = params.row;
        return (
          <StyledSelectButton
            variant="outlined"
            data-testid="select-facility"
            onClick={() => handleSelectData(rowData)}
            bgcolor={rowData.isChecked ? "#00B03C" : "white"}
            border={rowData.isChecked ? "#00B03C" : "#034A93"}
          >
            {rowData.isChecked ? <SelectIcon /> : "Select"}
          </StyledSelectButton>
        );
      },
    },
  ];
  return columns;
}
export { userColumns, facilityColumns };
