import styled from "styled-components";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const DialogHeader = styled.div`
  display: flex;
  justify-content: center;
  color: #034a93;
  cursor: pointer;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-top: 1rem;
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 30rem;
  border: 1px solid #d1d3d4;
  border-radius: 6px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1d3d4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;
export const SearchIconDiv = styled(Box)`
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  background: #034a93;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DialogSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogRow = styled(Box)`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  .custom-checkbox-root .MuiSvgIcon-root {
    width: 36px;
    height: 36px;
  }
`;
export const DialogActionDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;
export const AddDiv = styled(LoadingButton)`
  text-transform: none;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  padding: 2% 14%;
  color: white;
`;
export const CancelDiv = styled(AddDiv)`
  background: white;
  color: #034a93;
`;
