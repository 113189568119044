import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getLayoutsFilter } from "src/services/layouts-service";
import { getConvertedSentenceToUpperCase } from "src/utils/convert-strings-case";

export const getLayoutsFilterThunk = createAsyncThunk(
  "getLayoutsFilterThunk",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLayoutsFilter();
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
