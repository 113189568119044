import styled from "styled-components";
import { Box, Button } from "@mui/material";

interface Button {
  textColor: string;
  bgColor: string;
}

export const StyledSharedLayoutHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  color: #034a93;
  margin: 0;
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
`;

export const StyledSharedLayoutContent = styled.p``;

export const StyledName = styled.span`
  font-weight: 700;
  margin-right: 0.8rem;
`;

export const StyledLayoutName = styled.span`
  font-weight: 700;
`;

export const StyledFacilityName = styled.span`
  font-weight: 700;
  margin-left: 0.8rem;
`;

export const StyledEmail = styled.span`
  color: gray;
`;
export const StyledParagraph = styled.p``;

export const StyledShareLayoutFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const StyledAcceptButton = styled(Button)<Button>`
  text-transform: none;
  background: props => props.bgColor;
  color: props => props.textColor !important;
  font-weight: bold;
  padding: 1% 4%;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
`;
