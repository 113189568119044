/* eslint-disable quotes */
import { Box, Button } from "@mui/material";
import styled from "styled-components";

export const StyledFilterForm = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 8px 10px 8px 10px;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledDiv = styled(Box)`
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledClearButton = styled.div`
  text-decoration: underline;
  color: #034a93;
  font-size: 16px;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 16px;
  padding: 6px 24px;
  text-transform: none;
  border: 1px solid #034a93;
  border-radius: 6px;
`;
