import * as React from "react";

interface Props {
  color?: string;
}
const CustomLayoutIcon = ({ color }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill={color ? color : "#FFFFFF"}
      d="M20 2C20.552 2 21 2.448 21 3V6.757L19 8.757V4H5V20H19V17.242L21 15.242V21C21 21.552 20.552 22 20 22H4C3.448 22 3 21.552 3 21V3C3 2.448 3.448 2 4 2H20ZM21.778 8.808L23.192 10.222L15.414 18L13.998 17.998L14 16.586L21.778 8.808V8.808ZM13 12V14H8V12H13ZM16 8V10H8V8H16Z"
    />
  </svg>
);
export default CustomLayoutIcon;
