import { Grid, Select } from "@mui/material";
import styled from "styled-components";
import { Checkbox } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AccordionTemplate from "src/shared/ui/accordion";

export const GridContainer = styled(Grid)`
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const LeftGridItem = styled(Grid)`
  border-radius: 6px;
`;

export const RightGridItem = styled(Grid)`
  border-radius: 6px;
  background: #fff;
  padding: 1rem;
`;

export const FieldItemGridContainer = styled(Grid)`
  align-items: center;
  margin: 8px 0;
  padding: 0 1rem 0 2.5rem;
  border-radius: 6px;
  background: #fff;
  justify-content: space-between;
`;
export const FieldItemGrid = styled(Grid)`
  display: flex;
  align-items: center;
  cursor: default;
`;

export const IncludeCheckbox = styled(Checkbox)`
  && {
    color: #034a93;
    padding: 4px;
    font-size: 1.5rem;
  }
`;
export const RequiredCheckbox = styled(Checkbox)`
  && {
    color: #f10008;
    padding: 4px;
    font-size: 1.5rem;
    margin-right: 10px;
  }
`;

export const StyledSelect = styled(Select)(() => ({
  width: "100%",
  padding: 0,
  "& .MuiSelect-select": {
    padding: "0.5rem 2rem 0.5rem 0.8rem !important",
  },
  "& .MuiSelect-icon": {
    color: "#034a93",
  },
}));

export const GripIcon = styled(DragIndicatorIcon)`
  && {
    color: #c3e0fe;
    font-size: 22px;
    margin-top: 12px;
  }
`;

export const Accordion = styled(AccordionTemplate)(({ expanded }) => ({
  "&&": {
    boxShadow: "none",
    marginBottom: "8px !important",
  },
  p: {
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "1.5 rem",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  ".MuiAccordionSummary-root": {
    borderBottom: expanded ? "1px solid #DEDEDF" : "1px solid #fff",
    borderRadius: expanded ? "6px 6px 0 0 !important" : "6px",
    paddingLeft: "8px",
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    order: 1,
  },
}));

export const MoreOptions = styled.div``;

export const AccordionHeading = styled.span<{ parentGUID: string | null }>`
  font-size: ${({ parentGUID }) => (parentGUID ? "20px" : "24px")};
  margin-left: 1rem;
`;
