import { createTheme } from "@mui/material";
// When using TypeScript 4.x and above
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#034A93",
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#034A93",
          "&.Mui-checked": {},
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.MuiDayCalendar-header": {
            fontSize: "20px",
          },
          fontSize: "14px",
          "&.Mui-selected": {
            background: "#034A93",
            borderRadius: "4px 0px 0px 4px",
          },
          borderRadius: "4px !important",
        },
      },
    },
  },
});
