import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "src/shared/icons/delete-icon";
import PublishedLayoutIcon from "src/shared/icons/published-layout-icon";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import EditIcon from "src/shared/icons/edit-icon";
import AddLayoutIcon from "src/shared/icons/add-layout-icon";
import {
  ActionDiv,
  DeleteUserIconDiv,
  EditUserIconDiv,
} from "src/pages/opie-forms-management/groups/styles";
import MoreIcon from "src/shared/icons/more-icon";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { useLocation } from "react-router-dom";
import { Layout } from "src/interfaces/layouts.interface";
import { layoutsMenuItem } from "src/pages/facility-forms-management/layouts/constants";
import { OPIE_LAYOUTS, PUBLISHED_LAYOUTS } from "src/constants/route-constants";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

interface Props {
  params: GridCellParams;
  handleMenuItemClick: (type: string, rowData: Layout) => void;
}
export const LayoutActionColumnsRenderer = ({ params, handleMenuItemClick }: Props) => {
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuList = (pathname: string) => {
    switch (pathname) {
      case PUBLISHED_LAYOUTS:
        return [
          {
            id: "preview-form",
            name: layoutsMenuItem.PREVIEW_FORM,
            icon: <RemoveRedEyeOutlinedIcon style={{ color: "rgb(3, 74, 147)" }} />,
            disabled: false,
          },
          {
            id: "update-access",
            name: layoutsMenuItem.UPDATE_ACCESS,
            icon: <PublishedLayoutIcon color="#034A93" />,
            disabled: false,
          },
        ];
      case OPIE_LAYOUTS:
        return [
          {
            id: "menuItem-publish-to-OA",
            name: layoutsMenuItem.PUBLISH_TO_OA,
            icon: <PublishedLayoutIcon color="rgb(3, 74, 147)" />,
            disabled: false,
          },
          {
            id: "menuItem-delete",
            name: layoutsMenuItem.DELETE,
            icon: <DeleteIcon />,
            disabled: false,
          },
        ];

      default:
        return [
          {
            id: "menuItem-publish-to-OA",
            name: layoutsMenuItem.PUBLISH_TO_OA,
            icon: <PublishedLayoutIcon color="rgb(3, 74, 147)" />,
            disabled: false,
          },
          {
            id: "menuItem-share",
            name: layoutsMenuItem.SHARE,
            icon: <ShareOutlinedIcon style={{ color: "rgb(3, 74, 147)" }} />,
            disabled: !params.row.allowSharing,
          },
          {
            id: "menuItem-copy",
            name: layoutsMenuItem.COPY,
            icon: <AddLayoutIcon color="#034A93" />,
            disabled: false,
          },
          {
            id: "menuItem-delete",
            name: layoutsMenuItem.DELETE,
            icon: <DeleteIcon />,
            disabled: false,
          },
        ];
    }
  };

  const menuList = getMenuList(location.pathname);

  return (
    <ActionDiv>
      {!params.row.isHidden && (
        <>
          {location.pathname === PUBLISHED_LAYOUTS ? (
            <EditUserIconDiv
              onClick={() => handleMenuItemClick(layoutsMenuItem.REMOVE, params.row)}
              data-testid={"unpublish-layout"}
            >
              <RemoveCircleIcon />
            </EditUserIconDiv>
          ) : (
            <EditUserIconDiv
              onClick={() => handleMenuItemClick(layoutsMenuItem.EDIT, params.row)}
              data-testid={"edit-layout"}
            >
              <EditIcon />
            </EditUserIconDiv>
          )}
          <DeleteUserIconDiv onClick={handleClick} data-testid={"layout-action"}>
            <MoreIcon />
          </DeleteUserIconDiv>
        </>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        data-testid={"menu-options"}
      >
        {menuList.map((menuItem, index) => {
          return (
            <MenuItem
              onClick={() => handleMenuItemClick(menuItem.name, params.row)}
              key={index}
              data-testid={menuItem.id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
              disabled={menuItem.disabled}
            >
              {menuItem.icon}
              <div
                style={
                  menuItem.name === "Delete"
                    ? { fontWeight: "bolder", marginBottom: 7 }
                    : { fontWeight: "bolder" }
                }
              >
                {menuItem.name}
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </ActionDiv>
  );
};
