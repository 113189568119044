import axios from "axios";
import { BASE_URL } from "src/constants/app-config-constants";
import { HTTPResponse } from "src/interfaces/httpResponse.interface";

export async function hydrateFacilities(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/cron/facilities`;
  return axios.post(url);
}

export async function hydrateLayouts(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/cron/layouts`;
  return axios.post(url);
}

export async function assignAllFacilitiesToDefaultLayouts(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/cron/hydrate-default-groups`;
  return axios.post(url);
}
