import Button from "@mui/material/Button";
import styled from "styled-components";

export const Message = styled.p`
  text-align: center;
`;

export const CustomButton = styled(Button)`
  text-transform: none;
  background: white;
  font-weight: bold;
  padding: 2% 4%;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #034a93;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const ConfirmationButton = styled(Button)`
  text-transform: none;
  font-weight: bold;
  padding: 2% 6%;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #034a93;
  text-transform: uppercase;
  letter-spacing: 1px;
`;
