import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogButton,
  DialogHeading,
  CloseIcon,
  DialogHeader,
  DialogContent,
  DialogActionDiv,
  UrlBox,
  CopyIcon,
  UrlLink,
} from "src/pages/facility-forms-management/shared/share-modal/styles";
import { QRCodeSVG } from "qrcode.react";
import { LOCALSTORAGE_FACILITY_ID } from "src/constants/auth-constants";
import { getValueFromStorage } from "src/utils/storage";
import { layoutManagementURL } from "src/utils/get_env";
import { Tooltip } from "@mui/material";

interface Props {
  open: boolean;
  handleClose: () => void;
  layoutId: string | null | undefined;
}

function ShareLayoutModal({ open, handleClose, layoutId }: Props) {
  const [copied, setCopied] = useState<boolean>(false);
  const getUrl = () => {
    const facilityId = getValueFromStorage(LOCALSTORAGE_FACILITY_ID) as string;
    return `${layoutManagementURL}share/facility/${facilityId}/layout/${layoutId}`;
  };
  const copyHandler = () => {
    navigator.clipboard.writeText(getUrl());
    setCopied(true);
  };
  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth>
      <DialogHeader>
        <CloseIcon data-testid="shareModalClose" onClick={handleClose} />
      </DialogHeader>
      <DialogContent>
        <DialogHeading>Share</DialogHeading>
        <QRCodeSVG value={getUrl()} />
        <UrlBox>
          <UrlLink>{getUrl()} </UrlLink>
          <Tooltip title={copied ? "Copied" : "Copy"} placement="top">
            <CopyIcon onClick={copyHandler} data-testid={"copy-handler"} />
          </Tooltip>
        </UrlBox>

        <DialogActionDiv>
          <DialogButton data-testid="ok" onClick={handleClose} variant="contained">
            OK
          </DialogButton>
        </DialogActionDiv>
      </DialogContent>
    </Dialog>
  );
}

export default ShareLayoutModal;
