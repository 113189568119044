import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LayoutCountsResponse } from "src/interfaces/layouts.interface";
import { initialState } from "src/slices/facility-forms-management/dashboard";
import { DashboardStore } from "src/slices/facility-forms-management/dashboard/dashboard-interface";
import { getLayoutCountsThunk } from "src/slices/facility-forms-management/dashboard/dashboard-thunks";

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get layouts
    builder.addCase(
      getLayoutCountsThunk.fulfilled,
      (state: DashboardStore, { payload }: PayloadAction<LayoutCountsResponse>) => {
        const { data } = payload;
        state.counts.defaultLayoutCount.count = data.count.default;
        state.counts.customLayoutCount.count = data.count.custom;
        state.counts.publishedLayoutCount.count = data.count.published;
        state.counts.isLoading = false;
      }
    );
    builder.addCase(getLayoutCountsThunk.pending, (state) => {
      state.counts.isLoading = true;
    });
    builder.addCase(getLayoutCountsThunk.rejected, (state) => {
      state.counts.isLoading = false;
    });
  },
});
