import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { limit } from "src/constants/pagination-limit";
import { Layout, LayoutResponse } from "src/interfaces/layouts.interface";
import { initialState } from "src/slices/opie-forms-management/layouts";
import { LayoutsStore } from "src/slices/opie-forms-management/layouts/layouts-interface";
import {
  getImpersonationLayoutsThunk,
  getLayoutsThunk,
} from "src/slices/facility-forms-management/layouts/layouts-thunks";

export const facilityLayoutsSlice = createSlice({
  name: "facilityLayoutsSlice",
  initialState,
  reducers: {
    resetLayoutsData: (state) => {
      state.fetchMore = true;
      state.layouts = [];
    },
  },
  extraReducers: (builder) => {
    //get layouts
    builder.addCase(
      getLayoutsThunk.fulfilled,
      (state: LayoutsStore, { payload }: PayloadAction<LayoutResponse>) => {
        const copy = payload.data.map((layout: Layout) => {
          return {
            id: layout.id,
            defaultLayoutGuid: layout.defaultLayoutGuid,
            defaultLayoutName: layout.defaultLayoutName,
            associatedType: layout.associatedType,
            createdDate: layout.createdDate,
            modifiedDate: layout.modifiedDate,
            associatedDeviceType: layout.associatedDeviceTypes,
            associatedVisitTypes: layout.associatedVisitTypes,
            associatedTrack: layout.associatedTrack,
            isHidden: layout.isHidden,
            associatedAnatomy: layout.associatedAnatomy,
          };
        });
        state.isLoading = false;
        state.layouts = [...copy];
      }
    );
    builder.addCase(getLayoutsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLayoutsThunk.rejected, (state) => {
      state.isLoading = false;
    });
    // get layouts with pagination post impersonation
    builder.addCase(
      getImpersonationLayoutsThunk.fulfilled,
      (state: LayoutsStore, { payload }: PayloadAction<LayoutResponse>) => {
        if (payload.data.length === 0 || payload.data.length < limit) {
          state.fetchMore = false;
        }
        const copy = payload.data.map((layout: Layout) => {
          return {
            id: layout.id,
            defaultLayoutGuid: layout.defaultLayoutGuid,
            defaultLayoutName: layout.defaultLayoutName,
            associatedType: layout.associatedType,
            createdDate: layout.createdDate,
            modifiedDate: layout.modifiedDate,
            associatedDeviceType: layout.associatedDeviceTypes,
            associatedVisitTypes: layout.associatedVisitTypes,
            associatedTrack: layout.associatedTrack,
            defaultLayoutFriendlyName: layout.defaultLayoutFriendlyName,
            isHidden: layout.isHidden,
            allowSharing: layout.allowSharing,
            publishedOn: layout.publishedOn,
            associatedAnatomy: layout.associatedAnatomy,
          };
        });

        state.isLoading = false;
        state.layouts = [...state.layouts, ...copy].filter(
          (layout, index, self) => index === self.findIndex((l) => l.id === layout.id)
        );
      }
    );
    builder.addCase(getImpersonationLayoutsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getImpersonationLayoutsThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
