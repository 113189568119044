import * as React from "react";
import { toast } from "react-toastify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { GearIcon } from "src/shared/header/settings/styles";
import {
  assignAllFacilitiesToDefaultLayouts,
  hydrateFacilities,
  hydrateLayouts,
} from "src/services/settings-service";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

enum Options {
  HF = "Hydrate Facilities",
  HL = "Hydrate Layouts",
  AAFTDL = "Assign All Facilities To Default Layouts",
}

export default function SettingsMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState<Options>();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const renderConfirmationDialog = (opt: Options): boolean => {
    setSelectedOption(opt);
    setOpenConfirmationDialog(true);
    return false;
  };

  const confirmAction = () => {
    switch (selectedOption) {
      case Options.HF:
        handleHydrateFacilities();
        break;
      case Options.HL:
        handleHydrateLayouts();
        break;
      case Options.AAFTDL:
        handleAssignAllFacilitiesToDefaultLayouts();
        break;
    }
  };
  const closeAll = () => {
    setOpenConfirmationDialog(false);
    setSelectedOption(undefined);
    handleClose();
  };

  const handleHydrateFacilities = async () => {
    try {
      await hydrateFacilities();
      toast.success("Hydration Request For Facilities Submitted Successfully!");
    } catch (error) {
      toast.error("Failed to Submit Hydration Request!");
    } finally {
      closeAll();
    }
  };

  const handleHydrateLayouts = async () => {
    try {
      await hydrateLayouts();
      toast.success("Hydration Request For Layouts Submitted Successfully!");
    } catch (error) {
      toast.error("Failed to Submit Hydration Request!");
    } finally {
      closeAll();
    }
  };
  const handleAssignAllFacilitiesToDefaultLayouts = async () => {
    try {
      await assignAllFacilitiesToDefaultLayouts();
      toast.success(
        "Request For Assigning All Facilities To Default Layouts Submitted Successfully!"
      );
    } catch (error) {
      toast.error("Failed to Submit Assigning All Facilities To Default Layouts Request!");
    } finally {
      closeAll();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Settings">
        <IconButton
          data-testid="openSettings"
          id="setting-button"
          aria-controls={open ? "setting-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <GearIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          data-testid="setting-item-hf"
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onClick={(_e) => renderConfirmationDialog(Options.HF)}
        >
          Hydrate Facilities
        </MenuItem>
        <MenuItem
          data-testid="setting-item-hl"
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onClick={(_e) => renderConfirmationDialog(Options.HL)}
        >
          Hydrate Default Layouts and Fields
        </MenuItem>
        <MenuItem
          data-testid="setting-item-ad"
          onClick={() => renderConfirmationDialog(Options.AAFTDL)}
        >
          Assign all facilities to Default groups
        </MenuItem>
      </Menu>
      <Dialog data-testid="confirm-dialog" open={openConfirmationDialog}>
        <Box>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>Are you sure you want to {selectedOption}?</DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeAll}>
              No
            </Button>
            <Button data-testid="confirm" onClick={confirmAction}>
              Yes
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
