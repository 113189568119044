import { FilterType } from "src/interfaces/groups.interface";
import {
  Field,
  LayoutDetails,
  LayoutDetailsFilterInterface,
} from "src/interfaces/layout-details.interface";

export const getListWithExistingItemsChecked = (list: FilterType[], data: string[]) => {
  return list.map((item) => ({
    ...item,
    isChecked: data && data.includes(item.name),
  }));
};

export const filterLayoutData = (layoutData: Field[], filter: LayoutDetailsFilterInterface) => {
  const filterType =
    filter.isRequired && filter.isVisible
      ? "both"
      : filter.isRequired
      ? "required"
      : filter.isVisible
      ? "include"
      : "";
  const data = generateNestedItems([...layoutData], null, "filter", filterType);
  const checkedDelegates = (filter.delegates ?? [])
    .filter((delegate) => delegate.isSelected)
    .map((delegate) => delegate.name);

  const filteredData = [...layoutData].filter((field) => {
    const isDelegateIncluded = field.delegateTypes.some((delegate) =>
      checkedDelegates.includes(delegate)
    );

    if (filter.isRequired && filter.isVisible) {
      const both = getFieldStatus(field.fieldGUID, data, "both", checkedDelegates);
      // Show only required fields and visible fields and fields with delegates
      return both;
    } else if (filter.isRequired) {
      const isRequired = getFieldStatus(field.fieldGUID, data, "required", checkedDelegates);
      // Show only required fields and fields with delegates if both are true
      return isRequired;
    } else if (filter.isVisible) {
      const isInclude = getFieldStatus(field.fieldGUID, data, "include", checkedDelegates);
      // Show only included fields and fields with delegates if both are true
      return isInclude;
    } else if (checkedDelegates.length > 0) {
      // Show only fields with delegates if checkedDelegates has elements
      return isDelegateIncluded;
    }
    return false;
  });
  return filteredData;
};

export function generateNestedItems(
  inputArray: Field[],
  parentGUID: string | null = null,
  type?: string,
  filterType?: string
) {
  return inputArray.reduce((result: Field[], field: Field): Field[] => {
    const item = { ...field };
    if (item.parentGUID === parentGUID) {
      const children = generateNestedItems(inputArray, item.fieldGUID, type, filterType);
      if (children.length > 0) {
        item.children = children;
      }
      const updateChildrenData = (children: Field[]) => {
        let status: boolean = false;
        for (const child of children) {
          if (child.include && child.required) {
            status = true;
            return status;
          } else {
            status = false;
          }
        }
        return status;
      };

      if (type === "filter") {
        //Required
        if (
          filterType === "required" &&
          children.length > 0 &&
          children.some((child) => child.required)
        ) {
          item.required = true;
        }
        //Include
        if (
          filterType === "include" &&
          children.length > 0 &&
          children.every((child) => !child.include)
        ) {
          item.include = false;
        }
        //Required and Include
        if (filterType === "both" && children && children.length > 0) {
          const status = updateChildrenData(children);
          if (status) {
            item.include = true;
            item.required = true;
          } else {
            item.include = false;
            item.required = false;
          }
        }
      }
      result.push(item as Field);
    }
    return result.sort((a, b) => a.position - b.position);
  }, []);
}

// filtered data without isOpen, selectedFieldOnViewport
export const getFilteredLayoutDetails = (layoutsData: LayoutDetails) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedFieldOnViewport, ...layoutRestDetails } = layoutsData as LayoutDetails;
  return {
    ...layoutRestDetails,
    fields:
      layoutsData &&
      layoutsData.fields.map((item: Field) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isOpen, ...rest } = item;
        return rest;
      }),
  };
};

export const getFieldStatus = (
  fieldID: string,
  items: Field[],
  type: "required" | "include" | "both",
  checkedDelegates: Array<string>
): boolean => {
  const getData = (data: Field): boolean | undefined => {
    const children: Field[] = data ? (data.children as Field[]) : [];
    if (
      data.fieldGUID === fieldID &&
      ((type === "required" && data.required) ||
        (type === "include" && data.include) ||
        (type === "both" && data["required"] && data["include"]))
    ) {
      return true;
    }

    const childStatus =
      children &&
      children.map((child) => {
        const result = getData(child);
        if (checkedDelegates.length > 0) {
          const isDelegateIncluded = child.delegateTypes.some((delegate) =>
            checkedDelegates.includes(delegate)
          );
          if (result && isDelegateIncluded) {
            return true;
          } else {
            return false;
          }
        } else if (result) {
          return true;
        }
      });
    return children && children.length > 0 ? childStatus.includes(true) : false;
  };

  const getParentStatus = items.map((data) => {
    const finalRes = getData(data);
    if (checkedDelegates.length > 0) {
      if (
        finalRes &&
        getDelegateStatusForParent(data.children as Field[], checkedDelegates, type)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (finalRes) {
      return true;
    }
  });
  return getParentStatus.includes(true);
};

//To check whether if any checked delegate is available in children
const getDelegateStatusForParent = (
  children: Field[],
  checkedDelegates: Array<string>,
  type: "required" | "include" | "both"
) => {
  let status: boolean = false;
  for (const child of children) {
    const isDelegateIncluded = child.delegateTypes.some((delegate) =>
      checkedDelegates.includes(delegate)
    );
    if (
      (type === "both" && child.include && child.required && isDelegateIncluded) ||
      (type === "required" && child.required && isDelegateIncluded) ||
      (type === "include" && child.include && isDelegateIncluded)
    ) {
      if (child.children) {
        const isDelegate = getDelegateStatusForParent(
          child.children as Field[],
          checkedDelegates,
          type
        );
        if (isDelegate) {
          status = true;
        } else {
          status = false;
        }
      } else {
        status = true;
      }
      return status;
    } else {
      status = false;
    }
  }
  return status;
};

export const handleFieldIncludeStatus = (
  field: Field,
  option: string,
  layoutFieldsData: Field[],
  actionType: "update" | "getCount",
  fieldCount: { count: number }
) => {
  let layoutDataCopy = [...layoutFieldsData];
  const children = layoutDataCopy.filter((item) => item.parentGUID === field.fieldGUID);

  if (children && children.length > 0) {
    children.forEach((fieldData) => {
      handleFieldIncludeStatus(fieldData, option, layoutDataCopy, actionType, fieldCount);
    });
  } else {
    switch (option) {
      case "Include All":
        if (actionType == "getCount" && field.include === false) {
          fieldCount.count++;
        } else {
          field.include = true;
        }
        break;
      case "Exclude All":
        if (actionType == "getCount" && field.include === true) {
          fieldCount.count++;
        } else {
          field.include = false;
        }
        break;
      case "Require All Fields":
        if (actionType == "getCount" && field.required === false) {
          fieldCount.count++;
        } else {
          field.required = true;
        }
        break;
      case "Clear Requirements":
        if (actionType == "getCount" && field.required === true) {
          fieldCount.count++;
        } else {
          field.required = false;
        }
        break;
      case "Require All Included Fields":
        if (actionType == "getCount" && field.include === true && field.required === false) {
          fieldCount.count++;
        } else {
          if (field.include) field.required = true;
        }

        break;
    }
    layoutDataCopy = layoutDataCopy.map((item) =>
      item.fieldGUID === field.fieldGUID ? field : item
    );
  }
  return { updatedData: layoutDataCopy, updatedCount: fieldCount };
};
