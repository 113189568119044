import {
  PASSWORD_REGEX_ONE_UPPERCASE,
  PASSWORD_REGEX_ONE_SPECIAL_CHAR,
  PASSWORD_REGEX_ONE_LOWERCASE,
  PASSWORD_REGEX_MINIMUM_EIGHT_LENGTH,
  PASSWORD_REGEX_ONE_NUMBER,
  EMAIL_REGEX,
} from "src/constants/regex-constants";

//check upper case
export const checkUpperCase = new RegExp(PASSWORD_REGEX_ONE_UPPERCASE);
// special char
export const checkSpecialChar = new RegExp(PASSWORD_REGEX_ONE_SPECIAL_CHAR);
//check lower case
export const checkLowerCase = new RegExp(PASSWORD_REGEX_ONE_LOWERCASE);
//check minimum length
export const checkMinimumLength = new RegExp(PASSWORD_REGEX_MINIMUM_EIGHT_LENGTH);
//check digit
export const checkDigit = new RegExp(PASSWORD_REGEX_ONE_NUMBER);

export const checkEmail = new RegExp(EMAIL_REGEX);
