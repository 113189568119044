import { GridSortItem } from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SELECT_FACILITY } from "src/constants/error-constants";
import { Facility } from "src/interfaces/groups.interface";
import { FacilityUser } from "src/interfaces/user-impersonation.interface";
import { PaginationFormat } from "src/interfaces/pagination.interface";
import { StyledImpersonateUserContainer } from "src/pages/facility-forms-management/user-impersonation/styles";
import { AppDispatch } from "src/redux/store";
import { mappedFacilities } from "src/slices/opie-forms-management/groups";
import { getFacilitiesThunk } from "src/slices/opie-forms-management/groups/groups-thunk";
import { impersonationSelector } from "src/slices/facility-forms-management/impersonation";
import { getFacilityUsersThunk } from "src/slices/facility-forms-management/impersonation/impersonation-thunks";
import Tab from "src/pages/facility-forms-management/user-impersonation/components/tab";
import Footer from "src/pages/facility-forms-management/user-impersonation/components/footer";
import FacilityTab from "src/pages/facility-forms-management/user-impersonation/components/facility-tab";
import UserTab from "src/pages/facility-forms-management/user-impersonation/components/user-tab";
import pageLimitContext from "src/context-api";
import { getPaginationSize } from "src/utils/pagination-methods";

interface Props {
  isDisableVirtualization?: boolean;
}

function UserImpersonation({ isDisableVirtualization }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const { limit } = useContext(pageLimitContext);
  const facilityUsers = useSelector(impersonationSelector);
  const mappedFacilitiesData = useSelector(mappedFacilities);
  const [isFacilityTabSelected, setIsFacilityTabSelected] = useState<boolean>(true);
  const [selectedFacility, setSelectedFacility] = useState<Facility>();
  const [prevFacilityCurrentPage, setPrevFacilityCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationFormat>({
    currentPage: 1,
    paginationSize: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    dataCount: 0,
  });
  const [facilitySortModel, setFacilitySortModel] = useState<GridSortItem[]>([]);

  useEffect(() => {
    dispatch(getFacilitiesThunk());
  }, [dispatch]);

  const handleSortModal = (model: GridSortItem[]) => {
    setFacilitySortModel(model);
  };

  useEffect(() => {
    setPagination({
      ...pagination,
      paginationSize: getPaginationSize(pagination.dataCount, limit),
      lastRecordIndex: pagination.currentPage * limit,
      firstRecordIndex: pagination.currentPage * limit - limit,
    });
    // eslint-disable-next-line
  }, [pagination.currentPage, limit]);

  const handleFacilityTab = () => {
    setPagination({
      ...pagination,
      currentPage: prevFacilityCurrentPage,
      paginationSize: getPaginationSize(mappedFacilitiesData.length, limit),
      dataCount: mappedFacilitiesData.length,
    });
    setIsFacilityTabSelected(true);
  };

  const handleUserTab = () => {
    if (selectedFacility) {
      setPrevFacilityCurrentPage(pagination.currentPage);
      setIsFacilityTabSelected(false);
      handleUserSelect();
    } else {
      toast.error(SELECT_FACILITY);
    }
  };

  const resetPagination = (dataLength: number) => {
    setPagination({
      ...pagination,
      paginationSize: getPaginationSize(dataLength, limit),
      currentPage: 1,
      dataCount: dataLength,
    });
  };

  const handleUserSelect = () => {
    if (selectedFacility) {
      dispatch(getFacilityUsersThunk(selectedFacility.id));
      setIsFacilityTabSelected(false);
      resetPagination(facilityUsers.users.filter((item: FacilityUser) => item.isAdmin).length);
    }
  };

  const handleSelectFacility = (facility: Facility | undefined) => {
    setSelectedFacility(facility);
  };

  const handlePreviousPage = () => {
    setPagination({
      ...pagination,
      currentPage: pagination.currentPage > 1 ? pagination.currentPage - 1 : 1,
    });
  };

  const handleNextPage = () => {
    setPagination({
      ...pagination,
      currentPage:
        pagination.currentPage < pagination.paginationSize
          ? pagination.currentPage + 1
          : pagination.paginationSize,
    });
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagination({
      ...pagination,
      currentPage: value,
    });
  };

  return (
    <StyledImpersonateUserContainer>
      <Tab
        handleFacilityTab={handleFacilityTab}
        handleUserTab={handleUserTab}
        isFacilityTabSelected={isFacilityTabSelected}
      />
      {isFacilityTabSelected ? (
        <FacilityTab
          isFacilityTabSelected={isFacilityTabSelected}
          handleSortModal={handleSortModal}
          resetPagination={resetPagination}
          handleSelectFacility={handleSelectFacility}
          selectedFacility={selectedFacility}
          pagination={pagination}
          isDisableVirtualization={isDisableVirtualization}
          sortModel={facilitySortModel}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handlePagination={handlePagination}
        />
      ) : (
        <UserTab
          isFacilityTabSelected={isFacilityTabSelected}
          resetPagination={resetPagination}
          selectedFacility={selectedFacility}
          pagination={pagination}
          isDisableVirtualization={isDisableVirtualization}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handlePagination={handlePagination}
        />
      )}
      <Footer
        handleUserSelect={handleUserSelect}
        handleFacilityTab={handleFacilityTab}
        setPrevFacilityCurrentPage={setPrevFacilityCurrentPage}
        pagination={pagination}
        isFacilityTabSelected={isFacilityTabSelected}
        isDisable={selectedFacility !== undefined ? false : true}
      />
    </StyledImpersonateUserContainer>
  );
}
export default UserImpersonation;
