import { initialState } from "src/slices/facility-forms-management/layout-details/layout-detail-initial-state";
import {
  layoutDetailsByIdSelector,
  layoutDetailsSelector,
  filterSelector,
  layoutDetailsSelectorIsLoading,
  layoutFieldDetailsByIdSelector,
} from "src/slices/facility-forms-management/layout-details/layout-detail-selector";
import { layoutDetailSlice } from "src/slices/facility-forms-management/layout-details/layout-detail-slices";

export { initialState };

export const {
  updateLayoutDetails,
  updateLayoutField,
  reArrangeLayoutFields,
  updateLayoutFieldDelegateTypes,
  resetLayoutDetails,
  updateFilter,
  resetFilter,
  updateLayoutAllFileds,
  bulkUpdateFields,
} = layoutDetailSlice.actions;

export default layoutDetailSlice.reducer;

export {
  layoutDetailsByIdSelector,
  layoutDetailsSelector,
  filterSelector,
  layoutDetailsSelectorIsLoading,
  layoutFieldDetailsByIdSelector,
};
