import { Grid, styled, Button } from "@mui/material";
import { StyledSelectButtonProps } from "src/pages/facility-forms-management/user-impersonation/components/table/styles";

export const StyledRightColumn = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const StyledSelectButtonHide = styled(Button)`
  display: flex;
  gap: 0.4rem;
  text-transform: none;
  background: #034a93;
  font-weight: bold;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

export const StyledLayoutButton = styled(Button)<StyledSelectButtonProps>`
  font-weight: 500;
  font-size: 16px;
  padding: 20px 20px;
  text-transform: none;
  border-radius: 6px;
  width: 6rem;
  height: 2rem;
  background-color: ${(props) => props.bgcolor};
  border: 1px solid ${(props) => props.border};
  width: fit-content;
  display: flex;
  gap: 0.4rem;
  color: ${(props) => props.textColor};
`;
