import * as React from "react";
const CrossIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fill="#fff"
      d="M0 8c0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8Zm5.469-1.469L6.937 8 5.47 9.469c-.313.312-.313.781 0 1.062a.684.684 0 0 0 1.031 0l1.469-1.469 1.5 1.47a.684.684 0 0 0 1.031 0c.313-.282.313-.75 0-1.063L9.031 8 10.5 6.531c.313-.281.313-.75 0-1.062a.736.736 0 0 0-1.031 0l-1.5 1.5-1.469-1.5a.736.736 0 0 0-1.031 0c-.313.312-.313.781 0 1.062Z"
    />
  </svg>
);
export default CrossIcon;
