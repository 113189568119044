import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { LayoutSearchParams } from "src/interfaces/layouts.interface";
import { getLayouts } from "src/services/layouts-service";
import { getConvertedSentenceToUpperCase } from "src/utils/convert-strings-case";

export const getLayoutsThunk = createAsyncThunk(
  "getOpieLayoutsThunk",
  async (req: LayoutSearchParams, { rejectWithValue }) => {
    try {
      const res = await getLayouts(req);
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
