import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid whitesmoke;
  border-radius: 0.5rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

export const StyledHeading = styled.h3`
  color: #034a93;
`;
export const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 16px;
  padding: 6px 24px;
  text-transform: none;
  border: 1px solid #034a93;
  border-radius: 6px;
`;
