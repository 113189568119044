import { Facility } from "src/interfaces/groups.interface";

export const mapFacilitiesWithCheckedKey = (data: Facility[]) => {
  return data.map((item) => {
    const copy = Object.assign({}, item);
    copy.isChecked = false;
    return copy;
  });
};

export const filterCheckedFacilitiesFromList = (
  list: Facility[],
  selectedFacilities: Facility[]
) => {
  if (selectedFacilities.length > 0) {
    return list.filter((facility) => {
      const matchingFacilities = selectedFacilities.map((existingFacility: Facility) => {
        if (existingFacility.id === facility.id) {
          facility.isChecked = true;
        }
        return facility;
      });
      return matchingFacilities;
    });
  } else {
    return [];
  }
};
