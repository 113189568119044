import { ArrowForward } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  TextContainer,
  ActionContainer,
  Count,
  Text,
  Open,
} from "src/pages/facility-forms-management/dashboard/components/layout-count-card/styles";

interface Props {
  count: number;
  text: string;
  route: string;
}
function LayoutCountCard({ count, text, route }: Props) {
  const navigate = useNavigate();
  const handleOpenClick = () => {
    navigate(route);
  };
  return (
    <Card>
      <TextContainer>
        <Count>{count}</Count>
        <Text>{text}</Text>
      </TextContainer>
      <ActionContainer>
        <Open
          variant="contained"
          data-testid="open-click"
          onClick={() => {
            handleOpenClick();
          }}
          endIcon={<ArrowForward />}
        >
          Open
        </Open>
      </ActionContainer>
    </Card>
  );
}

export default LayoutCountCard;
