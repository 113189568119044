import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { getIsCheckedMappedData } from "src/utils/layout-method";

export const sharedLayoutsStoreSelector = (state: RootState) => state.sharedLayoutStore;
export const sharedLayoutsLoaderSelector = (state: RootState) => state.sharedLayoutStore.isLoading;
export const sharedLayoutsFiltersSelector = (state: RootState) => state.sharedLayoutStore.filters;

export const associatedLayoutTypes = createSelector(
  (state: RootState) => state.sharedLayoutStore,
  (items) => {
    return getIsCheckedMappedData(items.filters.associatedTypes);
  }
);

export const visitedLayoutTypes = createSelector(
  (state: RootState) => state.sharedLayoutStore,
  (items) => {
    return getIsCheckedMappedData(items.filters.associatedVisitTypes);
  }
);

export const associatedDeviceTypesSelector = createSelector(
  (state: RootState) => state.sharedLayoutStore,
  (items) => {
    return getIsCheckedMappedData(items.filters.associatedDeviceTypes);
  }
);

export const associatedVisitTypesSelector = createSelector(
  (state: RootState) => state.sharedLayoutStore,
  (items) => {
    return getIsCheckedMappedData(items.filters.associatedVisitTypes);
  }
);

export const formCategoriesSelector = createSelector(
  (state: RootState) => state.sharedLayoutStore,
  (items) => {
    return getIsCheckedMappedData(items.filters.formCategories);
  }
);
