import { Checkbox, Grid, ListItemText, MenuItem, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  LayoutMetaDataContainer,
  StyledSwitch,
  StyledSelect,
  MenuProps,
  ToggleGroup,
} from "src/pages/facility-forms-management/layout-details/components/layout-metadata/styles";
import Switch from "src/shared/switch";
import {
  InputLabelText,
  InputTextField,
  RequiredText,
} from "src/pages/opie-forms-management/user-management/components/create-user/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AppDispatch } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  associatedDeviceTypesSelector,
  associatedVisitTypesSelector,
  associatedLayoutTypes,
} from "src/slices/shared/layouts";
import { renderValues } from "src/utils/layout-method";
import { FilterType } from "src/interfaces/groups.interface";
import { getListWithExistingItemsChecked } from "src/pages/facility-forms-management/layout-details/utils/layout-details-methods";
import {
  layoutDetailsByIdSelector,
  updateLayoutDetails,
  filterSelector,
  updateFilter,
} from "src/slices/facility-forms-management/layout-details";
import { DelegateInterface } from "src/interfaces/layout-details.interface";
function LayoutMetaData() {
  const dispatch = useDispatch<AppDispatch>();
  const layoutDetailData = useSelector(layoutDetailsByIdSelector);
  const { allowSharing } = layoutDetailData;
  const associatedDeviceTypesSelectorData = useSelector(associatedDeviceTypesSelector);
  const associatedVisitTypesSelectorData = useSelector(associatedVisitTypesSelector);
  const associatedLayoutTypesSelectorData = useSelector(associatedLayoutTypes);
  const {
    associatedDeviceTypes,
    associatedType,
    associatedVisitTypes,
    defaultLayoutFriendlyName,
    layoutDescription,
    isPublic,
  } = layoutDetailData;

  const [deviceType, setDeviceType] = useState<FilterType[]>([]);
  const [visitType, setVisitType] = useState<FilterType[]>([]);
  const [device, setDevice] = useState<FilterType[]>([]);
  const filterData = useSelector(filterSelector);
  const [delegatesData, setDelegatesData] = useState<DelegateInterface[]>();

  const [isVisible, setIsVisible] = useState<boolean>(filterData.isVisible);

  useEffect(() => {
    setDelegatesData(filterData.delegates);
  }, [filterData.delegates]);

  useEffect(() => {
    setIsVisible(filterData.isVisible);
  }, [filterData.isVisible]);

  useEffect(() => {
    setDevice(getListWithExistingItemsChecked(associatedLayoutTypesSelectorData, associatedType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedType]);

  useEffect(() => {
    setDeviceType(
      getListWithExistingItemsChecked(associatedDeviceTypesSelectorData, associatedDeviceTypes)
    );
  }, [associatedDeviceTypes, associatedDeviceTypesSelectorData]);

  useEffect(() => {
    setVisitType(
      getListWithExistingItemsChecked(associatedVisitTypesSelectorData, associatedVisitTypes)
    );
  }, [associatedVisitTypes, associatedVisitTypesSelectorData]);

  useEffect(() => {
    setDevice(getListWithExistingItemsChecked(associatedLayoutTypesSelectorData, associatedType));
  }, [associatedLayoutTypesSelectorData, associatedType]);

  const handleSwitch = () => {
    handleUpdate("allowSharing", !allowSharing);
  };

  const handleUpdate = (field: string, value: string | boolean | string[]) => {
    dispatch(updateLayoutDetails({ field, value }));
  };

  const handleChangeLayoutName = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdate("defaultLayoutFriendlyName", event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdate("layoutDescription", event.target.value);
  };

  const handleSelect = (event: SelectChangeEvent<unknown>, name: string) => {
    const selectedValues = Array.isArray(event.target.value)
      ? event.target.value
      : [event.target.value];
    handleUpdate(name, selectedValues);
  };

  const handleShowIncludedSwitch = () => {
    const isSelected: [] = delegatesData?.filter((item) => item.isSelected) as [];
    dispatch(
      updateFilter({
        ...filterData,
        isApplied: isSelected?.length <= 0 && !filterData.isRequired && isVisible ? false : true,
        isVisible: !isVisible,
      })
    );
  };

  return (
    <LayoutMetaDataContainer>
      <ToggleGroup>
        <StyledSwitch>
          <Switch disabled={!isPublic} checked={allowSharing} handleFunction={handleSwitch} />
          Allow Sharing
        </StyledSwitch>
        <StyledSwitch>
          <Switch checked={filterData.isVisible} handleFunction={handleShowIncludedSwitch} />
          Show Included Fields
        </StyledSwitch>
      </ToggleGroup>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <InputLabelText>
            Layout Name <RequiredText>*</RequiredText>
          </InputLabelText>
          <InputTextField
            id="name"
            variant="outlined"
            size="small"
            placeholder="Layout Name"
            onChange={handleChangeLayoutName}
            value={defaultLayoutFriendlyName ? defaultLayoutFriendlyName : ""}
            inputProps={{ "data-testid": "layout-name", maxLength: 500 }}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputLabelText>Layout Description...</InputLabelText>
          <InputTextField
            id="description"
            variant="outlined"
            size="small"
            value={layoutDescription || ""}
            placeholder="Layout Description"
            inputProps={{ "data-testid": "layout-description" }}
            onChange={handleDescriptionChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputLabelText>
            Device <RequiredText>*</RequiredText>
          </InputLabelText>
          <StyledSelect
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            name="associatedType"
            value={associatedType || []}
            onChange={(e) => handleSelect(e, "associatedType")}
            IconComponent={KeyboardArrowDownIcon}
            inputProps={{ "data-testid": "layout-device" }}
            renderValue={renderValues}
            MenuProps={MenuProps}
          >
            {device.map((item: FilterType) => (
              <MenuItem key={item.name} value={item.name}>
                <Checkbox data-testid={"device-checkbox"} checked={item.isChecked} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <InputLabelText>
            Device Type <RequiredText>*</RequiredText>
          </InputLabelText>
          <StyledSelect
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={associatedDeviceTypes || []}
            onChange={(e) => handleSelect(e, "associatedDeviceTypes")}
            IconComponent={KeyboardArrowDownIcon}
            inputProps={{ "data-testid": "layout-device-type" }}
            renderValue={renderValues}
            MenuProps={MenuProps}
          >
            {deviceType.map((item: FilterType) => (
              <MenuItem key={item.name} value={item.name}>
                <Checkbox data-testid={"device-type-checkbox"} checked={item.isChecked} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <InputLabelText>
            Visit Type <RequiredText>*</RequiredText>
          </InputLabelText>
          <StyledSelect
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={associatedVisitTypes || []}
            IconComponent={KeyboardArrowDownIcon}
            onChange={(e) => handleSelect(e, "associatedVisitTypes")}
            renderValue={renderValues}
            MenuProps={MenuProps}
            inputProps={{ "data-testid": "visit-type" }}
          >
            {visitType.map((item: FilterType) => (
              <MenuItem key={item.name} value={item.name}>
                <Checkbox data-testid={"visit-type-checkbox"} checked={item.isChecked} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
    </LayoutMetaDataContainer>
  );
}

export default LayoutMetaData;
