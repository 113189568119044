import React, { useState } from "react";
import { FormControlLabel, TextField, Typography } from "@mui/material";
import AccordionTemplate from "src/shared/ui/accordion";

import {
  StyledAccordionBody,
  StyledGroupForm,
  InputTextField,
  InputLabelText,
  RequiredText,
  GroupDefaultText,
  StyledDateDiv,
} from "src/pages/opie-forms-management/groups/components/form-data/styles";
import { CheckBoxText } from "src/pages/shared/auth/login/styles";
import { useSelector } from "react-redux";
import { currentUserSelector } from "src/slices/shared/user";
import moment from "moment";

interface Props {
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  groupData: {
    name: string;
    description: string;
    is_default: boolean;
    is_system_generated?: boolean;
    updated_time?: Date;
    created_time?: Date;
  };
}
export default function FormData({ handleInput, groupData }: Props) {
  const [expand, setExpand] = useState<boolean>(true);
  const currentUser = useSelector(currentUserSelector);
  const currentRole = currentUser && currentUser.role && currentUser.role.role_name;

  return (
    <AccordionTemplate
      heading="Facility Group Name and Details"
      handleExpand={() => {
        setExpand(!expand);
      }}
      expanded={expand}
    >
      <StyledAccordionBody>
        <StyledGroupForm>
          {groupData.created_time && groupData.updated_time && (
            <StyledDateDiv
              sx={{
                lineHeight: { xs: "1.1", sm: "2" },
              }}
            >
              <Typography sx={{ fontSize: { xs: "12px", sm: "18px" } }} variant="h6" component="h6">
                Created on: {moment(groupData.created_time as Date).format("L")}
              </Typography>

              <Typography sx={{ fontSize: { xs: "12px", sm: "18px" } }} variant="h6" component="h6">
                Updated on: {moment(groupData.updated_time as Date).format("L")}
              </Typography>
            </StyledDateDiv>
          )}
          <FormControlLabel
            sx={{ height: "1rem", marginLeft: "-0.5rem", marginTop: "-0.65rem" }}
            control={
              <CheckBoxText
                size="small"
                id="checkbox"
                checked={groupData.is_default}
                disabled={groupData.is_system_generated || currentRole !== "super_admin"}
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                inputProps={{ "data-testid": "default-checkbox" }}
                onChange={handleInput}
                color="primary"
              />
            }
            label={<GroupDefaultText>Make this facility group Default</GroupDefaultText>}
          />
          <InputLabelText>
            Name <RequiredText>*</RequiredText>
          </InputLabelText>
          <InputTextField
            id="name"
            variant="outlined"
            size="small"
            placeholder="Name"
            onChange={handleInput}
            value={groupData.name}
            inputProps={{ "data-testid": "name", maxLength: 50 }}
            disabled={groupData.is_system_generated ? true : false}
          />
          <InputLabelText sx={{ marginTop: "0.6rem" }}>
            Description <RequiredText>*</RequiredText>
          </InputLabelText>
          <TextField
            id="description"
            placeholder="Description"
            minRows={4}
            multiline
            draggable={false}
            sx={{ width: "100%" }}
            onChange={handleInput}
            inputProps={{ "data-testid": "description", maxLength: 500 }}
            value={groupData.description}
            disabled={groupData.is_system_generated ? true : false}
          />
        </StyledGroupForm>
      </StyledAccordionBody>
    </AccordionTemplate>
  );
}
