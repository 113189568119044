import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getUsersThunk,
  getRolesThunk,
  getUserByIdThunk,
  updateUserStatusThunk,
  deleteUserThunk,
  updateUserThunk,
  getUserLayoutThunk,
  deleteUserLayoutThunk,
} from "src/slices/opie-forms-management/user-management/user-management-thunks";
import { initialState } from "src/slices/opie-forms-management/user-management/user-management-initial-state";
import {
  UserManagementStore,
  UsersResponse,
  UserByIdResponse,
} from "src/slices/opie-forms-management/user-management/user-management-interface";
import { GetRolesResponse, Role } from "src/interfaces/user-management.interface";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";
import { Layout } from "src/interfaces/layouts.interface";
import { User } from "src/interfaces/user-management.interface";

export const userManagementSlice = createSlice({
  name: "userManagementSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getUsersThunk.fulfilled,
      (state: UserManagementStore, { payload }: PayloadAction<UsersResponse>) => {
        state.isLoading = false;
        const filteredUsers = payload.data.filter((user) => !user.is_deleted);
        state.users = filteredUsers.map((item) => {
          const { first_name, last_name } = item;
          item.first_name = getConvertedUpperCaseString(first_name);
          item.last_name = getConvertedUpperCaseString(last_name);
          item.name = `${item.first_name} ${item.last_name}`;
          return item;
        });
      }
    );
    builder.addCase(getUsersThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsersThunk.rejected, (state) => {
      state.isLoading = false;
    });
    //get user by id
    builder.addCase(
      getUserByIdThunk.fulfilled,
      (state: UserManagementStore, { payload }: PayloadAction<UserByIdResponse>) => {
        const selectedUser = { ...payload.data };
        const user = {
          id: selectedUser.id as string,
          first_name: selectedUser.first_name as string,
          last_name: selectedUser.last_name as string,
          email: selectedUser.email as string,
          title: selectedUser.title as string,
          role_id: selectedUser.role_id as string,
          is_active: selectedUser.is_active as boolean,
          role: {
            ...selectedUser.role,
            role_name:
              selectedUser.role.role_name === "super_admin"
                ? "Super Admin"
                : getConvertedUpperCaseString(selectedUser.role.role_name),
          } as Role,
        };
        state.currentUser = user;
        state.isLoading = false;
        state.isUserLoading = false;
      }
    );
    builder.addCase(getUserByIdThunk.pending, (state) => {
      state.isLoading = true;
      state.isUserLoading = true;
    });
    builder.addCase(getUserByIdThunk.rejected, (state) => {
      state.isLoading = false;
      state.isUserLoading = false;
    });
    //get Roles
    builder.addCase(
      getRolesThunk.fulfilled,
      (state: UserManagementStore, { payload }: PayloadAction<GetRolesResponse>) => {
        state.isLoading = false;
        const data = payload.data;
        state.roles = data.map((item) => {
          if (item.role_name === "super_admin") {
            item.role_name = "Super Admin";
          } else {
            item.role_name = getConvertedUpperCaseString(item.role_name);
          }
          return item;
        });
      }
    );
    builder.addCase(getRolesThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRolesThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateUserStatusThunk.fulfilled,
      (state: UserManagementStore, { payload }: PayloadAction<{ id: string }>) => {
        state.isStatusLoading = false;
        const updatesUsers = state.users.map((item) => {
          const newItem = { ...item };
          newItem.is_active = newItem.id === payload.id ? !newItem.is_active : newItem.is_active;
          return newItem;
        });
        state.users = updatesUsers;
      }
    );
    builder.addCase(updateUserStatusThunk.pending, (state) => {
      state.isStatusLoading = true;
    });
    builder.addCase(updateUserStatusThunk.rejected, (state) => {
      state.isStatusLoading = false;
    });
    //delete User
    builder.addCase(
      deleteUserThunk.fulfilled,
      (state: UserManagementStore, { payload }: PayloadAction<{ id: string }>) => {
        state.isLoading = false;
        const updatesUsers = state.users
          .map((item) => {
            const newItem = { ...item };
            newItem.is_deleted =
              newItem.id === payload.id ? !newItem.is_deleted : newItem.is_deleted;
            return newItem;
          })
          .filter((user) => !user.is_deleted);
        state.users = updatesUsers;
      }
    );
    builder.addCase(deleteUserThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteUserThunk.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(
      updateUserThunk.fulfilled,
      (state: UserManagementStore, { payload }: PayloadAction<{ data: User }>) => {
        state.isUpdateLoading = false;
        state.currentUser = { ...payload.data };
      }
    );
    builder.addCase(updateUserThunk.pending, (state) => {
      state.isUpdateLoading = true;
    });
    builder.addCase(updateUserThunk.rejected, (state) => {
      state.isUpdateLoading = false;
    });

    // get user layouts
    builder.addCase(
      getUserLayoutThunk.fulfilled,
      (state: UserManagementStore, { payload }: PayloadAction<{ data: Layout[] }>) => {
        const layouts: Layout[] = payload.data?.map((layout: Layout) => {
          return {
            ...layout,
          };
        });

        state.isLoading = false;
        state.privateLayouts = layouts;
      }
    );
    builder.addCase(getUserLayoutThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserLayoutThunk.rejected, (state) => {
      state.isLoading = false;
    });

    //delete User Layout
    builder.addCase(
      deleteUserLayoutThunk.fulfilled,
      (
        state: UserManagementStore,
        { payload }: PayloadAction<{ id: string; layoutId: string }>
      ) => {
        state.isLoading = false;
        const updateUserLayout: Layout[] = state.privateLayouts.filter(
          (item) => item.id !== payload.layoutId
        );
        state.privateLayouts = updateUserLayout;
      }
    );
  },
});
