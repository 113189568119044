import { Checkbox, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

export const LoginContainer = styled(Grid)`
  height: 100vh;
  display: flex;
`;

export const LeftContainer = styled(Grid)`
  background: white;
`;

export const Heading = styled.h1`
  text-align: center;
  font-weight: 600;
  color: #000a14;
  @media only screen and (min-width: 600px) {
    font-size: 34px;
  }
  font-size: 28px;
`;

export const OuterHeadingText = styled.h3`
  margin: 0;
  color: #c8cbce;
`;

export const InputTextField = styled(TextField)`
  width: 100%;
  white-space: pre-line;
`;
export const InputLabelText = styled(InputLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #011428;
`;
export const ForgotPasswordDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`;

export const ForgotPasswordText = styled(Link)`
  font-size: 12px;
  color: #023364;
  text-decoration: none;
`;

export const RememberMeText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #000a14;
`;
export const SubmitButton = styled(LoadingButton)`
  text-transform: none;
  background: #034a93;
  color: white !important;
  font-weight: bold;
  width: 100%;
  height: 3.5rem;
  border-radius: 8px;
  cursor: pointer;
`;

export const RequiredText = styled.sup`
  color: #034a93;
`;

export const CheckBoxText = styled(Checkbox)`
  margin-top: -0.1rem;
`;

export const ShowPasswordIcon = styled(VisibilityOutlined)`
  color: #034a93;
  font-size: 1.2em !important;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  z-index: 2;
`;

export const HidePasswordIcon = styled(VisibilityOffOutlined)`
  color: #034a93;
  font-size: 1.2em !important;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  z-index: 2;
`;

export const PasswordDiv = styled.div`
  position: relative;
`;
