import axios from "axios";
import { BASE_URL } from "src/constants/app-config-constants";
import {
  CreateGroupInterface,
  FilterOptions,
  HistoryReq,
  UpdateGroupReq,
} from "src/interfaces/groups.interface";

import { HTTPResponse } from "src/interfaces/httpResponse.interface";

export async function getGroups(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group`;
  return axios.get(url);
}

export async function createGroup(group: CreateGroupInterface): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group`;
  return axios.post(url, group);
}

export async function updateGroup(group: UpdateGroupReq): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group/${group.id}`;
  return axios.put(url, group);
}

export async function getFacilities(): Promise<HTTPResponse> {
  const url = `${BASE_URL}/facility`;
  return axios.get(url);
}

export async function getGroupById(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group/${id}`;
  return axios.get(url);
}

export async function deleteGroup(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group/${id}`;
  return axios.delete(url);
}

export async function deleteFacilityFromGroup(req: {
  group_id: string;
  facility_id: string;
}): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group/${req.group_id}/facility/${req.facility_id}`;
  return axios.delete(url);
}

export async function getLayouts(isPublic: boolean): Promise<HTTPResponse> {
  const url = `${BASE_URL}/layouts?public=${isPublic}`;
  return axios.get(url);
}

export async function addGroupLayout(id: string, layouts: Array<string>): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group/${id}/layouts`;
  return axios.post(url, { layouts });
}

export async function getAssignedLayout(id: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group/${id}/assigned-layouts`;
  return axios.get(url);
}
export async function getGroupsBySearch(req: FilterOptions): Promise<HTTPResponse> {
  let url = "";
  if (req.createdBy.id) {
    url = `${BASE_URL}/group?created_by=${req.createdBy.id}&text=${req.searchValue}&from_date=${req.fromDate}&to_date=${req.toDate}`;
  } else {
    url = `${BASE_URL}/group?text=${req.searchValue}&from_date=${req.fromDate}&to_date=${req.toDate}`;
  }
  return axios.get(url);
}

export async function searchFacility(req: string): Promise<HTTPResponse> {
  const url = `${BASE_URL}/facility?searchTerm=${req}`;
  return await axios.get(url);
}

export async function getHistoryDetails(req: HistoryReq): Promise<HTTPResponse> {
  const url = `${BASE_URL}/audit/group?entity_id=${req.entity_id}`;
  return await axios.get(url);
}

export async function deleteLayoutFromGroup(req: {
  group_id: string;
  layout_id: string;
}): Promise<HTTPResponse> {
  const url = `${BASE_URL}/group/${req.group_id}/layout/${req.layout_id}`;
  return axios.delete(url);
}
