import React, { useEffect, useMemo, useState } from "react";
import LayoutMetaData from "src/pages/facility-forms-management/layout-details/components/layout-metadata/index";
import Header from "src/pages/facility-forms-management/layout-details/components/header";
import Footer from "src/pages/facility-forms-management/layout-details/components/footer";
import { StyledHeaderDiv } from "src/pages/facility-forms-management/layout-details/styles";
import Toolbar from "src/pages/facility-forms-management/layout-details/components/toolbar/index";
import { useParams } from "react-router-dom";
import { getLayoutDetailThunk } from "src/slices/facility-forms-management/layout-details/layout-detail-thunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/redux/store";
import {
  layoutDetailsByIdSelector,
  layoutDetailsSelector,
  layoutFieldDetailsByIdSelector,
  resetFilter,
  resetLayoutDetails,
} from "src/slices/facility-forms-management/layout-details";
import {
  openFields,
  requiredFields,
} from "src/pages/facility-forms-management/layout-details/validation-constants";
import FieldsMetaData from "src/pages/facility-forms-management/layout-details/components/fields-metadata";
import ValidateImpersonateUser from "src/shared/hoc/validate-impersonate-user";
import Loader from "src/shared/loader";
import { LayoutDetails as LayoutDetailsInterface } from "src/interfaces/layout-details.interface";
import LayoutPreview from "src/pages/facility-forms-management/shared/preview-layout";
import { getFilteredLayoutDetails } from "src/pages/facility-forms-management/layout-details/utils/layout-details-methods";
import { Box } from "@mui/material";

function LayoutDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const layoutDetailData = useSelector(layoutDetailsByIdSelector);
  const fieldsData = useSelector(layoutFieldDetailsByIdSelector);
  const layoutDetailLoading = useSelector(layoutDetailsSelector);
  const { defaultLayoutFriendlyName } = layoutDetailData;
  const [isLayoutFormInvalid, setIsLayoutFormInvalid] = useState<boolean>(true);
  const [layoutInitialData, setLayoutInitialData] = useState<LayoutDetailsInterface>();
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [isPreviewLayout, setIsPreviewLayout] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetFilter());
  }, [dispatch]);

  const filteredLayoutList = useMemo(() => {
    return getFilteredLayoutDetails({ ...layoutDetailData, fields: fieldsData });
  }, [fieldsData, layoutDetailData]);

  useEffect(() => {
    if (id) dispatch(getLayoutDetailThunk(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetLayoutDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    if (layoutInitialData?.id !== layoutDetailData.id) {
      setLayoutInitialData({ ...layoutDetailData, fields: fieldsData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutDetailData]);

  useEffect(() => {
    if (layoutInitialData?.id === filteredLayoutList.id) {
      const isFormStatus =
        JSON.stringify(getFilteredLayoutDetails(layoutInitialData)) !==
        JSON.stringify(filteredLayoutList);
      setIsFormChanged(isFormStatus);
    } else {
      setIsFormChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredLayoutList]);

  const updateLayoutFormStatus = (status: boolean) => {
    setIsLayoutFormInvalid(status);
  };

  useEffect(() => {
    if (filteredLayoutList.id && isFormChanged) {
      for (const [key, value] of Object.entries(filteredLayoutList)) {
        if (
          !openFields.includes(key) &&
          requiredFields.includes(key) &&
          (!value || Object.keys(value).length <= 0)
        ) {
          setIsLayoutFormInvalid(true);
          return;
        }
        setIsLayoutFormInvalid(false);
      }
    } else {
      setIsLayoutFormInvalid(true);
    }
  }, [isFormChanged, filteredLayoutList]);

  const handlePreviewLayout = (status: boolean) => {
    setIsPreviewLayout(status);
  };

  return (
    <>
      {!layoutDetailLoading.isLoading ? (
        <>
          {!isPreviewLayout ? (
            <Box sx={{ margin: "0 -2rem", padding: "0 2rem", backgroundColor: "#f5f5f5" }}>
              <Box>
                <StyledHeaderDiv>
                  <Header layoutName={defaultLayoutFriendlyName} />
                  <Toolbar />
                </StyledHeaderDiv>

                <LayoutMetaData />
              </Box>

              <FieldsMetaData />
            </Box>
          ) : (
            <LayoutPreview
              handlePreviewLayout={handlePreviewLayout}
              layoutDetailData={{ ...layoutDetailData, fields: fieldsData }}
              editMode={true}
            />
          )}
          {/* TODO - rename variables; localLayoutDetailData should be passed to layoutDetailData, not filteredLayoutList. localLayoutDetailData currently contains the filtered list. */}
          <Footer
            layoutDetailData={filteredLayoutList}
            isLayoutFormInvalid={isLayoutFormInvalid}
            updateLayoutFormStatus={updateLayoutFormStatus}
            handlePreviewLayout={handlePreviewLayout}
            isPreviewLayout={isPreviewLayout}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default ValidateImpersonateUser(LayoutDetails);
