import Box from "@mui/material/Box";
import React from "react";
import { useSelector } from "react-redux";
import {
  CustomButton,
  ConfirmationButton,
  Message,
} from "src/pages/facility-forms-management/layout-details/components/bulk-update-modal/styles";
import CustomDialog from "src/shared/modal";
import { layoutDetailStore } from "src/slices/facility-forms-management/layout-details/layout-detail-selector";
interface Props {
  openModal: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

function BulkUpdateConfirmationModal({ openModal, handleClose, handleConfirm }: Props) {
  const { affectedNoOfFields } = useSelector(layoutDetailStore);

  const handleConfirmation = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <CustomDialog
      data-testid="confirmation-dialog"
      open={openModal}
      maxWidth={"sm"}
      handleOpen={handleClose}
    >
      <Box sx={{ padding: "1rem" }}>
        <Box sx={{ margin: "1rem" }}>
          <Message style={{ fontWeight: "bold", fontSize: "30px" }}> Are you sure?</Message>
          <Message> You are about to affect {affectedNoOfFields} fields.</Message>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            paddingBottom: "1rem ",
          }}
        >
          <ConfirmationButton
            data-testid="handle-confirmation"
            variant="contained"
            sx={{ marginRight: "2rem" }}
            onClick={handleConfirmation}
          >
            Yes
          </ConfirmationButton>
          <CustomButton data-testid="handle-cancel-request" onClick={handleClose}>
            Cancel
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
}

export default BulkUpdateConfirmationModal;
