import styled from "styled-components";
import Close from "@mui/icons-material/Close";

export const DialogDiv = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: 0 10px;
  cursor: pointer;
  z-index: 100;
`;
export const CloseIcon = styled(Close)``;
