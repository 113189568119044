import React, { useState } from "react";
import {
  StyledZeuModalHeading,
  StyledZeuModalBody,
  StyledZeuModalInputBox,
  StyledZeuModalFooter,
} from "src/pages/facility-forms-management/user-impersonation/components/zeus-user-modal/styles";
import { Autocomplete, Box, TextField } from "@mui/material";
import { StyledFooterNextButton } from "src/pages/facility-forms-management/user-impersonation/components/footer/styles";
import CustomDialog from "src/shared/modal";
import { useSelector } from "react-redux";
import { impersonationSelector } from "src/slices/facility-forms-management/impersonation";
import { FacilityUser } from "src/interfaces/user-impersonation.interface";
import { useAppDispatch } from "src/redux/hooks";
import { addZeusUserThunk } from "src/slices/facility-forms-management/impersonation/impersonation-thunks";
import DropDownIcon from "src/shared/icons/dropdown";

interface Props {
  open: boolean;
  handleOpen: (open: boolean) => void;
}

function ZeusUserModal({ open, handleOpen }: Props) {
  const dispatch = useAppDispatch();
  const facilityUsers = useSelector(impersonationSelector);
  const [userData, setUserData] = useState<FacilityUser>({
    facilityID: "",
    userID: "",
    firstName: "",
    middleName: "",
    lastName: "",
    opieClassification: "",
    isAdmin: true,
    username: "",
    isActive: false,
    name: "",
    emailAddress: "",
    isFormsLayoutAdmin: false,
  });
  const sortedUsers = [...facilityUsers.users].sort((a, b) => a.name.localeCompare(b.name));

  const handleUser = (event: React.SyntheticEvent<Element, Event>, value: FacilityUser) => {
    setUserData(value);
  };

  const addUser = () => {
    dispatch(addZeusUserThunk(userData)).finally(() => {
      handleOpen(false);
    });
  };

  return (
    <CustomDialog open={open} handleOpen={handleOpen} maxWidth={"md"}>
      <StyledZeuModalHeading>Add Forms Administrator</StyledZeuModalHeading>
      <StyledZeuModalBody>
        <StyledZeuModalInputBox>
          <Autocomplete
            id="user"
            popupIcon={<DropDownIcon />}
            options={sortedUsers.filter((user: FacilityUser) => !user.isFormsLayoutAdmin)}
            noOptionsText={"No Options"}
            autoHighlight
            size="small"
            onChange={handleUser}
            isOptionEqualToValue={(option: FacilityUser, value: FacilityUser) =>
              option.facilityID === value.facilityID
            }
            disableClearable
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} className="text-field" data-testid="select-role" />
            )}
            data-testid="autocomplete-select"
          />
        </StyledZeuModalInputBox>
        <StyledZeuModalFooter>
          <StyledFooterNextButton
            data-testid="add-user"
            variant="contained"
            style={{ flex: "1" }}
            // disabled={userData.facilityID || userData.userID ? false : true}
            disabled={true} //Updating the above condition and disabling the button temporarily for OF-1720
            loading={facilityUsers.isAddZeusUserLoading}
            onClick={addUser}
          >
            ADD
          </StyledFooterNextButton>
          <StyledFooterNextButton
            style={{ flex: "1" }}
            sx={{ background: "white !important", color: "#034a93" }}
            variant="contained"
            data-testid="cancel"
            onClick={() => {
              handleOpen(false);
            }}
          >
            CANCEL
          </StyledFooterNextButton>
        </StyledZeuModalFooter>
      </StyledZeuModalBody>
    </CustomDialog>
  );
}

export default ZeusUserModal;
