import { GridSortItem, GridSortModel } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EMPTY_SEARCH_VALUE } from "src/constants/error-constants";
import { PaginationFormat } from "src/interfaces/pagination.interface";
import { Facility } from "src/interfaces/groups.interface";
import {
  sortArray,
  mapSelectedFacilityToList,
  getFacilitySearchData,
} from "src/pages/facility-forms-management/user-impersonation/util";
import { groupSelector, mappedFacilities } from "src/slices/opie-forms-management/groups";
import SearchBar from "src/shared/ui/search-bar";
import Table from "src/pages/facility-forms-management/user-impersonation/components/table";
import { Box } from "@mui/material";
import { facilityColumns } from "src/pages/facility-forms-management/user-impersonation/components/table/columns";

interface Props {
  isFacilityTabSelected: boolean;
  handleSortModal: (model: GridSortItem[]) => void;
  resetPagination: (dataLength: number) => void;
  handleSelectFacility: (facility: Facility | undefined) => void;
  selectedFacility: Facility | undefined;
  pagination: PaginationFormat;
  isDisableVirtualization?: boolean;
  sortModel: GridSortItem[];
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  handlePagination: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export default function FacilityTab({
  isFacilityTabSelected,
  handleSortModal,
  resetPagination,
  handleSelectFacility,
  selectedFacility,
  pagination,
  isDisableVirtualization,
  sortModel,
  handlePreviousPage,
  handleNextPage,
  handlePagination,
}: Props) {
  const facilityData = useSelector(groupSelector);
  const mappedFacilitiesData = useSelector(mappedFacilities);
  const [searchValue, setSearchValue] = useState("");
  const [facilityList, setFacilityList] = useState<Facility[]>([]);
  const [facilitySearchList, setFacilitySearchList] = useState<Facility[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const mapData = mappedFacilitiesData.map((f: Facility) => ({
    ...f,
    net_suite_id: f.net_suite_id && f.net_suite_id.length > 0 ? f.net_suite_id.trim() : "",
  }));

  useEffect(() => {
    setFacilityList(mapData);
    resetPagination(mappedFacilitiesData.length);
    if (selectedFacility) {
      handleSortModelChange(
        sortModel,
        mapSelectedFacilityToList(mapData, selectedFacility as Facility)
      );
    }
    // eslint-disable-next-line
  }, [facilityData.facilities]);

  const handleSortModelChange = (incomingModel: GridSortModel, facilityData?: Facility[]) => {
    const newSortModel = incomingModel[0] as GridSortItem;
    const facility = facilityData && facilityData?.length > 0 ? facilityData : facilityList;
    if (newSortModel) {
      const facilities = sortArray<Facility>(
        facility,
        newSortModel.field as keyof Facility,
        newSortModel.sort
      );
      setFacilityList(facilities);
      handleSortModal(incomingModel);
    }
  };

  const getUpdatedSelectedFacilitiesList = (list: Facility[], selected: Facility) => {
    return list.map((item) => {
      if (item.id === selected.id) {
        if (!selected.isChecked) {
          handleSelectFacility({ ...item, isChecked: !item.isChecked });
        } else {
          handleSelectFacility(undefined);
        }
        return { ...item, isChecked: !item.isChecked };
      } else {
        return { ...item, isChecked: false };
      }
    });
  };

  const handleSelectData = (data: Facility) => {
    if (facilitySearchList.length > 0) {
      setFacilitySearchList(getUpdatedSelectedFacilitiesList(facilitySearchList, data));
    } else {
      setFacilityList(getUpdatedSelectedFacilitiesList(facilityList, data));
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value === "") {
      setIsSearching(false);
      setFacilitySearchList([]);
      if (selectedFacility) {
        setFacilityList(mapSelectedFacilityToList(facilityList, selectedFacility));
      }
      resetPagination(facilityList.length);
    }
  };

  const filterSearchList = () => {
    const value = searchValue.toLowerCase();
    if (value) {
      setIsSearching(true);
      const filteredFacilityList = getFacilitySearchData(facilityList, value);
      setFacilitySearchList(filteredFacilityList);
      resetPagination(filteredFacilityList.length);
    } else {
      toast.error(EMPTY_SEARCH_VALUE);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === "Enter" && searchValue) {
      filterSearchList();
    }
  };

  const showPagination = facilityData.isLoading || pagination.dataCount === 0;

  const getFacilityList = () => {
    return isSearching
      ? facilitySearchList.slice(pagination.firstRecordIndex, pagination.lastRecordIndex)
      : facilityList.slice(pagination.firstRecordIndex, pagination.lastRecordIndex);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SearchBar
        handleSearch={handleSearch}
        handleKeyPress={handleKeyPress}
        handleFilterList={filterSearchList}
        styles={{ margin: "1rem 0" }}
        data-testid="content-input"
        searchValue={searchValue}
      />
      <Table
        isDisableVirtualization={isDisableVirtualization}
        isFacilityTabSelected={isFacilityTabSelected}
        pagination={pagination}
        sortModel={sortModel}
        rows={facilityData.isLoading ? [] : getFacilityList()}
        handleSortModelChange={handleSortModelChange}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        facilityDataLoading={facilityData.isLoading}
        handlePagination={handlePagination}
        dataSetSize={pagination.paginationSize}
        currentPage={pagination.currentPage}
        showPagination={showPagination}
        columns={facilityColumns(handleSelectData as () => void)}
        rowId={(row: Facility) => row && (row.id as string)}
      />
    </Box>
  );
}
