import * as React from "react";

interface Props {
  color?: string;
}
const UserIcon = ({ color }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill={color ? color : "#FFFFFF"}
      d="M2 22a8 8 0 0 1 16 0h-2a6 6 0 1 0-12 0H2Zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6Zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6 6 0 0 0-3.537-5.473l.82-1.824h.001Zm-.688-11.29a5.5 5.5 0 0 1 1.966 8.796A5.499 5.499 0 0 1 16 13.978v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943Z"
    />
  </svg>
);
export default UserIcon;
