import { RootState } from "src/redux/store";

export const layoutDetailsByIdSelector = (state: RootState) =>
  state.layoutDetailStore.layoutDetail.details;

export const layoutDetailStore = (state: RootState) => state.layoutDetailStore;
export const layoutFieldDetailsByIdSelector = (state: RootState) =>
  state.layoutDetailStore.layoutDetail.fields;
export const filterSelector = (state: RootState) => state.layoutDetailStore.filter;
export const layoutDetailsSelectorIsLoading = (state: RootState) =>
  state.layoutDetailStore.isLoading;
export const layoutDetailsSelector = (state: RootState) => state.layoutDetailStore;
