import React, { useState } from "react";
import AccordionTemplate from "src/shared/ui/accordion";
import DeleteIcon from "src/shared/icons/delete-icon";
import {
  FormDiv,
  FormHeading,
  FormsParentDiv,
  NoAssignFormDiv,
} from "src/pages/opie-forms-management/groups/components/forms-accordion/styles";
import { Box, Divider } from "@mui/material";
import { Layout } from "src/interfaces/layouts.interface";
import {
  deleteLayoutFromGroupThunk,
  getAssignedLayoutsThunk,
} from "src/slices/opie-forms-management/groups/groups-thunk";
import DeleteGroup from "src/shared/delete-confirmation-modal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/redux/store";
interface Props {
  accordionData: Layout[];
  groupId: string;
}

export default function FormsAccordion({ accordionData, groupId }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedLayout, setSelectedLayout] = useState<Layout>();
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteLayout = () => {
    setIsLoading(true);
    dispatch(
      deleteLayoutFromGroupThunk({
        group_id: groupId,
        layout_id: selectedLayout?.id as string,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getAssignedLayoutsThunk(groupId as string));
      })
      .finally(() => {
        setIsLoading(false);
        setOpenDeleteModal(false);
      });
  };
  return (
    <>
      <AccordionTemplate heading="Assigned Layouts">
        <FormsParentDiv>
          {accordionData.length > 0 ? (
            accordionData.map((item: Layout, i: number) => (
              <div key={i}>
                <FormDiv>
                  <FormHeading>{item.defaultLayoutName}</FormHeading>
                  <Box
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setSelectedLayout(item);
                    }}
                    data-testid="delete-layout"
                  >
                    <DeleteIcon />
                  </Box>
                </FormDiv>
                {accordionData.length - 1 !== i && <Divider />}
              </div>
            ))
          ) : (
            <NoAssignFormDiv>No Assigned Layouts Available</NoAssignFormDiv>
          )}
        </FormsParentDiv>
      </AccordionTemplate>
      {openDeleteModal && (
        <DeleteGroup
          open={openDeleteModal}
          type=""
          handleOpen={() => {
            setOpenDeleteModal(!openDeleteModal);
          }}
          heading={"Delete Layout"}
          subHeading={"Are you sure you want to delete"}
          handleDeleteUser={handleDeleteLayout}
          isLoading={isLoading}
          name={selectedLayout?.defaultLayoutName as string}
        />
      )}
    </>
  );
}
