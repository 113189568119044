import * as React from "react";
const DeleteCrossIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" viewBox="0 0 41 40">
    <path
      fill="#F10008"
      d="M20.809 40c11.045 0 20-8.954 20-20s-8.955-20-20-20c-11.046 0-20 8.954-20 20s8.954 20 20 20Z"
    />
    <path
      fill="#fff"
      d="m27.88 29.428-7.071-7.07-7.072 7.07-2.357-2.357 7.072-7.07-7.072-7.072 2.357-2.357 7.072 7.071 7.07-7.071 2.358 2.357L23.166 20l7.07 7.071-2.356 2.357Z"
    />
  </svg>
);

export default DeleteCrossIcon;
