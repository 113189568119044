export const delegatesList: {
  [key: string]: string;
} = {
  cpo: "Practitioner",
  cc: "Care Coordinator",
  res: "Resident",
  pa: "Practitioner Assistant",
  i: "Intern",
  pat: "Patient",
  cg: "Caregiver",
  fam: "Family",
  cw: "Caseworker",
  tech: "Technician",
  pt: "Physical Therapist",
  ot: "Occupational Therapist",
  md: "Physician",
  mdpa: "Physician Assistant",
  rn: "Nurse",
  qa: "Quality Assurance",
  pth: "Pedorthist",
  tch: "Technician",
  oa: "Office Admin",
};
