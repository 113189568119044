import React, { useEffect } from "react";
import { getUserImpersonationFromSession } from "src/utils/storage";
import { LOCALSTORAGE_FACILITY_ID, LOCALSTORAGE_IDP_USER_FLAG } from "src/constants/auth-constants";
import { LayoutPageProps } from "src/interfaces/layouts.interface";
import { useLocation, useNavigate } from "react-router-dom";

const ValidateImpersonateUser = (WrappedComponent: React.ComponentType<LayoutPageProps>) => {
  const Component = (props: LayoutPageProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      const facilityId = getUserImpersonationFromSession(LOCALSTORAGE_FACILITY_ID);
      const isIDPUser = getUserImpersonationFromSession(LOCALSTORAGE_IDP_USER_FLAG);
      if (!facilityId && (!isIDPUser || isIDPUser == "undefined")) {
        window.location.replace("/");
      } else {
        const { state } = location;
        if (state && state.redirectUrl) navigate(state.redirectUrl);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <WrappedComponent {...props} />;
  };
  return Component;
};

export default ValidateImpersonateUser;
