export function getConvertedUpperCaseString(name: string) {
  const data = name || "";
  return data.charAt(0).toUpperCase() + data.slice(1);
}

export function getConvertedSentenceToUpperCase(sentence: string) {
  const data = sentence || "";
  return data
    .split(" ")
    .map((item) => getConvertedUpperCaseString(item))
    .join(" ");
}
