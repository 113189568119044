import { SharedLayoutsStore } from "src/slices/shared/layouts/layouts-interface";

export const initialState: SharedLayoutsStore = {
  isLoading: false,
  fetchMore: true,
  filters: {
    associatedDeviceTypes: [],
    associatedTracks: [],
    associatedTypes: [],
    associatedVisitTypes: [],
    formCategories: [],
    associatedAnatomy: [],
  },
};
