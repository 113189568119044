import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Select, styled } from "@mui/material";

export const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    padding: "0.625rem !important", // Customized padding
    borderRadius: "5px", // Customized border radius
    backgroundColor: "#fff", // Customized background color
    width: "12.5rem", // Customized minimum width
    border: "1px solid #034a93",
  },
  "& .MuiSelect-selectMenu": {
    minHeight: "40px", // Customized minimum height
    display: "flex",
    alignItems: "center",
  },
});

export const CustomIcon = styled(CheckCircleOutlineOutlined)({
  display: "none",
});
