import * as React from "react";
const FormIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={20} fill="none">
    <path
      fill="#034A93"
      d="M12 2H2v16h14V6h-4V2ZM0 .992C0 .444.447 0 .999 0H13l5 5v13.993A1 1 0 0 1 17.007 20H.993A1 1 0 0 1 0 19.008V.992ZM8 9V6h2v3h3v2h-3v3H8v-3H5V9h3Z"
    />
  </svg>
);
export default FormIcon;
