import { Box, Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledImpersonationBar = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),

  // Responsive styles
  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
  },
}));

export const ImpersonationButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#034A93",
  backgroundColor: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  minWidth: 0,
  [theme.breakpoints.up("md")]: {
    width: "auto",
    minWidth: theme.spacing(10),
  },
  fontSize: "0.875rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
}));
