import * as React from "react";
const HistoryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      fill="#034A93"
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10h2a8 8 0 1 0 1.385-4.5H6v2H0v-6h2V4a9.98 9.98 0 0 1 8-4Zm1 5v4.585l3.243 3.243-1.415 1.415L9 10.413V5h2Z"
    />
  </svg>
);
export default HistoryIcon;
