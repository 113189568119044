import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  UserContainer,
  InputLabelText,
  InputTextField,
  RequiredText,
  Container,
  SaveUserDiv,
  SaveUserIcon,
} from "src/pages/opie-forms-management/user-management/components/create-user/styles";

import { Role, User } from "src/interfaces/user-management.interface";
import { Autocomplete, Box, TextField } from "@mui/material";
import {
  createUserThunk,
  getRolesThunk,
  updateUserThunk,
  getUserByIdThunk,
  updateUserStatusThunk,
} from "src/slices/opie-forms-management/user-management/user-management-thunks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/redux/store";
import {
  rolesSelector,
  currentSelectedUserSelector,
  userManagementData,
} from "src/slices/opie-forms-management/user-management";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { CREATE_USER, USER_MANAGEMENT } from "src/constants/route-constants";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  HeaderDiv,
  Heading,
  UserButtonDiv,
  DeactivateUserDiv,
  DeactivateUserIcon,
  AddUserDiv,
  AddUserIcon,
} from "src/pages/opie-forms-management/user-management/styles";
import { NavLink } from "src/shared/header/styles";
import DeleteUser from "src/shared/delete-confirmation-modal";
import { checkEmail } from "src/utils/regex-functions";
import { IN_VALID_EMAIL } from "src/constants/error-constants";
import Layouts from "src/pages/opie-forms-management/user-management/components/layouts/index";
import { UserRole } from "src/constants/user-role-constants";
import Loader from "src/shared/loader";

const initialState: User = {
  last_name: "",
  first_name: "",
  email: "",
  title: "",
  role_id: "",
  is_active: false,
  role: {
    id: "",
    role_name: "",
  },
};
interface Props {
  isDisableVirtualization?: boolean;
}
function CreateUser({ isDisableVirtualization }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const roles = useSelector(rolesSelector);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedUser = useSelector(currentSelectedUserSelector);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [userData, setUserData] = useState<User>(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [deleteModalType, setDeleteModalType] = useState<string>("");
  const userManagementStore = useSelector(userManagementData);

  const isFormChanged = JSON.stringify(userData) !== JSON.stringify(selectedUser);

  const handleSelectedUSer = () => {
    if (Object.keys(selectedUser).length) {
      setUserData({
        ...selectedUser,
      });
    }
  };

  useEffect(() => {
    setIsDisabled(!isFormChanged);
  }, [isFormChanged]);

  useEffect(() => {
    handleSelectedUSer();
    // eslint-disable-next-line
  }, [selectedUser]);

  useEffect(() => {
    const type = location.pathname;
    const path = type.includes("create-user") ? "create" : type.includes("users") ? "edit" : "";
    setModalType(path);
    dispatch(getRolesThunk());
  }, [location, dispatch]);

  useEffect(() => {
    if (modalType === "create") {
      setUserData({ ...initialState });
    } else if (modalType === "edit" && Object.keys(params).length !== 0) {
      dispatch(getUserByIdThunk(params.id as string));
    }
    // eslint-disable-next-line
  }, [modalType, dispatch]);

  const handleModalEmpty = () => {
    if (modalType === "create") {
      setUserData({ ...initialState });
    } else {
      handleSelectedUSer();
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;

    if (id === "email" && !checkEmail.test(value) && value.length > 0) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    setUserData({ ...userData, [id]: value });
  };

  const submitUserData = () => {
    if (modalType === "create") {
      setIsLoading(true);
      dispatch(createUserThunk(userData)).finally(() => {
        setIsLoading(false);
        handleModalEmpty();
      });
    } else {
      dispatch(updateUserThunk(userData));
    }
  };

  useEffect(() => {
    for (const [key, value] of Object.entries(userData as User)) {
      if (key !== "is_active" && (!value || emailError)) {
        setIsDisabled(true);
        return;
      }
      if (modalType === "create") {
        setIsDisabled(false);
      } else if (Object.keys(selectedUser).length > 0 && isFormChanged && modalType === "edit") {
        setIsDisabled(false);
      }
    }
  }, [emailError, userData, isFormChanged, selectedUser, modalType]);

  const handleRole = (event: React.SyntheticEvent<Element, Event>, value: Role | null) => {
    setUserData({ ...userData, role_id: value?.id as string, role: value as Role });
  };

  const handleDeleteModal = (open: boolean, type: string) => {
    setDeleteModalType(type);
    setIsDeleteModal(open);
  };

  useEffect(() => {
    if (!userManagementStore.isStatusLoading) {
      handleModalEmpty();
      handleDeleteModal(false, "");
    }
    // eslint-disable-next-line
  }, [userManagementStore.isStatusLoading]);

  const handleDeleteUser = () => {
    dispatch(
      updateUserStatusThunk({
        id: selectedUser.id as string,
        status: !selectedUser.is_active as boolean,
      })
    ).finally(() => {
      dispatch(getUserByIdThunk(params.id as string));
      handleModalEmpty();
      handleDeleteModal(false, "");
    });
  };

  return (
    <Container>
      <HeaderDiv>
        <Heading>Users</Heading>
        <UserButtonDiv>
          {modalType === "edit" && (
            <>
              <DeactivateUserDiv
                variant="outlined"
                data-testid="delete-row"
                sx={{
                  fontSize: { sm: "14px", md: "16px" },
                  height: "3rem",
                }}
                color={selectedUser.is_active ? "error" : "success"}
                textcolor={selectedUser.is_active ? "#f10008" : "green"}
                onClick={() => {
                  selectedUser.is_active
                    ? handleDeleteModal(true, "activate")
                    : handleDeleteModal(true, "deactivate");
                }}
              >
                {selectedUser.is_active ? <DeactivateUserIcon /> : null}
                {selectedUser.is_active ? "Deactivate" : "Activate"}
              </DeactivateUserDiv>
              <AddUserDiv
                variant="contained"
                data-testid="create-user"
                sx={{
                  fontSize: { sm: "14px", md: "16px" },
                  height: "3rem",
                }}
                onClick={() => {
                  navigate(`${CREATE_USER}`, {
                    replace: true,
                    state: {
                      modalType: "create",
                    },
                  });
                }}
              >
                <AddUserIcon />
                Create User
              </AddUserDiv>
            </>
          )}
        </UserButtonDiv>
      </HeaderDiv>

      {isDeleteModal && (
        <DeleteUser
          open={isDeleteModal}
          handleOpen={handleDeleteModal}
          heading={deleteModalType === "activate" ? "Deactivate user" : "Activate User"}
          type={deleteModalType}
          subHeading={
            deleteModalType === "activate"
              ? "Are you sure you want to deactivate"
              : "Are you sure you want to Activate"
          }
          handleDeleteUser={handleDeleteUser}
          isLoading={userManagementStore.isStatusLoading}
          name={`${selectedUser.first_name as string} ${selectedUser.last_name}` as string}
        />
      )}

      {modalType && (
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: "12px" }}>
          <NavLink to={USER_MANAGEMENT} data-testid="users">
            Users
          </NavLink>
          <ArrowForwardIosIcon fontSize="inherit" htmlColor="#034A93" />
        </Box>
      )}
      {modalType === "edit" && userManagementStore.isUserLoading ? (
        <Loader />
      ) : (
        <>
          <UserContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <InputLabelText>
                  First Name <RequiredText>*</RequiredText>
                </InputLabelText>
                <InputTextField
                  id="first_name"
                  variant="outlined"
                  size="small"
                  placeholder="First Name"
                  onChange={handleInput}
                  value={userData.first_name}
                  inputProps={{ "data-testid": "first-name", maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <InputLabelText>
                  Last Name <RequiredText>*</RequiredText>
                </InputLabelText>
                <InputTextField
                  id="last_name"
                  variant="outlined"
                  size="small"
                  placeholder="Last Name"
                  onChange={handleInput}
                  value={userData.last_name}
                  inputProps={{ "data-testid": "last-name", maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <InputLabelText>
                  Email <RequiredText>*</RequiredText>
                </InputLabelText>
                <InputTextField
                  id="email"
                  variant="outlined"
                  size="small"
                  placeholder="Email"
                  onChange={handleInput}
                  value={userData.email}
                  disabled={modalType === "edit" ? true : false}
                  inputProps={{ "data-testid": "email", maxLength: 256 }}
                  helperText={!emailError ? "" : IN_VALID_EMAIL}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <InputLabelText>
                  Title <RequiredText>*</RequiredText>
                </InputLabelText>
                <InputTextField
                  id="title"
                  variant="outlined"
                  size="small"
                  placeholder="Title"
                  onChange={handleInput}
                  value={userData.title}
                  inputProps={{ "data-testid": "title-data", maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <InputLabelText>Role</InputLabelText>
                <Autocomplete
                  id="role"
                  options={roles}
                  autoHighlight
                  onChange={handleRole}
                  value={userData.role}
                  size="small"
                  isOptionEqualToValue={(option: Role, value: Role) => option.id === value.id}
                  getOptionLabel={(option) => option.role_name || ""}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.role_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} className="text-field" data-testid="select-role" />
                  )}
                  data-testid="autocomplete-select"
                />
              </Grid>
            </Grid>
          </UserContainer>
          {modalType === "edit"
            ? selectedUser.role?.role_name.toLowerCase() != UserRole.SUPER_ADMIN && (
                <Layouts isDisableVirtualization={isDisableVirtualization} />
              )
            : null}
        </>
      )}
      <Box sx={{ display: "flex", gap: "15px" }}>
        <SaveUserDiv
          data-testid="save-data"
          loading={userManagementStore.isUpdateLoading || isLoading}
          variant="contained"
          onClick={submitUserData}
          disabled={isDisabled}
        >
          <SaveUserIcon />
          Save
        </SaveUserDiv>
        <SaveUserDiv
          data-testid="cancel"
          onClick={() => {
            handleModalEmpty();
            navigate(USER_MANAGEMENT);
          }}
          variant="outlined"
          sx={{ background: "white !important", color: "#034a93" }}
        >
          Cancel
        </SaveUserDiv>
      </Box>
    </Container>
  );
}

export default CreateUser;
