import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "src/slices/shared/layouts";
import { SharedLayoutsStore } from "src/slices/shared/layouts/layouts-interface";
import { getUniqueValuesFromArray } from "src/utils/layout-method";
import { getLayoutsFilterThunk } from "src/slices/shared/layouts/layouts-thunks";
import { FilterLayout } from "src/interfaces/layouts.interface";

export const sharedLayoutsSlice = createSlice({
  name: "sharedLayoutsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get filter layouts
    builder.addCase(
      getLayoutsFilterThunk.fulfilled,
      (state: SharedLayoutsStore, { payload }: PayloadAction<{ data: FilterLayout }>) => {
        const {
          associatedDeviceTypes,
          associatedTracks,
          associatedTypes,
          associatedVisitTypes,
          formCategories,
          associatedAnatomy,
        } = payload.data;

        state.isLoading = false;
        state.filters.associatedDeviceTypes = getUniqueValuesFromArray(associatedDeviceTypes);
        state.filters.associatedTracks = getUniqueValuesFromArray(associatedTracks);
        state.filters.associatedTypes = getUniqueValuesFromArray(associatedTypes);
        state.filters.associatedVisitTypes = getUniqueValuesFromArray(associatedVisitTypes);
        state.filters.formCategories = getUniqueValuesFromArray(formCategories);
        state.filters.associatedAnatomy = getUniqueValuesFromArray(associatedAnatomy);
      }
    );
    builder.addCase(getLayoutsFilterThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLayoutsFilterThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
