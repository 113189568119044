import styled from "styled-components";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LoadingButton from "@mui/lab/LoadingButton";

interface PreviewProps {
  isPreviewLayout: boolean;
}

export const StyledFooterLayout = styled.div<PreviewProps>`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 1rem;
  margin-left: -2rem;
  background: #f5f5f5;
  padding-left: 2rem;
  position: ${(props) => (props.isPreviewLayout ? "" : "fixed")};
  bottom: 0;
  width: 100%;
  padding-top: ${(props) => (props.isPreviewLayout ? "" : "1rem")};
  padding-bottom: ${(props) => (props.isPreviewLayout ? "" : "1rem")};
`;

export const StyledVisibilityIcon = styled(RemoveRedEyeOutlinedIcon)`
  color: rgb(3, 74, 147);
`;

export const StyledSelectButtonHide = styled(LoadingButton)`
  display: flex;
  gap: 0.4rem;
  padding: 8px 20px;
  text-transform: none;
  background: #034a93;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;
