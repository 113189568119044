import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutCountCard from "src/pages/facility-forms-management/dashboard/components/layout-count-card";
import {
  DashboardContainer,
  HeadingText,
  CountsContainer,
  ChangeLogContainer,
} from "src/pages/facility-forms-management/dashboard/styles";
import { AppDispatch } from "src/redux/store";
import { dashboardLayoutCountSelector } from "src/slices/facility-forms-management/dashboard";
import { getLayoutCountsThunk } from "src/slices/facility-forms-management/dashboard/dashboard-thunks";
import ChangeLog from "src/pages/facility-forms-management/dashboard/components/changelog";
import Filter from "src/pages/facility-forms-management/dashboard/components/filter";
import ValidateImpersonateUser from "src/shared/hoc/validate-impersonate-user";

function Dashboard() {
  const dispatch = useDispatch<AppDispatch>();

  const counts = useSelector(dashboardLayoutCountSelector);

  useEffect(() => {
    dispatch(getLayoutCountsThunk());
  }, [dispatch]);

  return (
    <DashboardContainer>
      <HeadingText>Facility Layout Management</HeadingText>
      <CountsContainer>
        <Grid container spacing={2} alignItems="stretch" height={"100%"}>
          {Object.keys(counts).map((key) => {
            const countObject = counts[key as keyof typeof counts];
            if (typeof countObject === "boolean") return null;
            return (
              <Grid item xs={12} md={4} key={countObject.label}>
                <LayoutCountCard
                  count={countObject.count}
                  text={countObject.label}
                  route={countObject.route}
                />
              </Grid>
            );
          })}
        </Grid>
      </CountsContainer>
      <ChangeLogContainer>
        <Grid container alignItems="stretch" height={"100%"}>
          <Grid item xs={12} md={9} sx={{ borderRight: 1 }}>
            <Box>
              <ChangeLog></ChangeLog>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              <Filter></Filter>
            </Box>
          </Grid>
        </Grid>
      </ChangeLogContainer>
    </DashboardContainer>
  );
}

export default ValidateImpersonateUser(Dashboard);
