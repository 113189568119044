/* eslint-disable @typescript-eslint/no-unused-vars */
import { Label } from "@mui/icons-material";
import { Box, FormControl, Select, SelectChangeEvent } from "@mui/material";
import React from "react";

function Filter() {
  return (
    <>
      <Box sx={{ display: "flex", p: "1.5rem", flexDirection: "column" }}>
        <FormControl sx={{ m: 1 }}>
          <Select displayEmpty inputProps={{ "aria-label": "Without label" }}></Select>
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <Select displayEmpty inputProps={{ "aria-label": "Without label" }}></Select>
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <Select displayEmpty inputProps={{ "aria-label": "Without label" }}></Select>
        </FormControl>
      </Box>
    </>
  );
}

export default Filter;
