import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "src/slices/facility-forms-management/impersonation";
import {
  getFacilityUsersThunk,
  addZeusUserThunk,
  impersonateUserThunk,
} from "src/slices/facility-forms-management/impersonation/impersonation-thunks";
import { userImpersonationStore } from "src/slices/facility-forms-management/impersonation/impersonation-interface";
import { FacilityUser, FacilityUsersInterface } from "src/interfaces/user-impersonation.interface";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";

export const userImpersonationSlice = createSlice({
  name: "userImpersonationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getFacilityUsersThunk.fulfilled,
      (state: userImpersonationStore, { payload }: PayloadAction<FacilityUsersInterface>) => {
        state.users = payload.data.map((item) => {
          item.isActive = true;
          item.name = getConvertedUpperCaseString(
            `${item.firstName} ${item.middleName} ${item.lastName}`
          )
            .replace("_", "")
            .replace(/ +(?= )/g, "");
          return item;
        });
        state.isLoading = false;
      }
    );
    builder.addCase(getFacilityUsersThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFacilityUsersThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      addZeusUserThunk.fulfilled,
      (
        state: userImpersonationStore,
        {
          payload,
        }: PayloadAction<{
          user: FacilityUser;
          message: string;
          statusCode: string;
        }>
      ) => {
        state.users = state.users.map((user) => {
          if (user.userID === payload.user.userID) {
            user.isAdmin = true;
            return user;
          }
          return user;
        });
        state.isAddZeusUserLoading = false;
      }
    );
    builder.addCase(addZeusUserThunk.pending, (state) => {
      state.isAddZeusUserLoading = true;
    });
    builder.addCase(addZeusUserThunk.rejected, (state) => {
      state.isAddZeusUserLoading = false;
    });
    builder.addCase(impersonateUserThunk.fulfilled, (state: userImpersonationStore) => {
      state.isImpersonationLoading = false;
    });

    builder.addCase(impersonateUserThunk.pending, (state) => {
      state.isImpersonationLoading = true;
    });
    builder.addCase(impersonateUserThunk.rejected, (state) => {
      state.isImpersonationLoading = false;
    });
  },
});
