import { Box } from "@mui/system";
import UserListIcon from "src/shared/icons/users-list.icon";
import styled from "styled-components";

export const EmptyListDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  height: calc(100vh - 300px);
  justify-content: center;
`;

export const SubHeading = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: rgba(0, 10, 20, 0.4);
  line-height: 24px;
  text-align: center;
`;

export const Heading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #a3a6aa;
  margin-bottom: 0;
`;

export const EmptyUsersList = styled(UserListIcon)``;

export const UserIconDIv = styled.div`
  position: relative;
`;

export const CountDiv = styled.div`
  position: absolute;
  background: #034a93;
  height: 2rem;
  width: 2rem;
  color: white;
  border-radius: 50%;
  bottom: 8px;
  right: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
