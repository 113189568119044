import { Box } from "@mui/material";
import styled from "styled-components";

interface StyledSelectFacilityProps {
  isfacility: string;
}

export const StyledHeading = styled.h1`
  text-align: center;
`;

export const StyledArrow = styled(Box)<StyledSelectFacilityProps>`
  position: absolute;
  right: -10px;
  z-index: 99;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${(props) => (props.isfacility === "true" ? "#034A93" : "#EBF4FF")};
`;

export const StyledTabDiv = styled(Box)`
  cursor: pointer;
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
`;

export const StyledSelectTab = styled(Box)<StyledSelectFacilityProps>`
  position: relative;
  background-color: ${(props) => (props.isfacility === "true" ? "#034A93" : "#EBF4FF")};
  flex: 1;
  padding: 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: ${(props) => (props.isfacility === "true" ? "white" : "#034A93")};
  border-radius: 0.3rem;
`;
