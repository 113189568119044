import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { User } from "src/interfaces/user.interface";

import { getLoggedInUser, getCurrentLoggedInUser, logoutUser } from "src/services/user-services";
import { getConvertedSentenceToUpperCase } from "src/utils/convert-strings-case";

export const getLoggedInUserThunk = createAsyncThunk(
  "getLoggedInUserThunk",
  async (loginData: User, { rejectWithValue }) => {
    try {
      const res = await getLoggedInUser({
        email: loginData.email,
        password: loginData.password,
        idpToken: loginData.idpToken,
      });
      res.data = {
        ...res.data,
      };
      if (loginData.idpToken != "") {
        res.data = {
          ...res.data,
          isIDPToken: true,
          data: {
            token: loginData.idpToken,
          },
        };
      }
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentLoggedInUserThunk = createAsyncThunk(
  "getCurrentLoggedInUserThunk",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCurrentLoggedInUser();
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
// logOutUser

export const logoutUserThunk = createAsyncThunk(
  "logoutUserThunk",
  async (_, { rejectWithValue }) => {
    try {
      const res = await logoutUser();
      return res.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(getConvertedSentenceToUpperCase(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  }
);
