import React, { useEffect, useState } from "react";
import {
  ActionsDiv,
  AddUserDiv,
  AddUserIcon,
  DeleteUserIconDiv,
  EditUserIconDiv,
  HeaderDiv,
  Heading,
  RemoveIconDiv,
  SearchIconDiv,
  UserButtonDiv,
  UserManagementDiv,
} from "src/pages/opie-forms-management/user-management/styles";
import EditIcon from "src/shared/icons/edit-icon";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DataGridPro, GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dataGridStyles } from "src/constants/datagrid-styles";
import { CREATE_USER } from "src/constants/route-constants";
import { User } from "src/interfaces/user-management.interface";
import { AppDispatch } from "src/redux/store";
import DeleteUser from "src/shared/delete-confirmation-modal";
import EmptyList from "src/shared/empty-list";
import DeleteIcon from "src/shared/icons/delete-icon";
import SortingIcon from "src/shared/icons/sorting-icon";
import Switch from "src/shared/switch";
import {
  userManagementData,
  userManagementSelector,
} from "src/slices/opie-forms-management/user-management";
import {
  deleteUserThunk,
  getUsersThunk,
  updateUserStatusThunk,
} from "src/slices/opie-forms-management/user-management/user-management-thunks";
import { getConvertedUpperCaseString } from "src/utils/convert-strings-case";
import UserListIcon from "src/shared/icons/users-list.icon";
import DeleteCrossIcon from "src/shared/icons/delete-cross-icon";
import DeleteFormIcon from "src/shared/icons/delete-form-icon";

interface Props {
  isDisableVirtualization?: boolean;
}

const UserManagement = ({ isDisableVirtualization }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userList = useSelector(userManagementSelector);
  const userData = useSelector(userManagementData);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [deleteModalType, setDeleteModalType] = useState<string>("");
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    dispatch(getUsersThunk());
  }, [dispatch]);

  useEffect(() => {
    setUsers(userList);
  }, [userList]);

  const handleDeleteModal = (open: boolean, type: string) => {
    setDeleteModalType(type);
    setIsDeleteModal(open);
  };

  const handleEdit = (data: User) => {
    setSelectedUser(data);
  };

  const handleDeleteUser = () => {
    setIsLoading(true);
    dispatch(deleteUserThunk(selectedUser?.id as string)).finally(() => {
      setIsLoading(false);
    });
    handleDeleteModal(false, "");
  };

  const handleStatus = (id: string, status: boolean) => {
    dispatch(updateUserStatusThunk({ id: id as string, status: !status }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value === "") {
      setUsers(userList);
    }
  };

  const filterUsersList = () => {
    const value = searchValue.toLowerCase();
    const filteredUserList = users.filter(
      (user) =>
        user.last_name.toLowerCase().includes(value) ||
        user.first_name.toLowerCase().includes(value) ||
        user.role.role_name.toLowerCase().includes(value)
    );
    setUsers(filteredUserList);
  };

  const handleFilterData = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === "Enter" && searchValue) {
      filterUsersList();
    }
  };

  const deleteUserIcon = () => {
    return (
      <>
        <UserListIcon />
        <RemoveIconDiv>
          {deleteModalType === "deactivate" ? <DeleteFormIcon /> : <DeleteCrossIcon />}
        </RemoveIconDiv>
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const rowData: User = params.row;
        const isChecked: boolean = rowData.is_active;
        return (
          <Switch
            handleFunction={handleStatus}
            checked={isChecked}
            rowData={{ id: rowData.id as string, is_active: rowData.is_active }}
          />
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData: User = params.row;
        return (
          <p data-testid="render-role">
            {rowData.role.role_name === "super_admin"
              ? "Super Admin"
              : getConvertedUpperCaseString(rowData.role.role_name)}
          </p>
        );
      },
      sortingOrder: ["asc", "desc"],
      sortComparator: (roleA: { role_name: string }, roleB: { role_name: string }) => {
        return roleA.role_name.localeCompare(roleB.role_name);
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const rowData: User = params.row;
        return (
          <ActionsDiv>
            <EditUserIconDiv
              data-testid="editRow"
              onClick={() => {
                handleEdit(rowData);
                navigate(`/users/${rowData.id}`);
              }}
            >
              <EditIcon />
            </EditUserIconDiv>
            <DeleteUserIconDiv
              data-testid="delete-user"
              onClick={() => {
                setSelectedUser(rowData);
                handleDeleteModal(true, "delete");
              }}
            >
              <DeleteIcon />
            </DeleteUserIconDiv>
          </ActionsDiv>
        );
      },
    },
  ];
  return (
    <UserManagementDiv>
      <HeaderDiv>
        <Heading>Users</Heading>
        <UserButtonDiv>
          <AddUserDiv
            variant="contained"
            data-testid="create-user"
            onClick={() => {
              navigate(CREATE_USER);
            }}
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              height: "3rem",
            }}
          >
            <AddUserIcon />
            Create User
          </AddUserDiv>
        </UserButtonDiv>
      </HeaderDiv>

      <TextField
        id="search"
        className="text"
        onChange={handleSearch}
        onKeyPress={handleFilterData}
        variant="outlined"
        placeholder="Search"
        size="small"
        sx={{
          background: "white",
          width: "100%",
          margin: "1rem 0",
          borderRadius: "6px",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIconDiv onClick={filterUsersList}>
                <SearchRoundedIcon />
              </SearchIconDiv>
            </InputAdornment>
          ),
        }}
        inputProps={{ "data-testid": "content-input" }}
      />

      {!userData.isLoading && users.length <= 0 ? (
        <EmptyList
          heading={"OOPS! It’s Empty"}
          subHeading={"Looks like you haven’t created any users yet..."}
          handleNavigate={() => {
            navigate(CREATE_USER);
          }}
          buttonText="Create User"
        />
      ) : (
        <DataGridPro
          disableColumnMenu
          autoHeight
          rows={users}
          columns={columns}
          disableRowSelectionOnClick
          hideFooterRowCount
          sx={dataGridStyles}
          loading={userData.isLoading}
          slots={{
            columnSortedAscendingIcon: SortingIcon,
            columnSortedDescendingIcon: SortingIcon,
          }}
          disableVirtualization={isDisableVirtualization ? true : false}
          sortingOrder={["asc", "desc"]}
          onCellClick={(params: GridCellParams) => {
            if (params.colDef.field === "actions" || params.colDef.field === "status") {
              return;
            }
            navigate(`/users/${params?.id as string}`);
          }}
        />
      )}

      {isDeleteModal && (
        <DeleteUser
          open={isDeleteModal}
          handleOpen={handleDeleteModal}
          heading={"Delete User"}
          type={deleteModalType}
          subHeading={"Are you sure you want to delete"}
          handleDeleteUser={handleDeleteUser}
          isLoading={isLoading}
          name={`${selectedUser?.first_name as string} ${selectedUser?.last_name}` as string}
          icon={deleteUserIcon()}
        />
      )}
    </UserManagementDiv>
  );
};

export default UserManagement;
