import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FilterLayoutModal from "src/pages/facility-forms-management/layout-details/components/filter-layout-modal";
import {
  StyledLayoutButton,
  StyledRightColumn,
  StyledSelectButtonHide,
} from "src/pages/facility-forms-management/layout-details/components/toolbar/styles";
import HideFormModal from "src/pages/facility-forms-management/layout-details/components/hide-form-modal";
import { DelegateInterface } from "src/interfaces/layout-details.interface";
import { updateHideFormLayout } from "src/slices/facility-forms-management/layout-details/layout-detail-thunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import {
  filterSelector,
  layoutDetailsByIdSelector,
  layoutFieldDetailsByIdSelector,
  updateFilter,
} from "src/slices/facility-forms-management/layout-details";
import { uniquePreFilteredDelegates } from "src/pages/facility-forms-management/layout-details/components/fields-metadata/utils";
import ShareModal from "src/pages/facility-forms-management/shared/share-modal";

function Toolbar() {
  const layoutDetailData = useSelector(layoutDetailsByIdSelector);
  const fieldsData = useSelector(layoutFieldDetailsByIdSelector);
  const { allowSharing } = layoutDetailData;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];
  const filter = useSelector(filterSelector);
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [isHideForm, setIsHideForm] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const filteredDelegatesData = useMemo(
    () =>
      uniquePreFilteredDelegates({ ...layoutDetailData, fields: fieldsData }).map((delegate) => ({
        name: delegate,
        isSelected: false,
      })) as DelegateInterface[],
    [fieldsData, layoutDetailData]
  );

  useEffect(() => {
    if (!filter.isApplied) {
      dispatch(
        updateFilter({
          delegates: filter.isApplied ? filter.delegates : filteredDelegatesData,
          isRequired: false,
          isVisible: false,
          isApplied: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFilter = (isShow: boolean) => {
    setIsShowFilter(isShow);
  };

  const handleSelect = () => {
    return;
  };

  const handleClear = () => {
    dispatch(
      updateFilter({
        isApplied: false,
        delegates: filteredDelegatesData,
        isRequired: false,
        isVisible: false,
      })
    );
    return;
  };

  const handleShare = () => {
    if (allowSharing) {
      setShowShareModal(true);
    }
  };
  const toggleShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  const handleHideForm = async (open: boolean, isContinue?: boolean) => {
    const data = {
      ...layoutDetailData,
      isHidden: true,
      fields: fieldsData,
    };
    if (layoutDetailData.isPublished) {
      if (isContinue) {
        setIsHideForm(open);
        await dispatch(updateHideFormLayout(data));
        navigate(`/${pathName}`);
      }
      setIsHideForm(open);
      return;
    }
    await dispatch(updateHideFormLayout(data));
    navigate(`/${pathName}`);
  };

  const toolbarFilters = [
    {
      type: "filter",
      text: "Filters",
      actionFunction: handleFilter,
      icon: <FilterAltOutlinedIcon />,
      disabled: false,
    },
    {
      type: "select",
      text: "Select All",
      actionFunction: handleSelect,
      icon: "",
      disabled: false,
    },
    {
      type: "clear",
      text: "Clear All",
      actionFunction: handleClear,
      icon: "",
      disabled: false,
    },
    {
      type: "share",
      text: "Share",
      actionFunction: handleShare,
      icon: <ShareOutlinedIcon />,
      disabled: !allowSharing,
    },
  ];

  return (
    <StyledRightColumn item xs={12}>
      <Grid container spacing={1}>
        {toolbarFilters.map((button, index: number) => {
          return (
            <Grid item onClick={() => button.actionFunction(true)} key={index}>
              <StyledLayoutButton
                variant={filter.isApplied && button.type === "filter" ? "contained" : "outlined"}
                disabled={button.disabled}
                data-testid={`${button.type}`}
                bgcolor={filter.isApplied && button.type === "filter" ? "#034A93" : "white"}
                textColor={filter.isApplied && button.type === "filter" ? "white" : ""}
                border={"#034A93"}
              >
                {button.icon}
                {button.text}
              </StyledLayoutButton>
            </Grid>
          );
        })}
        <Grid item>
          <StyledSelectButtonHide
            variant="contained"
            onClick={() => handleHideForm(true, false)}
            data-testid="hide-form"
          >
            <VisibilityOffOutlinedIcon />
            Hide Form
          </StyledSelectButtonHide>
        </Grid>
      </Grid>
      {isShowFilter && <FilterLayoutModal open={isShowFilter} handleOpen={handleFilter} />}
      {isHideForm && layoutDetailData.isPublished && (
        <HideFormModal open={isHideForm} handleOpen={handleHideForm} />
      )}
      <ShareModal
        layoutId={layoutDetailData.id}
        open={showShareModal}
        handleClose={toggleShareModal}
      />
    </StyledRightColumn>
  );
}

export default Toolbar;
