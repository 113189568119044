import React, { ReactElement, useContext } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {
  PageDiv,
  CurrentPageText,
  CountDiv,
  CurrentPageDiv,
} from "src/shared/pagination/page/styles";
import pageLimitContext from "src/context-api";

interface Props {
  paginationData: {
    endNumber: number;
    totalSetCount: number;
    currentPage: number;
    handlePreviousPage: () => void;
    handleNextPage: () => void;
  };
  styles?: {
    [key: string]: string | number;
  };
}

export default function CurrentPage({ paginationData, styles }: Props): ReactElement {
  const { endNumber, totalSetCount, currentPage, handlePreviousPage, handleNextPage } =
    paginationData;
  const { limit } = useContext(pageLimitContext);

  return (
    <PageDiv style={{ ...styles }}>
      <CountDiv>
        {(currentPage - 1) * limit + 1}-
        {totalSetCount <= currentPage * limit ? totalSetCount : currentPage * limit} out of{" "}
        {totalSetCount}
      </CountDiv>
      <CurrentPageDiv>
        <ChevronLeftIcon
          data-testid="previous-page"
          sx={{ color: currentPage === 1 ? "#DEDEDF" : "#034A93", cursor: "pointer" }}
          onClick={handlePreviousPage}
        />
        <CurrentPageText>{currentPage}</CurrentPageText>
        <ChevronRightIcon
          data-testid="next-page"
          sx={{ color: currentPage === endNumber ? "#DEDEDF" : "#034A93", cursor: "pointer" }}
          onClick={handleNextPage}
        />
      </CurrentPageDiv>
    </PageDiv>
  );
}
