import styled from "styled-components";
import { InputLabel, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Done";
import LoadingButton from "@mui/lab/LoadingButton";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const UserContainer = styled.div`
  background: white;
  padding: 1rem;
`;
export const InputLabelText = styled(InputLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #011428;
`;
export const InputTextField = styled(TextField)`
  width: 100%;
  white-space: pre-line;
`;
export const RequiredText = styled.sup`
  color: #034a93;
`;

export const SaveUserDiv = styled(LoadingButton)`
  text-transform: none;
  background: #034a93;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  height: 56px;
`;

export const SaveUserIcon = styled(SaveIcon)`
  margin-right: 0.5rem;
`;
