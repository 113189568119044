import React from "react";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { renderValues } from "src/utils/layout-method";
import { MenuProps, StyledSelect } from "src/shared/ui/select/styles";
import { Options } from "src/interfaces/layouts.interface";
import { InputLabel, OutlinedInput } from "@mui/material";

interface SelectProps {
  handleChange: (event: SelectChangeEvent<string | string[] | unknown>) => void;
  value: string[];
  options: Options[];
  multiple?: boolean;
  disabled?: boolean;
  label?: string;
  type: string;
  customRenderValue?: (value: unknown) => React.ReactNode;
  handleChecked: (item: string, type: string) => boolean;
}

export default function CustomSelect(props: SelectProps) {
  const {
    handleChange,
    value,
    multiple = false,
    options,
    label,
    disabled = false,
    handleChecked,
    type,
    customRenderValue = renderValues,
  } = props;
  return (
    <div>
      <FormControl sx={{ m: 1, width: "100%" }}>
        {value.length <= 0 && <InputLabel id="demo-multiple-name">{label}</InputLabel>}
        <StyledSelect
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple={multiple}
          value={value || []}
          onChange={handleChange}
          input={<OutlinedInput />}
          disabled={disabled}
          name="associatedType"
          IconComponent={KeyboardArrowDownIcon}
          inputProps={{ "data-testid": "layout-device" }}
          renderValue={customRenderValue}
          MenuProps={MenuProps}
        >
          {options.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={handleChecked(item.id, type)} data-testid={"published-checkbox"} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
}
